import {
  observable,
  makeObservable,
  action,
  computed,
  runInAction
} from 'mobx';
import UserService from 'src/services/user';
import UserError from 'src/services/error/models/user';
import AuthService from 'src/services/authenticate';

export default class InputNameViewModel {
  @observable name = '';
  @observable isAwait = false;
  @observable isShowResMessage = false;
  @observable resMessage = 'Res 錯誤';

  @observable onCheck = {
    nickname: false
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  @computed
  get validName() {
    return !!this.name.trim();
  }

  @computed
  get isDisabledSubmit() {
    return !this.validName || this.isAwait;
  }

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  @action
  setName = (event) => {
    this.name = event.target.value;
    this.isShowResMessage = false;
  };

  @action
  setOnCheck = () => {
    this.onCheck.nickname = true;
  }

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 返回.
  handlerPrev = async () => {
    try {
      await AuthService.logout();

      runInAction(() => {
        // 清除 profile.
        this.props.setSaveProfile(null);
        // 返回登入.
        this.props.handlerToLogin();
      });
    } catch (error) {
      runInAction(() => {
        console.log('InputNameViewModel', 'handlerPrev', error);
      });
    }
  };

  @action
  handlerNext = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isDisabledSubmit) {
      // 異步中.
      this.isAwait = true;

      const bodyFormData = new FormData();
      const name = this.name.trim();

      bodyFormData.append('nickname', name);

      try {
        const res = await UserService.updateProfile({
          id: this.props.saveProfile.id,
          data: bodyFormData
        });

        runInAction(() => {
          // 保存 profile
          this.props.setSaveProfile(res);
          // 前往下一步.
          this.props.handlerNextStep();
          // 異步結束.
          this.isAwait = false;
        });
      } catch (error) {
        runInAction(() => {
          // 異步結束.
          this.isAwait = false;
          // 顯示 res 錯誤.
          UserError.updateProfile(error);
        });
      }
    }
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  @action
  didMount = () => {
    if (this.props.saveProfile && this.props.saveProfile.nickname) {
      this.name = this.props.saveProfile.nickname;
    }
  };
}
