import dayjs from 'dayjs';

export default class CourseCard {
  constructor(data) {
    const {
      id,
      name,
      type,
      thumbnails,
      author,
      popularity,
      releaseAt,
      lastUpdatedAt,
      top,
      saleType,
      isFavorite,
      discount,
      isOwned
    } = data;

    const { startAt, endAt } = discount ?? {};

    this.id = id;
    this.name = name;
    this.type = type;
    this.thumbnail = thumbnails.web.url;
    this.authorName = author.name;
    this.lastUpdatedAt = Math.max(lastUpdatedAt, releaseAt);
    this.top = top;
    this.popularity = popularity;
    this.saleType = saleType;
    this.isFavorite = isFavorite;
    this.isDiscount = startAt ? dayjs().valueOf() > startAt && dayjs().valueOf() < endAt : false;
    this.isOwned = isOwned;
  }

  static fromRes(data) {
    return new CourseCard(data);
  }
}
