export default class UserTag {
  static fromRes(data) {
    return new UserTag(data);
  }

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }
}
