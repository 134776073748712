import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';

class InputViewModel {
  @observable isShowMessage = false;
  @observable isShowPassword = false;
  inputContainerRef = React.createRef();

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  @computed
  get isPassword() {
    return this.props.type === 'password';
  }

  @computed
  get getType() {
    if (this.props.type === 'email') {
      return 'email';
    }

    if (this.props.type === 'password') {
      return this.isShowPassword ? 'text' : 'password';
    }

    return 'text';
  }

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  @action
  handlerSetShowPassword=() => {
    this.isShowPassword = !this.isShowPassword;
  }

  @action
  handlerMessageShow = () => {
    this.isShowMessage = true;
  };

  @action
  handlerMessageHide = () => {
    this.isShowMessage = false;
  };
}

export default InputViewModel;
