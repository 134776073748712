import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import i18n from 'src/i18n';

import VideoIcon from 'src/assets/courseCard/videoIcon.svg';
import AudiobookIcon from 'src/assets/courseCard/audiobookIcon.svg';
import AudioIcon from 'src/assets/courseCard/audioIcon.svg';

import favIcon from 'src/assets/courseCard/favIcon.png';
import unfavIcon from 'src/assets/courseCard/unfavIcon.svg';
import viewIcon from 'src/assets/courseCard/viewIcon.svg';
import disk from 'src/assets/courseCard/disk.png';

import withProfile from 'src/components/withProfile';

import styles from './styles.module.scss';

@observer
class LearningCard extends React.Component {
  constructor(props) {
    super(props);
  }

  _renderTypeIcon = (type) => {
    switch (type) {
      case 'Video':
        return VideoIcon;
      case 'Audio':
        return AudioIcon;
      case 'Audiobook':
        return AudiobookIcon;
      default:
        return null;
    }
  }

  _renderLearnedText = (learningProgress) => {
    if (learningProgress === 0) {
      return i18n.t('tag_not_yet_learn');
    }
    if (learningProgress > 0 && learningProgress < 100) {
      return `${i18n.t('tag_learned')} ${learningProgress}%`;
    }

    return i18n.t('tag_finished');
  }

  render() {
    const { viewModel, profile, syncFavorite, section } = this.props;
    return (

      <Link
        to={`/course/${viewModel.id}/info`}
        className={clsx('resently-01 ', styles.learnedCard, styles.learnedCard_layout, 'block')}
      >
        <div className={clsx(styles.upperContainer, styles.upperContainer_layout)}>
          {
            viewModel.type === 'Video' && (
              <div className={clsx(styles.vThumbnailContainer, styles.vThumbnailContainer_layout)}>
                <img
                  src={viewModel.thumbnail}
                  alt={viewModel.title}
                  className={`${clsx(styles.vThumbnail, styles.vThumbnail_layout)} hover-zoom`}
                />
              </div>
            )
          }
          {
            viewModel.type === 'Audio' && (
              <>
                <div
                  style={{ '--src': `url(${disk})` }}
                  className={`${clsx(styles.disk, styles.disk_layout)} hover-zoom`}
                />
                <div className={clsx(styles.aThumbnailContainer, styles.aThumbnailContainer_layout)}>
                  <img
                    src={viewModel.thumbnail}
                    alt={viewModel.title}
                    className={`${clsx(styles.aThumbnail, styles.aThumbnail_layout)} hover-zoom`}
                  />
                </div>
              </>
            )
          }
          {
            viewModel.type === 'Audiobook' && (
              <div className={clsx(styles.abThumbnailContainer, styles.abThumbnailContainer_layout)}>
                <img
                  src={viewModel.thumbnail}
                  alt={viewModel.title}
                  className={clsx(styles.abThumbnail, styles.abThumbnail_layout, 'hover-zoom')}
                />
              </div>
            )
          }

          <div
            style={{ '--src': `url(${viewModel.isFavorite ? favIcon : unfavIcon})` }}
            className={clsx(styles.favIcon, styles.favIcon_layout, 'like')}
            onClick={(e) => viewModel.changeIsFavorite(e, profile?.id, syncFavorite, section)}
          />
          {
            section === 'Learned'
            && (
              <>
                {
                  (!viewModel.isRating && (viewModel.isOwned || (!viewModel.isPurchaseOnly && profile.group === 'Subscriber'))) && (
                    <div className={clsx(styles.notRating)}>
                      {i18n.t('tag_not_yet_rate')}
                    </div>
                  )
                }
              </>
            )
          }
        </div>

        <div className={clsx(styles.infoContainer, styles.infoContainer_layout)}>
          <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
            <div className={clsx(styles.titleContainer, styles.titleContainer_layout)}>
              <div style={{ '--src': `url(${this._renderTypeIcon(viewModel.type)})` }} className={clsx(styles.typeIcon, styles.typeIcon_layout)} />
              <h3 className={clsx(styles.title_box, styles.title_box_layout)}>
                <pre className={clsx(styles.title)}>
                  {viewModel.title}
                </pre>
              </h3>
            </div>

            <div className={clsx(styles.otherInfoContainer, styles.otherInfoContainer_layout)}>
              <div className={styles.otherLeft}>
                <h5 className={clsx(styles.authorName, styles.authorName_layout)}>
                  {viewModel.lector}
                </h5>
              </div>

              {
                section === 'Learned' && (
                  <div className={styles.otherRight}>
                    <h5 className={clsx(styles.progress, styles.progress_layout)}>
                      {this._renderLearnedText(viewModel.learningProgress)}
                    </h5>
                  </div>
                )
              }
              {
                section === 'Ranking' && (
                  <div className={styles.otherRight}>
                    <div
                      className={clsx(
                        styles.popularityContainer,
                        styles.popularityContainer_layout
                      )}
                    >
                      <div className={clsx(styles.popularityContainer_item)}>
                        <div
                          style={{ '--src': `url(${viewIcon})` }}
                          className={clsx(styles.viewIcon, styles.viewIcon_layout)}
                        />
                      </div>
                      <div className={clsx(styles.popularityContainer_spacer)} />
                      <h5 className={clsx(styles.popularity, styles.popularity_layout)}>
                        {viewModel.popularity}
                      </h5>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

          <div className={clsx(styles.leftBar, styles.leftBar_layout)} />
        </div>
      </Link>
    );
  }
}

LearningCard.propTypes = {
  viewModel: PropTypes.object,
  profile: PropTypes.object,
  syncFavorite: PropTypes.func,
  section: PropTypes.string
};

LearningCard.defaultProps = {
  viewModel: {},
  profile: {},
  syncFavorite: () => { },
  section: ''
};

export default withProfile(LearningCard, false);
