// 課程的播放進度, type 是課程的類型.
// 因為 view 相同, 故依照 Home deserializeLearned data model.
export default class LearnedType {
  static fromRes(data) {
    return new LearnedType(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    this.id = data.id;

    // 計算學習百分比.
    this.learned = data.learned;
    this.total = data.total;

    this.progress = data.learned / data.total ?? null;

    this.course = {
      id: data.course?.id,
      name: data.course?.name,
      type: data.course?.type,
      // 課程縮圖.
      thumbnail: data.course?.thumbnails?.web.url,
      // 作者.
      authorName: data.course?.author?.name,
      // 是否收藏.
      isFavorite: data.course?.isFavorite,
      // 是否評分.
      isRating: data.course?.isRating,

      isOwned: data.course?.isOwned,

      saleType: data.course?.saleType
    };
  }
}
