import { request, getHost } from './utils';

// 讀取通知列表.
export const getNotificationsList = ({ id, limit, anchor, token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/notification`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 打開通知.
export const putNotification = ({ id, nid, token }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/user/${id}/notification/${nid}/read`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// 讀取全部通知.
export const putNotificationAllRead = ({ id, token }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/user/${id}/notification/read`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

// 讀取公告列表.
export const getAnnouncementsList = ({ limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/announcement`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 讀取公告詳情.
export const getAnnouncement = ({ id }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/announcement/${id}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  return request(options);
};
