import defaultClassNames from 'react-input-range/src/js/input-range/default-class-names';

// style.
import styles from './style.module.scss';

const volumeLineClassNames = {
  ...defaultClassNames,

  inputRange: `inputRange ${styles.inputRange}`,
  // 已經播放的時間軸.
  activeTrack: `input-range__track input-range__track--active ${styles.selfVolumeActiveTrack}`,
  // 時間軸圓形按鈕.
  slider: `input-range__slider ${styles.selfVolumeSlider}`,
  // 灰色的線, 有 mouseDown 事件.
  track: `input-range__track input-range__track--background ${styles.selfVolumeTrack}`
};

export default volumeLineClassNames;
