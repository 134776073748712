import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AppStateContext } from 'src/stores';
import Loadable from 'react-loadable';

// import { ToastContainer } from 'react-toastify';
// import HeaderView from 'src/components/Header';
// import PlayerView from 'src/components/Player';

import LayoutView from 'src/components/Layout';

import HomePage from './HomePage';

import NotFoundPage from './NotFoundPage';
import PagesRootViewModel from './viewModel';

import AccountSetting from './FAQPage/components/AccountSetting';
import CoursePurchase from './FAQPage/components/CoursePurchase';
import PointRecharge from './FAQPage/components/PointRecharge';
import OrderRelated from './FAQPage/components/OrderRelated';
import CouponGifts from './FAQPage/components/CouponGifts';
import Subscriptions from './FAQPage/components/Subscriptions';
import CourseLearning from './FAQPage/components/CourseLearning';
import UpdateIssue from './FAQPage/components/UpdateIssue';
import MomoPurchase from './FAQPage/components/MomoPurchase';
import ContactUs from './FAQPage/components/ContactUs';

import LoadingPage from './LoadingPage';


@observer
class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = new PagesRootViewModel();
  }

  // NOTE: landing
  _loadLandingPage = Loadable({
    loader: () => import('./SubscriptionPage'),
    loading: LoadingPage
  })

  // NOTE: about-us
  _loadAboutUsPage = Loadable({
    loader: () => import('./AboutUsPage'),
    loading: LoadingPage
  })
  // NOTE: list page
  _loadListPage = Loadable({
    loader: () => import('./ListPage'),
    loading: LoadingPage
  })
  // NOTE: list search
  _loadSearchPage = Loadable({
    loader: () => import('./SearchPage'),
    loading: LoadingPage
  })

  _loadSearchByTypePage = Loadable({
    loader: () => import('./SearchByTypePage'),
    loading: LoadingPage
  })

  // > NOTE: module page

  _loadModulePage = Loadable({
    loader: () => import('./ModulePage'),
    loading: LoadingPage
  })

  // NOTE: course
  _loadCoursePage = Loadable({
    loader: () => import('./CoursePage'),
    loading: LoadingPage
  })

  _loadInfoSection = Loadable({
    loader: () => import('./CoursePage/components/InfoSection'),
    loading: LoadingPage
  })

  _loadChapterSection = Loadable({
    loader: () => import('./CoursePage/components/ChapterSection'),
    loading: LoadingPage
  })

  _loadCommentSection = Loadable({
    loader: () => import('./CoursePage/components/CommentSection'),
    loading: LoadingPage
  })

  // NOTE: manuscript
  _loadManuscriptPage = Loadable({
    loader: () => import('./ManuscriptPage'),
    loading: LoadingPage
  })

  // NOTE: purchase
  _loadPurchasePage = Loadable({
    loader: () => import('./PurchasePage'),
    loading: LoadingPage
  })

  _loadPurchaseResultPage = Loadable({
    loader: () => import('./PurchaseResultPage'),
    loading: LoadingPage
  })

  // > NOTE: favorite
  _loadFavoritePage = Loadable({
    loader: () => import('./FavoritePage'),
    loading: LoadingPage
  })

  // NOTE: profile
  _loadProfilePage = Loadable({
    loader: () => import('./ProfilePage'),
    loading: LoadingPage
  })

  _loadBasicInfoSection = Loadable({
    loader: () => import('./ProfilePage/component/BasicInfo'),
    loading: LoadingPage
  })

  _loadSubscriptionStatus = Loadable({
    loader: () => import('./ProfilePage/component/SubscriptionStatus'),
    loading: LoadingPage
  })

  _loadCouponView = Loadable({
    loader: () => import('./ProfilePage/component/Coupon'),
    loading: LoadingPage
  })

  _loadRedeemView = Loadable({
    loader: () => import('./ProfilePage/component/Redeem'),
    loading: LoadingPage
  })

  _loadPurchaseView = Loadable({
    loader: () => import('./ProfilePage/component/Purchase'),
    loading: LoadingPage
  })

  _loadLearnedView = Loadable({
    loader: () => import('./ProfilePage/component/Learned'),
    loading: LoadingPage
  })

  _loadInterestsView = Loadable({
    loader: () => import('./ProfilePage/component/Interests'),
    loading: LoadingPage
  })

  // NOTE: for webview use
  _loadForWebViewManuscript = Loadable({
    loader: () => import('./ForWebViewManuscript'),
    loading: LoadingPage
  })

  _loadForWebViewPdf = Loadable({
    loader: () => import('./ForWebViewPdf'),
    loading: LoadingPage
  })

  // NOTE: preview
  _loadCourseInfoPreview = Loadable({
    loader: () => import('./PreviewPages/CourseInfoPreview'),
    loading: LoadingPage
  })

  _loadManuscriptPreview = Loadable({
    loader: () => import('./PreviewPages/ManuscriptPreview'),
    loading: LoadingPage
  })

  _loadAnnouncementPreview = Loadable({
    loader: () => import('./PreviewPages/AnnouncementPreview'),
    loading: LoadingPage
  })

  // NOTE: policy
  _loadPrivatePolicy = Loadable({
    loader: () => import('./PrivatePolicy'),
    loading: LoadingPage
  })

  _loadTermOfUse = Loadable({
    loader: () => import('./TermOfUsePage'),
    loading: LoadingPage
  })

  _loadFAQPage = Loadable({
    loader: () => import('./FAQPage'),
    loading: LoadingPage
  })


  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<LayoutView />}>
            <Route
              path="/list/:type"
              element={<this._loadListPage context={this.context} />}
            />

            <Route
              path="/bookList/:lid"
              element={<this._loadModulePage context={this.context} />}
            />

            <Route
              path="/search"
              element={<this._loadSearchPage context={this.context} />}
            />

            <Route
              path="/search/:type"
              element={<this._loadSearchByTypePage context={this.context} />}
            />

            <Route
              path="/course/:cid"
              element={(
                <this._loadCoursePage context={this.context} />
              )}
            >
              <Route
                index
                element={<Navigate to="info" replace />}
              />

              <Route
                path="info"
                element={<this._loadInfoSection />}
              />

              <Route
                path="chapter"
                element={<this._loadChapterSection />}
              />

              <Route
                path="comment"
                element={<this._loadCommentSection />}
              />

              <Route
                path="*"
                element={<Navigate to="info" replace />}
              />

            </Route>

            <Route
              path="/course/:cid/chapter/:tid"
              element={<this._loadManuscriptPage context={this.context} />}
            />

            <Route
              path="/purchase/:cid"
              element={<this._loadPurchasePage context={this.context} />}
            />

            <Route
              path="/purchase-result"
              element={<this._loadPurchaseResultPage context={this.context} />}
            />

            {/* 我的收藏. */}
            <Route
              path="/favorite"
              element={<this._loadFavoritePage context={this.context} />}
            />

            {/* 訂閱. */}
            <Route
              path="/subscription"
              element={<this._loadLandingPage />}
            />

            {/* 會員中心. */}
            <Route
              expect
              path="/profile"
              element={<this._loadProfilePage context={this.context} />}
            >
              {/* 會員資料. */}
              <Route
                path="information"
                element={<this._loadBasicInfoSection context={this.context} />}
              />

              {/* 訂閱管理 */}
              <Route
                path="subscription-status"
                element={<this._loadSubscriptionStatus context={this.context} />}
              />

              {/* 優惠卷. */}
              <Route
                path="coupon"
                element={<this._loadCouponView context={this.context} />}
              />

              {/* 兌換. */}
              <Route
                path="redeem"
                element={<this._loadRedeemView context={this.context} />}
              />

              {/* 訂單. */}
              <Route
                path="purchase"
                element={<this._loadPurchaseView context={this.context} />}
              />

              {/* 學習履歷. */}
              <Route
                path="learned"
                element={<this._loadLearnedView context={this.context} />}
              />

              {/* 興趣與喜好. */}
              <Route
                path="interests"
                element={<this._loadInterestsView context={this.context} />}
              />

              {/* 會員資料. */}
              <Route
                path=""
                element={<this._loadBasicInfoSection context={this.context} />}
              />

              {/* 會員資料. */}
              <Route
                path="*"
                element={<this._loadBasicInfoSection context={this.context} />}
              />
            </Route>
            <Route path="/private-policy" element={<this._loadPrivatePolicy />} />
            <Route path="/term-of-use" element={<this._loadTermOfUse />} />
            <Route path="/faq" element={<this._loadFAQPage />} isMobile={false}>
              <Route
                index
                element={<Navigate to="account" replace />}
              />
              <Route path="account" element={<AccountSetting />} isMobile={false} />
              <Route path="purchase" element={<CoursePurchase />} isMobile={false} />
              <Route path="point" element={<PointRecharge />} isMobile={false} />
              <Route path="order" element={<OrderRelated />} isMobile={false} />
              <Route path="gift" element={<CouponGifts />} isMobile={false} />
              <Route path="subscription" element={<Subscriptions />} isMobile={false} />
              <Route path="learn" element={<CourseLearning />} isMobile={false} />
              <Route path="app-update" element={<UpdateIssue />} isMobile={false} />
              <Route path="momo" element={<MomoPurchase />} isMobile={false} />
              <Route path="contact-us" element={<ContactUs />} isMobile={false} />
              <Route
                path="*"
                element={<Navigate to="account" replace />}
              />
            </Route>
            <Route path="/about-us" element={<this._loadAboutUsPage />} />

            <Route path="/" element={<HomePage context={this.context} />} />
            <Route path="/*" element={<Navigate to="/" replace />} />

            <Route path="*" element={<NotFoundPage context={this.context} />} />
          </Route>
          {/* NOTE: for web view / preview routes without header/footer. */}
          <Route
            path="/manuscript"
            element={<this._loadForWebViewManuscript context={this.context} />}
          />
          <Route
            path="/app-pdf"
            element={<this._loadForWebViewPdf context={this.context} />}
          />
          <Route
            path="/preview-course"
            element={<this._loadCourseInfoPreview />}
          />

          <Route
            path="/preview-chapter"
            element={<this._loadManuscriptPreview />}
          />
          <Route
            path="/preview-announcement"
            element={<this._loadAnnouncementPreview />}
          />

          <Route
            path="/webview-private-policy"
            element={<this._loadPrivatePolicy />}
          />

          <Route path="/webview-faq" element={<this._loadFAQPage />} isMobile>
            <Route
              index
              element={<Navigate to="account" replace />}
            />
            <Route path="account" element={<AccountSetting />} isMobile />
            <Route path="purchase" element={<CoursePurchase />} isMobile />
            <Route path="point" element={<PointRecharge />} isMobile />
            <Route path="order" element={<OrderRelated />} isMobile />
            <Route path="gift" element={<CouponGifts />} isMobile />
            <Route path="subscription" element={<Subscriptions />} isMobile />
            <Route path="learn" element={<CourseLearning />} isMobile />
            <Route path="app-update" element={<UpdateIssue />} isMobile />
            <Route path="momo" element={<MomoPurchase />} isMobile />
            <Route path="contact-us" element={<ContactUs />} isMobile />
            <Route
              path="*"
              element={<Navigate to="account" replace />}
            />
          </Route>
          <Route
            path="/webview-term-of-use"
            element={<this._loadTermOfUse isMobile />}
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default PagesRoot;

