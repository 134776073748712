export default class Keyword {
  static fromRes(data) {
    return new Keyword(data);
  }

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }
}
