import dayjs from 'dayjs';
import i18n from 'src/i18n';

export default class SubscriptionHistory {
  constructor(data) {
    const {
      list,
      anchor
    } = data;

    this.anchor = anchor;
    this.list = list.map((el) => {
      const {
        id,
        amount,
        recordStatus,
        createdAt,
        type,
        item
      } = el;

      const typeText = (() => {
        switch (type) {
          case 'FreeTrialSubscription':
            return i18n.t('profile_substa_content_free');
          case 'BindCard':
            return i18n.t('profile_substa_content_update_plan');
          default:
            switch (item) {
              case 'UpgradeYearly':
                return i18n.t('profile_substa_content_upgrade');
              case 'DowngradeMonthly':
                return i18n.t('profile_substa_content_downgrade');
              case 'Monthly':
                return i18n.t('profile_substa_content_monthly');
              case 'Yearly':
              case 'FirstYearly':
                return i18n.t('profile_substa_content_yearly');
              default:
                return i18n.t('profile_substa_content_active');
            }
        }
      })();

      const description = (() => {
        switch (recordStatus) {
          case -1:
            return '付款失敗';
          case 0:
            return '尚未請款';
          case 1:
            return '付款完成';
          case 2:
            return '部分退款';
          case 3:
            return '完全退款';
          case 4:
            return '待付款';
          case 5:
            return '取消交易';
          default:
            if (item === 'UpgradeYearly' || item === 'DowngradeMonthly') {
              return '-';
            }
            return '資料異常，請稍後再試';
        }
      })();

      const isDescOnly = type !== 'Subscription' || item === 'UpgradeYearly' || item === 'DowngradeMonthly';

      return ({
        id,
        amount: !isDescOnly ? `${i18n.t('dollar_unit')} ${Math.floor(Number(amount)).toString()}` : '-',
        typeText,
        description: !isDescOnly ? description : '-',
        date: dayjs(createdAt).format('YYYY/MM/DD')
      });
    });
  }

  static fromRes(data) {
    return new SubscriptionHistory(data);
  }
}
