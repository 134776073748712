import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import MediaQuery from 'react-responsive';
import { withResizeDetector } from 'react-resize-detector';
import isMobile from 'ismobilejs';
import ReactPlayer from 'react-player';
// css 動畫.
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';

import i18n from 'src/i18n';
import dayjs from 'dayjs';
import screenfull from 'screenfull';

import disk from 'src/assets/courseCard/disk.png';
import ShareIcon from 'src/assets/player/shareIcon.svg';
import unfavIcon from 'src/assets/player/favIconBrown.svg';
import favIcon from 'src/assets/player/liked.svg';
// ViewModel, 實例.
import playerViewModel from './viewModel';
// style.
import styles from './styles/style.module.scss';
import './styles/animate.scss';


import withRouter from '../withRouter';
import withProfile from '../withProfile';

import ControlsView from './components/Controls';
import ChapterView from './components/Chapter';
import ScreenControlsView from './components/ScreenControls';

import playerStore from './store';


@observer
class PlayerView extends React.Component {
  // CSSTransition refs.
  playerOpenNodeRef = React.createRef();
  playerScrollNodeRef = React.createRef();
  bigScreenNodeRef = React.createRef();
  // Waypoint ref.
  rightContainerRef = React.createRef();

  constructor(props) {
    super(props);
    this.vm = playerViewModel;
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentWillUnmount() {
    this.vm.unMount();
  }

  // 將 profile 保存至 playerStore.
  componentDidUpdate() {
    playerStore.setProfile(this.props.profile);
  }

  _renderThumbnail = () => {
    const isVideo = playerStore.chapter?.type === 'Video';
    switch (playerStore.course?.type) {
      case 'Video':
        return (
          <div className={clsx(styles.imgContainer, !isVideo && styles.active)}>
            <img
              className={clsx([
                styles.thumbnail,
                !isVideo && styles.active
              ])}
              src={playerStore.course?.thumbnails.web.url}
              alt={playerStore.course?.name}
            />
          </div>
        );
      case 'Audio':
        return (
          <div className={clsx(styles.audioImgContainer, !isVideo && styles.active)}>
            <div
              style={{ '--src': `url(${disk})` }}
              className={styles.disk}
            />
            <img
              className={clsx([
                styles.thumbnail,
                !isVideo && styles.active
              ])}
              src={playerStore.course?.thumbnails.web.url}
              alt={playerStore.course?.name}
            />
          </div>
        );
      case 'Audiobook':
        return (
          <div className={clsx(styles.audiobookImgContainer, !isVideo && styles.active)}>
            <img
              className={clsx([
                styles.thumbnail,
                !isVideo && styles.active
              ])}
              src={playerStore.course?.thumbnails.web.url}
              alt={playerStore.course?.name}
            />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    // 幫前播放的類型.
    const isVideo = playerStore.chapter?.type === 'Video';
    const { width } = this.props;

    return (
      <div id="player-animate" className={clsx(styles.player, playerStore.isBigScreen && styles.fullScreen)}>
        <CSSTransition
          in={playerStore.isPlayerOpen}
          timeout={300}
          classNames="play-open"
          nodeRef={this.playerOpenNodeRef}
        >
          <div
            className={clsx(styles.playerOpenContainer)}
            ref={this.playerOpenNodeRef}
          >
            <CSSTransition
              in={playerStore.isPlayerShow}
              timeout={300}
              classNames="play-scroll"
              nodeRef={this.playerScrollNodeRef}
            >
              <div
                className={clsx(
                  styles.playerScrollContainer,
                  playerStore.isBigScreen && styles.bigScreen
                )}
                ref={this.playerScrollNodeRef}
              >
                <CSSTransition
                  in={playerStore.isBigScreen}
                  timeout={300}
                  classNames="big-screen"
                  nodeRef={this.bigScreenNodeRef}
                >
                  <div
                    className={clsx(styles.bigScreenContainer, 'bigScreen')}
                    ref={this.bigScreenNodeRef}
                  >
                    <div className={styles.leftContainer}>
                      <MediaQuery minWidth={992}>
                        {playerStore.chapter && (
                          <div className={styles.infoContainer}>
                            <div>
                              <div className={styles.chapter}>
                                {playerStore.chapter.name}
                              </div>

                              <Link
                                to={`/course/${playerStore.course?.id}/info`}
                                onClick={this.vm.onCourseTitleClick}
                              >
                                <div className={styles.course}>
                                  {playerStore.course.name}
                                </div>
                              </Link>
                            </div>
                            <div className={styles.actions}>
                              <div
                                onClick={this.vm.onShareSelectToggle}
                                className={styles.shareContainer}
                                onBlur={this.vm.onShareSelectClose}
                                tabIndex={-1}
                              >
                                <img src={ShareIcon} alt="" className={styles.shareIcon} />
                                <div className={clsx(this.vm.isShareSelectOpen && styles.show, styles.shareSelect)}>
                                  <div
                                    className={styles.shareChapter}
                                    ref={this.vm.shareChapterRef}
                                    data-clipboard-text={this.vm.shareUrl}
                                    data-clipboard-action="copy"
                                  >
                                    {i18n.t('chapter_main_share_chapter')}
                                  </div>
                                  <div
                                    className={styles.shareNow}
                                    ref={this.vm.shareNowRef}
                                    data-clipboard-text={this.vm.shareCurrentUrl}
                                    data-clipboard-action="copy"
                                  >
                                    {i18n.t('chapter_main_share_chapter_current')}
                                  </div>
                                </div>
                              </div>
                              <div onClick={this.vm.onFavoriteClick}>
                                <img src={playerStore.chapter?.isFavorite ? favIcon : unfavIcon} alt="" className={styles.favIcon} />
                              </div>
                            </div>
                          </div>
                        )}
                      </MediaQuery>

                      <div
                        className={clsx(styles.bgBlack)}
                      >
                        <div
                          className={clsx(
                            styles.mediaContainer,
                            (this.vm.base === 'width' && !isVideo) && styles.mediaBaseWidth,
                            (this.vm.base === 'height' && !isVideo) && styles.mediaBaseHeight,
                            isVideo && styles.isVideo
                          )}
                          ref={playerStore.mediaContainerRef}
                        >
                          {this._renderThumbnail()}
                          <ReactPlayer
                            className={clsx(
                              styles.video,
                              isVideo && styles.active
                            )}
                            ref={playerStore.mediaRef}
                            url={playerStore.url}
                            playing={!playerStore.isPaused}
                            playbackRate={playerStore.speed}
                            volume={!isMobile(window.navigator).any ? playerStore.volume : null}
                            progressInterval={500}
                            width="100%"
                            height="100%"
                            onReady={this.vm.onCanplay}
                            onPause={this.vm.onPause}
                            onPlay={this.vm.onPlay}
                            onProgress={this.vm.onProgress}
                            onEnded={this.vm.onEnded}
                            onSeek={this.vm.onSeek}
                            onLoadedMetadata={this.vm.onLoadedMetadata}
                            onLoadedData={this.vm.onLoadedData}
                            onPlaybackRateChange={this.vm.onRateChange}
                            playsinline
                            autoPlay
                          />
                          {
                            (isVideo) && (
                              <ScreenControlsView emitter={this.vm.emitter} />
                            )
                          }
                        </div>
                      </div>
                      <MediaQuery maxWidth={991}>
                        {playerStore.chapter && (
                          <div className={styles.infoContainer}>
                            <div>
                              <div className={styles.chapter}>
                                {playerStore.chapter.name}
                              </div>

                              <Link
                                to={`/course/${playerStore.course?.id}/info`}
                                onClick={this.vm.onCourseTitleClick}
                              >
                                <div className={styles.course}>
                                  {playerStore.course.name}
                                </div>
                              </Link>
                            </div>
                            <div className={styles.actions}>
                              <div
                                onClick={this.vm.onShareSelectToggle}
                                className={styles.shareContainer}
                                onBlur={this.vm.onShareSelectClose}
                                tabIndex={-1}
                              >
                                <img src={ShareIcon} alt="" className={styles.shareIcon} />
                                <div className={clsx(this.vm.isShareSelectOpen && styles.show, styles.shareSelect)}>
                                  <div
                                    className={styles.shareChapter}
                                    ref={this.vm.shareChapterRef}
                                    data-clipboard-text={this.vm.shareUrl}
                                    data-clipboard-action="copy"
                                  >
                                    {i18n.t('chapter_main_share_chapter')}
                                  </div>
                                  <div
                                    className={styles.shareNow}
                                    ref={this.vm.shareNowRef}
                                    data-clipboard-text={this.vm.shareCurrentUrl}
                                    data-clipboard-action="copy"
                                  >
                                    {i18n.t('chapter_main_share_chapter_current')}
                                  </div>
                                </div>
                              </div>
                              <div onClick={this.vm.onFavoriteClick}>
                                <img src={playerStore.chapter?.isFavorite ? favIcon : unfavIcon} alt="" className={styles.favIcon} />
                              </div>
                            </div>
                          </div>
                        )}
                      </MediaQuery>
                    </div>

                    <div
                      className={styles.rightContainer}
                    >
                      <div className={styles.listTitle}>
                        {i18n.t('player_playlist')}
                      </div>
                      <div className={styles.listContainer} ref={this.rightContainerRef}>
                        {playerStore.list.length
                          && playerStore.list.map((item) => {
                            return (
                              <div className={styles.item} key={item.id}>
                                <ChapterView
                                  data={item}
                                  emitter={this.vm.emitter}
                                  lastAt={item.lastAt}
                                />
                              </div>
                            );
                          })}
                        {(playerStore.anchor && width >= 991)
                          && (
                            <Waypoint
                              scrollableAncestor={this.rightContainerRef.current}
                              bottomOffset={0}
                              onEnter={this.vm.onEnterWaypoint}
                            />
                          )}
                      </div>
                    </div>
                    {(playerStore.anchor && width < 991)
                      && (
                        <Waypoint
                          scrollableAncestor={this.bigScreenNodeRef.current}
                          bottomOffset={0}
                          onEnter={this.vm.onEnterWaypoint}
                        />
                      )}
                  </div>
                </CSSTransition>

                {/* 控制器容器. */}
                <ControlsView emitter={this.vm.emitter} />
              </div>
            </CSSTransition>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default withResizeDetector(withProfile(withRouter(PlayerView), false));
