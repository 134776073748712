import { makeObservable, observable, action, computed, toJS } from 'mobx';

import playerStore from 'src/components/Player/store';
import AlertViewModel from 'src/components/Alert/viewModel';

export default class SpeedSelectorViewModel extends AlertViewModel {
  @observable speedList = [0.5, 1, 1.25, 1.5, 2]
  @observable isSpeedSelectorOpen = false;

  constructor() {
    super();

    makeObservable(this);
  }

  @action
  toggleOpen = () => {
    this.isSpeedSelectorOpen = !this.isSpeedSelectorOpen;
  }

  @action
  onSpeedSet = (value) => {
    if (value === playerStore.speed) {
      return;
    }
    playerStore.speed = value;
    playerStore.mediaRef.current.playbackRate = playerStore.speed;

    if (this.isSpeedSelectorOpen) {
      this.toggleOpen();
    } else {
      this.handlerCloseAlert();
    }
  }
}
