import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { observer } from 'mobx-react';
import { AppStateContext } from 'src/stores';
import isMobile from 'ismobilejs';
import clsx from 'clsx';
import i18n from 'src/i18n';

import Lottie from 'lottie-react';
import LottieFile from 'src/assets/splash.json';

import CloseBtn from 'src/assets/player/closeBtn.svg';
import SquareIcon from 'src/assets/squareIcon.jpg';

import ScrollToTop from 'src/libs/scrollToTop';
import adjustManifest from 'src/services/appLabel';

import HeaderView from 'src/components/Header';
import FooterView from 'src/components/Footer';
import PlayerView from 'src/components/Player';
import loading from 'src/services/loading';

import withRouter from '../withRouter';
import scrollPlayer from '../ScrollPlayer';

import LogoutAlert from '../LogoutAlert';

import LayoutViewModel from './viewModel';

import styles from './styles.module.css';

@observer
class LayoutView extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = new LayoutViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
    adjustManifest();
  }

  componentDidUpdate(prevProps) {
    const prevPath = prevProps.router.location.pathname;
    const nowPath = this.props.router.location.pathname;
    if (prevPath !== nowPath) {
      this.vm.toggleFooter();

      adjustManifest();
    }
  }

  render() {
    const notApple = !isMobile(window.navigator).apple.device;
    return (
      <>
        <div
          className={clsx('pageContainer', styles.page)}
          style={{
            paddingTop: this.vm.headerHeight
          }}
        >
          <ScrollToTop />
          <Outlet context={this.vm.headerHeight} />
        </div>
        {
          this.vm.isShowFooter && (
            <FooterView context={this.context} />
          )
        }

        <HeaderView
          context={this.context}
          headerRef={this.vm.headerRef}
          updateHeight={this.vm.updateHeight}
          headerHeight={this.vm.headerHeight}
        />

        <PlayerView context={this.context} />

        <ToastContainer
          // NOTE:
          // className: applied to the container
          // toastClassName: applied on the toast wrapper
          // bodyClassName: applied on the toast body
          // progressClassName: applied on the progress bar
          // style: inline style applied to the container
          className={styles.toastOuterContainer}
          toastClassName={styles.toastClass}
          bodyClassName={styles.bodyStyle}
          closeButton={() => { }}
        />
        {
          (isMobile(window.navigator).any && this.vm.isAlertOpen)
          && (
            <div className={clsx(styles.appAlert)}>
              <div
                className={styles.closeBtnContainer}
                onClick={this.vm.closeAlert}
              >
                <img src={CloseBtn} alt="closeBtn" className={styles.closeBtn} />
              </div>
              <div className={styles.appIconContainer}>
                <img src={SquareIcon} alt="SquareIcon" className={styles.icon} />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.headerText}>
                  sandshour
                </div>
                <div className={styles.alertDescription}>
                  {i18n.t('download_slogan')}
                </div>
              </div>
              <a
                href={
                  notApple
                    ? 'https://play.google.com/store/apps/details?id=com.sandshour'
                    : 'https://apps.apple.com/tw/app/sandshour-%E5%BD%B1%E9%9F%B3%E5%85%A7%E5%AE%B9%E8%A8%82%E9%96%B1%E5%B9%B3%E5%8F%B0/id1611532905'
                }
                className={styles.goBtnContainer}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t('download_btn')}
              </a>
            </div>
          )
        }
        <LogoutAlert />
        {
          loading.isLoading
          && (
            <div className={styles.globalLoading}>
              <div className={styles.loadingContainer}>
                <div className={styles.inner}>
                  <Lottie animationData={LottieFile} loop />
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  }
}

export default scrollPlayer(withRouter(LayoutView));
