import API from 'src/apis';
import UserData from 'src/models/response/UserData';
import Auth from './auth';

export default class AuthService {
  // 讀取會員資料.
  static async getProfile() {
    const res = await API.authenticate.profile(Auth.getToken());
    // 資料轉換.
    return UserData.fromRes(res.data);
  }

  // 登入.
  static async login(data) {
    const res = await API.authenticate.login(data);

    Auth.setToken(res.data.token);

    return UserData.fromRes(res.data);
  }

  // 登出．
  static async logout() {
    const res = await API.authenticate.logout(Auth.getToken());

    Auth.removeToken();
    const fontSize = localStorage.getItem('fontSize');
    if (fontSize) {
      localStorage.removeItem('fontSize');
    }

    return res.data;
  }

  // 註冊.
  static async signup(data) {
    const res = await API.authenticate.signup(data);

    Auth.setToken(res.data.token);

    return UserData.fromRes(res.data);
  }

  // 忘記密碼.
  static async forgotPassword({ email }) {
    const res = await API.authenticate.forgotPassword({ email });

    return res;
  }

  // 重置密碼.
  static async resetPassword({ email, password, code }) {
    const res = await API.authenticate.resetPassword({
      email,
      password,
      code
    });

    return res;
  }

  // 註冊驗證 email
  static async emailAuth({ email }) {
    const res = await API.authenticate.emailAuth({
      email
    });

    return res;
  }

  static async checkAvailable() {
    const token = Auth.getToken();
    if (!token) {
      return;
    }
    await API.authenticate.checkAvailable(Auth.getToken());
  }

  static async isCouponOn() {
    const res = await API.authenticate.isCouponOn();

    return res.data.coupon;
  }

  static async checkYearSubscription() {
    const res = await API.authenticate.checkYearSubscription();

    return res.data;
  }

}
