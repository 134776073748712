import React from 'react';
import { observer } from 'mobx-react';
import { AppStateContext } from 'src/stores';
// View Model, 在 viewModel 頁已經 new 過了.
import signInViewModel from 'src/components/Header/component/SignIn/viewModel';
// Alert
import Alert from 'src/components/Alert';
// 忘記密碼.
import ForgotPasswordView from 'src/components/Header/component/SignIn/component/ForgotPassword';

// 選擇登入方法
import LoginSelect from './component/LoginSelect';
// 登入頁.
import LoginView from './component/Login';
// 輸入兩次密碼.
import ResetPasswordView from './component/ResetPassword';
// 輸入暱稱.
import InputNameView from './component/InputName';
// 輸入興趣.
import InputInterestsView from './component/InputInterests';
// 註冊驗證 email.
import EmailAuthView from './component/EmailAuth';

import styles from './style.module.scss';

@observer
class SignInView extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = signInViewModel;
  }

  componentDidMount() {
    this.vm.didMount({ context: this.context });
  }

  render() {
    const { page, pages } = this.vm;
    const isLogin = page === pages.login || page === pages.signup;
    const isSelect = page === pages.selectLogin || page === pages.selectSignup;



    return (
      <Alert
        vm={this.vm}
        showButtons={false}
        showClose={false}
        className={styles.highestLevel}
        contentContainerStyles={styles.contentContainerStyles}
      >
        <div className={styles.signInContainer}>
          {/* 登入選擇 */}
          {
            isSelect && (
              <LoginSelect
                handlerToLogin={this.vm.handlerToLogin}
                handlerToSignup={this.vm.handlerToSignup}
                handleSelectChange={this.vm.handleSelectChange}
                handlerCloseAlert={this.vm.handleClose}
                isSelectLogin={page === pages.selectLogin}
              />
            )
          }

          {/* 登入頁面 */}
          {isLogin && (
            <LoginView
              page={this.vm.page}
              pages={this.vm.pages}
              // 設定來源.
              setSource={this.vm.setSource}
              // saveProfile
              saveProfile={this.vm.saveProfile}
              setSaveProfile={this.vm.setSaveProfile}
              // 前往忘記密碼.
              handlerToForgotPassword={this.vm.handlerToForgotPassword}
              // 前往下一步.
              handlerNextStep={this.vm.handlerNextStep}
              handlerCloseAlert={this.vm.handleClose}
              handleBack={this.vm.handleBack}
            />
          )}

          {/* 忘記密碼 */}
          {this.vm.page === this.vm.pages.forgotPassword && (
            <ForgotPasswordView
              // 讓元件可以更新 email 至 parent.
              setEmail={this.vm.setEmail}
              email={this.vm.resetEmail}
              onEmailChange={this.vm.onEmailChange}
              handlerToLogin={this.vm.handlerToLogin}
              handlerToResetPassword={this.vm.handlerToResetPassword}
              handleBack={this.vm.handleBack}
              handlerCloseAlert={this.vm.handleClose}
              reciprocal={this.vm.reciprocal}
              reciprocalTimer={this.vm.reciprocalTimer}
              resetReciprocal={this.vm.resetReciprocal}
            />
          )}

          {/* 輸入兩次密碼 */}
          {this.vm.page === this.vm.pages.resetPassword && (
            <ResetPasswordView
              // 忘記密碼的 email.
              email={this.vm.email}
              pages={this.vm.pages}
              // 保存 Profile.
              setSaveProfile={this.vm.setSaveProfile}
              // 上一步
              handlerToForgotPassword={this.vm.handlerToForgotPassword}
              // 下一步.
              handlerNextStep={this.vm.handlerNextStep}
              // 設定來源.
              setSource={this.vm.setSource}
              handlerCloseAlert={this.vm.handleClose}
              handleBack={this.vm.handleBack}
            />
          )}

          {/* 註冊驗證 email */}
          {this.vm.page === this.vm.pages.emailAuth && (
            <EmailAuthView
              pages={this.vm.pages}
              // 來源.
              source={this.vm.source}
              saveProfile={this.vm.saveProfile}
              // 保存 profile.
              setSaveProfile={this.vm.setSaveProfile}
              // 前往註冊.
              handlerToLogin={this.vm.handlerToLogin}
              // 前往下一步.
              handlerNextStep={this.vm.handlerNextStep}
              handleBack={this.vm.handleBack}
              handlerCloseAlert={this.vm.handleClose}
            />
          )}

          {/* 輸入暱稱. */}
          {this.vm.page === this.vm.pages.inputName && (
            <InputNameView
              // saveProfile
              saveProfile={this.vm.saveProfile}
              setSaveProfile={this.vm.setSaveProfile}
              // 前往註冊.
              handlerToLogin={this.vm.handlerToLogin}
              // 前往下一步.
              handlerNextStep={this.vm.handlerNextStep}
              handleBack={this.vm.handleBack}
              handlerCloseAlert={this.vm.handleClose}
            />
          )}

          {/* 選擇興趣 */}
          {this.vm.page === this.vm.pages.inputInterests && (
            <InputInterestsView
              // saveProfile
              saveProfile={this.vm.saveProfile}
              setSaveProfile={this.vm.setSaveProfile}
              // 前往設置暱稱.
              handlerToInputName={this.vm.handlerToInputName}
              // 前往下一步.
              handlerNextStep={this.vm.handlerNextStep}
              handleBack={this.vm.handleBack}
              handlerCloseAlert={this.vm.handleClose}
            />
          )}
        </div>


      </Alert>
    );
  }
}

export default SignInView;
