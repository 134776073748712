import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Player from 'src/components/Player/viewModel';
import i18n from 'src/i18n';

import VideoIcon from 'src/assets/courseCard/videoIcon.svg';
import AudioIcon from 'src/assets/courseCard/audioIcon.svg';
import AudiobookIcon from 'src/assets/courseCard/audiobookIcon.svg';
import ViewIcon from 'src/assets/courseCard/viewIcon.svg';


import withRouter from 'src/components/withRouter';

import styles from './styles.module.scss';

@observer
class RecommendCard extends React.Component {

  _renderIcon = (type) => {
    switch (type) {
      case 'Audio':
        return <div style={{ '--src': `url(${AudioIcon})` }} className={clsx(styles.audioIcon, styles.audioIcon_layout)} />;
      case 'Audiobook':
        return <div style={{ '--src': `url(${AudiobookIcon})` }} className={clsx(styles.audiobookIcon, styles.audiobookIcon_layout)} />;
      case 'Video':
        return <div style={{ '--src': `url(${VideoIcon})` }} className={clsx(styles.videoIcon, styles.videoIcon_layout)} />;
      default:
        return null;
    }
  }

  render() {
    const { viewModel, openModalFunction, isAvailable, router } = this.props;
    return (
      <div
        className={`interest ${clsx(styles.recommendCard, styles.recommendCard_layout)}`}
        onClick={() => {
          if (viewModel.type === 'Document') {
            router.navigate(`/course/${viewModel.courseId}/chapter/${viewModel.id}`);
            return;
          }
          if (isAvailable || viewModel.isTrial) {
            Player.handlerSetChapterId({ chapterId: viewModel.id });
            return;
          }
          openModalFunction();
        }}
      >

        <div className={clsx(styles.recommendCard_item)}>
          <div className={clsx(styles.innerContent, styles.innerContent_layout)}>
            <div className={clsx(styles.titleContainer, styles.titleContainer_layout)}>
              <div className={clsx(styles.iconContainer, styles.iconContainer_layout)}>
                {this._renderIcon(viewModel.courseType)}
              </div>

              <h5 className={clsx(styles.chapterTitle_box, styles.chapterTitle_box_layout)}>
                <pre className={clsx(styles.chapterTitle)}>
                  {viewModel.name}
                </pre>
              </h5>
            </div>

            <div className={clsx(styles.bottomContainer, styles.bottomContainer_layout)}>
              <h5 className={clsx(styles.authorName, styles.authorName_layout)}>
                {viewModel.authorName}
              </h5>
              {
                viewModel.isNew && (
                  <div className={clsx(styles.bottomContainer_item)}>
                    <div className={clsx(styles.newTag, styles.newTag_layout)}>
                      <div className={clsx(styles.text_body, styles.text_body_layout)}>
                        {i18n.t('tag_new')}
                      </div>
                    </div>
                  </div>
                )
              }
              <div className={clsx(styles.bottomContainer_item1)}>
                <div className={clsx(styles.popularityContainer, styles.popularityContainer_layout)}>
                  <div className={clsx(styles.popularityContainer_item)}>
                    <div style={{ '--src': `url(${ViewIcon})` }} className={clsx(styles.viewIcon, styles.viewIcon_layout)} />
                  </div>
                  <div className={clsx(styles.popularityContainer_spacer)} />
                  <h5 className={clsx(styles.popularity, styles.popularity_layout)}>
                    {viewModel.popularity}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

RecommendCard.propTypes = {
  router: PropTypes.object,
  viewModel: PropTypes.object,
  openModalFunction: PropTypes.func,
  isAvailable: PropTypes.bool
};

RecommendCard.defaultProps = {
  router: {},
  viewModel: null,
  openModalFunction: () => { },
  isAvailable: true
};

export default withRouter(RecommendCard);
