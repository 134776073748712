import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

@observer
class BookListCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { viewModel, moduleId } = this.props;
    return (

      <Link
        to={`/bookList/${moduleId}?list=${viewModel.id}`}
        className={clsx('resently-01 ', styles.learnedCard, styles.learnedCard_layout, 'block')}
      >
        <div className={clsx(styles.upperContainer, styles.upperContainer_layout)}>
          <div className={clsx(styles.vThumbnailContainer, styles.vThumbnailContainer_layout)}>
            <img
              src={viewModel.thumbnail}
              alt={viewModel.title}
              className={`${clsx(styles.vThumbnail, styles.vThumbnail_layout)} hover-zoom`}
            />
            <div className={styles.tagContainer}>
              {viewModel.tag}
            </div>
          </div>
        </div>

        <div className={clsx(styles.infoContainer, styles.infoContainer_layout)}>
          <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
            <div className={clsx(styles.titleContainer, styles.titleContainer_layout)}>
              <h3 className={clsx(styles.title_box, styles.title_box_layout)}>
                <pre className={clsx(styles.title)}>
                  {viewModel.title}
                </pre>
              </h3>
            </div>

            <div className={clsx(styles.otherInfoContainer, styles.otherInfoContainer_layout)}>
              <div className={styles.otherLeft}>
                <h5 className={clsx(styles.authorName, styles.authorName_layout)}>
                  {viewModel.subtitle}
                </h5>
              </div>
            </div>
          </div>

          <div className={clsx(styles.leftBar, styles.leftBar_layout)} />
        </div>
      </Link>
    );
  }
}

BookListCard.propTypes = {
  viewModel: PropTypes.object,
  moduleId: PropTypes.string.isRequired
};

BookListCard.defaultProps = {
  viewModel: {}
};

export default BookListCard;
