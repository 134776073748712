import * as user from './user';
import * as home from './home';
import * as course from './course';
import * as chapter from './chapter';
import * as authenticate from './authenticate';
import * as search from './search';
import * as message from './message';
import * as keyword from './keyword';
import * as player from './player';
import * as trade from './trade';
import * as invoice from './invoice';
import * as bookList from './bookList';

export default {
  user,
  home,
  course,
  chapter,
  authenticate,
  search,
  message,
  keyword,
  player,
  trade,
  invoice,
  bookList
};
