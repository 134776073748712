import { makeObservable, observable, action, computed } from 'mobx';
import { getHost } from 'src/apis/utils';
import Auth from 'src/services/auth';

import AlertViewModel from '../Alert/viewModel';

class InAppRedirectModalViewModel extends AlertViewModel {
  constructor(props) {
    super(props);
    makeObservable(this);
  }


  onContinue = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.append('openExternalBrowser', '1');
    alert(currentUrl.toString());
  }
}

const inAppRedirectModalViewModel = new InAppRedirectModalViewModel();

export default inAppRedirectModalViewModel;
