import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import screenfull from 'screenfull';
import isMobile from 'ismobilejs';

import playerStore from '../../store';
import playerViewModel from '../../viewModel';

class ScreenControlsViewModel {
  @observable isHover = false;
  @observable firstIn = false;
  timer = null;

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  constructor() {
    makeObservable(this);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @computed
  get isShow() {
    return playerStore.isScreenfull && this.isHover;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  setTimeout = () => {
    this.timer = clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      runInAction(() => {
        if (playerViewModel.speedSelectorModal.isSpeedSelectorOpen || playerViewModel.showMoreContent) {
          return;
        }
        this.isHover = false;
      });
    }, 3000);
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  onMouseMove = () => {
    if (isMobile(window.navigator).any) {
      return;
    }
    // NOTE: workaround for first in close.
    if (this.firstIn) {
      this.firstIn = false;
      return;
    }
    this.isHover = true;
    this.setTimeout();
  };

  @action
  onClick = () => {
    this.isHover = !this.isHover;
    if (this.isHover) {
      this.setTimeout();
    } else {
      this.timer = clearTimeout(this.timer);
      if (playerViewModel.speedSelectorModal.isSpeedSelectorOpen) {
        playerViewModel.speedSelectorModal.toggleOpen();
      }
      if (playerViewModel.showMoreContent) {
        playerViewModel.toggleMoreContent();
      }
    }
  }

  // 全螢幕切換.
  @action
  onScreenfull = () => {
    if (!playerStore.isBigScreen) {
      return;
    }

    if (screenfull.isEnabled && !isMobile(window.navigator).apple.device) {
      // 紀錄全螢幕狀態.
      // ! 已經有一個監視器了 還需要這個嗎？
      // 切換全螢幕.
      if (!playerStore.isScreenfull) {
        screenfull.request(playerStore.mediaContainerRef.current);
        this.firstIn = true;
        this.isHover = true;
        if (isMobile(window.navigator).any) {
          this.setTimeout();
        }
      }
    } else {
      const player = playerStore.mediaRef.current.getInternalPlayer();
      player.webkitEnterFullScreen();
    }
  };

  @computed
  get showFullScreen() {
    const notApple = !isMobile(window.navigator).apple.device;
    const isAppleTablet = isMobile(window.navigator).apple.tablet;
    const isChrome = !!window.navigator.userAgent.match(/chrome|chromium|crios/i);

    if (notApple) {
      return true;
    }

    if (isAppleTablet && !isChrome) {
      return true;
    }

    return true;
  }
}

export default ScreenControlsViewModel;
