import { makeObservable, action, observable, computed } from 'mobx';

import BookListCardViewModel from 'src/components/BookListCard/viewModel';

export default class ModuleSectionViewModel {
  @observable id = '';
  @observable title = '';
  @observable icon = '';
  @observable cardList = [];
  @observable swiper = null;

  constructor(data) {
    makeObservable(this);

    this.updateData(data);
  }

  @action
  updateData = (data) => {
    const {
      id,
      title,
      icon,
      list
    } = data;

    this.id = id;
    this.title = title;
    this.icon = icon;
    this.cardList = list.map((card) => new BookListCardViewModel(card));
  }

  @action
  onSwiper = (swiper) => {
    this.swiper = swiper;
  }
}
