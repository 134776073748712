import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';
import CommonError from './common';

export default class MessageError {
  static getNotificationsList(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_notification_list_4xx'));
        break;
    }
  }

  static putNotification(error) {
    switch (error.response?.status) {
      case 404:
        ErrorToast(i18n.t('api_notification_read_404'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static putNotificationAllRead(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_notification_read_all_4xx'));
        break;
    }
  }

  static getAnnouncementsList(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_announcement_4xx'));
        break;
    }
  }

  static getAnnouncement(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        ErrorToast(i18n.t('api_announcement_detail_404'));
        break;
      default:
        ErrorToast(i18n.t('api_announcement_detail_4xx'));
        break;
    }
  }
}
