import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';
import { Link } from 'react-router-dom';

import { getHost } from 'src/apis/utils';

import withRouter from 'src/components/withRouter';

import Logo from 'src/assets/account/mainLogo.png';
import AccountBg from 'src/assets/account/bg.png';
import CloseBtn from 'src/assets/account/closeBtn.svg';

import MailIcon from 'src/assets/account/emailIcon.svg';
import GoogleIcon from 'src/assets/account/googleIcon.png';
import AppleIcon from 'src/assets/account/appleIcon.png';

import styles from './styles.module.scss';


@observer
class LoginSelect extends React.Component {
  render() {
    const { isSelectLogin, router, handleSelectChange, handlerToLogin, handlerToSignup, handlerCloseAlert } = this.props;
    const { pathname, search } = router.location;



    return (
      <div
        style={{ '--src': `url(${AccountBg})` }}
        className={`login2cloned ${clsx(
          styles.loginContainer,
          styles.loginContainer_layout
        )}`}
      >
        <div
          style={{ '--src': `url(${AccountBg})` }}
          className={clsx(styles.loginFlex, styles.loginFlex_layout)}
        >
          <div
            style={{ '--src': `url(${CloseBtn})` }}
            className={clsx(styles.closeBtn, styles.closeBtn_layout)}
            onClick={handlerCloseAlert}
          />

          <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
            <div className={clsx(styles.contentFlex_item)}>
              <div
                style={{ '--src': `url(${Logo})` }}
                className={clsx(styles.mainLogo, styles.mainLogo_layout)}
              />
            </div>
            <div className={clsx(styles.contentFlex_spacer)} />
            <div className={clsx(styles.contentFlex_item)}>
              <div
                className={clsx(styles.emailLogin, styles.emailLogin_layout)}
                onClick={isSelectLogin ? handlerToLogin : handlerToSignup}
              >
                <div className={clsx(styles.emailFlex, styles.emailFlex_layout)}>
                  <div className={clsx(styles.emailFlex_item)}>
                    <div
                      style={{ '--src': `url(${MailIcon})` }}
                      className={clsx(styles.emailIcon, styles.emailIcon_layout)}
                    />
                  </div>
                  <div className={clsx(styles.emailFlex_spacer)} />
                  <h5 className={clsx(styles.emailText, styles.emailText_layout)}>
                    {isSelectLogin ? i18n.t('account_mail_login') : i18n.t('account_mail_signup')}
                  </h5>
                </div>
              </div>
            </div>
            <div className={clsx(styles.contentFlex_spacer1)} />
            <div className={clsx(styles.contentFlex_item)}>
              <a
                href={`${getHost()}/api/v1/google/login?redirect=${window.location.origin}${pathname}${search}`}
                target="_self"
                className={clsx(styles.googleLogin, styles.googleLogin_layout)}
              >
                <div className={clsx(styles.googleFlex, styles.googleFlex_layout)}>
                  <div className={clsx(styles.googleFlex_item)}>
                    <div
                      style={{ '--src': `url(${GoogleIcon})` }}
                      className={clsx(styles.googleIcon, styles.googleIcon_layout)}
                    />
                  </div>

                  <h5 className={clsx(styles.googleText, styles.googleText_layout)}>
                    {isSelectLogin ? i18n.t('account_google_login') : i18n.t('account_google_signup')}
                  </h5>
                </div>
              </a>
            </div>
            <div className={clsx(styles.contentFlex_spacer2)} />
            <div className={clsx(styles.contentFlex_item)}>
              <a
                href={`${getHost()}/api/v1/apple/login?redirect=${window.location.origin}${pathname}${search}`}
                className={clsx(styles.appleLogin, styles.appleLogin_layout)}
              >
                <div className={clsx(styles.appleFlex, styles.appleFlex_layout)}>
                  <div className={clsx(styles.appleFlex_item)}>
                    <div
                      style={{ '--src': `url(${AppleIcon})` }}
                      className={clsx(styles.appleIcon, styles.appleIcon_layout)}
                    />
                  </div>
                  <h5 className={clsx(styles.appleText, styles.appleText_layout)}>
                    {isSelectLogin ? i18n.t('account_apple_login') : i18n.t('account_apple_signup')}
                  </h5>
                </div>
              </a>
            </div>
            <div className={clsx(styles.contentFlex_spacer3)} />
            <div className={clsx(styles.contentFlex_item)}>
              <h5 className={clsx(styles.changeTabBtn, styles.changeTabBtn_layout)}>
                {isSelectLogin ? i18n.t('account_noaccount_title') : i18n.t('account_hasaccount_title')}
                <div
                  onClick={handleSelectChange}
                  className={styles.registerBtn}
                >
                  {
                    isSelectLogin ? i18n.t('account_noaccount_btn') : i18n.t('account_hasaccount_btn')
                  }
                </div>
              </h5>
            </div>
            <div className={clsx(styles.contentFlex_spacer4)} />
            <div className={clsx(styles.contentFlex_item)}>
              <Link to="/term-of-use" target="_blank">
                <div className={clsx(styles.policy, styles.policy_layout)}>
                  {i18n.t('account_privacy_content')}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginSelect.propTypes = {
  handleSelectChange: PropTypes.func,
  handlerToLogin: PropTypes.func
};

LoginSelect.defaultProps = {
  handleSelectChange: () => { },
  handlerToLogin: () => { }
};

export default withRouter(LoginSelect);
