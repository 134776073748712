import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

class PlayerStore {
  // 打開或是關閉播放器.
  @observable isPlayerOpen = false;
  // 依照 scroll 顯示或隱藏播放器.
  @observable isPlayerShow = true;
  // 播放器大螢幕.
  @observable isBigScreen = false;
  // 打開播放速度選單.
  @observable isOpenSelectSpeed = false;
  // 全螢幕切換.
  @observable isScreenfull = false;
  // 當前是否暫停.
  @observable isPaused = true;
  // for seek paused
  @observable isPreviousPaused = true;
  // 當前是否靜音.
  @observable isMuted = false;
  // 當前秒數.
  @observable currentTime = 0;
  // 影片長度.
  @observable duration = 0;
  // 快轉和倒退秒數.
  @observable jump = 15;
  // 播放速度.
  @observable speed = 1;
  // 音量.
  @observable volume = 0.5;

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // api 列表數量.
  @observable limit = 10;
  // 允許 Waypoint 開始下載.
  @observable isReady = false;
  // 剛更新播放影片, 用於剛換 url 的自動播放.
  @observable isFirstPlay = false;
  // 是否已看完, 用於排程的檢查.
  @observable isLearned = false;
  // 單集 media url.
  @observable url = 'init';
  // 課程資料.
  @observable course = null;
  // 影片資料.
  @observable chapter = null;
  // 播放列表
  @observable list = [];
  // 讀取下頁列表的東西.
  @observable anchor = undefined;
  // 排程.
  @observable timerHistory = null;
  @observable timerLearned = null;

  @observable key = dayjs().valueOf();

  @observable needStop = false;

  @observable seekTo = null;

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 會員資料.
  profile = null;

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  mediaContainerRef = React.createRef();
  mediaRef = React.createRef();
  sourceRef = React.createRef();

  @observable isRegisteredFullScreen = false;
  @observable iosFullscreen = false;
  @observable iosMuted = true;
  @observable firstInStopPreventer = null;
  @observable screenOrientationIsLand = false;

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  constructor() {
    makeObservable(this);
  }

  // 刪除小數的當前秒數.
  @computed
  get calcCurrentTime() {
    return Math.ceil(this.currentTime);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  setProfile = (value) => {
    this.profile = value;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 清空狀態.
  @action
  resetStates = () => {
    // 依照 scroll 顯示或隱藏播放器.
    this.isPlayerShow = true;
    // 播放器大螢幕.
    this.isBigScreen = false;
    // 打開播放速度選單.
    this.isOpenSelectSpeed = false;
    // 全螢幕切換.
    this.isScreenfull = false;
    // 當前是否暫停.
    this.isPaused = true;
    // 當前秒數.
    this.currentTime = 0;
    // 影片長度.
    this.duration = null;
    // 快轉和倒退秒數.
    this.jump = 15;

    // 允許 Waypoint 開始下載.
    this.isReady = false;
    // 剛更新播放影片.
    this.isFirstPlay = false;
    // 是否已看完
    this.isLearned = false;
    // 單集 media url.
    this.url = '';
    // 課程資料.
    this.course = null;
    // 影片資料.
    this.chapter = null;
    // 播放列表
    this.list = [];
    // 讀取下頁列表的東西.
    this.anchor = true;

    // 清除自動開啟stop flag
    this.needStop = false;
    // 清除自東開啟seek to
    this.seekTo = null;

  };
}

const playerStore = new PlayerStore();

export default playerStore;
