import API from 'src/apis';
import TradeInfo from 'src/models/response/TradeInfo';
import SubscriptionTradeInfo from 'src/models/response/SubscriptionTradeInfo';
import SubscriptionHistory from 'src/models/response/SubscriptionHistory';
import CardInfo from 'src/models/response/CardInfo';

import Auth from './auth';


export default class TradeService {
  static async payForSubscription(uid, prime, phone, name, email, invoice, item, couponId) {
    const res = await API.trade.payForSubscription(Auth.getToken(), uid, prime, phone, name, email, invoice, item, couponId);

    return res;
  }

  static async payForSingleCourse(cid, price, couponId, shouldUsePoints, prime, phone, name, email, invoice) {
    const res = await API.trade.payForSingleCourse(Auth.getToken(), cid, price, couponId, shouldUsePoints, prime, phone, name, email, invoice);

    return res;
  }

  static async cancelSubscription(uid) {
    const res = await API.trade.cancelSubscription(Auth.getToken(), uid);

    return res;
  }

  static async changeSubscriptionCard(uid, prime, phone, name, email, invoice) {
    const res = await API.trade.changeSubscriptionCard(Auth.getToken(), uid, prime, phone, name, email, invoice);

    return res;
  }

  static async getTradeInfo(uid, tid, rec) {
    const res = await API.trade.getTradeInfo(Auth.getToken(), uid, tid, rec);

    return TradeInfo.fromRes(res.data);
  }

  static async getSubscriptionTradeInfo(uid, tid) {
    const res = await API.trade.getSubscriptionTradeInfo(Auth.getToken(), uid, tid);

    return SubscriptionTradeInfo.fromRes(res.data);
  }

  static async getUserPoint(uid) {
    const res = await API.trade.getUserPoint(Auth.getToken(), uid);

    return res.data.points;
  }

  static async getUserCards(uid) {
    const res = await API.trade.getUserCards(Auth.getToken(), uid);

    return res.data.list.map((el) => CardInfo.fromRes(el));
  }

  static async getSubscriptionHistory(uid, anchor) {
    const res = await API.trade.getSubscriptionHistory(Auth.getToken(), uid, anchor);

    return SubscriptionHistory.fromRes(res.data);
  }

  static async changeSubscriptionType(uid, item) {
    await API.trade.changeSubscriptionType(Auth.getToken(), uid, item);
    // > item
    // > enum: DowngradeMonthly, UpgradeYearly
  }
}

