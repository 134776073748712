import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import clsx from 'clsx';
// import BlotFormatter from 'quill-blot-formatter';
// import VideoResize from 'quill-video-resize-module2';
import PropTypes from 'prop-types';

import CustomImage from './Customize/CustomizeImage';
import CustomizeVideo from './Customize/CustomizeVideo';
// import Carousel from './Customize/CustomizeCarousel';

import { showSize } from './Customize/constants';

import styles from './styles.module.css';
import './Customize/customizeStyle.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

// NOTE: mark for edit
const Size = Quill.import('attributors/style/size');
Size.whitelist = showSize;

Quill.register(Size, true);
// Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('formats/video', CustomizeVideo);
// Quill.register('modules/VideoResize', VideoResize);
Quill.register('formats/image', CustomImage);
// > register carousel
// Quill.register('formats/carousel', Carousel);


class ContentQuill extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.viewModel.didMount();
  }

  render() {
    const { viewModel } = this.props;
    return (
      <div className={clsx('quill-container', styles.quillContainer)}>
        <ReactQuill
          theme="bubble"
          readOnly
          ref={viewModel.quillRef}
          modules={viewModel.modules}
          formats={viewModel.formats}
          value={viewModel.value}
          onChange={viewModel.setValue}
        />
      </div>
    );
  }
}

ContentQuill.propTypes = {
  viewModel: PropTypes.object
};

ContentQuill.defaultProps = {
  viewModel: {}
};

export default ContentQuill;
