import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';
import CommonError from './common';

export default class AuthenticateError {
  static profile(error) {
    switch (error.response?.status) {
      case 401:
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static login(error) {
    switch (error.response?.status) {
      case 401:
        ErrorToast(i18n.t('api_user_login_401'));
        break;
      case 403:
        ErrorToast(i18n.t('api_user_login_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_user_login_404'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static logout(error) {
    switch (error.response?.status) {
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static signup(error) {
    switch (error.response?.status) {
      case 409:
        ErrorToast(i18n.t('api_user_signup_409'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }


  static forgotPassword(error) {
    switch (error.response?.status) {
      case 404:
        ErrorToast(i18n.t('api_user_forgot_password_404'));
        break;
      case 429:
        ErrorToast(i18n.t('api_user_forgot_password_429'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static resetPassword(error) {
    switch (error.response?.status) {
      case 401:
        ErrorToast(i18n.t('api_user_reset_password_401'));
        break;
      case 403:
        ErrorToast(i18n.t('api_user_reset_password_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_user_reset_password_404'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static emailAuth(error) {
    switch (error.response?.status) {
      case 404:
        ErrorToast(i18n.t('api_user_email_auth_404'));
        break;
      case 429:
        ErrorToast(i18n.t('api_user_email_auth_429'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static checkAvailable(error) {
    switch (error.response?.status) {
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static isCouponOn(error) {
    switch (error.response?.status) {
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }
}
