import dayjs from 'dayjs';

export default class UserData {
  static fromRes(data) {
    return new UserData(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    // 會員 id.
    this.id = data.profile.id;
    // 帳號.
    this.email = data.profile.email;
    // 會員暱稱.
    this.nickname = data.profile.nickname;
    // 照片網址.
    this.avatar = data.profile.avatar;
    // 性別.
    this.gender = data.profile.gender;
    // 手機號碼.
    this.phone = data.profile.phone;
    // 待補充.

    const {
      isAutoRenewEnable,
      expiredAt,
      isUsedToRedeemed,
      isSubscribed,
      isUsedToSubscribed,
      source,
      hasYearDiscount,
      hasFreeTrial,
      item
    } = data.subscriptions;

    // 是否是正在訂閱的狀態
    this.isAutoRenewEnable = isAutoRenewEnable;
    // 到期日
    this.expiredAt = expiredAt;
    // > 到期日字串
    this.expiredAtText = expiredAt ? dayjs(expiredAt).format('YYYY/MM/DD') : null;
    // > 是否兌換過
    this.isUsedToRedeemed = isUsedToRedeemed;
    // > 是否訂閱過
    this.isUsedToSubscribed = isUsedToSubscribed;
    // > 訂閱來源
    this.subscriptionSource = source;
    // > 年訂閱優惠
    this.hasYearDiscount = hasYearDiscount?.web ?? null;
    // > 是否有免費 7 天
    this.hasFreeTrial = hasFreeTrial?.web;
    // > 訂閱方案
    this.subscriptionType = item ?? null;

    // 登入方式.
    this.source = data.profile.source;

    // 點數.
    this.points = data.profile.points;

    // 生日字串.
    this.birthday = data.profile.birthday;

    // 是否為員工.
    this.isInternal = data.profile.isInternal;

    // 最後在線時間.
    this.lastOnlineAt = data.profile.lastOnlineAt;

    // 是否訂閱電子報.
    this.hasNewspaper = data.profile.hasNewspaper;

    // email 是否驗證.
    // 尚未驗證 Pending.
    // 驗證成功 Verified.
    this.status = data.profile.status;
    this.group = (() => {
      if (isSubscribed) {
        return 'Subscriber';
      }

      if (isUsedToSubscribed) {
        return 'ExpiredSubscriber';
      }

      return 'Member';
    })();
  }
}
