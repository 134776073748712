import { useEffect, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

// NOTE:  https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition.

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const navType = useNavigationType();
  const [state, updateState] = useState(null);

  useEffect(() => {
    if (!state) {
      updateState(pathname);
    }

    if (pathname?.split('/')[1] === 'course' && state?.split('/')[1] === 'course' && !pathname?.split('/')[4]) {
      updateState(pathname);
      return;
    }

    if (navType === 'POP') {
      setTimeout(() => {
        window.scrollTo(0, 0);
        updateState(pathname);
      }, 1);
      return;
    }

    updateState(pathname);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }, [pathname, state, navType]);


  return null;
}
