import dayjs from 'dayjs';
import Manuscript from './Manuscript';

export default class Announcement {
  static fromRes(data) {
    return new Announcement(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    this.id = data.id;

    this.title = data.title;

    this.date = data.date;

    // dayjs 實例.
    this.dayjsInstance = dayjs(this.date);
    this.dayjsFormat = this.dayjsInstance.format('MM月DD日');
  }
}
