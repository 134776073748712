import dayjs from 'dayjs';

export default class Chapter {
  constructor(data) {
    this.id = data.id;

    this.type = data.type;

    this.name = data.name;

    this.duration = Math.ceil(data.duration);

    this.popularity = data.popularity;

    this.lastAt = data.lastAt;

    this.isTrial = data.isTrial;

    this.isFavorite = data.isFavorite;

    this.document = data.document;

    this.course = {
      id: data.course?.id,

      name: data.course?.name,

      type: data.course?.type,

      saleType: data.course?.saleType,

      popularity: data.course?.popularity,

      releaseAt: data.course?.releaseAt,

      isOwned: data.course?.isOwned,

      thumbnails: {
        app: {
          url: data.course?.thumbnails.app.url,
          width: data.course?.thumbnails.app.width,
          height: data.course?.thumbnails.app.height
        },

        web: {
          url: data.course?.thumbnails.web.url,
          width: data.course?.thumbnails.web.width,
          height: data.course?.thumbnails.web.height
        }
      }
    };

    const isNew = (() => {
      const today = dayjs().startOf('day');
      const releaseDate = dayjs(data.course?.releaseAt);

      return today.diff(releaseDate, 'day') <= 14;
    })();

    const isPurchaseOnly = (() => {
      return data.course?.saleType === 'Single';
    })();

    // view Model 需求.
    this.courseId = data.course?.id;
    this.courseName = data.course?.name;
    // this.popularity = data.course?.popularity;
    this.createdAt = data.createdAt;
    this.isLastLearned = undefined;
    this.saleType = data.course?.saleType;
    this.isOwned = data.course?.isOwned;

    this.authorName = data.course?.author.name;
    this.isNew = isNew;
    this.courseType = data.course?.type;
    this.isPurchaseOnly = isPurchaseOnly;

    this.priority = data.priority;
  }

  static fromRes(data) {
    return new Chapter(data);
  }
}
