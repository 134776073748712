import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

class AlertViewModel {
  @observable isOpenAlert = false;

  constructor() {
    makeObservable(this);

    this.toggleOverflow = this.toggleOverflow.bind(this);
    this.handlerOpenAlert = this.handlerOpenAlert.bind(this);
    this.handlerCloseAlert = this.handlerCloseAlert.bind(this);
    this.handlerConfirmCloseAlert = this.handlerConfirmCloseAlert.bind(this);
    this.handlerCancelCloseAlert = this.handlerCancelCloseAlert.bind(this);
    this.handlerBackgroundCloseAlert
      = this.handlerBackgroundCloseAlert.bind(this);
  }

  toggleOverflow() {
    const doc = document.documentElement;
    const overflow = this.isOpenAlert ? 'hidden' : 'auto';

    doc.style.overflow = overflow;
  }

  // 開啟彈窗
  @action
  handlerOpenAlert() {
    this.isOpenAlert = true;
    this.toggleOverflow();
  }

  // 右上關閉彈窗.
  @action
  handlerCloseAlert() {
    this.isOpenAlert = false;
    this.toggleOverflow();
  }

  // 取消按鈕關閉彈窗.
  @action
  handlerCancelCloseAlert() {
    this.isOpenAlert = false;
    this.toggleOverflow();
  }

  // 確認按鈕關閉彈窗.
  @action
  handlerConfirmCloseAlert() {
    this.isOpenAlert = false;
    this.toggleOverflow();
  }

  // 背景關閉彈窗.
  @action
  handlerBackgroundCloseAlert() {
    this.isOpenAlert = false;
    this.toggleOverflow();
  }
}

export default AlertViewModel;
