export const Action = {
  UpdateProfile: 'UpdateProfile',
  UpdateStatus: 'UpdateStatus'
};

export const actions = (dispatch) => ({
  updateProfile: (profile) => dispatch({
    type: Action.UpdateProfile,
    payload: profile
  }),

  updateStatus: () => dispatch({
    type: Action.UpdateStatus
  })
});
