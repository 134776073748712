import {
  observable,
  makeObservable,
  action,
  computed,
  runInAction
} from 'mobx';
import AuthService from 'src/services/authenticate';
import AuthenticateError from 'src/services/error/models/authenticate';
import SelfValidator from '../../selfValidator';

class LoginViewModel {
  @observable email = '';
  @observable password = '';
  // 異步執行中, 阻擋 api 連發.
  @observable isAwait = false;
  // 顯示 res 錯誤提示.
  @observable isShowResMessage = false;
  @observable resMessage = 'Res 錯誤';

  @observable channel;

  @observable onCheck = {
    email: false,
    password: false,
    signUpEmail: false,
    signUpPassword: false
  };

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  // email 驗證.
  @computed
  get validEmail() {
    return SelfValidator.email(this.email);
  }

  // password 驗證.
  @computed
  get validPassword() {
    return SelfValidator.password(this.password);
  }

  // 阻擋 submit.
  @computed
  get isDisabledSubmit() {
    return this.isAwait || !this.validEmail || !this.validPassword;
  }

  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------

  @action
  setEmail = (event) => {
    this.email = event.target.value.trim();
    // 關閉 res 錯誤提示.
    this.isShowResMessage = false;
  };

  @action
  setPassword = (event) => {
    this.password = event.target.value.trim();
    // 關閉 res 錯誤提示.
    this.isShowResMessage = false;
  };

  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------

  // 前往登入.
  @action
  handlerToLogin = () => {
    this.isShowResMessage = false;
    this.props.handlerToLogin();
  };

  // 前往註冊.
  @action
  handlerToSignup = () => {
    this.isShowResMessage = false;
    this.props.handlerToSignup();
  };

  // 前往忘記密碼.
  handlerToForgotPassword = () => {
    this.props.handlerToForgotPassword();
  };

  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------

  @action
  setOnCheck = (type) => {
    switch (type) {
      case 'email':
        this.onCheck.email = true;
        break;
      case 'password':
        this.onCheck.password = true;
        break;
      case 'signUpEmail':
        this.onCheck.signUpEmail = true;
        break;
      case 'signUpPassword':
        this.onCheck.signUpPassword = true;
        break;
      default:
        break;
    }
  }

  // 登入.
  @action
  handlerLogin = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isDisabledSubmit) {
      // 異步執行中.
      this.isAwait = true;

      try {
        const res = await AuthService.login({
          email: this.email,
          password: this.password
        });

        runInAction(() => {
          // 來源紀錄.
          this.props.setSource(this.props.pages.login);
          // 保存 profile.
          this.props.setSaveProfile(res);
          // 前往下一步.
          this.props.handlerNextStep();
          // 異步結束.
          this.isAwait = false;
        });
      } catch (error) {
        runInAction(() => {
          // 異步結束.
          this.isAwait = false;
          // 顯示 res 錯誤.
          AuthenticateError.login(error);

        });
      }
    }
  };

  // 註冊.
  @action
  handlerSignup = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isDisabledSubmit) {
      // 異步執行中.
      this.isAwait = true;

      try {
        const res = await AuthService.signup({
          email: this.email,
          password: this.password
        });

        runInAction(() => {
          // 來源紀錄.
          this.props.setSource(this.props.pages.signup);
          // 保存 profile
          this.props.setSaveProfile(res);
          // 前往下一步.
          this.props.handlerNextStep();
          // 異步結束.
          this.isAwait = false;
        });
      } catch (error) {
        runInAction(() => {
          // 異步結束.
          this.isAwait = false;
          // 顯示 res 錯誤.
          AuthenticateError.signup(error);
        });
      }
    }
  };
}

export default LoginViewModel;
