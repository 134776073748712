import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import i18n from 'src/i18n';

import { withResizeDetector } from 'react-resize-detector';

import logoIcon from 'src/assets/header/logoIcon.svg';
import logoName from 'src/assets/header/logoName.svg';
import logoNameCT from 'src/assets/header/logoNameCT.svg';

import closeBtn from 'src/assets/closeWithBorder.svg';

import configStore from 'src/stores/config';
import { SUBSCRIPTION_ITEM, SUBSCRIPTION_SOURCE_TYPE } from 'src/constants';
import withProfile from 'src/components/withProfile';
// viewModel.
import HeaderViewModel from 'src/components/Header/viewModel';
// 登入.
import SignInView from 'src/components/Header/component/SignIn';
// 彈窗.
import AlertView from 'src/components/Alert';
// 搜尋.
import KeywordView from './component/Keyword';
// 消息中心.
import MessagesView from './component/Messages';
// 會員中心.
import ProfileNavView from './component/ProfileNav';


import styles from './styles.module.scss';

import withRouter from '../withRouter';

/**
 * 簡單的結構介紹.
 *
 * withProfile 是 HOC 高級元件, 該元件 DidMount 時, 會使用 UserService 的 getProfile 函式發出 API.user.profile 的請求, 請求成功後會呼叫 store 的 context, 將資料存放至最外層的 context 的 state 的 profile.
 */

@observer
class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new HeaderViewModel();
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    const { height } = this.props;

    this.vm.didUpdate(this.props);
    if (height !== prevProps.height) {
      this.props.updateHeight(height);
    }
  }

  componentWillUnmount() {
    this.vm.unMount();
  }

  _getProfileText = (profile) => {
    if (!profile) {
      return i18n.t('header_subscription_free_trial');
    }
    const { group, subscriptionType, hasFreeTrial } = profile;
    // const { group, subscriptionType, hasFreeTrial, source } = profile;
    if (group === 'Member' || hasFreeTrial) {
      return i18n.t('header_subscription_free_trial');
    }
    if (group === 'ExpiredSubscriber') {
      return i18n.t('header_subscription_expired');
    }
    // if (source === SUBSCRIPTION_SOURCE_TYPE.Redeem) {
    if ([SUBSCRIPTION_ITEM.Free7].includes(subscriptionType)) {

      return configStore.isYearlyOn ? i18n.t('header_subscription_first_year') : i18n.t('header_subscription_check_plan');
    }
    // if ([SUBSCRIPTION_ITEM.Monthly, SUBSCRIPTION_ITEM.Free7].includes(subscriptionType)) {
    if ([SUBSCRIPTION_ITEM.Monthly].includes(subscriptionType)) {
      return configStore.isYearlyOn ? i18n.t('header_subscription_upgrade') : i18n.t('header_subscription_check_plan');
    }

    return i18n.t('header_subscription_check_plan');
  }

  _renderContent = () => {
    const { isUpdated } = this.props.context.state;
    const { headerHeight, width, profile } = this.props;
    return (
      <div className={clsx('header', styles.headerContainer, styles.headerContainer_layout)}>
        <div className={clsx(styles.mainContent, styles.mainContent_layout)}>
          <div className={clsx(styles.contentFlexBox, styles.contentFlexBox_layout)}>
            <div className={clsx(styles.contentFlexBox_item)}>
              <Link
                to="/"
                className={clsx(styles.logoFlexBox, styles.logoFlexBox_layout)}
              >
                <div className={clsx(styles.logoFlexBox_item)}>
                  <div
                    style={{ '--src': `url(${logoIcon})` }}
                    className={clsx(styles.icon, styles.icon_layout)}
                  />
                </div>
                <div className={clsx(styles.logoFlexBox_spacer)} />
                <div className={clsx(styles.logoFlexBox_item1)}>
                  <img
                    src={logoName}
                    alt="alt text"
                    className={clsx(styles.name, styles.name_layout)}
                  />
                </div>
                <div className={clsx(styles.logoFlexBox_spacer1)} />
                <div className={clsx(styles.logoFlexBox_item2)}>
                  <img
                    src={logoNameCT}
                    alt="alt text"
                    className={clsx(styles.nameCT, styles.nameCT_layout)}
                  />
                </div>
              </Link>
            </div>
            <div className={clsx(styles.contentFlexBox_spacer)} />
            <KeywordView />
            <div className={clsx(styles.contentFlexBox_spacer2)} />
            <Link
              className={styles.subscriptionBtn}
              to="/subscription"
            >
              <div className={styles.vipIcon} />
              <div className={styles.subDesc}>
                {this._getProfileText(profile)}
              </div>
            </Link>
            <div className={clsx(styles.contentFlexBox_spacer22)} />
            {
              isUpdated && (
                <>
                  {
                    this.props.profile && (
                      <>
                        <Link
                          to="/favorite"
                          className={clsx(styles.contentFlexBox_item3)}
                        >
                          <h5 className={clsx(styles.myCourse, styles.myCourse_layout)}>
                            {i18n.t('header_mine')}
                          </h5>
                        </Link>
                        <div className={clsx(styles.contentFlexBox_spacer3)} />
                      </>
                    )
                  }
                  <div className={clsx(styles.contentFlexBox_item4)}>
                    <MessagesView />
                  </div>
                  <div className={clsx(styles.contentFlexBox_spacer4)} />
                  {
                    this.props.profile
                      ? (
                        <div className={clsx(styles.contentFlexBox_item5)}>
                          <ProfileNavView
                            textStyle={clsx(styles.user_name, styles.user_name_layout)}
                            headerHeight={headerHeight}
                            width={width}
                          />
                        </div>
                      )
                      : (
                        <div className={clsx(styles.contentFlexBox_item5)}>
                          <div
                            className={clsx(styles.loginContain)}
                            onClick={this.vm.signInViewModel.handleOpen}
                          >
                            <h5 className={clsx(styles.login)}>
                              {i18n.t('profile_login_n_register')}
                            </h5>
                          </div>
                        </div>
                      )
                  }
                </>
              )
            }
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { headerRef } = this.props;

    return (
      <section
        className={clsx(
          styles.header,
          styles.header_layout,
          'header-with-subscription',
          'header-fixed',
          !this.vm.isShowHeader && styles.hideHeader,
          !this.vm.isShowHeader && 'hide-header'
        )}
        ref={headerRef}
      >
        <div
          className={clsx(
            styles.headerMainContainer,
            styles.headerMainContainer_layout
          )}
        >

          {this._renderContent()}
        </div>
        <SignInView />
        {/* popup */}
        {this.vm.isOpenAlert && (
          <AlertView
            vm={this.vm}
            showButtons={false}
            showClose={false}
            className={styles.highest}
            contentContainerStyles={styles.popupContainer}
          >
            <div className={styles.popup} onClick={this.vm.onGoToLink}>
              <img
                src={this.vm.popupImageUrl}
                alt="barrage"
                className={styles.popupImage}
              />
            </div>
            <div
              className={styles.closeBtnContainer}
              onClick={this.vm.handlerCloseAlert}
            >
              <img src={closeBtn} alt="closeBtn" className={styles.closeBtn} />
            </div>
          </AlertView>
        )}
      </section>
    );
  }
}

HeaderView.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  headerHeight: PropTypes.number,
  profile: PropTypes.object,
  updateHeight: PropTypes.func,
  context: PropTypes.object
};

HeaderView.defaultProps = {
  height: 0,
  width: 0,
  headerHeight: 79,
  profile: {},
  updateHeight: () => { },
  context: {}
};

export default withResizeDetector(withProfile(withRouter(HeaderView), false));
