import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';

import RecommendIcon from 'src/assets/homepage/recommendIcon.svg';

import RecommendCard from 'src/components/ChapterCards/RecommendCard';

import styles from './styles.module.scss';

@observer
class RecommendSection extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { viewModel } = this.props;
    return (
      <section className={clsx(styles.recommendSection, styles.recommendSection_layout)}>
        <div className={clsx(styles.contentFlexBox, styles.contentFlexBox_layout)}>
          <div className={clsx(styles.titleContainer, styles.titleContainer_layout)}>
            <div className={clsx(styles.titleContainer_item)}>
              <div className={clsx(styles.titleFlex, styles.titleFlex_layout)}>
                <div className={clsx(styles.titleFlex_item)}>
                  <div
                    style={{ '--src': `url(${RecommendIcon})` }}
                    className={clsx(styles.recommendIcon, styles.recommendIcon_layout)}
                  />
                </div>
                <div className={clsx(styles.titleFlex_spacer)} />
                <div className={clsx(styles.titleFlex_item1)}>
                  <h1 className={clsx(styles.recommendTitle, styles.recommendTitle_layout)}>
                    {i18n.t('home_title_you_may_like')}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className={clsx(styles.contentFlexBox_spacer)} />

          <div className={clsx(styles.cardsContainer, styles.cardsContainer_layout)}>
            {
              viewModel.cardList?.map((card) => (
                <div
                  key={card.id}
                  className={clsx(styles.cardContainer, styles.cardContainer_layout)}
                >
                  <RecommendCard viewModel={card} />
                </div>
              ))
            }
          </div>
        </div>
      </section>
    );
  }
}

RecommendSection.propTypes = {
  viewModel: PropTypes.object
};

RecommendSection.defaultProps = {
  viewModel: {}
};

export default RecommendSection;
