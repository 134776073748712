import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';

import dayjs from 'dayjs';

import { AppStateContext } from 'src/stores';
import Auth from 'src/services/auth';
import AuthService from 'src/services/authenticate';
import TradeService from 'src/services/trade';
import withRouter from 'src/components/withRouter';
import logoutAlertViewModel from 'src/components/LogoutAlert/viewModel';

import { ErrorToast } from 'src/libs/toast';

let updatedAt = null;
let isUpdating = false;

const withProfile = (WrappedComponent, isRequired = true) => {
  class WithProfileHOC extends React.Component {
    static propTypes = {
      router: PropTypes.object.isRequired,
      forwardedRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ])
    }

    async componentDidMount() {
      const { actions } = this.context ?? {};
      const hasToken = Auth.hasToken();

      try {
        const now = dayjs().valueOf();
        if (updatedAt === null || dayjs(now).diff(dayjs(updatedAt)) > 3000) {
          updatedAt = now;
          isUpdating = true;

          const p = await AuthService.getProfile();
          const points = await TradeService.getUserPoint(p.id);

          actions.updateProfile({ ...p, points });
          actions.updateStatus();

          isUpdating = false;
        } else if (isRequired && !this.context.state.profile && !hasToken) {
          throw new Error('no permission');
        }

      } catch (err) {
        if (hasToken) {
          logoutAlertViewModel.handlerOpenAlert(err?.response?.status);
          Auth.removeToken();
        }
        actions.updateProfile(null);
        actions.updateStatus();
        // UserService.logout();

        if (isRequired) {
          this.props.router?.navigate('/');
        }
      }
    }

    componentDidUpdate() {
      // 有開啟頁面驗證, 並且 profile 不存在, 跳轉至首頁.
      if (isRequired && !this.context.state.profile && updatedAt) {
        this.props.router.navigate('/');
      }
    }

    render() {
      const {
        forwardedRef,
        router,
        ...ps
      } = this.props;

      return isRequired && !this.context.state.profile ? null : (
        <WrappedComponent
          {...ps}
          profile={this.context.state.profile}
          isUpdated={this.context.state.isUpdated}
          ref={forwardedRef}
        />
      );
    }
  }

  WithProfileHOC.contextType = AppStateContext;

  const Component = withRouter(WithProfileHOC);

  return React.forwardRef((props, ref) => {
    return <Component {...props} forwardedRef={ref} />;
  });
};

export default withProfile;
