import dayjs from 'dayjs';

export default class TradeInfo {
  constructor(data) {

    this.init(data);
  }

  init = (data) => {
    console.log('data', data);
    const { type, item, price: payPrice, createdAt, coupon, amount } = data.record;
    if (type === 'Course') {
      const { id, type: courseType, name, price, discount, thumbnails } = item;
      this.transactionType = type;
      this.transactionTime = dayjs(createdAt).format('YYYY-MM-DD -- HH:mm:ss');
      this.transactionPrice = payPrice;
      this.isUsedCoupon = !!coupon;

      this.itemId = id;
      this.itemType = courseType;
      this.itemName = name;
      this.itemPrice = price;
      this.itemDiscount = discount;
      this.itemThumbnail = thumbnails.web.url;
    } else {
      this.itemType = item;
    }

    if (type === 'Subscription' || type === 'FreeTrialSubscription' || type === 'BindCard') {
      this.transactionType = type;
      this.transactionTime = dayjs(createdAt).format('YYYY-MM-DD -- HH:mm:ss');
      this.transactionPrice = payPrice;
      this.isUsedCoupon = !!coupon;
      this.amount = Math.ceil(amount);
    }
  }

  static fromRes(data) {
    return new TradeInfo(data);
  }
}
