export default class CardInfo {
  constructor(data) {
    const {
      lastFour,
      type,
      expiryDate
    } = data;

    this.lastFour = lastFour;
    this.expiryDate = (() => {
      const month = expiryDate.slice(4, 6);
      const year = expiryDate.slice(2, 4);
      return `${month}/${year}`;
    })();
    this.type = (() => {
      switch (type) {
        case 1:
          return 'VISA';
        case 2:
          return 'Master Card';
        case 3:
          return 'JCB';
        case 4:
          return 'Union Pay';
        case 5:
          return 'AMEX';
        default:
          return 'UNKNOWN';
      }
    })();
  }

  static fromRes(data) {
    return new CardInfo(data);
  }
}
