import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';
import { Link } from 'react-router-dom';

import withRouter from 'src/components/withRouter';

import Logo from 'src/assets/account/mainLogo.png';
import AccountBg from 'src/assets/account/bg.png';
import CloseBtn from 'src/assets/account/closeBtn.svg';
import BackIcon from 'src/assets/account/back.svg';

import LoginViewModel from './viewModel';
import styles from './style.module.scss';
import InputView from '../input';
import LogoView from '../Logo';
import ButtonView from '../Button';


@observer
class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new LoginViewModel(props);
  }

  _renderLogin = () => {
    return (
      <>
        <div className={clsx(styles.codeInputContainer, styles.codeInputContainer_layout)}>
          <div className={clsx(styles.codeFlex, styles.codeFlex_layout)}>
            <h5 className={clsx(styles.title, styles.title_layout)}>
              {i18n.t('login_email')}
            </h5>
            <div className={clsx(styles.passwordFlex_item1)}>
              <InputView
                value={this.vm.email}
                handlerChange={this.vm.setEmail}
                onSubmit={this.vm.handlerLogin}
                valid={this.vm.validEmail}
                placeholder={i18n.t('login_email_placeholder')}
                inputStyle={clsx(styles.placeholder, styles.placeholder_layout)}
                inputContainerStyles={styles.noBorder}
                onBlur={() => this.vm.setOnCheck('email')}
              />
            </div>
          </div>

          <hr className={clsx(styles.divider, styles.divider_layout)} />
          {
            (!this.vm.validEmail && this.vm.onCheck.email) && (
              <div className={clsx(styles.errorAlert, styles.errorAlert_layout)}>
                {i18n.t('login_email_wrong')}
              </div>
            )
          }
        </div>

        <div className={clsx(styles.passwordContainer, styles.passwordContainer_layout)}>
          <div className={clsx(styles.passwordBox, styles.passwordBox_layout)}>
            <div className={clsx(styles.passwordFlex, styles.passwordFlex_layout)}>
              <div className={clsx(styles.passwordFlex_item)}>
                <h5 className={clsx(styles.title, styles.title_layout1)}>
                  {i18n.t('login_password')}
                </h5>
              </div>
              <div className={clsx(styles.passwordFlex_item1)}>
                <InputView
                  value={this.vm.password}
                  handlerChange={this.vm.setPassword}
                  onSubmit={this.vm.handlerLogin}
                  valid={this.vm.validPassword}
                  placeholder={i18n.t('login_password_placeholder')}
                  type="password"
                  inputStyle={clsx(styles.placeholder, styles.placeholder_layout)}
                  inputContainerStyles={styles.noBorder}
                  onBlur={() => this.vm.setOnCheck('password')}
                />
              </div>
            </div>
          </div>

          <hr className={clsx(styles.divider, styles.divider_layout)} />
          {
            (!this.vm.validPassword && this.vm.onCheck.password) && (
              <div className={clsx(styles.errorAlert, styles.errorAlert_layout)}>
                {i18n.t('login_password_wrong')}
              </div>
            )
          }
        </div>

        <div className={clsx(styles.componentContainer_item1)}>
          <div
            className={clsx(styles.submitBtn, styles.submitBtn_layout)}
            onClick={this.vm.handlerLogin}
          >
            <h5 className={clsx(styles.submitText, styles.submitText_layout)}>
              {i18n.t('login_submit')}
            </h5>
          </div>
        </div>


        <div className={clsx(styles.componentContainer_item1)}>
          <div
            className={clsx(styles.forgetBtn, styles.forgetBtn_layout)}
            onClick={this.vm.handlerToForgotPassword}
          >
            <h5 className={clsx(styles.forgetText, styles.forgetText_layout)}>
              {i18n.t('login_forget_password')}
            </h5>
          </div>
        </div>
      </>
    );
  }

  _renderSignup = () => {
    return (
      <>
        <div className={clsx(styles.codeInputContainer, styles.codeInputContainer_layout)}>
          <div className={clsx(styles.codeFlex, styles.codeFlex_layout)}>
            <h5 className={clsx(styles.title, styles.title_layout)}>
              {i18n.t('login_email')}
            </h5>
            <div className={clsx(styles.passwordFlex_item1)}>
              <InputView
                value={this.vm.email}
                handlerChange={this.vm.setEmail}
                onSubmit={this.vm.handlerSignup}
                valid={this.vm.validEmail}
                placeholder={i18n.t('login_email_placeholder')}
                inputStyle={clsx(styles.placeholder, styles.placeholder_layout)}
                inputContainerStyles={styles.noBorder}
                onBlur={() => this.vm.setOnCheck('signUpEmail')}
              />
            </div>
          </div>

          <hr className={clsx(styles.divider, styles.divider_layout)} />
          {
            (!this.vm.validEmail && this.vm.onCheck.signUpEmail) && (
              <div className={clsx(styles.errorAlert, styles.errorAlert_layout)}>
                {i18n.t('login_email_wrong')}
              </div>
            )
          }
        </div>

        <div className={clsx(styles.passwordContainer, styles.passwordContainer_layout)}>
          <div className={clsx(styles.passwordBox, styles.passwordBox_layout)}>
            <div className={clsx(styles.passwordFlex, styles.passwordFlex_layout)}>
              <div className={clsx(styles.passwordFlex_item)}>
                <h5 className={clsx(styles.title, styles.title_layout1)}>
                  {i18n.t('login_password')}
                </h5>
              </div>
              <div className={clsx(styles.passwordFlex_item1)}>
                <InputView
                  value={this.vm.password}
                  handlerChange={this.vm.setPassword}
                  onSubmit={this.vm.handlerSignup}
                  valid={this.vm.validPassword}
                  placeholder={i18n.t('login_password_placeholder')}
                  type="password"
                  inputStyle={clsx(styles.placeholder, styles.placeholder_layout)}
                  inputContainerStyles={styles.noBorder}
                  onBlur={() => this.vm.setOnCheck('signUpPassword')}
                />
              </div>
            </div>
          </div>

          <hr className={clsx(styles.divider, styles.divider_layout)} />
          {
            (!this.vm.validPassword && this.vm.onCheck.signUpPassword) && (
              <div className={clsx(styles.errorAlert, styles.errorAlert_layout)}>
                {i18n.t('login_password_wrong')}
              </div>
            )
          }
        </div>

        <div className={clsx(styles.componentContainer_item1)}>
          <div
            className={clsx(styles.submitBtn, styles.submitBtn_layout)}
            onClick={this.vm.handlerSignup}
          >
            <h5 className={clsx(styles.submitText, styles.submitText_layout)}>
              {i18n.t('signup_submit')}
            </h5>
          </div>
        </div>

      </>
    );
  }

  render() {
    const { page, pages, handlerCloseAlert, handleBack } = this.props;

    const isLogin = page === pages.login;

    return (
      <div style={{ '--src': `url(${AccountBg})` }} className={`login2password ${clsx(styles.forgetPassword, styles.forgetPassword_layout)}`}>
        <div className={clsx(styles.contentContainer, styles.contentContainer_layout)}>
          <div className={styles.flexBox}>
            <div
              style={{ '--src': `url(${BackIcon})` }}
              className={clsx(styles.closeBtn, styles.closeBtn_layout)}
              onClick={handleBack}
            />
            <div
              style={{ '--src': `url(${CloseBtn})` }}
              className={clsx(styles.closeBtn, styles.closeBtn_layout)}
              onClick={handlerCloseAlert}
            />
          </div>


          <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
            <div style={{ '--src': `url(${Logo})` }} className={clsx(styles.mainLogo, styles.mainLogo_layout)} />
            <div className={clsx(styles.contentFlex_spacer)} />
            <div className={clsx(styles.inputsContainer, styles.inputsContainer_layout)}>
              <div className={`loginpassword ${clsx(styles.newPasswordComponent, styles.newPasswordComponent_layout)}`}>
                <div className={clsx(styles.componentContainer, styles.componentContainer_layout)}>
                  <h2 className={clsx(styles.componentTitle, styles.componentTitle_layout)}>
                    {isLogin ? i18n.t('login_title') : i18n.t('signup_title')}
                  </h2>
                  {
                    isLogin
                      ? this._renderLogin()
                      : this._renderSignup()
                  }
                </div>
              </div>
            </div>
            <div className={clsx(styles.contentFlex_spacer1)} />
            <Link to="/term-of-use" target="_blank">
              <div className={clsx(styles.policyBtns, styles.policyBtns_layout)}>
                {i18n.t('login_forget_password_protocol')}
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

LoginView.propTypes = {
  router: PropTypes.object,
  handlerCloseAlert: PropTypes.func,
  handleBack: PropTypes.func,
  page: PropTypes.string,
  pages: PropTypes.object
};

LoginView.defaultProps = {
  router: {},
  handlerCloseAlert: () => { },
  handleBack: () => { },
  page: '',
  pages: {}
};

export default withRouter(LoginView);
