import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { Link } from 'react-router-dom';

import Logo from 'src/assets/account/mainLogo.png';
import AccountBg from 'src/assets/account/bg.png';
import CloseBtn from 'src/assets/account/closeBtn.svg';
import BackIcon from 'src/assets/account/back.svg';

import ResetPasswordViewModel from './viewModel';

import LogoView from '../Logo';
import InputView from '../input';
import ButtonView from '../Button';
import styles from './style.module.scss';


@observer
class ResetPasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ResetPasswordViewModel(props);
  }

  render() {
    const { handleBack } = this.props;
    return (
      <div style={{ '--src': `url(${AccountBg})` }} className={`login2password ${clsx(styles.forgetPassword, styles.forgetPassword_layout)}`}>
        <div className={clsx(styles.contentContainer, styles.contentContainer_layout)}>
          <div className={styles.flexBox}>
            <div
              style={{ '--src': `url(${BackIcon})` }}
              className={clsx(styles.closeBtn, styles.closeBtn_layout)}
              onClick={handleBack}
            />
            <div
              style={{ '--src': `url(${CloseBtn})` }}
              className={clsx(styles.closeBtn, styles.closeBtn_layout)}
              onClick={this.props.handlerCloseAlert}
            />
          </div>

          <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
            <div style={{ '--src': `url(${Logo})` }} className={clsx(styles.mainLogo, styles.mainLogo_layout)} />
            <div className={clsx(styles.contentFlex_spacer)} />
            <div className={clsx(styles.inputsContainer, styles.inputsContainer_layout)}>
              <div className={`loginpassword ${clsx(styles.newPasswordComponent, styles.newPasswordComponent_layout)}`}>
                <div className={clsx(styles.componentContainer, styles.componentContainer_layout)}>
                  <h2 className={clsx(styles.componentTitle, styles.componentTitle_layout)}>
                    {i18n.t('login_new_password_title')}
                  </h2>

                  <div className={clsx(styles.codeInputContainer, styles.codeInputContainer_layout)}>
                    <div className={clsx(styles.codeFlex, styles.codeFlex_layout)}>
                      <h5 className={clsx(styles.title, styles.title_layout)}>
                        {i18n.t('login_new_password_code')}
                      </h5>
                      <div className={clsx(styles.passwordFlex_item1)}>
                        <InputView
                          value={this.vm.code}
                          handlerChange={this.vm.setCode}
                          onSubmit={this.vm.handlerSubmit}
                          valid={this.vm.validCode}
                          placeholder={i18n.t('login_new_password_code_input')}
                          inputStyle={clsx(styles.placeholder, styles.placeholder_layout)}
                          inputContainerStyles={styles.noBorder}
                          onBlur={() => this.vm.setOnCheck('code')}
                        />
                      </div>
                    </div>

                    <hr className={clsx(styles.divider, styles.divider_layout)} />
                    {
                      (!this.vm.validCode && this.vm.onCheck.code) && (
                        <div className={clsx(styles.errorAlert, styles.errorAlert_layout)}>
                          {i18n.t('login_new_password_code_error')}
                        </div>
                      )
                    }
                  </div>

                  <div className={clsx(styles.passwordContainer, styles.passwordContainer_layout)}>
                    <div className={clsx(styles.passwordBox, styles.passwordBox_layout)}>
                      <div className={clsx(styles.passwordFlex, styles.passwordFlex_layout)}>
                        <div className={clsx(styles.passwordFlex_item)}>
                          <h5 className={clsx(styles.title, styles.title_layout1)}>
                            {i18n.t('login_new_password_new_password')}
                          </h5>
                        </div>
                        <div className={clsx(styles.passwordFlex_item1)}>
                          <InputView
                            value={this.vm.password}
                            handlerChange={this.vm.setPassword}
                            onSubmit={this.vm.handlerSubmit}
                            valid={this.vm.validPassword}
                            placeholder={i18n.t('login_password_placeholder')}
                            type="password"
                            inputStyle={clsx(styles.placeholder, styles.placeholder_layout)}
                            inputContainerStyles={styles.noBorder}
                            onBlur={() => this.vm.setOnCheck('password')}
                          />
                        </div>
                      </div>
                    </div>

                    <hr className={clsx(styles.divider, styles.divider_layout)} />
                    {
                      (!this.vm.validPassword && this.vm.onCheck.password) && (
                        <div className={clsx(styles.errorAlert, styles.errorAlert_layout)}>
                          {i18n.t('login_new_password_new_password_error')}
                        </div>
                      )
                    }
                  </div>

                  <div className={clsx(styles.componentContainer_item)}>
                    <div className={clsx(styles.passwordConfirmContainer, styles.passwordConfirmContainer_layout)}>
                      <div className={clsx(styles.passwordConfirmBox, styles.passwordConfirmBox_layout)}>
                        <div className={clsx(styles.passwordConfirmFlex, styles.passwordConfirmFlex_layout)}>
                          <div className={clsx(styles.passwordConfirmFlex_item)}>
                            <h5 className={clsx(styles.title, styles.title_layout2)}>
                              {i18n.t('login_new_password_new_password＿confirm')}
                            </h5>
                          </div>
                          <div className={clsx(styles.passwordConfirmFlex_item1)}>
                            <InputView
                              value={this.vm.passwordAgain}
                              handlerChange={this.vm.setPasswordAgain}
                              onSubmit={this.vm.handlerSubmit}
                              valid={this.vm.validPasswordAgain}
                              placeholder={i18n.t('login_password_placeholder')}
                              type="password"
                              inputStyle={clsx(styles.placeholder, styles.placeholder_layout)}
                              inputContainerStyles={styles.noBorder}
                              onBlur={() => this.vm.setOnCheck('passwordCheck')}
                            />
                          </div>
                        </div>
                      </div>
                      <hr className={clsx(styles.divider, styles.divider_layout)} />
                      {
                        (!this.vm.validPasswordAgain && this.vm.onCheck.passwordCheck) && (
                          <div className={clsx(styles.errorAlert, styles.errorAlert_layout)}>
                            {i18n.t('login_new_password_new_password＿confirm_error')}
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className={clsx(styles.componentContainer_item1)}>
                    <div
                      className={clsx(styles.submitBtn, styles.submitBtn_layout)}
                      onClick={this.vm.handlerSubmit}
                    >
                      <h5 className={clsx(styles.submitText, styles.submitText_layout)}>
                        {i18n.t('login_new_password_set_password')}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={clsx(styles.contentFlex_spacer1)} />
            <Link to="term-of-use" target="_blank">
              <div className={clsx(styles.policyBtns, styles.policyBtns_layout)}>
                {i18n.t('login_forget_password_protocol')}
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordView;
