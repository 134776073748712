import { request, getHost } from './utils';

// 更新會員資料.
export const updateProfile = ({ id, data, token }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/user/${id}`,

    headers: {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    data
  };

  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

// get point history.
export const getPointHistory = (token, uid, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/${uid}/points/history`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: 10,
      anchor
    }
  };
  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

// 讀取優惠卷資料.
export const getCoupon = ({ id, token, anchor, status }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/coupon`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit: 10,
      anchor,
      status
    }
  };

  return request(options);
};

// 輸入優惠卷.
export const postCoupon = ({ id, token, data }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/${id}/coupon`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    data
  };

  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

// 讀取兌換紀錄.
export const getRedeem = ({ id, token, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/redemption`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 輸入兌換.
export const postRedeem = ({ id, token, data }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/${id}/redemption/redeem`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data
  };

  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

// 讀取訂單列表資料.
export const getPurchaseList = ({ id, token, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/purchase/history`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 讀取單筆訂單資料.
export const getPurchaseItem = ({ id, pid, token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/purchase/${pid}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

// 學習履歷, 歷史紀錄
export const getLearnedStatus = ({ id, token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/learned/status`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// 學習履歷, 歷史紀錄
export const getLearnedHistory = ({ id, token, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/learned/history`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

// 讀取現在可以勾選的興趣列表.
export const getTag = () => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/tag`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  return request(options);
};

// 讀取會員的興趣.
export const getUserTag = ({ id, token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/tag`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// 設定會員的興趣.
export const putUserTag = ({ id, token, data }) => {
  const options = {
    method: 'put',

    url: `${getHost()}/api/v1/user/${id}/tag`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    data
  };

  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

// 課程的播放進度, type 是課程的類型.
export const getLearnedType = ({ id, type, limit, anchor, token }) => {
  const calcType = type ? `/${type}` : '';

  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/learned/course${calcType}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 我收藏的課程.
export const getFavoriteCourse = ({ id, token, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/${id}/favorite/course`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// 我收藏的單集.
export const getFavoriteChapter = ({ id, token, limit, anchor }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v2/user/${id}/favorite/chapter`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit,
      anchor
    }
  };

  return request(options);
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
