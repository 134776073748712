import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const PointRecharge = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="為什麼需要儲值點數" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            為配合 Google / Apple 公司相關規範，在 sandshour 散時間 App 內購買學習課程，需要使用點數付款，點數與現金採1：1轉換。
          </div>
          <div className={useStyles.content}>
            如需購買課程，你可以在個人帳號中儲值足夠點數，購買課程時會直接扣除與售價相同的點數。（於 App 內儲值的點數，在網站結帳時也能使用抵扣）
          </div>
          <div className={useStyles.content}>
            若需要以信用卡支付購買課程，可以前往網站購買。
          </div>

        </div>
      </FAQCard>
      <FAQCard title="如何進行線上儲值" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊功能列右下方
            <span className={useStyles.bolder}>
              「我的」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點選
            <span className={useStyles.bolder}>
              「點數」
            </span>
            ，選取欲儲值的點數金額，系統將引導至 Google play / App store 系統結帳，即完成點數儲值，購買發票將由 Google / Apple 公司開立
          </div>
        </div>
      </FAQCard>
      <FAQCard title="點數使用期限" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            個人帳號中已儲值之點數於平台存續期間無使用期限。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="無法儲值" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            請確認所屬 Apple / Google 支付系統綁定之信用卡資料是否正確，或來信至客服信箱
            <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`} className={useStyles.aLink}>
              service@sandshour.com
            </a>
            ，我們會協助查詢確認狀況。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="扣款成功卻沒有儲值點數或金額與儲值點數不符" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            請提供會員帳號、扣款成功通知憑證，來信至客服信箱
            <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`} className={useStyles.aLink}>
              service@sandshour.com
            </a>
            ，我們會協助查詢確認狀況。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="點數儲值退款" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            在 sandshour 散時間 App 內儲值之點數係透過系統商  Google Play 或 Apple Store 平台完成付款，如有退款需求需直接向原購買平台申請。
          </div>
          <div className={useStyles.content}>
            請點擊以下平台客服管道提出退費需求，及詳述退費原因。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            <span className={useStyles.bolder}>
              Google Play 客服中心：
            </span>
            <a href="https://support.google.com/googleplay/answer/7205930 " className={useStyles.aLink}>
              瞭解 Google Play 退款相關資訊
            </a>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            <span className={useStyles.bolder}>
              App store 客服中心：
            </span>
            <a href="https://support.apple.com/zh-tw/HT204084" className={useStyles.aLink}>
              針對購自 Apple 的 App 或內容申請退款
            </a>
          </div>
        </div>
      </FAQCard>
    </>
  );

};

export default PointRecharge;
