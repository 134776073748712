import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import UserService from 'src/services/user';
import UserError from 'src/services/error/models/user';
import { ErrorToast } from 'src/libs/toast';
import i18n from 'src/i18n';
import TagViewModel from './TagViewModel';

export default class InputInterestsViewModel {
  @observable ids = [];
  @observable hasNewspaper = true;
  @observable isAwait = false;
  @observable isShowResMessage = false;
  @observable resMessage = '';

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------------------------------------------

  @computed
  get filterIds() {
    return this.ids.filter((item) => item.isChecked)?.map((item) => item.id);
  }

  @computed
  get isDisabledSubmit() {
    return this.isAwait;
  }

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------------------------------------------

  @action
  setHasNewspaper = () => {
    this.hasNewspaper = !this.hasNewspaper;
  };

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------------------------------------------

  // 設置興趣.
  handlerPutTagAPI = async () => {
    try {
      await UserService.putUserTag({
        id: this.props.saveProfile.id,
        data: {
          ids: this.filterIds
        }
      });
    } catch (error) {
      UserError.putUserTag(error);
    }
  };

  // 設置是否要訂閱新聞.
  handlerUpdateProfileAPI = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('hasNewspaper', this.hasNewspaper);

    try {
      const res = await UserService.updateProfile({
        id: this.props.saveProfile.id,
        data: bodyFormData
      });

      runInAction(() => {
        // 保存 profile.
        this.props.setSaveProfile(res);
        // 前往下一步.
        this.props.handlerNextStep();
      });
    } catch (error) {
      runInAction(() => {
        UserError.updateProfile(error);
      });
    }
  };

  // 按鈕, 完成註冊.
  @action
  handlerSubmit = async () => {
    if (!this.isDisabledSubmit) {
      this.isAwait = true;

      // 更新興趣.
      await this.handlerPutTagAPI();

      // 更新 profile.
      await this.handlerUpdateProfileAPI();

      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------------------------------------------

  // 讀取可選的興趣列表.
  didMount = async () => {
    try {
      // 可勾選的 tag.
      const res = await UserService.getTag();

      runInAction(() => {
        this.ids = res.list.map((item) => new TagViewModel(item));
      });
    } catch (error) {
      runInAction(() => {
        UserError.getTag(error);
      });
    }
  };
}
