import API from 'src/apis';
import Notification from 'src/models/response/Notification';
import Announcement from 'src/models/response/Announcement';
import Manuscript from 'src/models/response/Manuscript';
import messages from 'src/json/messages.json';
import Auth from './auth';

export default class MessageService {
  // 通知列表.
  static async getNotificationsList({ id, limit, anchor }) {
    const res = await API.message.getNotificationsList({
      id,
      limit,
      anchor,
      token: Auth.getToken()
    });

    return {
      anchor: res.data.anchor,
      totalUnreadCount: res.data.totalUnreadCount,
      list: res.data.list.map((item) => new Notification(item))
    };
  }

  // 讀取通知.
  static async putNotification({ id, nid }) {
    const res = await API.message.putNotification({
      id,
      nid,
      token: Auth.getToken()
    });

    return res.data;
  }

  // 讀取全部通知.
  static async putNotificationAllRead({ id }) {
    const res = await API.message.putNotificationAllRead({
      id,
      token: Auth.getToken()
    });

    return res.data;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 公告列表.
  static async getAnnouncementsList({ limit, anchor }) {
    const res = await API.message.getAnnouncementsList({ limit, anchor });

    return {
      anchor: res.data.anchor,
      list: res.data.list.map((item) => new Announcement(item))
    };
  }

  // 公告詳情
  static async getAnnouncement({ id }) {
    const res = await API.message.getAnnouncement({ id });

    return res.data.announcement;
  }
}
