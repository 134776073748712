import i18n from 'src/i18n';
import screenfull from 'screenfull';
import { ErrorToast } from 'src/libs/toast';
import Auth from 'src/services/auth';
import { getHost } from 'src/apis/utils';
import playerStore from 'src/components/Player/store';

import logoutAlertViewModel from 'src/components/LogoutAlert/viewModel';


export default class CommonError {
  static default401Error(cb = () => {}) {
    playerStore.isPaused = true;


    if (playerStore.isScreenfull && screenfull.isEnabled) {
      screenfull.exit(playerStore.mediaContainerRef.current);
    }

    if (playerStore.iosFullscreen) {
      const player = playerStore.mediaRef.current.getInternalPlayer();
      player.webkitExitFullScreen();
    }

    Auth.removeToken();

    logoutAlertViewModel.handlerOpenAlert();

  }

  static defaultError(status) {
    switch (status) {
      case 400:
        ErrorToast(i18n.t('api_common_400'));
        break;
      case 401:
        this.default401Error();
        break;
      case 403:
        ErrorToast(i18n.t('api_common_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_common_403'));
        break;
      default:
        ErrorToast(`${i18n.t('api_common_general')}; 異常代碼: ${status}`);
        break;
    }
  }
}
