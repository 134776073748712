import { ErrorToast } from 'src/libs/toast';
import i18n from 'src/i18n';
import CommonError from './common';

export default class PlayerError {
  static getChapter(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getChapterPrev(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getChapterNext(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getChapterList(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getPlayerUrl(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static postLearnedLast(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast('登錄學習最後學習時間失敗，請稍後再試。');
        break;
    }
  }

  static postLearnedHistory(error) {
    // 登錄學習履歷失敗，請稍後再試。
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        ErrorToast('登錄學習履歷失敗，請稍後再試。');
        break;
    }
  }

  static postLearnedCourse(error) {
    // 登錄上次學到失敗，請稍後再試。
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        ErrorToast('登錄正在學習失敗，請稍後再試。');
        break;
    }
  }
}
