import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import LearningCard from 'src/components/CourseCards/LearningCard';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';

import LearningIcon from 'src/assets/homepage/learningIcon.svg';
import arrowRight from 'src/assets/homepage/arrowRight.svg';

import CarouselLeft from 'src/assets/homepage/carouselLeft.svg';
import CarouselRight from 'src/assets/homepage/carouselRight.svg';

import styles from './styles.module.scss';
import 'swiper/css';

@observer
class LearningSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { viewModel, syncFavorite, browser } = this.props;
    return (
      <div className={clsx(styles.learnedSection, styles.learnedSection_layout)}>
        <section className={clsx(styles.learnedContent, styles.learnedContent_layout)}>
          <div className={clsx(styles.learnedContent_item)}>
            <div className={clsx(styles.titleFlex, styles.titleFlex_layout)}>
              <div className={clsx(styles.titleFlex_item)}>
                <div className={clsx(styles.titleContainer, styles.titleContainer_layout)}>
                  <div className={clsx(styles.titleContainer_item)}>
                    <div
                      style={{ '--src': `url(${LearningIcon})` }}
                      className={clsx(styles.learnedIcon, styles.learnedIcon_layout)}
                    />
                  </div>
                  <div className={clsx(styles.titleContainer_spacer)} />
                  <div className={clsx(styles.titleContainer_item1)}>
                    <h1 className={clsx(styles.title, styles.title_layout)}>
                      {i18n.t('home_recently_title')}
                    </h1>
                  </div>
                </div>
              </div>
              <div className={clsx(styles.titleFlex_spacer)} />
              <Link
                to="/favorite"
                className={clsx(styles.titleFlex_item1)}
              >
                <div className={clsx(styles.moreBtn, styles.moreBtn_layout)}>
                  <h5 className={clsx(styles.moreText, styles.moreText_layout)}>
                    {i18n.t('btn_see_more')}
                  </h5>
                  <div className={clsx(styles.moreBtn_spacer)} />
                  <div className={clsx(styles.moreBtn_item)}>
                    <div
                      style={{ '--src': `url(${arrowRight})` }}
                      className={clsx(styles.arrowRight, styles.arrowRight_layout)}
                    />
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className={clsx(styles.learnedContent_spacer)} />
          <div className={clsx(styles.learnedContent_item)}>
            <div className={clsx(styles.group, styles.group_layout)}>
              <div className={clsx(styles.flex10, styles.flex10_layout)}>
                <Swiper
                  style={{ minWidth: 0, width: '100%', height: '100%' }}
                  direction="horizontal"
                  onSwiper={viewModel.onSwiper}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: '.slidePreRank',
                    nextEl: '.slideNextRank'
                  }}
                  cssMode={browser !== 'safari'}
                  breakpoints={{
                    0: { slidesPerView: 1, spaceBetween: 20 },
                    384: { slidesPerView: 1, spaceBetween: 20 },
                    480: { slidesPerView: 1, spaceBetween: 20 },
                    576: { slidesPerView: 2, spaceBetween: 20 },
                    768: { slidesPerView: 2, spaceBetween: 20 },
                    992: { slidesPerView: 3, spaceBetween: 20 },
                    1200: { slidesPerView: 3, spaceBetween: 20 },
                    1400: { slidesPerView: 3, spaceBetween: 20 },
                    1920: { slidesPerView: 3, spaceBetween: 20 }
                  }}
                >
                  {
                    viewModel.cardList?.map((card) => {
                      return (
                        <SwiperSlide key={card.id}>
                          <LearningCard
                            viewModel={card}
                            syncFavorite={syncFavorite}
                            section="Learned"
                          />
                        </SwiperSlide>
                      );
                    })
                  }
                </Swiper>
              </div>
            </div>
            <div
              className={clsx('slidePreRank', styles.leftArrow)}
              style={{ '--src': `url(${CarouselLeft})` }}
            />

            <div
              className={clsx('slideNextRank', styles.rightArrow)}
              style={{ '--src': `url(${CarouselRight})` }}
            />
          </div>
        </section>
      </div>
    );
  }
}

LearningSection.propTypes = {
  viewModel: PropTypes.object,
  syncFavorite: PropTypes.func,
  browser: PropTypes.string
};

LearningSection.defaultProps = {
  viewModel: {},
  syncFavorite: () => { },
  browser: ''
};

export default LearningSection;
