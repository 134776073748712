import { makeObservable, observable, action, runInAction } from 'mobx';
import { createSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import HomeService from 'src/services/home';
import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';

import signInViewModel from './component/SignIn/viewModel';
import AlertViewModel from '../Alert/viewModel';

class HeaderViewModel extends AlertViewModel {
  @observable popup = null;
  @observable popupImageUrl = '';
  @observable popupLinkUrl = '';
  @observable scrollTop = 0;
  @observable isShowHeader = true;

  constructor(props) {
    super(props);
    this.signInViewModel = signInViewModel;
    makeObservable(this);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  onScroll = (event) => {
    const secTab = document.querySelector('.secTabs');
    const scrollElement = document.querySelector('.App');
    const scrollTop = event.target === scrollElement
      ? scrollElement.scrollTop
      : document.documentElement.scrollTop;

    // > scroll top > 0 prevent overscroll trigger hide.
    if (scrollTop > this.saveScrollTop && scrollTop > 0) {
      // scroll hide.
      this.isShowHeader = false;
      if (secTab) {
        secTab.classList.remove('show');
      }

    } else {
      // scroll show.
      this.isShowHeader = true;
      if (secTab) {
        secTab.classList.add('show');
      }
    }
    // 保存 scrollTop.
    this.saveScrollTop = scrollTop;
  };

  didMount = async (props) => {
    this.router = props.router;

    // 檢查 profile 資料.
    if (props.profile) {
      this.checkProfile(props);
    }

    await this.handlerPopupAPI();

    // Cookies.remove('popup');

    const scrollElement = document.querySelector('.App');
    scrollElement.addEventListener('scroll', this.onScroll);
    document.addEventListener('scroll', this.onScroll);
  };

  didUpdate = (props) => {
    // 檢查 profile 資料.
    if (props.profile) {
      this.checkProfile(props);
    }
  };

  unMount = () => {
    // 事件刪除.
    const scrollElement = document.querySelector('.App');
    scrollElement.removeEventListener('scroll', this.onScroll);
    document.addEventListener('scroll', this.onScroll);
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 彈屏 link 轉換.
  getPopupLink = ({ type, value }) => {
    if (type === 'Home') {
      return '/';
    }

    if (type === 'VideosList') {
      return {
        pathname: '/list/video',
        search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
      };
    }

    if (type === 'AudiosList') {
      return {
        pathname: '/list/audio',
        search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
      };
    }

    if (type === 'AudiobooksList') {
      return {
        pathname: '/list/audiobook',
        search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
      };
    }

    if (type === 'Course') {
      return `/course/${value}/info`;
    }

    if (type === 'Me') {
      return '/profile/information';
    }

    if (type === 'Subscription') {
      return '/subscription';
    }

    if (type === 'Link') {
      return value;
    }

    return '/';
  };

  // 前往通知網址.
  onGoToLink = () => {
    const navigation = this.popup.navigation;
    const link = this.getPopupLink(navigation);

    // 關閉 alert.
    this.handlerCloseAlert();

    if (navigation.type === 'Link') {
      window.open(link);
    } else if (navigation.type === 'Home') {
      // 跳轉至 home.
      this.router.navigate(link);
      // 重新整理.
      window.location.reload();
    } else {
      this.router.navigate(link);
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 檢查 profile 資料.
  @action
  checkProfile = (props) => {
    const { profile } = props;

    if (profile) {
      // 保存 profile.
      this.signInViewModel.setSaveProfile(profile);

      if (profile.status === 'Pending') {
        this.signInViewModel.handleOpen();
        this.signInViewModel.handlerToEmailAuth();
      } else if (profile.nickname === null) {
        this.signInViewModel.handleOpen();
        this.signInViewModel.handlerToInputName();
      } else if (profile.hasNewspaper === null) {
        this.signInViewModel.handleOpen();
        this.signInViewModel.handlerToInputInterests();
      }
    } else {
      // 保存 profile.
      this.signInViewModel.setSaveProfile(null);
    }
  };

  // 檢查彈屏.
  @action
  checkPopup = async () => {
    const value = Cookies.get('popup');

    if (!this.popup?.id) {
      return;
    }

    // cookies 沒資料, 或是與現在的資料不同.
    if (value !== this.popup.id) {
      // 紀錄 cookies.
      Cookies.set('popup', this.popup.id, { expires: 30 });
      // 彈屏圖片.
      this.popupImageUrl = this.popup.thumbnails.web.url;
      // 開啟彈窗.
      this.handlerOpenAlert();
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取彈屏的 api.
  handlerPopupAPI = async () => {
    try {
      const res = await HomeService.getHome();

      runInAction(() => {
        // 彈屏資料.
        this.popup = res.popup;
        // 檢查彈過沒.
        this.checkPopup();
      });
    } catch (error) {
      runInAction(() => {
        // ErrorToast(`${i18n.t('api_common_general')}: ${error.response?.status}`);
      });
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
}

export default HeaderViewModel;
