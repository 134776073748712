import dayjs from 'dayjs';

export default class LearnedStatus {
  static fromRes(data) {
    return new LearnedStatus(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {

    const days = dayjs.duration(data.totalTime * 1000).get('days') || null;
    const hours = dayjs.duration(data.totalTime * 1000).get('hours') || null;
    const minutes = dayjs.duration(data.totalTime * 1000).get('minutes');
    const seconds = dayjs.duration(data.totalTime * 1000).get('seconds');

    const hoursText = (() => {
      if (hours) {
        const text = hours.toString();
        if (text.length < 2 && !days) {
          return `0${text}`;
        }
        if (days) {
          return (days * 24 + hours).toString();
        }
        return text;
      }
      return null;
    })();

    const minutesText = (() => {
      const text = minutes.toString();
      if (text.length < 2) {
        return `0${text}`;
      }
      return text;
    })();

    const secondsText = (() => {
      const text = seconds.toString();
      if (text.length < 2) {
        return `0${text}`;
      }
      return text;
    })();

    // 分鐘.
    this.totalTime = data.totalTime;
    this.video = data.video;
    this.audio = data.audio;
    this.audiobook = data.audiobook;

    this.hours = hoursText;
    this.minutes = minutesText;
    this.seconds = secondsText;
  }
}
