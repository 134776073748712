import React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import styles from './style.module.scss';

@observer
class MenuView extends React.Component {
  render() {
    return ReactDOM.createPortal(
      <div
        className={clsx([styles.menu, this.props.vm.isOpen && styles.active])}
      >
        <div
          className={styles.backgroundClose}
          onClick={this.props.vm.handlerCloseMenu}
        />
        {this.props.children}
      </div>,
      document.querySelector('#root')
    );
  }
}

export default MenuView;
