import { request, getHost } from './utils';

export const getBookListByBlockId = (id, token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/custom/group/list/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getBookListDetail = (id, token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/custom/list/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};
