import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import clsx from 'clsx';
import styles from '../Notifications/style.module.scss';


// 公告列表.
function AnnouncementsView(props) {
  const listRef = useRef(null);

  return (
    <>
      <div className={styles.divider} />
      <div className={styles.list} ref={listRef}>
        {props.list.map((item) => {
          return (
            <div
              className={clsx(styles.announceItem, styles.read)}
              key={Math.random()}
              onClick={() => {
                props.onClickItem(item.id);
              }}
            >
              <div className={styles.announceTitle}>{item.title}</div>

              <div className={styles.time}>{item.dayjsFormat}</div>
            </div>
          );
        })}

        {props.isReady && (
          <Waypoint
            scrollableAncestor={listRef.current}
            topOffset={0}
            onEnter={props.onEnterWaypoint}
          />
        )}
      </div>
    </>
  );
}

AnnouncementsView.propTypes = {
  // 列表.
  list: PropTypes.array.isRequired,
  // item click function.
  onClickItem: PropTypes.func.isRequired,
  // Waypoint enter function
  onEnterWaypoint: PropTypes.func.isRequired,
  // 開始使用 Waypoint.
  isReady: PropTypes.bool.isRequired
};

export default AnnouncementsView;
