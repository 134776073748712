import PagesRoot from 'src/pages';

import { AppStateProvider } from 'src/stores';
import clsx from 'clsx';

import styles from './App.module.css';

function App() {
  return (
    <div className={clsx('App', styles.app)}>
      <AppStateProvider>
        <PagesRoot />
      </AppStateProvider>
    </div>
  );
}

export default App;
