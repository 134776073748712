import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const CoursePurchase = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="課程方案" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            sandshour 散時間有影音課程、音頻課程及有聲書三種類型，提供
            <span className={useStyles.bolder}>
              「訂閱制」
            </span>
            <span>
              與
            </span>
            <span className={useStyles.bolder}>
              「單課購買」
            </span>
            兩種方案。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            訂閱制：採每月／每年定期定額扣款。訂閱期間可不限次數與時間，學習全站非「限單購」內容
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            單課購買：一次性付款，購買後於平台存續期間，不限次數與時間學習課程內容。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="付款方式" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.bolder}>
            ▎  網站
          </div>
          <div className={useStyles.content}>
            網站目前透過「Tappay」支付平台收取費用，僅提供線上信用卡付款方式（支援信用卡：Master、Visa、JCB、AE）
          </div>
          <div className={useStyles.bolder}>
            ▎  App
          </div>
          <div className={useStyles.content}>
            App 依所屬系統綁定之信用卡進行點數儲值及訂閱制扣款（儲值點數相關問題請參考下方
            <span className={useStyles.bolder}>
              【點數儲值】
            </span>
            ）
          </div>
        </div>
      </FAQCard>
      <FAQCard title="單課購買" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={clsx(useStyles.bolder, useStyles.content)}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            選擇你有興趣的課程，點選
            <span className={useStyles.bolder}>
              「購買」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            進入結帳頁面：請依序填寫信用卡付款資料，選擇是否使用優惠券、點數，並且填寫購買人真實姓名、聯絡用電話、 Email 及發票開立方式
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑷</div>
            送出購買資訊，進入Tappay 結帳頁面並輸入授權碼，即可完成課程訂購
          </div>
          <br />
          <div className={clsx(useStyles.bolder, useStyles.content)}>
            ▎  App
          </div>
          <div className={useStyles.content}>
            在 App上購買單課需以
            <span className={useStyles.bolder}>
              「點數」
            </span>
            方式結帳，點數1點等值新台幣1元，請先確認個人帳號是否有足夠的點數。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            選擇你有興趣的課程，點選
            <span className={useStyles.bolder}>
              「購買」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            進行相對應點數扣抵，即完成課程結帳
          </div>
          <br />
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            訂閱制相關問題請參考下方
            <span className={useStyles.bolder}>
              【訂閱會員】
            </span>
            。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            儲值點數相關問題請參考下方
            <span className={useStyles.bolder}>
              【點數儲值】
            </span>
            。
          </div>
        </div>
      </FAQCard>
    </>
  );

};

export default CoursePurchase;
