import { observable, action, makeObservable } from 'mobx';

export default class TagViewModel {
  @observable id = '';
  @observable name = '';
  @observable isChecked = false;

  constructor(props) {
    this.id = props.id;
    this.name = props.name;

    if (props.isChecked) {
      this.isChecked = props.isChecked;
    }

    makeObservable(this);
  }

  @action
  handlerChange = () => {
    // this.isChecked = event.target.checked;
    this.isChecked = !this.isChecked;
  };
}
