import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import withProfile from 'src/components/withProfile';

import SubscriptionSection from './components/SubscriptionSection';
import BannerSection from './components/BannerSection';
import LearningSection from './components/LearningSection';
import ModuleSection from './components/ModuleSection';
import ExposeSection from './components/ExposeSection';
import RankingSection from './components/RankingSection';
import CelebritySection from './components/CelebritySection';
import RecommendSection from './components/RecommendSection';
import HomePageViewModel from './viewModel';

import styles from './styles.module.css';
import './customizeSwiper.css';

@observer
class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.viewModel = new HomePageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount();
  }

  componentDidUpdate(prevProps) {
    this.viewModel.didUpdate(prevProps.profile, this.props.profile);
  }

  render() {
    const { profile } = this.props;
    return (
      <>
        <Helmet>
          <title>sandshour 翻轉零散時間的價值｜影音內容訂閱平台</title>
        </Helmet>
        <div className={clsx('home')}>
          <BannerSection viewModel={this.viewModel.bannerSectionVm} browser={this.viewModel.browser} />
          {
            this.props.profile && this.viewModel.learningSectionVm.cardList?.length > 0 && (
              <LearningSection
                viewModel={this.viewModel.learningSectionVm}
                syncFavorite={this.viewModel.syncFavorite}
                browser={this.viewModel.browser}
              />
            )
          }
          {
            this.viewModel.modules.map((el) => {
              return (
                <ModuleSection
                  key={el.id}
                  viewModel={el}
                />
              );
            })
          }
          <ExposeSection
            viewModel={this.viewModel.videoExposeVm}
            syncFavorite={this.viewModel.syncFavorite}
            browser={this.viewModel.browser}
          />
          <ExposeSection
            viewModel={this.viewModel.audioExposeVm}
            syncFavorite={this.viewModel.syncFavorite}
            browser={this.viewModel.browser}
          />
          <SubscriptionSection />
          <ExposeSection
            viewModel={this.viewModel.audioBookExposeVm}
            syncFavorite={this.viewModel.syncFavorite}
            browser={this.viewModel.browser}
          />
          <RankingSection
            viewModel={this.viewModel.rankingVm}
            syncFavorite={this.viewModel.syncFavorite}
            browser={this.viewModel.browser}
          />
          <CelebritySection browser={this.viewModel.browser} />
          <RecommendSection viewModel={this.viewModel.recommendVm} />

        </div>
      </>
    );
  }
}

HomePage.propTypes = {
  profile: PropTypes.object
};

HomePage.defaultProps = {
  profile: {}
};

export default withProfile(HomePage, false);
