/* eslint-disable import/first */

import React from 'react';
import ReactDOM from 'react-dom';
import isMobile from 'ismobilejs';
import { configure } from 'mobx';
import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/zh-tw';


dayjs.extend(duration);
dayjs.locale('zh-tw');

configure({
  enforceActions: 'always'
});

import { some } from 'src/utils';

import 'normalize.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



const rootElement = document.getElementById('root');

// ! Note: must `hydrate` a single root dom element, and must have an entity, for example, it cannot be a provider
if (
  some(
    window.location.pathname,
    [
      /^\/$/
    ]
  )
  && !isMobile(window.navigator).any
  && rootElement.hasChildNodes()
) {
  ReactDOM.hydrate(
    (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    ),
    rootElement
  );
} else {
  ReactDOM.render(
    (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    ),
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
