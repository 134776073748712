import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import GreenSelect from 'src/assets/profilePage/greenSelect.svg';
import Reveal from 'src/assets/profilePage/reveal.png';

import CloseBtn from 'src/assets/account/closeBtn.svg';
import CheckBox from 'src/assets/account/checkBox.svg';
import UnCheck from 'src/assets/account/uncheck.svg';

import InputInterestsViewModel from './viewModel';
import styles from './style.module.scss';

@observer
class InputInterestsView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InputInterestsViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={`interetslogin ${clsx(styles.interestsInput, styles.interestsInput_layout)}`}>
        <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
          <div className={clsx(styles.titleContainer, styles.titleContainer_layout)}>
            <div className={clsx(styles.titleContainer_item1)}>
              <h3 className={clsx(styles.title, styles.title_layout)}>
                興趣與喜好
              </h3>
            </div>
            <div className={clsx(styles.titleContainer_item2)}>
              <div style={{ '--src': `url(${CloseBtn})` }} className={clsx(styles.closeBtn, styles.closeBtn_layout)} />
            </div>
          </div>

          <div className={clsx(styles.selectsContainer, styles.selectsContainer_layout)}>
            <div className={clsx(styles.selectFlex, styles.selectFlex_layout)}>
              <h5 className={clsx(styles.selectTitle, styles.selectTitle_layout)}>
                請選擇您的興趣與喜好
              </h5>

              <div className={`${clsx(styles.selectGrid, styles.selectGrid_layout)} grid4`}>
                {this.vm.ids.map((el) => (
                  <div
                    key={el.id}
                    className={clsx(styles.selectItem, styles.selectItem_layout)}
                    onClick={el.handlerChange}
                  >
                    <div className={clsx(styles.contentFlex1, styles.contentFlex1_layout)}>
                      <div className={clsx(styles.contentFlex_item)}>
                        <div style={{ '--src': `url(${el.isChecked ? GreenSelect : Reveal})` }} className={clsx(styles.toggle, styles.toggle_layout)} />
                      </div>
                      <div className={clsx(styles.contentFlex_spacer)} />
                      <div className={clsx(styles.contentFlex_item1)}>
                        <h5 className={clsx(styles.toggleText, styles.toggleText_layout)}>
                          {el.name}
                        </h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className={clsx(styles.newsPaperBtn, styles.newsPaperBtn_layout)}
                onClick={this.vm.setHasNewspaper}
              >
                <div className={clsx(styles.newsPaperBtn_spacer)} />
                <div className={clsx(styles.newsPaperBtn_item)}>
                  <div style={{ '--src': `url(${this.vm.hasNewspaper ? CheckBox : UnCheck})` }} className={clsx(styles.checkBox, styles.checkBox_layout)} />
                </div>
                <div className={clsx(styles.newsPaperBtn_spacer1)} />
                <div className={clsx(styles.newsPaperBtn_item1)}>
                  <h5 className={clsx(styles.newsPaperText, styles.newsPaperText_layout)}>
                    我希望收到有關驚喜折扣以及個性化推薦的電子報。
                  </h5>
                </div>
                <div className={clsx(styles.newsPaperBtn_spacer)} />
              </div>

              <div className={clsx(styles.noteContainer, styles.noteContainer_layout)}>
                <h5 className={clsx(styles.noteText, styles.noteText_layout)}>
                  登入後 可至『我的』修改基本資料、興趣與喜好
                </h5>
              </div>
            </div>
          </div>

          <div className={clsx(styles.btnsContainer, styles.btnsContainer_layout)}>
            <div className={clsx(styles.btnsContainer_item)}>
              <div
                onClick={this.props.handlerToInputName}
                className={clsx(styles.prevStepBtnContainer, styles.prevStepBtnContainer_layout)}
              >
                <h5 className={clsx(styles.prevContainerText, styles.prevContainerText_layout)}>
                  上一步
                </h5>
              </div>
            </div>
            <div className={clsx(styles.btnsContainer_item)}>
              <div
                onClick={this.vm.handlerSubmit}
                className={clsx(styles.submitBtnContainer, styles.submitBtnContainer_layout)}
              // disabled={this.vm.isDisabledSubmit}
              >
                <h5 className={clsx(styles.subnitText, styles.subnitText_layout)}>
                  完成註冊
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InputInterestsView;
