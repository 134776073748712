import { makeObservable, observable, action, computed } from 'mobx';
import { getHost } from 'src/apis/utils';
import Auth from 'src/services/auth';

import AlertViewModel from '../Alert/viewModel';


class LogoutAlertViewModel extends AlertViewModel {
  @observable isForbidden = false
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  handlerBackgroundCloseAlert = () => {
  }

  handlerOpenAlert = (code = 401) => {
    if (code === 403) {
      this.isForbidden = true;
    } else {
      this.isForbidden = false;
    }
    this.isOpenAlert = true;
    this.toggleOverflow();
  }


  @action
  onLinkClick = () => {
    Auth.removeToken();
    // window.location.replace('http://localhost:3000');
    window.location.replace(getHost());
    this.handlerCloseAlert();
  }
}

const logoutAlertViewModel = new LogoutAlertViewModel();

export default logoutAlertViewModel;
