import { LIST_LIMIT_PER_CALL } from 'src/constants';
import { getHost, request } from './utils';

export const searchForAllType = (token, keyword, type) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/search`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      keyword,
      type,
      limit: LIST_LIMIT_PER_CALL.searchPage
    }
  };
  return request(options);
};

export const searchForType = (token, keyword, type, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/search`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      keyword,
      type,
      limit: LIST_LIMIT_PER_CALL.courseList,
      anchor
    }
  };
  return request(options);
};
