import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { createSearchParams, Link } from 'react-router-dom';
import i18n from 'src/i18n';

import LearningCard from 'src/components/CourseCards/LearningCard';

import DecoWhite from 'src/assets/homepage/decoWhite.png';
import TrophyIcon from 'src/assets/homepage/trophyIcon.svg';
import ArrowRight from 'src/assets/homepage/arrowRight.svg';

import CarouselLeft from 'src/assets/homepage/carouselLeft.svg';
import CarouselRight from 'src/assets/homepage/carouselRight.svg';

// import styles from './styles.module.css';
import styles from './styles.module.scss';
import 'swiper/css';

@observer
class RankingSection extends React.Component {
  render() {
    const { viewModel, syncFavorite, browser } = this.props;
    return (
      <section className={clsx(styles.rankingSection, styles.rankingSection_layout)}>
        <div style={{ '--src': `url(${DecoWhite})` }} className={clsx(styles.deco, styles.deco_layout)} />

        <div className={clsx(styles.rankingContent, styles.rankingContent_layout)}>
          <div className={clsx(styles.innerBox, styles.innerBox_layout)}>
            <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
              <div className={clsx(styles.contentFlex_item)}>
                <div className={clsx(styles.sectionTitle, styles.sectionTitle_layout)}>
                  <div className={clsx(styles.sectionTitle_item)}>
                    <div className={clsx(styles.titleFlex, styles.titleFlex_layout)}>
                      <div className={clsx(styles.titleFlex_item)}>
                        <div
                          style={{ '--src': `url(${TrophyIcon})` }}
                          className={clsx(styles.icon, styles.icon_layout)}
                        />
                      </div>
                      <div className={clsx(styles.titleFlex_spacer)} />
                      <div className={clsx(styles.titleFlex_item1)}>
                        <h1 className={clsx(styles.title, styles.title_layout)}>
                          {i18n.t('home_ranking_title')}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={clsx(styles.contentFlex_spacer)} />
              <div className={clsx(styles.contentFlex_item)}>
                <div className={clsx(styles.carouselContainer, styles.carouselContainer_layout)}>
                  <div className={clsx(styles.innerContent, styles.innerContent_layout)}>
                    <div className={clsx(styles.innerContent_item)}>
                      <div className={clsx(styles.tabBtnFlex, styles.tabBtnFlex_layout)}>
                        {
                          viewModel.typeBtns.map((el) => (
                            <div
                              key={el.id}
                              className={clsx(styles.tabBtnFlex_item1)}
                              onClick={() => viewModel.onBtnClick(el.id)}
                            >
                              <div className={clsx(styles.tabItem, styles.tabItem_layout)}>
                                <h5
                                  className={clsx(
                                    el.id === viewModel.nowType ? styles.tabTitle1 : styles.tabTitle,
                                    styles.tabTitle1_layout
                                  )}
                                >
                                  {el.name}
                                </h5>
                                {
                                  el.id === viewModel.nowType && (
                                    <div className={clsx(styles.activeLine, styles.activeLine_layout)} />
                                  )
                                }
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className={clsx(styles.innerContent_spacer)} />
                    <div className={clsx(styles.innerContent_item3)}>
                      <div className={clsx(styles.group, styles.group_layout)}>
                        <div className={clsx(styles.group, styles.group_layout)}>
                          <div className={clsx(styles.flex10, styles.flex10_layout)}>
                            <Swiper
                              style={{ minWidth: 0, width: '100%', height: '100%' }}
                              direction="horizontal"
                              onSwiper={viewModel.onSwiper}
                              modules={[Navigation]}
                              navigation={{
                                prevEl: '.slidePreRank',
                                nextEl: '.slideNextRank'
                              }}
                              cssMode={browser !== 'safari'}
                              breakpoints={{
                                0: { slidesPerView: 1, spaceBetween: 20 },
                                384: { slidesPerView: 1, spaceBetween: 20 },
                                480: { slidesPerView: 1, spaceBetween: 20 },
                                576: { slidesPerView: 2, spaceBetween: 20 },
                                768: { slidesPerView: 2, spaceBetween: 20 },
                                992: { slidesPerView: 3, spaceBetween: 20 },
                                1200: { slidesPerView: 3, spaceBetween: 20 },
                                1400: { slidesPerView: 3, spaceBetween: 20 },
                                1920: { slidesPerView: 3, spaceBetween: 20 }
                              }}
                            >
                              {
                                viewModel.nowList?.map((card) => {
                                  return (
                                    <SwiperSlide key={card.id}>
                                      <LearningCard
                                        viewModel={card}
                                        syncFavorite={syncFavorite}
                                        section="Ranking"
                                      />
                                    </SwiperSlide>
                                  );
                                })
                              }
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div
                        className={clsx('slidePreRank', styles.leftArrow)}
                        style={{ '--src': `url(${CarouselLeft})` }}
                      />

                      <div
                        className={clsx('slideNextRank', styles.rightArrow)}
                        style={{ '--src': `url(${CarouselRight})` }}
                      />
                    </div>
                    <div className={clsx(styles.innerContent_spacer1)} />
                    <div className={clsx(styles.innerContent_item)}>
                      <Link
                        className={clsx(styles.moreBtn, styles.moreBtn_layout)}
                        to={{
                          pathname: `/list/${viewModel.nowType}`,
                          search: `?${createSearchParams({ filter: 'all', sort: 'Rank' })}`
                        }}
                      >
                        <div className={clsx(styles.moreContent, styles.moreContent_layout)}>
                          <div className={clsx(styles.moreContent_item)}>
                            <h5 className={clsx(styles.moreText, styles.moreText_layout)}>
                              {i18n.t('btn_see_more')}
                            </h5>
                          </div>
                          <div className={clsx(styles.moreContent_spacer)} />
                          <div className={clsx(styles.moreContent_item1)}>
                            <div
                              style={{ '--src': `url(${ArrowRight})` }}
                              className={clsx(styles.arrowRight, styles.arrowRight_layout)}
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

RankingSection.propTypes = {
  viewModel: PropTypes.object,
  syncFavorite: PropTypes.func,
  browser: PropTypes.string
};

RankingSection.defaultProps = {
  viewModel: {},
  syncFavorite: () => { },
  browser: ''
};

export default RankingSection;
