/* eslint-disable */
export default {
  "ID": "zh_TW",
  "": "",
  "common_login_unlock": "登入後解鎖此功能",
  "average": "平均",
  "website": "網站",
  "app": "APP",
  "mobile_phone": "手機",
  "home_search": "請輸入關鍵字",
  "btn_video_entrance": "影音課程",
  "btn_audio_entrance": "音頻課程",
  "btn_audiobook_entrance": "有聲書",
  "entrance_subscription_title_expire": "你的訂閱已到期",
  "entrance_subscription_title_free7": "免費試用７天",
  "entrance_subscription_title_upgrade_from_monthly": "升級年訂閱激省 $2000",
  "entrance_subscription_title_upgrade_from_monthly_twd": "升級年訂閱激省 TWD 2000",
  "entrance_subscription_title_upgrade_from_free7": "首訂超值年訂閱 $1600 立即折",
  "entrance_subscription_title_upgrade_from_free7_twd": "首訂超值年訂閱 TWD 1600 立即折",
  "entrance_subscription_cta_expire": "立即續訂",
  "entrance_subscription_cta_free7": "立即加入",
  "entrance_subscription_cta_join": "立即加入",
  "entrance_subscription_cta_free7_visitor": "立即註冊",
  "entrance_subscription_cta_upgrade": "升級訂閱",
  "entrance_subscription_cta_resubscribe": "重新訂閱",
  "entrance_subscription_cta_view": "訂閱管理",
  "entrance_subscription_yearly_intro": "年訂閱首訂優惠",
  "entrance_subscription_alert": "進入訂閱流程",
  "home_recently_title": "最近在學",
  "home_video_title": "影音課程",
  "home_audio_title": "音頻課程",
  "home_audiobook_title": "有聲書",
  "home_subscription_title": "知識訂閱服務",
  "home_ranking_title": "排行榜",
  "home_ranking_tab_video": "影音課程TOP5",
  "home_ranking_tab_audio": "音頻課程TOP5",
  "home_ranking_tab_audiobook": "有聲書TOP5",
  "home_recommend_title": "好評推薦",
  "home_title_you_may_like": "你可能感興趣",
  "home_banner_slogan": "從生活美學到職場應用，放鬆自己到大膽挑戰\n嚴選各領域專家用影像、音頻、文字傳授專業\n讓散時間有意義，全方位掌握系統學習節奏",
  "btn_see_more": "查看全部",
  "header_mine": "我的課程",
  "header_subscription_free_trial": "免費試用７天",
  "header_subscription_expired": "你的訂閱已到期",
  "header_subscription_first_year": "首訂超值年訂閱 $1600 立即折",
  "header_subscription_upgrade": "升級年訂閱激省 $2000",
  "header_subscription_check_plan": "檢視會員資格",
  "menu_home": "首頁",
  "menu_like": "收藏",
  "menu_subscription": "訂閱",
  "menu_learn": "學習",
  "menu_account": "我的",
  "download_slogan": "零散時間的線上學習夥伴，全系列影音課程、有聲書盡情探索",
  "download_btn": "下載APP",
  "landing_main_title": "A Grain of Sand",
  "landing_sub_title_one": "學一點、用一點  散時間累積的水滴也能湧現巨浪",
  "landing_sub_title_two": "To see a world in a grain of sand, and a heaven in a wild flower.\nHold infinity in the palm of your hand, and eternity in an hour.",
  "landing_slogan_one_slogan1": "每一次零散的學習積累",
  "landing_slogan_one_slogan2": "都是為下一段翻轉做準備",
  "landing_slogan_two_slogan1": "sandshour\nAnytime, Anywhere",
  "landing_slogan_two_slogan2": "閱讀點滴滲透生活，\n讓散時間匯聚你的改變",
  "landing_statistic_one_number": "450+",
  "landing_statistic_one_title": "影音內容",
  "landing_statistic_two_number": "2300+",
  "landing_statistic_two_title": "課程時數",
  "landing_statistic_three_number": "200+",
  "landing_statistic_three_title": "課程師資",
  "landing_statistic_four_number": "250+",
  "landing_statistic_four_title": "訂閱人數",
  "landing_sub_main_title": "Premium VIP",
  "landing_sub_main_subtitle_pre": "閒散時間的",
  "landing_sub_main_subtitle_follow": "輕月讀饗宴",
  "landing_sub_member": "訂閱會員",
  "landing_sub_month": "個月",
  "landing_sub_price": "TWD 300",
  "landing_sub_renew": "*到期自動續訂",
  "landing_sub_rights_title": "可享會員權益",
  "landing_sub_right_one": "影音、音頻課程、有聲書一站滿足",
  "landing_sub_right_two": "無限制閱聽各類內容",
  "landing_sub_right_three": "訂閱期間支援 App 下載功能",
  "landing_sub_btn": "立刻訂閱",
  "landing_sub_auto_renew": "續期方案",
  "landing_sub_change_plan": "變更方案",
  "landing_sub_alert": "您已經是訂閱會員囉！",
  "landing_feature_main_title": "Feature Project",
  "landing_feature_main_subtitle": "精選課程一覽",
  "landing_feature_category_popular": "本月最熱",
  "landing_feature_category_learned": "學習最多",
  "landing_feature_category_recommend": "編輯推薦",
  "landing_faq_main_title": "常見問題",
  "landing_sub_rights_free7": "7 天免費試用，隨時取消",
  "landing_sub_description1": "會員可盡享全站影像、聲音課程，及有聲書（部分限定零售內容除外）",
  "landing_sub_description2": "訂閱期間可無限制學習，包括持續添加的新內容",
  "landing_sub_description3": "支援下載影音內容，使用 App 離線閱聽",
  "landing_sub_description4": "訂閱將採每月自動扣款。如欲取消訂閱須至 Apple Store 上進行操作。取消訂閱後，仍然可以在已付費的訂閱期內繼續使用訂閱內容",
  "landing_sub_description4_android": "訂閱將採每月自動扣款。如欲取消訂閱須至 Play Store 上進行操作。取消訂閱後，仍然可以在已付費的訂閱期內繼續使用訂閱內容",
  "landing_sub_policy1": "請參閱",
  "landing_sub_policy2": "服務條款",
  "landing_sub_policy3": "以及",
  "landing_sub_policy4": "隱私權政策",
  "subscription_section_stats_title": "會員暢享全站優質影音內容\n開啟知識的閱聽百匯",
  "subscription_section_stats_subtitle": "閱聽世界　跨領域學習為你人生引路\n用零碎時間擁抱新閱讀的美好生活",
  "subscription_section_banner_title": "黃金陣容講師一次解鎖\n隨時隨地打開全方位視界",
  "subscription_section_banner_subtitle": "Learn from the best！超過百位知識型專家，從日常生活到專業領域，助你知識內化，一次給你所需的學習補給，輕鬆學以致用",
  "subscription_section_faq_title": "常見問題",
  "subscription_page_title_1": "Welcome to sandshour !",
  "subscription_page_title_2": "選擇您的會員方案",
  "subscription_page_subtitle": "加入訂閱會員，輕鬆省、學更多",
  "subscription_plan_tag_free": "Free",
  "subscription_plan_tag_popular": "最受歡迎",
  "subscription_plan_tag_favorable": "超值首選",
  "subscription_plan_tag_intro_offer": "首訂專屬優惠",
  "subscription_plan_tag_upgrade": "老朋友推薦",
  "subscription_plan_tag_current": "當前方案",
  "subscription_plan_title_free_trial": "免費試用七天",
  "subscription_plan_description_free_trial": "提供７天試用期，結束後將自動切換至月訂閱服務，以 %price%／月自動扣款，可隨時取消",
  "subscription_plan_description_price_per_month": "%price%/月",
  "subscription_plan_description_monthly": "每月自動續訂，可隨時變更、取消，學習輕鬆付\n會員獨享無限次瀏覽全站「非單購」內容",
  "subscription_plan_description_annually_title": "Annually",
  "subscription_plan_description_monthly_title": "Monthly",
  "subscription_plan_title_upgrade": "升級年訂閱",
  "subscription_plan_title_monthly": "月訂閱",
  "subscription_plan_title_first_yearly": "超值年訂閱",
  "subscription_plan_title_yearly": "年訂閱",
  "subscription_plan_period_free7": "7 Days",
  "subscription_plan_period_monthly": "30 Days",
  "subscription_plan_period_yearly": "4800",
  "subscription_plan_description_upgrade": "原價一年 $4800，首次升級激省$2000\n每帳號限使用一次升級優惠",
  "subscription_plan_description_upgrade_twd": "原價一年 {{offerPrice}}，首次升級激省 TWD 2000\n每帳號限使用一次升級優惠",
  "subscription_plan_description_upgrade_universal": "首次升級年訂閱再享優惠\n當前訂閱到期後以 %originalPrice%／年自動扣款，可隨時取消",
  "subscription_plan_description_first_yearly": "首年訂閱 $1600 立即折\n每帳號限使用一次首訂優惠",
  "subscription_plan_description_first_yearly_twd": "首年訂閱 TWD 1600 立即折\n每帳號限使用一次首訂優惠",
  "subscription_plan_description_first_yearly_universal": "首年訂閱享折扣優惠\n第二年開始以 %originalPrice%／年自動扣款，可隨時取消",
  "subscription_plan_description_yearly": "全年訂閱，盡情學，省更多！\n會員獨享無限次瀏覽全站「非單購」內容",
  "subscription_plan_use_coupon": "使用優惠折抵",
  "subscription_plan_select_coupon": "選擇優惠券",
  "subscription_plan_available_coupon": "適合本訂閱方案之優惠券",
  "subscription_plan_unavailable_coupon": "不適合本訂閱方案之優惠券",
  "subscription_toast_select_plan": "請選擇方案",
  "subscription_warning_free_users_switching_plans": "免費體驗期間不可更改訂閱方案",
  "subscription_faq_what_is_subscription": "什麼是訂閱會員",
  "subscription_faq_what_is_subscription_answer": "加入訂閱會員後，將立即開通全站內容服務，可跨裝置在 sandshour 散時間網站和 App 隨點隨看。不限時間、次數學習全站影音課程、音頻課程及有聲書。唯特別標示「限單購」課程則需單獨購買。",
  "subscription_faq_subscription_management": "訂閱規則",
  "subscription_faq_subscription_management_answer": "加入任一方案即可成為訂閱會員，會員訂閱可隨時加入，隨時停用。本服務採自動續訂制，後續將會按您設定的付款方案週期進行續訂，直到主動取消訂閱為止。如遇信用卡扣款失敗，無法順利完成扣款時，訂閱方案將會自動取消。方案到期後如無續訂，將無法繼續觀看、下載或收聽內容。",
  "subscription_faq_free_trial": "訂閱會員免費體驗",
  "subscription_faq_free_trial_answer": "每個帳號可享 1 次 7 天免費體驗。申請試用後，可享與有訂閱會員相同的會員權益，於試用期間內，無限次數暢享學習全站非「限單購」之內容。試用期結束時，系統會按月繳方案自動續訂。如果未在試用期間內取消訂閱服務，系統每月會按照當前方案價格收費。\n\n⚠︎ 免費試用期間無法變更續訂年訂閱方案。你可以在試用期結束轉為月訂閱後，使用升級年訂閱服務，或於試用期結束前取消訂閱再直接加入年  訂閱方案。",
  "subscription_faq_payment": "訂閱管理",
  "subscription_faq_payment_answer": "在 sandshour 網站或 APP 加入訂閱會員後，依系統設定採信用卡定期扣款。收費週期說明如下。",
  "subscription_faq_payment_answer_web": "登入會員帳號後，至「我的帳戶」→「訂閱管理」，以檢視目前訂閱方案及付款明細紀錄、綁定扣款之信用卡，並可以隨時取消訂閱或更換方案。\n\n取消訂閱\n取消訂閱後，下一個付款週期系統就不會再向您收費。（您仍然可以在剩餘的訂閱期間內享受會員服務）\n\n更換訂閱方案\n可隨時變更訂閱新的方案（請留意原方案到期日）",
  "subscription_faq_payment_answer_app": "登入會員帳號後，點擊下方「訂閱」圖示，進入訂閱管理頁面，以檢視目前訂閱方案及付款明細紀錄，並可以隨時取消訂閱或更換方案。\n\n取消訂閱\n取消訂閱後，下一個付款週期系統就不會再向你收費。（你仍然可以在剩餘的訂閱期間內享受會員服務）\n\n更換訂閱方案\n可隨時變更訂閱新的方案（請留意原方案到期日）",
  "subscription_faq_cancel": "查詢訂閱到期日",
  "subscription_faq_cancel_answer": "若欲取消訂閱會員，需至原加入管道（網站／APP）進行取消流程。若於當前使用效期前提早取消訂閱，相關權益仍可使用至該期效期結束。",
  "subscription_faq_cancel_answer_web": "使用電腦版／手機版網頁瀏覽，請先登入會員帳號，至右上方「我的帳戶」→「訂閱管理」，查看目前訂閱狀態與到期資訊。\n\n若以 App 平台訂閱，請點擊下方「訂閱」圖示，進入訂閱管理頁面，以檢視當前方案到期日，與變更方案生效日期。",
  "subscription_faq_cancel_answer_app": "在 App 平台上訂閱，可以點擊下方「訂閱」圖示，進入訂閱管理頁面，以檢視當前方案到期日，與變更方案生效日期。",
  "subscription_faq_change_plan_title": "更換方案",
  "subscription_faq_change_plan_answer_web": "訂閱會員會在當期方案到期後隔日自動扣款，為您續訂下一期服務，在當期方案到期日之前，可至「訂閱方案頁」隨時更換您想要使用的方案，待原方案的會員到期後才會啟用新方案並收取新方案費用。",
  "subscription_faq_change_plan_answer_app": "訂閱會員會在當期方案到期後隔日自動扣款，為你續訂下一期服務，在當期方案到期日之前，可隨時至「訂閱方案頁」變更續訂方案，待原方案的會員到期後才會啟用新方案並收取新方案費用。",
  "subscription_plan_change_note": "在%expiredAt%之前你可以隨時變更方案",
  "subscription_plan_change_success_note": "訂閱方案變更成功",
  "subscription_plan_change_error_internal": "內部人員無法更改訂閱方案！",
  "subscription_plan_change_error_redeem": "你目前正在免費體驗期間，已是訂閱會員身分，需等訂閱會員權限到期後才能再加入訂閱",
  "subscription_plan_change_error_other_platform": "變更訂閱方案需至原購買平台進行。變更後，你仍可享有會員權益直到當期方案到期日",
  "subscription_plan_change_error_is_active_monthly": "您正在使用月訂閱方案",
  "subscription_plan_change_error_is_active_yearly": "您正在使用年訂閱方案",
  "subscription_plan_change_error_duplicate_monthly": "該帳號已為月訂閱，無法重複購買同類型訂閱方案。若要變更或取消方案內容，可前往原應用設定中進行更改。",
  "subscription_plan_change_error_duplicate_yearly": "該帳號已為年訂閱，無法重複購買同類型訂閱方案。若要變更或取消方案內容，可前往原應用設定中進行更改。",
  "subscription_plan_change_error_duplicate_upgrade": "該帳號已為升級年訂閱，無法重複購買同類型訂閱方案。若要變更或取消方案內容，可前往原應用設定中進行更改。",
  "subscription_plan_change_error_duplicate_downgrade": "該帳號已降為月訂閱，無法重複購買同類型訂閱方案。若要變更或取消方案內容，可前往原應用設定中進行更改。",
  "subscription_plan_change_error_duplicate_default": "帳號無法重複購買同類型訂閱方案。若要變更或取消方案內容，可前往原應用設定中進行更改。",
  "subscription_plan_change_error_btn_go_to_store": "立即前往",
  "subscription_plan_change_error_free7": "免費體驗期間內無法變更訂閱方案",
  "videolist_title": "影音課程列表",
  "audiolist_title": "音頻課程列表",
  "audiobooklist_title": "有聲書列表",
  "btn_back": "返回",
  "tab_newest": "最新上架",
  "tab_popular": "熱門課程",
  "tab_category_all": "全部",
  "entrance_subscription": "加入訂閱會員，盡享探索全站課程",
  "btn_cancel": "取消",
  "btn_confirm": "確認",
  "search_res_title": "搜尋結果",
  "tab_video_title": "影音課程",
  "tab_audio_title": "音頻課程",
  "tab_audiobook_title": "有聲書",
  "search_episode_title": "單集內容",
  "search_nodata": "沒有找到相關的內容",
  "search_hot": "熱門關鍵字",
  "toast_go_to_course": "尚未解鎖此課程，將引導至課程簡介",
  "learn_mine_title": "我的學習",
  "learn_title": "學習",
  "tab_all": "全部",
  "learn_nodata": "您尚未開始學習",
  "like_mine_title": "我的收藏",
  "like_tab_course": "所有課程",
  "like_tab_episode": "所有單集",
  "like_tab_script": "文稿",
  "like_tab_download": "下載",
  "like_tab_share": "分享",
  "like_course_nodata": "您尚未收藏任何課程",
  "like_chapter_nodata": "您尚未收藏任何單集",
  "tag_not_yet_purchase": "尚未購買",
  "tag_not_yet_rate": "尚未評價",
  "tag_purchased_only": "限單購",
  "tag_new": "NEW",
  "tag_last_learned": "上次學到",
  "tag_not_yet_learn": "尚未學習",
  "tag_learned": "已學習",
  "tag_finished": "已學完",
  "tag_is_discount": "優惠中",
  "profile_UID": "UID",
  "profile_membership_subscribe": "訂閱會員",
  "profile_membership_member": "一般會員",
  "profile_login_method": "登入方式",
  "profile_subscription_title_expire": "您的訂閱會員已到期",
  "profile_subscription_cta_expire": "立即續訂",
  "profile_subscription_title_free7": "免費 7 天體驗訂閱",
  "profile_subscription_cta_free7": "立即加入",
  "profile_subscription_cta_free7_visitor": "立即註冊",
  "profile_my_account": "我的帳戶",
  "profile_my_point_left": "剩餘點數：",
  "profile_my_point_left_unit": "點",
  "profile_point": "點數",
  "profile_basic_information": "基本資料",
  "profile_subscription_status": "訂閱管理",
  "profile_coupon": "優惠券",
  "profile_redemption": "禮物",
  "profile_trade": "訂單",
  "profile_notice": "推播通知",
  "profile_learning": "學習記錄",
  "profile_history": "學習履歷",
  "profile_download": "下　　載",
  "profile_interest": "興趣喜好",
  "profile_others": "其他服務",
  "profile_faqs": "常見問題",
  "profile_customer_service": "聯絡客服",
  "profile_privacy": "隱私條款",
  "profile_bind_guest": "訪客歸戶",
  "profile_restore_subs": "恢復購買",
  "profile_cancel_subscription": "取消訂閱",
  "profile_cancel_subscription_message": "若欲取消訂閱會員，需至原加入管道進行取消流程",
  "profile_restore_message": "如果您的帳號訂閱全暢聽，點擊確認將會自動恢復會員權益至當前帳號與設備",
  "profile_term_of_use": "使用條款",
  "profile_login_n_register": "登入/註冊",
  "profile_login_to_unlock": "登入後解鎖完整功能",
  "profile_login_to_unlock_message": "登入後解鎖完整功能,是否前往登入？",
  "profile_login": "登入",
  "profile_logout": "登出",
  "profile_logout_confirm": "確認要登出？",
  "profile_logout_done": "已登出",
  "profile_logout_failed": "登出失敗",
  "profile_version_title": "內容變現股份有限公司  統編：90497945  版本",
  "profile_edit_title": "編輯檔案",
  "profile_edit_subscriber": "訂閱會員",
  "profile_edit_member": "一般會員",
  "profile_edit_upload": "選擇圖片或拖拉到這裡上傳",
  "profile_edit_login_method": "登入方式",
  "profile_edit_name": "暱稱",
  "profile_edit_name_placeholder": "請輸入暱稱",
  "profile_edit_illegal_name": "暱稱不得留空",
  "profile_edit_phone": "電話",
  "profile_edit_phone_placeholder": "請輸入電話",
  "profile_edit_illegal_phone": "電話格式不符",
  "profile_edit_gender": "性別",
  "profile_edit_gender_male": "男性",
  "profile_edit_gender_female": "女性",
  "profile_edit_gender_diversity": "多元性別",
  "profile_edit_birthday": "生日",
  "profile_edit_birthday_year": "年",
  "profile_edit_birthday_month": "月",
  "profile_edit_birthday_day": "日",
  "profile_edit_save": "儲存",
  "profile_edit_birthday_alert": "生日資料一旦完成設定後即無法再更改，請務必確認",
  "profile_edit_save_confirm": "確認",
  "profile_edit_save_cancel": "取消",
  "profile_edit_delete_account": "刪除帳號申請",
  "profile_edit_delete_account_confrim": "確定刪除",
  "profile_edit_delete_account_modal": "刪除申請送出隔日起算14天後，將自動永久刪除帳號。一但刪除帳號，你所有的資料及單堂購買的課程將會全數刪除且無法回復。確定要刪除帳號嗎？",
  "profile_edit_delete_account_modal_title": "確定送出刪除申請?",
  "profile_edit_delete_account_send": "送出申請",
  "profile_edit_delete_account_cancel": "取消申請",
  "profile_edit_delete_account_already": "已申請刪除帳號",
  "profile_edit_delete_account_description_title": "帳號刪除後將放棄以下資產和權益",
  "profile_edit_delete_account_description_content1": "•   刪除申請送出隔日起算14天後，將自動永久刪除帳號",
  "profile_edit_delete_account_description_content2": "•   一旦刪除帳號，該帳號的全部個人帳號下的用戶資料和所有紀錄將無法找回且無法回復",
  "profile_edit_delete_account_description_content3": "•  若您已購買訂閱方案，刪除帳號後，請在 App Store 設置中取消訂閱",
  "profile_coupon_title": "優惠券",
  "profile_coupon_valid": "可使用",
  "profile_coupon_used": "已使用",
  "profile_coupon_invalid": "已失效",
  "profile_coupon_input_placeholder": "請輸入優惠代碼",
  "profile_coupon_redeem": "領取",
  "profile_coupon_ticket_title": "優惠券",
  "profile_coupon_to_course": "前往課程",
  "profile_coupon_to_subscription": "前往訂閱",
  "profile_coupon_use_cta": "使用",
  "profile_coupon_no_ticket": "目前暫無優惠券",
  "profile_coupon_illegal": "不得留空",
  "profile_coupon_add_success": "新增優惠券成功！",
  "profile_coupon_add_success_illegal": "新增優惠券成功，但該優惠券不適用於本課程！",
  "profile_substa_title": "訂閱管理",
  "profile_substa_group_member": "一般會員",
  "profile_substa_group_vip": "訂閱會員",
  "profile_substa_vip_monthly": "月訂閱",
  "profile_substa_vip_yearly": "年訂閱",
  "profile_substa_vip_regular": "訂閱",
  "profile_substa_vip_free": "免費體驗",
  "profile_substa_vip_free7": "免費體驗",
  "profile_substa_cancel": "取消訂閱",
  "profile_substa_resubscribe": "重新訂閱",
  "profile_substa_slogan": "每月自動續訂，暢享訂閱會員獨家閱聽體驗。",
  "profile_substa_source": "購買平台：",
  "profile_substa_member_to_subpage": "立即加入",
  "profile_substa_expired_to_subpage": "重新訂閱",
  "profile_substa_upgrade_to_subpage": "升級訂閱",
  "profile_substa_check_to_subpage": "檢視方案",
  "profile_substa_change_to_subpage": "變更方案",
  "profile_substa_payments": "付款明細",
  "profile_substa_change_card": "更換綁定信用卡",
  "profile_substa_charge_prefix": "您的",
  "profile_substa_charge": "下次收費日期為",
  "profile_substa_detail_date": "日期",
  "profile_substa_detail_description": "付款內容",
  "profile_substa_detail_status": "狀態",
  "profile_substa_detail_amount": "金額",
  "profile_substa_detail_more": "更多",
  "profile_substa_nodata": "目前沒有正在訂閱的方案！",
  "profile_substa_cancel_title": "是否要退訂閱？",
  "profile_substa_cancel_content": "確定要取消訂閱嗎？",
  "profile_substa_cancel_no": "否",
  "profile_substa_cancel_yes": "是",
  "profile_substa_change_card_title": "更換綁定信用卡",
  "profile_substa_change_card_submit": "確認更換",
  "profile_substa_change_card_error": "資料有誤，請重新確認",
  "profile_substa_source_web": "網站訂閱",
  "profile_substa_source_redeem": "兌換訂閱",
  "profile_substa_source_IOS": "Apple訂閱",
  "profile_substa_source_android": "Google訂閱",
  "profile_substa_source_internal": "內部測試",
  "profile_substa_content_active": "連續型訂閱方案",
  "profile_substa_content_cancel": "訂閱項目已被取消",
  "profile_substa_content_free": "免費試用訂閱服務",
  "profile_substa_content_bind_card": "更換信用卡",
  "profile_substa_content_update_plan": "訂閱方案更新",
  "profile_substa_content_upgrade": "升級年訂閱",
  "profile_substa_content_downgrade": "退回月訂閱",
  "profile_substa_content_yearly": "年訂閱方案",
  "profile_substa_content_monthly": "月訂閱方案",
  "profile_substa_content_internal": "內部測試帳號",
  "profile_substa_content_expired": "您的訂閱已到期",
  "profile_substa_content_nodata": "目前沒有正在訂閱的方案",
  "profile_substa_content_sub_active": "每月自動續訂，暢享訂閱會員獨家閱聽體驗。",
  "profile_substa_content_sub_active_yearly": "每年自動續訂，暢享訂閱會員獨家閱聽體驗。",
  "profile_substa_content_sub_active_free_trial": "提供７天試用期，結束後將自動切換至月訂閱服務，以 %price%／月 自動扣款，可隨時取消",
  "profile_substa_content_sub_cancel_prefix": "您的訂閱項目已取消，在",
  "profile_substa_content_sub_cancel": "之前您仍可暢享此服務方案。",
  "profile_substa_content_sub_redeem": "您已獲得免費體驗資格，可暢享訂閱會員獨家閱聽體驗。",
  "profile_substa_content_sub_internal": "內部測試帳號",
  "profile_substa_content_sub_expired": "重新升級訂閱，暢享訂閱會員獨家學習體驗",
  "profile_substa_content_sub_nodata": "立即前往訂閱，暢享獨家學習體驗",
  "profile_substa_valid_prefix_active": "服務將於",
  "profile_substa_valid_prefix_expired": "服務已於",
  "profile_substa_valid_renewable_prefix": "到期，且",
  "profile_substa_valid_expired": "到期。",
  "profile_substa_valid_can_change_plan": "到期，在這之前你可以隨時變更方案。",
  "profile_substa_valid_renewable": "自動續訂。",
  "profile_substa_valid_upgrade": "到期後轉為年訂閱。",
  "profile_substa_valid_downgrade": "到期後轉為月訂閱。",
  "profile_substa_app_renewable": "取消訂閱須於手機內完成",
  "profile_substa_valid_internal": "內部測試帳號。",
  "profile_substa_valid_nodata": "7天免費體驗，隨時取消。",
  "profile_substa_source_different_notice": "取消或升級訂閱須於%source%完成",
  "profile_contact_mail": "service@sandshour.com",
  "profile_contact_subject": "聯繫散時間",
  "profile_substa_you_are_subscriber": "您已是訂閱用戶",
  "profile_view_subscription_plans": "檢視會員資格",
  "profile_subscription_management": "管理會員資格",
  "profile_my_subscription_plan": "我的訂閱方案",
  "subscription_expired_at": "到期日",
  "subscription_effective_at": "生效日",
  "my_subscription_source_web": "官網",
  "my_subscription_source_ios": "iOS",
  "my_subscription_source_android": "Android",
  "my_subscription_source_internal": "內部測試",
  "my_subscription_source_redeem": "兌換碼",
  "my_subscription_button_upgrade": "升級訂閱",
  "my_subscription_button_downgrade": "變更方案",
  "my_subscription_button_subscribe": "立即加入",
  "my_subscription_upgrade_note": "* {{date}}起將自動變更為年訂閱",
  "my_subscription_downgrade_note": "* {{date}}起將自動變更為月訂閱",
  "my_subscription_switching_note": "在這之前你可以隨時變更方案",
  "my_subscription_switching_note_free7": "免費體驗到期後才可變更方案",
  "my_subscription_switching_note_redeem": "需於兌換訂閱結束後方能進行訂閱",
  "my_subscription_switching_note_internal": "內部人員無法更改訂閱方案",
  "my_subscription_crossgrade_disclaimer": "(變更的訂閱方案將在續訂時依新的價格收費)",
  "dollar_unit": "TWD",
  "payment_title": "個人資料",
  "payment": "付款方式",
  "payment_noti_section1": "以有形媒介提供之數位內容，處於可複製之狀態，不適用七日鑑賞期，一經提供即為完成之線上服務，恕不提供退款。",
  "payment_noti_section2": "首次體驗訂閱服務及更換信用卡資訊時，sandshour 散時間將會進行一筆 1 元測試交易，以確認你的卡片是否有效，此筆款項不會實際扣款，請放心按下1元交易付款後，依照步驟進行認證。",
  "payment_read": "我已經詳閱並同意",
  "payment_term_of_use": "sandshour 散時間服務條款",
  "payment_and": "與",
  "payment_private_policy": "隱私權政策",
  "payment_header": "立刻訂閱",
  "payment_slogan": "只差一步，開始無限觀看課程。",
  "payment_vip_title": "加入 sandshour 訂閱會員",
  "payment_vip_title_monthly": "sandshour 月訂閱會員",
  "payment_vip_monthly": "月訂閱制",
  "payment_vip_yearly": "年訂閱制",
  "payment_vip_price": "TWD 300/月",
  "payment_vip_charge_today": "今天收取費用",
  "payment_vip_charge_day_prefix": "將於",
  "payment_vip_charge_day": "與您收費",
  "payment_vip_pricing_phases_desc": "年訂閱制一次收取 %regularPrice%，第一年僅需 %offerPrice%",
  "payment_creditcard_number": "信用卡卡號",
  "payment_creditcard_valid": "有效月年",
  "payment_creditcard_ccv": "安全碼",
  "payment_creditcard_error": "請確認信用卡資料是否有誤！",
  "payment_info_name": "姓名*",
  "payment_info_name_placeholder": "請輸入您的姓名",
  "payment_info_name_error": "姓名格式不正確，請確認！",
  "payment_info_phone": "聯絡電話*",
  "payment_info_phon_error": "聯絡電話格式不正確，請確認！",
  "payment_invoice_title": "發票類型",
  "payment_invoice_donate": "捐贈發票",
  "payment_invoice_donate_input": "自行輸入",
  "payment_invoice_donate_placeholder": "請輸入捐贈碼",
  "payment_invoice_donate_error": "捐贈碼格式錯誤",
  "payment_invoice_einvoice": "電子發票",
  "payment_invoice_einvoice_personal": "個人發票",
  "payment_invoice_einvoice_personal_note": "如發票中獎，將透過email寄信通知。",
  "payment_invoice_enivoice_email": "聯絡用 email*",
  "payment_invoice_enivoice_email_note": "請輸入 email",
  "payment_invoice_enivoice_email_error": "email 格式錯誤",
  "payment_invoice_einvoice_barcode": "手機條碼",
  "payment_invoice_einvoice_barcode_placeholder": "請輸入手機條碼",
  "payment_invoice_einvoice_barcode_note": "請輸入 / 及7碼大寫英數字",
  "payment_invoice_einvoice_barcode_error": "條碼格式錯誤",
  "payment_invoice_einvoice_CDC": "自然人憑證",
  "payment_invoice_einvoice_CDC_placeholder": "請輸入自然人憑證",
  "payment_invoice_einvoice_CDC_note": "請輸入共 16 碼大寫英文及數字",
  "payment_invoice_einvoice_CDC_error": "條碼格式錯誤",
  "payment_invoice_uniform": "統編發票",
  "payment_invoice_uniform_placeholder": "請輸入統一編號",
  "payment_invoice_uniform_note": "請輸入 8 碼數字",
  "payment_invoice_uniform_error": "統一編號格式錯誤",
  "payment_add_sub": "加入訂閱",
  "profile_interest_title": "興趣喜好",
  "profile_interest_save": "儲存",
  "course_favorite_add": "收藏成功",
  "course_favorite_remove": "取消收藏",
  "chapter_main_title": "文稿",
  "chapter_main_go_course": "課程簡介",
  "chapter_main_play": "播放",
  "chapter_main_change_font_size": "修改字級大小",
  "chapter_main_prev_chapter": "上一集",
  "chapter_main_next_chapter": "下一集",
  "chapter_main_totop": "回到頂部",
  "chapter_main_comment": "留言",
  "chapter_main_share": "分享",
  "chapter_main_share_chapter": "複製單集網址",
  "chapter_main_share_chapter_current": "複製目前播放時間的單集網址",
  "chapter_main_fontsize": "字級",
  "chapter_main_fontsize_title": "調整字級大小",
  "chapter_main_fontsize_normal": "標準",
  "chapter_main_fontsize_medium": "微大",
  "chapter_main_fontsize_big": "大",
  "chapter_main_fontsize_huge": "特大",
  "chapter_main_fontsize_biggest": "最大",
  "chapter_main_confirm": "確定",
  "chapter_main_error_no_course": "課程或章節不存在，回到首頁",
  "chapter_main_error_unauth_purcahse": "尚未購買課程，回到課程首頁",
  "chapter_main_error_unauth_sub": "尚未訂閱，回到課程首頁",
  "chapter_main_error_other": "發生錯誤，請稍後再試",
  "chapter_main_error_is_first": "已經是第一集了！",
  "chapter_main_error_is_last": "已經是最後一集了",
  "chapter_main_lack_manuscript": "影音課程無文稿內容",
  "chapter_main_error_unauth": "購買課程可以解鎖全部內容",
  "comment_new_title": "新增留言",
  "comment_edit_title": "編輯留言",
  "comment_input_comment": "留言（字數限500字以內）",
  "comment_add_success": "新增成功",
  "comment_edit_success": "編輯成功",
  "course_tab_intro": "簡介",
  "course_tab_chapter": "章節",
  "course_tab_comments": "留言",
  "course_info_duration": "總時長",
  "course_info_views": "人",
  "course_info_viewed": "觀看人數",
  "course_title_introduction": "課程介紹",
  "course_title_author": "作者",
  "course_title_narrator": "轉述師",
  "course_title_publisher": "供應商",
  "course_title_subscription_notice": "課程須知",
  "course_btn_see_all": "展開全部",
  "course_btn_full_content": "完整內容",
  "course_btn_close_all": "收起",
  "course_btn_open_all": "點擊查看更多",
  "course_purchase": "購      買",
  "course_start_learn": "開始學習",
  "course_free": "加入訂閱會員",
  "course_sub": "前往訂閱",
  "course_origin_price": "原價",
  "course_discount_left_today": "優惠只到今天！",
  "course_discount_until_7up": "限時特惠中，優惠只到",
  "course_discount_until_7": "優惠剩下最後",
  "course_discount_until_7_unit": "天",
  "course_free_7days": "免費體驗7天",
  "course_subscription": "訂閱全暢聽",
  "course_tab_comments_input": "請輸入留言",
  "course_tab_comments_checkbox": "只顯示我的留言",
  "course_tab_comments_sheet_title": "選擇章節",
  "course_tab_comments_sheet_confirm": "確定",
  "course_tab_comments_selectAll": "全部章節",
  "course_tab_comments_error1": "請填寫完整資料",
  "course_tab_comments_error2": "標題不能超過 30 字",
  "course_tab_comments_error3": "字數限 500 字以內",
  "course_tab_comments_editBtn": "編輯",
  "course_tab_comments_edit": "編輯評論",
  "course_tab_comments_add": "新增評論",
  "course_tab_comments_select_error": "請選擇單集",
  "course_tab_comments_add_success": "新增留言成功",
  "course_tab_comments_edit_success": "修改留言成功",
  "course_tab_comments_nodata": "目前尚無留言",
  "course_tab_chapter_total": "總共",
  "course_tab_chapter_unit": "集",
  "rating_list_title": "評分與評論",
  "rating_latest_rank": "最新評價",
  "rating_highest_rank": "最高評價",
  "rating_lowest_rank": "最低評價",
  "rating": "評分",
  "rating_count": "份評論數",
  "rating_nobody_score_course": "尚未收到足夠的評分或評論",
  "rating_nobody_score_course_tiny": "暫無評分",
  "rating_nobody_score": "尚無評論",
  "rating_insufficient_score": "暫無評分",
  "rating_my_score": "我的評分",
  "rating_press_to_score": "點一下來評分",
  "rating_input_title": "標題（字數上限30字）",
  "rating_input_comment": "評論（字數上限500字）",
  "rating_new_title": "新增評分",
  "rating_edit_title": "編輯評分",
  "course_purchase_amount": "訂單金額",
  "course_purchase_final_price": "總付款金額",
  "course_purchase_order": "訂單",
  "point_unit": "點",
  "course_purchase_title": "結帳",
  "course_purchase_normal_price": "總價",
  "course_purchase_discount_price": "優惠價",
  "course_purchase_use_coupon": "使用優惠券",
  "course_purchase_select_coupon": "選擇優惠券",
  "course_purchase_use_point": "使用點數",
  "course_purchase_point_left": "剩餘點數",
  "course_purchase_point_unit": "點",
  "course_purchase_btn_confirm": "確定",
  "course_purchase_input_placeholder": "請輸入優惠代碼",
  "course_purchase_btn_get": "領取",
  "course_purchase_legal_coupon_ticket": "適合本課程之優惠券",
  "course_purchase_nodata": "目前沒有適用本課程之優惠券！",
  "course_purchase_illegal_coupon_ticket": "不適合本課程之優惠券",
  "course_purchase_to_purchase": "購買課程可以解鎖全部內容，是否前往購買？",
  "course_purchase_to_go_subscribe": "加入訂閱會員解鎖全部課程內容，是否前往訂閱？",
  "course_purchase_noti": "＊以有形媒介提供之數位內容，處於可複製之狀態，不適用七日鑑賞期，一經提供即為完成之線上服務，恕不提供退款。\n\n＊首次體驗訂閱服務及更換信用卡資訊時，sandshour 將會進行一筆 1 元測試交易，以確認你的卡片是否有效，此筆款項不會實際扣款，請放心按下1元交易付款後，依照步驟進行認證。",
  "course_purchase_to_subscribe": "加入訂閱會員，即可免費觀看此課程",
  "course_purchase_to_subscribe_btn": "點我加入訂閱",
  "course_purchase_go_purcahse": "前往購買",
  "course_purchase_go_subscribe": "加入訂閱會員",
  "course_purcahse_owned_alert": "您已經購買過此課程",
  "course_purchase_total": "小計",
  "course_purchase_purchase": "確認結帳",
  "course_purchase_alert1": "您當前剩餘點數為",
  "course_purchase_alert2": "，無法繼續購買該課程，是否確認前往充值?",
  "purchase_res_title": "交易結果",
  "purchase_res_success_title_course": "購買成功",
  "purchase_res_success_title_subscribe": "訂閱成功",
  "purchase_res_orderId": "訂單編號",
  "purchase_res_course_title": "課程 | 點數購買",
  "purchase_res_course_amount": "總價",
  "purchase_res_course_discount": "使用優惠券",
  "purchase_res_course_total": "實付金額",
  "purchase_res_course_goto": "開始學習",
  "purchase_res_subscription_title": "訂閱商品",
  "purchase_res_subscription_amount": "總價",
  "purchase_res_subscription_item_monthly": "sandshour - 月訂閱方案",
  "purchase_res_subscription_item_yearly": "sandshour - 年訂閱方案",
  "purchase_res_subscription_item_first_yearly": "sandshour - 首年訂閱方案",
  "purchase_res_subscription_item_upgrade_yearly": "sandshour - 升級年訂閱方案",
  "purchase_res_subscription_item_bind_card": "sandshour - 更新訂閱方案",
  "purchase_res_error_title": "在處理要求時出現暫時錯誤",
  "purchase_res_error_message": "交易訂單發生錯誤，交易失敗（ 錯誤代碼：%errorCode% ）\n若有問題請和發卡機構瞭解詳情並重新訂購。",
  "purchase_res_error_footer": "我們對於因此可能造成您的不便表示歉意。\n請稍後再試，或聯絡客服信箱並告知錯誤代碼。",
  "purchase_res_go_home": "返回首頁",
  "player_playlist": "播放列表",
  "player_playrate": "播放速度",
  "player_status_downloaded": "已下載",
  "player_status_downloading": "下載中",
  "player_status_download": "下載",
  "player_script": "文稿",
  "player_pdf": "檔案",
  "player_prev": "上一集",
  "player_next": "下一集",
  "player_add_15": "前進15秒",
  "player_back_15": "後退15秒",
  "player_toast_no_prev": "沒有上一集了",
  "player_toast_no_next": "沒有下一集了",
  "player_playlist_select": "選取",
  "player_playlist_download": "開始下載",
  "player_duration_h": "時",
  "player_duration_m": "分",
  "player_duration_s": "秒",
  "download_downloaded": "已下載",
  "download_downloading": "下載中",
  "download_downloading_all": "全部下載",
  "download_downloading_clear": "全部清空",
  "download_downloading_process": "正在下載",
  "download_downloading_standby": "準備下載",
  "download_downloading_failed": "下載失敗",
  "download_empty": "尚未下載課程",
  "download_downloaded_chapters1": "已下載",
  "download_downloaded_chapters2": "集",
  "download_downloaded_delete": "刪除",
  "download_downloaded_edit": "編輯",
  "download_downloaded_cancel": "取消",
  "download_downloaded_selectAll": "全選",
  "download_downloaded_fileSize": "檔案大小",
  "toast_network_disconnect": "網路不穩，請稍候再試",
  "download_modal_alert1": "確定刪除正在播放章節？",
  "download_modal_alert2": "非訂閱會員，刪除未購買下載課程",
  "profile_redeem_title": "禮物",
  "profile_redeem_placeholder": "請輸入序號",
  "profile_redeem_action": "領取",
  "profile_redeem_record": "我的禮物",
  "profile_redeem_code": "序號",
  "profile_redeem_p": "點",
  "profile_redeem_free_description": "免費試用訂閱服務",
  "profile_redeem_illegal": "不得留空",
  "profile_redeem_nodata": "目前尚無領取紀錄",
  "profile_order_title": "訂單",
  "profile_order_tab_course": "課程＆有聲書",
  "profile_order_tab_point": "點數儲值",
  "profile_order_price": "實付金額",
  "profile_order_check": "查看明細",
  "profile_order_transactionId": "訂單編號",
  "profile_order_total": "總價",
  "profile_order_discount": "優惠價",
  "profile_order_coupon": "使用優惠券",
  "profile_order_buy_course": "課程 | 點數購買",
  "profile_order_none": "無",
  "profile_order_success": "購買成功",
  "profile_order_coupon_add": "新增成功",
  "profile_order_receipt": "訂單明細",
  "profile_order_receipt_open": "展開訂單明細",
  "profile_order_nodata_course": "目前沒有課程購買紀錄",
  "profile_order_nodata_point": "目前沒有點數儲值紀錄",
  "profile_point_mypoint": "我的點數",
  "profile_point_title": "點數儲值",
  "profile_point_alert": "確定儲值",
  "profile_point_success": "儲值成功",
  "profile_point_failed": "儲值失敗",
  "profile_point_noti": "1. 您儲值的點數將保留在sandshour帳戶中，用於購買sandshour產品；另若非因法律特殊事由，儲值虛擬金幣無法接受退費處理。\n2. 平台會依照您的帳號設定判斷交易，儲值單位若顯示為美金或其他外幣，可能是您的綁定帳號設定為海外，或是使用境外信用卡，請先檢查以免被收取海外交易手續費。\n3. 如有任何問題，請聯繫客服信箱：service@sandshour.com",
  "signup_interest_title": "興趣喜好",
  "signup_interest_choose": "請選擇您的興趣喜好",
  "signup_interest_enewspaper": "我希望收到有關驚喜折扣以及個性化推薦的電子報。",
  "signup_interest_change_directive": "登入後可至「我的」\n修改基本資料、興趣與喜好",
  "signup_interest_confirm": "完成註冊",
  "signup_verified_note": "請驗證電子信箱",
  "signup_verified_content_head": "我們發送了一封驗證信到",
  "signup_verified_content_end": "請到此信箱收取驗證信",
  "signup_verified_btn_resend": "再寄一次",
  "signup_verified_btn_resend_count": "重新寄發驗證信",
  "signup_email_existed": "此帳號已存在，點此登入",
  "signup_verified_success": "驗證成功",
  "signup_verified_failed": "驗證失敗",
  "signup_verified_success_continue": "繼續註冊流程",
  "signup_verified_failed_restart": "重新嘗試登入",
  "login_title": "會員登入",
  "login_email": "帳號",
  "login_email_placeholder": "請輸入Email",
  "login_email_wrong": "Email 格式不正確",
  "login_password": "密碼",
  "login_password_placeholder": "請輸入 8 碼以上英數組合",
  "login_password_wrong": "密碼格式不正確",
  "login_submit": "登入",
  "login_forget_password": "忘記密碼",
  "login_email_not_existed": "此帳號不存在，點此註冊",
  "login_failed_alert": "請嘗試重新登入",
  "signup_title": "Email 註冊",
  "signup_submit": "註冊",
  "signup_nickname_title": "請輸入你的暱稱",
  "signup_nickname_error": "暱稱請勿留白",
  "signup_next": "下一步",
  "login_forget_password_title": "忘記密碼",
  "login_forget_password_account": "帳號",
  "login_forget_password_account_input": "請輸入Email",
  "login_forget_password_account_wrong": "請輸入正確帳號",
  "login_forget_password_send": "發送驗證信",
  "login_forget_password_resend_count": "重新寄發驗證信",
  "login_forget_password_resend": "再寄一次",
  "login_forget_password_protocol": "登入即表示同意 sandshour 服務條款",
  "login_new_password_title": "設定新密碼",
  "login_new_password_code": "驗證碼",
  "login_new_password_code_input": "請輸入 6 碼驗證碼",
  "login_new_password_code_error": "驗證碼有誤",
  "login_new_password_new_password": "新密碼",
  "login_new_password_new_password_input": "請輸入新密碼",
  "login_new_password_new_password_error": "新密碼格式有誤或密碼不相同",
  "login_new_password_new_password＿confirm": "新密碼確認",
  "login_new_password_new_password＿confirm_input": "請再次輸入新密碼",
  "login_new_password_new_password＿confirm_error": "新密碼格式有誤或密碼不相同",
  "login_new_password_set_password": "重設密碼",
  "account_mail_signup": "使用信箱註冊",
  "account_mail_login": "使用信箱登入",
  "account_google_signup": "使用 Google 註冊",
  "account_google_login": "使用 Google 繼續",
  "account_apple_signup": "使用 Apple 註冊",
  "account_apple_login": "使用 Apple 繼續",
  "account_noaccount_title": "還沒有帳號？",
  "account_noaccount_btn": "點此註冊",
  "account_hasaccount_title": "已經有帳號了？",
  "account_hasaccount_btn": "點此登入",
  "account_privacy_content": "登入即表示同意 sandshour 服務條款",
  "account_guest": "以訪客身份繼續",
  "message_notification": "通知",
  "message_announcement": "公告",
  "message_messageCenter": "消息中心",
  "message_readAll_toast": "已讀所有通知",
  "message_readAll": "全部標示為已讀",
  "message_readAll_alert": "確定要已讀全部通知？",
  "offline_title": "目前無偵測到網路\n請確認網路連線後重試",
  "offline_refresh": "重新整理",
  "offline_toDownload": "移到下載頁面",
  "offline_reconnect": "重新連結成功",
  "offline_disconnect": "沒有網路",
  "nodata_learned": "您尚未開始學習",
  "nodata_favorite_course": "您尚未收藏任何課程",
  "nodata_favorite_chapter": "您尚未收藏任何單集",
  "nodata_default": "目前暫無資料",
  "learning_resume_title": "學習履歷",
  "learning_resume_time": "已完成的學習時數",
  "learning_resume_video": "影音課程",
  "learning_resume_audio": "音頻課程",
  "learning_resume_audiobook": "有聲書",
  "learning_resume_recent": "最近學習",
  "learning_no_data": "您尚未開始學習",
  "learning_unit_hour": "時",
  "learning_unit_minute": "分",
  "learning_unit_second": "秒",
  "learning_expired_subscriber_permission_error": "您的訂閱已到期，立刻續訂或直接購買",
  "bind_info1": "您現在是「訪客」身分，您購買的點數、課程或是訂閱服務、交易紀錄......等，都僅限於本裝置使用。",
  "bind_info2": "一旦本裝置遺失、損壞、重置或發生異常，或您更換新的裝置，將無法恢復您在本裝置的資料及使用紀錄。",
  "bind_info3": "為了讓您可在不同的 IOS 裝置上使用本服務，並確保您的使用權益，強烈建議您登入。",
  "bind_button": "確定歸戶",
  "bind_toast": "您現在是「訪客」身份，為了確保您的使用權益，您可以到「我的 - 訪客歸戶」進行帳號綁定，未來即可在不同的 IOS 裝置繼續使用本服務。",
  "bind_failed": "歸戶失敗，\n請重新選擇帳號",
  "bind_succeed": "歸戶成功",
  "toast_bind_to_unlock": "綁定登入解鎖此功能",
  "footer_about_sandshour": "關於 sandshour",
  "footer_about_us": "關於我們",
  "footer_sandsbook": "sandsbook 散冊",
  "footer_help": "需要幫助 / 政策",
  "footer_faq": "常見問題",
  "footer_term_of_use": "服務條款",
  "footer_private_policy": "隱私權政策",
  "footer_contact_us": "聯絡我們",
  "footer_social": "社群",
  "footer_facebook": "Facebook",
  "footer_instagram": "Instagram",
  "footer_youtube": "Youtube",
  "footer_download": "App 下載",
  "footer_content_right": "內容變現股份有限公司 統編：90497945\nContent monetization Co.,Ltd. © All Rights Reserved.",
  "toast_copied": "已複製",
  "toast_subscription_failed": "訂閱失敗，稍後再試",
  "toast_redeem_success": "兌換成功",
  "toast_redeem_failed": "兌換失敗",
  "api_common_general": "發生異常，請稍後再試",
  "modal_error": "溫馨提醒",
  "modal_alert": "溫馨提醒",
  "modal_purchase": "購買須知",
  "modal_i_got_it": "我知道了",
  "api_common_400": "參數錯誤",
  "api_common_401": "您的帳號已經於其他裝置登入，請重新登入！",
  "api_common_401_web": "此帳號已經於其他電腦登入\n請重新登入！",
  "api_common_403": "帳號停權",
  "api_common_404": "找不到資料。",
  "api_common_409": "資料衝突",
  "api_common_502": "伺服器錯誤",
  "api_user_login_401": "帳號密碼輸入錯誤",
  "api_user_login_403": "帳號停權",
  "api_user_login_404": "系統查無此帳號",
  "api_user_reset_password_401": "驗證碼錯誤",
  "api_user_reset_password_403": "帳號停權",
  "api_user_reset_password_404": "系統查無此帳號",
  "api_user_email_auth_404": "系統查無此帳號",
  "api_user_email_auth_429": "已寄出郵件，請至信箱確認",
  "api_user_signup_409": "此帳號已被使用，請重新輸入",
  "api_google_login_401": "帳密輸入錯誤",
  "api_google_login_4xx": "登入失敗",
  "api_apple_login_401": "帳密輸入錯誤",
  "api_apple_login_4xx": "登入失敗",
  "api_user_forgot_password_404": "系統查無此帳號",
  "api_user_forgot_password_429": "已經寄過驗證信了",
  "api_chapter_list_400": "取得章節列表失敗，請稍後再試。",
  "api_chapter_list_403": "無法取得課程列表，請稍後再試。",
  "api_chapter_list_404": "找不到章節資料，請重新確認。",
  "api_chapter_detail_403": "尚未購買課程，回到課程首頁",
  "api_chapter_detail_404": "課程或章節不存在，回到首頁",
  "api_chapter_prev_403": "購買課程可以解鎖全部內容",
  "api_chapter_prev_404": "已經是第一集了！",
  "api_chapter_next_403": "購買課程可以解鎖全部內容",
  "api_chapter_next_404": "已經是最後一集了",
  "api_chapter_favorite_4xx": "收藏/取消收藏失敗，請稍後再試。",
  "api_course_comment_4xx": "取得留言列表失敗，請稍後再試。",
  "api_course_my_comment_4xx": "取得我的留言失敗，請稍後再試。",
  "api_course_list_4xx": "無法取得課程列表，請稍後再試。",
  "api_course_detail_4xx": "無法取得課程資料，請稍後再試。",
  "api_course_detail_403": "很抱歉，該課程目前已下架，無法觀看，如有不便請見諒。",
  "api_course_detail_404": "該課程不存在、已下架或刪除。",
  "api_course_rating_4xx": "無法取得課程評分，請稍後再試。",
  "api_course_my_rating_4xx": "無法取得我的課程評分，請稍後再試。",
  "api_course_rating_add_4xx": "新增評分內容失敗，請稍後再試。",
  "api_course_rating_edit_4xx": "編輯評分內容失敗，請稍後再試。",
  "api_course_favorite_4xx": "收藏/取消收藏異常，請稍後再試。",
  "api_course_favorite_404": "收藏/取消收藏的課程不存在，請重新確認。",
  "api_category_4xx": "無法取得課程分類，請稍後再試。",
  "api_notification_list_4xx": "無法取得通知列表，請稍後再試。",
  "api_notification_read_404": "通知不存在，請稍後再試。",
  "api_notification_read_all_4xx": "無法更新通知狀態，請稍後再試。",
  "api_announcement_4xx": "無法取得公告列表，請稍後再試。",
  "api_announcement_detail_404": "公告不存在，請稍後再試。",
  "api_announcement_detail_4xx": "無法取得公告詳情，請稍後再試。",
  "api_search_400": "搜尋類別有誤，請稍後再試。",
  "api_search_4xx": "無法取得搜尋結果，請稍後再試。",
  "api_trade_subscription_4xx": "訂閱發生異常，請稍後再試。",
  "api_trade_subscription_409": "此帳號已與其他登入帳號綁定。",
  "api_trade_subscription_skerror_18": "發生錯誤，請再試一次。",
  "api_trade_course_400": "發票資料填寫有誤，請重新確認。",
  "api_trade_course_404": "找不到課程，請稍後再試。",
  "api_trade_course_4xx": "購買失敗，請稍後再試。",
  "api_trade_cancel_subscription_404": "找不到會員，請稍後再試。",
  "api_trade_cancel_subscription_4xx": "取消訂閱失敗，請稍後再試。",
  "api_trade_change_card_400": "發票資料填寫有誤，請重新確認。",
  "api_trade_change_card_404": "找不到會員，請稍候再試。",
  "api_trade_change_card_4xx": "更換信用卡失敗，請稍後再試。",
  "api_trade_info_detail_400": "資料異常，請稍後再試。",
  "api_trade_info_detail_403": "您沒有權限觀看該筆交易紀錄。",
  "api_trade_info_detail_404": "找不到該筆交易紀錄，請重新確認。",
  "api_trade_info_detail_4xx": "取得交易紀錄異常，請稍候再試。",
  "api_trade_points_404": "找不到用戶，請稍後再試。",
  "api_trade_points_4xx": "取得用戶點數異常，請稍後再試。",
  "api_trade_card_info_4xx": "取得信用卡資料異常，請稍後再試。",
  "api_trade_subscription_history_4xx": "取得訂閱付款明細異常，請稍後再試。",
  "api_user_edit_profile_400": "資料錯誤，請重新確認。",
  "api_user_edit_profile_404": "帳號不存在，請稍後再試。",
  "api_user_edit_profile_4xx": "編輯會員資料失敗，請稍後再試。",
  "api_user_get_profile_404": "帳號不存在，請稍後再試。",
  "api_user_learned_status_404": "帳號不存在，請稍後再試。",
  "api_learned_status_4xx": "無法取得學習履歷資料，請稍後再試。",
  "api_user_learned_history_404": "帳號不存在，請稍後再試。",
  "api_learned_history_4xx": "無法取得學習履歷列表，請稍後再試。",
  "api_user_learned_course_404": "帳號不存在，請稍後再試。",
  "api_learned_course_4xx": "無法取得學習課程列表，請稍後再試",
  "api_user_get_coupon_list_404": "帳號不存在，請稍後再試。",
  "api_user_get_coupon_list_4xx": "無法取得優惠券列表，請稍後再試。",
  "api_user_add_coupon_403": "優惠券無法使用 (ex: 後台取消使用權)",
  "api_user_add_coupon_404": "找不到優惠券",
  "api_user_add_coupon_412": "優惠券無法使用（ex: 今天是 4/18 ，該優惠券使用期間在 4/ 20 - 4/25 之間，在這時間外的都無法使用）",
  "api_user_add_coupon_409": "您已經擁有此優惠券！",
  "api_add_coupon_403": "優惠券無法使用！",
  "api_add_coupon_404": "找不到優惠券。",
  "api_add_coupon_409": "您已擁有此優惠券，無法重複兌換！",
  "api_add_coupon_412": "此優惠券目前不開放領取！",
  "api_add_coupon_4xx": "領取優惠卷失敗，請稍後再試。",
  "api_user_favorite_course_list_404": "沒有課程",
  "api_favorite_course_list_4xx": "無法取得收藏課程列表，請稍後再試。",
  "api_favorite_chapter_list_4xx": "無法取得收藏章節列表，請稍後再試。",
  "api_user_toggle_favorite_course_404": "沒有課程",
  "api_user_toggle_favorite_chapter_404": "沒有章節",
  "api_user_get_tag_list_404": "沒有列表",
  "api_user_tags_404": "無法取得用戶資料，請稍後再試。",
  "api_user_tags_4xx": "取得興趣標籤失敗，請稍後再試。",
  "api_user_update_tag_404": "沒這用戶",
  "api_user_edit_tags_400": "資料異常無法更新興趣標籤，請稍後再試。",
  "api_user_edit_tags_404": "無法取得用戶資料，請稍後再試。",
  "api_user_edit_tags_4xx": "更新興趣標籤失敗，請稍後再試。",
  "api_user_get_redemption_403": "沒有觀看權限（在 web 上透過改網址的方式看別人的資訊）",
  "api_user_get_redemption_404": "沒這用戶",
  "api_redeem_4xx": "取得禮物列表異常，請稍後再試。",
  "api_use_redeem_403": "此禮物碼目前尚未開放領取，請稍後再試。",
  "api_use_redeem_404": "找不到禮物碼，請重新確認。",
  "api_use_redeem_409": "您已擁有此課程，無法使用此禮物碼。",
  "api_use_redeem_410": "此序號已經兌換使用，請重新輸入禮物碼。",
  "api_use_redeem_412": "您目前為訂閱會員，不能同時兌換多個訂閱序號",
  "api_use_redeem_4xx": "無法兌換禮物，請稍後再試。",
  "api_user_get_setting_404": "沒這用戶",
  "api_user_update_setting_404": "沒這用戶",
  "api_user_get_point_history_list_403": "沒有觀看權限（在 web 上透過改網址的方式看別人的資訊）",
  "api_user_get_point_history_list_404": "找不到列表",
  "api_point_purchase_history_4xx": "無法取得點數交易訂單，請稍後再試。",
  "api_user_get_purchase_history_list_403": "沒有觀看權限（在 web 上透過改網址的方式看別人的資訊）",
  "api_user_get_purchase_history_list_404": "找不到列表",
  "api_course_purchase_history_4xx": "無法取得課程訂單，請稍後再試。",
  "api_user_get_purchase_detail_403": "沒有觀看權限（在 web 上透過改網址的方式看別人的資訊）",
  "api_user_get_purchase_detail_404": "找不到列表",
  "api_course_purchase_detail_404": "找不到該課程，請稍後再試。",
  "api_course_purchase_detail_4xx": "無法取得交易詳細資料，請稍後再試。",
  "api_course_get_detail_404": "課程下架或刪除"
};
