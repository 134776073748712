import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const UpdateIssue = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="已經有更新版本，但 Google Play / App store 卻沒有更新按鈕" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            不論是 Android 或 iOS 要發布到全世界，會有一些更新時間需要等待，導致你在 Google Play / App store 會找不到「更新」按鈕或無法自動更新，你可透過以下方式強制手動更新App。
          </div>
          <div className={useStyles.bolder}>
            Google Play / App store 強制手動更新 App 方式
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            請先將「 sandshour 」以及「Google Play / App store」，2個 App 自背景程式滑掉完全移除。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            重新開啟 Google Play / App store，搜尋「sandshour」，並點擊進入「 sandshour 」詳細介紹頁 ，下拉重整詳細介紹頁，再點擊右上角的【更新】(不要在搜尋結果列表按更新），待下載更新完成，即可使用最新版本的 App。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            若無法手動更新，建議將 sandshour App 刪掉移除，重新開機後，在 Google Play / App store 重新搜尋、下載，即可更新為最新版本的App。
          </div>

        </div>
      </FAQCard>
    </>
  );

};

export default UpdateIssue;
