import { request, getHost } from './utils';

export const payForSingleCourse = (token, cid, price, couponId, shouldUsePoints, prime, phone, name, email, invoice) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/course/${cid}/purchase`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      platform: 'Web',
      shouldUsePoints,
      price,
      couponId,
      prime,
      phone,
      name,
      email,
      invoice
    }
  };
  return request(options);
};

export const payForSubscription = (token, uid, prime, phone, name, email, invoice, item, couponId) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/user/${uid}/trade/subscribe`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      platform: 'Web',
      item,
      prime,
      phone,
      name,
      email,
      invoice,
      ...(couponId ? { couponId } : {})
    }
  };
  return request(options);
};

export const changeSubscriptionCard = (token, uid, prime, phone, name, email, invoice) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/user/${uid}/card`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      prime,
      phone,
      name,
      email,
      invoice
    }
  };
  return request(options);
};

export const cancelSubscription = (token, uid) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/user/${uid}/trade/subscribe`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      platform: 'Web'
    }
  };
  return request(options);
};

export const getTradeInfo = (token, uid, tid, rec) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/${uid}/trade/${tid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      platform: 'Web',
      rec
    }
  };
  return request(options);
};

// > new 2024/03/21
export const getSubscriptionTradeInfo = (token, uid, tid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/${uid}/subscription/${tid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getUserPoint = (token, uid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/${uid}/points`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getUserCards = (token, uid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/${uid}/card`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getSubscriptionHistory = (token, uid, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/${uid}/trade/history`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: 20,
      anchor
    }
  };
  return request(options);
};

export const changeSubscriptionType = (token, id, item) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/user/${id}/change/subscribe`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      item
    }
  };
  return request(options);
};

