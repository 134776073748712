import { makeObservable, observable, action, computed } from 'mobx';
import ExposeCardViewModel from 'src/components/CourseCards/ExposeCard/viewModel';

import { COURSE_CATEGORY } from 'src/constants';

export default class ExposeSectionViewModel {
  @observable type;
  @observable selectedTab = 'all';
  @observable swiper;
  @observable cardList;

  constructor(type) {
    makeObservable(this);

    this.init(type);
  }

  @action
  init = (type) => {
    this.type = type;
  }

  @action
  deserialize = (data) => {
    // TODO: call apis: get cards and tabs by types.
    // call outside cuz share same tabs.
    this.cardList = data.list.map((card) => new ExposeCardViewModel(card));
    // for test end.
  }

  @action
  updateData = (data) => {
    this.deserialize(data);
  }

  @action
  onSwiper = (swiper) => {
    this.swiper = swiper;
  }

  @action
  onTabClick = (id) => {
    this.selectedTab = id;
  }

  @computed
  get filteredCardList() {
    if (this.selectedTab === 'all') {
      return this.cardList;
    }
    return this.cardList.filter((card) => card.category === this.selectedTab);
  }
}
