import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import playerViewModel from '../Player/viewModel';

class ScrollPlayerViewModel {
  @observable saveScrollTop = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  onScroll = (event) => {
    const scrollElement = document.querySelector('.App');

    // console.log('scrollElement', scrollElement);
    // documentElement === body, 確實在 scroll 的 el.
    const scrollTop = event.target === scrollElement
      ? scrollElement.scrollTop
      : document.documentElement.scrollTop;

    if (event.target === document || event.target === scrollElement) {
      // > scroll top > 0 prevent overscroll trigger hide.
      if (scrollTop > this.saveScrollTop && scrollTop > 0) {
        // scroll hide.
        playerViewModel.hide();
      } else {
        // scroll show.
        playerViewModel.show();
      }
      // 保存 scrollTop.
      this.saveScrollTop = scrollTop;
    }
  };

  @action
  didMount = () => {
    // 事件綁定.
    const scrollElement = document.querySelector('.App');
    scrollElement.addEventListener('scroll', this.onScroll);
    document.addEventListener('scroll', this.onScroll);
  };

  unMount = () => {
    // 事件刪除.
    const scrollElement = document.querySelector('.App');
    scrollElement.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('scroll', this.onScroll);
  };
}

export default ScrollPlayerViewModel;
