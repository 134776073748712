import { action, runInAction, makeObservable } from 'mobx';
import { SuccessToast, ErrorToast } from 'src/libs/toast';
import i18n from 'src/i18n';
import screenfull from 'screenfull';

import PlayerService from 'src/services/player';
import PlayerError from 'src/services/error/models/player';
import ChapterService from 'src/services/chapter';
import ChapterError from 'src/services/error/models/chapter';
import playerStore from '../store';

class PlayerAPIs {
  constructor(props) {
    this.emitter = props.emitter;
    makeObservable(this);
  }

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 讀取單集詳情.
  handlerGetChapterAPI = async (id) => {
    try {
      // 需要單集 id.
      const res = await PlayerService.getChapter({ id });

      runInAction(() => {
        // 課程資料.
        playerStore.course = res.course;
        // 影片資料.
        playerStore.chapter = res.chapter;

        if ((playerStore.chapter.type !== 'Video' && playerStore.isScreenfull) && screenfull.isEnabled) {
          screenfull.exit(playerStore.mediaContainerRef.current);
        }
      });
    } catch (error) {
      runInAction(() => {
        ErrorToast('無法取得課程資料，請稍後再試');
      });
    }
  };

  // 讀取課程可播放清單.
  handlerGetChapterListAPI = async ({ include }) => {
    try {
      // 需要課程 id.
      const res = await PlayerService.getChapterList({
        id: playerStore.course.id,
        limit: playerStore.limit,
        include,
        available: 1
      });

      runInAction(() => {
        // 讀取 limit 的列表.
        playerStore.anchor = res.anchor;
        playerStore.list = res.list;
      });
    } catch (error) {
      runInAction(() => {
        // ErrorToast('無法取得播放清單，請稍後再試');
      });
    }
  };

  /**
   * 讀取下一頁.
   * isFirst 因為 handlerNextListAPI 會在剛開啟 player 執行, 也會在其他地方執行, 故準備一個標籤判斷是否要顯示沒有下一頁.
   *
   * nex 有可能在讀取下一頁的時候, 順便跳轉到下一頁的最新一集.
   */
  handlerNextListAPI = async ({ showMessage = false, next = false }) => {
    if (playerStore.anchor) {
      try {
        // 需要課程 id.
        const res = await PlayerService.getChapterList({
          id: playerStore.course.id,
          limit: playerStore.limit,
          anchor: playerStore.anchor,
          available: 1
        });

        runInAction(async () => {
          playerStore.anchor = res.anchor;
          // 把下一頁的 list 加進現在的陣列.
          playerStore.list = [...playerStore.list, ...res.list];

          // 讀取下一頁, 並播放下一頁的第一集.
          if (next) {
            this.handlerNextChapterAPI();
            await this.handlerLearnedLastAPI();
          }
        });
      } catch (error) {
        runInAction(() => {
          // ErrorToast('無法取得播放清單，請稍後再試');
        });
      }
    } else if (showMessage) {
      // ErrorToast('沒有下一頁了！');
    }
  };

  // 讀取影片 url 的 api function.
  handlerGetPlayerUrlAPI = async (id) => {
    try {
      // 需要課程 id.
      const res = await PlayerService.getPlayerUrl({ id });

      runInAction(() => {
        // 更新影片時間.
        playerStore.duration = playerStore.chapter.duration;
        playerStore.currentTime = playerStore.chapter.lastAt ?? 0;
        if (playerStore.currentTime >= Math.floor(playerStore.duration)) {
          playerStore.currentTime = 0;
        }
        // NOTE: sync played status.
        const target = playerStore.list.find((chapter) => chapter.id === id);
        target.lastAt = playerStore.currentTime;

        playerStore.isInit = true;

        // 新的影片第一次播放.
        playerStore.isFirstPlay = true;
        // 尚未看完.
        playerStore.isLearned = false;
        // 影片 url.
        playerStore.url = res.url;

        this.emitter.emit('changeChapter', { uid: playerStore.profile?.id ?? null, tid: playerStore.chapter.id });

      });
    } catch (error) {
      runInAction(() => {
      });
    }
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 播放上一集.
  handlerPrevChapterAPI = async () => {
    const index = playerStore.list.findIndex(
      (item) => item.id === playerStore.chapter.id
    );
    const calcIndex = index - 1;

    if (calcIndex > -1) {
      await this.handlerGetChapterAPI(playerStore.list[calcIndex].id);
      await this.handlerGetPlayerUrlAPI(playerStore.list[calcIndex].id);
      await this.handlerLearnedLastAPI();
    } else {
      ErrorToast(i18n.t('player_toast_no_prev'));
    }
  };

  // 播放下一集.
  handlerNextChapterAPI = async () => {
    playerStore.currentTime = 0;

    const index = playerStore.list.findIndex(
      (item) => item.id === playerStore.chapter.id
    );
    const calcIndex = index + 1;

    // 還沒有到最後一集.
    if (calcIndex < playerStore.list.length) {
      await this.handlerGetChapterAPI(playerStore.list[calcIndex].id);
      await this.handlerGetPlayerUrlAPI(playerStore.list[calcIndex].id);
      await this.handlerLearnedLastAPI();
    } else if (playerStore.anchor) {
      // 下一集是下一頁的.
      await this.handlerNextListAPI({ showMessage: true, next: true });
    } else {
      // 顯示提示, 目前 list 最後一集.
      ErrorToast(i18n.t('player_toast_no_next'));
    }
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 影片收藏.
  handlerFavoriteAPI = async ({ id, chapterId = playerStore.chapter.id }) => {
    try {
      const res = await ChapterService.changeIsFavorite(
        id,
        chapterId
      );

      runInAction(() => {
        // store 收藏更新.

        if (chapterId === playerStore.chapter.id) {
          playerStore.chapter = {
            ...playerStore.chapter,
            isFavorite: res.isFavorite
          };
        }

        // 自訂事件.
        this.emitter.emit('favorite', { id: chapterId, res });

        if (res.isFavorite) {
          SuccessToast(i18n.t('course_favorite_add'));
        } else {
          ErrorToast(i18n.t('course_favorite_remove'));
        }
      });
    } catch (error) {
      runInAction(() => {
        ChapterError.changeIsFavorite(error);
      });
    }
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 持續紀錄最後播放的影片與秒數.
  handlerLearnedLastAPI = async () => {
    if (playerStore.profile) {
      try {
        await PlayerService.postLearnedLast({
          id: playerStore.profile.id,
          chapterId: playerStore.chapter.id,
          lastAt: playerStore.calcCurrentTime
        });
      } catch (error) {
        PlayerError.postLearnedLast(error);
      }
    }
  };

  // 紀錄已完成學習的 api, 暫定 80% 秒數.
  @action
  handlerLearnedHistoryAPI = async () => {
    const percentage = playerStore.currentTime / playerStore.duration;

    if (playerStore.profile && !playerStore.isLearned && percentage > 0.8) {
      // 是否已經看完.
      playerStore.isLearned = true;

      try {
        await PlayerService.postLearnedHistory({
          id: playerStore.profile.id,
          chapterId: playerStore.chapter.id
        });
      } catch (error) {
        runInAction(() => {
          PlayerError.postLearnedHistory(error);
        });
      }
    }
  };

  // 持續紀錄最後播放的影片與秒數.
  handlerLearnedCourseAPI = async () => {
    if (playerStore.profile) {
      try {
        await PlayerService.postLearnedCourse({
          id: playerStore.profile.id,
          courseId: playerStore.course.id
        });
      } catch (error) {
        runInAction(() => {
          PlayerError.postLearnedCourse(error);
        });
      }
    }
  };

  // 開啟排程.
  @action
  setIntervals = async () => {
    // 清除排程.
    playerStore.timerHistory = clearInterval(playerStore.timerHistory);
    playerStore.timerLearned = clearInterval(playerStore.timerLearned);

    runInAction(() => {
      // 15 秒紀錄一次, 最後播放影片與秒數.
      const secondsHistory = 1000 * 15;
      // 一分鐘檢查一次已學習.
      const secondsLearned = 1000 * 60;

      // 開啟排程.
      playerStore.timerHistory = setInterval(
        this.handlerLearnedLastAPI,
        secondsHistory
      );
      playerStore.timerLearned = setInterval(
        this.handlerLearnedHistoryAPI,
        secondsLearned
      );
    });
  };

  // 清除排程.
  @action
  clearIntervals = async () => {
    playerStore.timerHistory = clearInterval(playerStore.timerHistory);
    playerStore.timerLearned = clearInterval(playerStore.timerLearned);
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
}

export default PlayerAPIs;
