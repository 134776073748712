import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';

// import unfavIcon from 'src/assets/player/favIcon.svg';
// import favIcon from 'src/assets/player/liked.svg';

import unfavIcon from 'src/assets/player/smallFav.png';
import favIcon from 'src/assets/player/smallFavActive.png';

import closeIcon from 'src/assets/player/closeBtn.svg';

import Back15 from 'src/assets/player/back15.svg';
import PrevBtn from 'src/assets/player/preBtn.svg';
import PlayIcon from 'src/assets/player/playIcon.svg';
import StopIcon from 'src/assets/player/stopIcon.svg';
import Fast15 from 'src/assets/player/fast15.svg';
import Speed50 from 'src/assets/player/speed05X.svg';
import Speed100 from 'src/assets/player/speed1X.svg';
import Speed125 from 'src/assets/player/speed125X.svg';
import Speed150 from 'src/assets/player/speed150X.svg';
import Speed200 from 'src/assets/player/speed2X.svg';
import ManuscriptBtn from 'src/assets/player/manuscriptBtn.svg';
import ManuscriptBtnActive from 'src/assets/player/manuscriptBtnActive.svg';
import PdfIcon from 'src/assets/chapterCard/pdfIconWhite.svg';
import PdfIconActivate from 'src/assets/chapterCard/pdfIconActivate.svg';
import VolumeIcon from 'src/assets/player/volumeIcon.svg';
import MuteIcon from 'src/assets/player/volumeMute.svg';
import OpenBtn from 'src/assets/player/openBtn.svg';
import MoreBtn from 'src/assets/player/moreBtn.svg';

import Disk from 'src/assets/courseCard/disk.png';

import { AppStateContext } from 'src/stores';

import withRouter from 'src/components/withRouter';

import timeLineClassNames from './classNames/timeLine';
import volumeLineClassNames from './classNames/volumeLine';

import ControlsViewModel from './viewModel';
import playerStore from '../../store';
import playerViewModel from '../../viewModel';
import SpeedSelector from './components/SpeedSelector';
import styles from './style.module.scss';

@observer
class ControlsView extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = new ControlsViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props.outerControls);
  }

  componentWillUnmount() {
    this.vm.willUnmount();
  }

  _renderImg = (course) => {
    const { type, thumbnails, name } = course;
    const { url } = thumbnails.web;
    switch (type) {
      case 'Video':
        return (
          <div className={clsx(styles.courseInfoContainer_item)}>
            <div className={clsx(styles.videoThumbnailContainer, styles.courseThumbnail_layout)}>
              <img
                src={url}
                alt={name ?? ''}
                className={clsx(styles.videoThumbnail)}
              />
            </div>
          </div>
        );
      case 'Audio':
        return (
          <div className={clsx(styles.courseInfoContainer_item)}>
            <div style={{ '--src': `url(${Disk})` }} className={styles.disk} />
            <div className={styles.imgContainer}>
              <img
                src={url}
                alt={name ?? ''}
                className={clsx(styles.squareThumbnail, styles.audio)}
              />
            </div>
          </div>
        );
      case 'Audiobook':
        return (
          <div className={clsx(styles.courseInfoContainer_item)}>
            <div className={styles.audioBookImgContainer}>
              <img
                src={url}
                alt={name ?? ''}
                className={clsx(styles.squareThumbnail, styles.thumbnail_layout)}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  _renderSpeedImg = () => {
    switch (playerStore.speed) {
      case 0.5:
        return Speed50;
      case 1:
        return Speed100;
      case 1.25:
        return Speed125;
      case 1.5:
        return Speed150;
      case 2:
        return Speed200;
      default:
        return null;
    }
  }

  render() {
    // 當前是否在文稿頁.
    const { tid } = this.props.router.params;
    const isChapterMain = !!tid && tid === playerStore.chapter?.id;
    const isLandscape = window.innerWidth > window.innerHeight;

    return (
      <div className={clsx(styles.littlePlayer, styles.littlePlayer_layout, playerStore.isScreenfull && styles.isFullScreen)}>
        <div className={clsx(styles.playerFlex, styles.playerFlex_layout)}>
          <div className={clsx(styles.playerFlex_spacer)} />
          {/* <div
            className={clsx(styles.playerFlex_item)}
            onClick={() => {
              this.vm.onFavoriteClick(playerStore.profile?.id, this.props.emitter);
            }}
          >
            <div style={{ '--src': `url(${playerStore.chapter?.isFavorite ? favIcon : unfavIcon})` }} className={clsx(styles.favIconRWD, styles.favIconRWD_layout)} />
          </div> */}
          <div
            className={clsx(styles.playerFlex_item1)}
            onClick={this.vm.onPlayerClose}
          >
            <div style={{ '--src': `url(${closeIcon})` }} className={clsx(styles.closeIcon, styles.closeIcon_layout)} />
          </div>
          <div className={clsx(styles.playerFlex_item2)}>
            <div className={clsx(styles.courseInfoContainer, styles.courseInfoContainer_layout)}>
              {playerStore.course && this._renderImg(playerStore.course)}
              <div className={clsx(styles.courseInfoContainer_spacer)} />
              <div className={clsx(styles.courseInfoContainer_item1)}>
                <div className={clsx(styles.couseDetail, styles.couseDetail_layout)}>
                  <div className={clsx(styles.couseDetail_item)}>
                    <div className={clsx(styles.courseTitle_box, styles.courseTitle_box_layout)}>
                      <div className={clsx(styles.courseTitle)}>
                        {`${playerStore.chapter?.priority ?? ''}. ${playerStore.chapter?.name ?? ''}`}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(styles.couseDetail_item1)}>
                    <div className={clsx(styles.otherInfoContainer, styles.otherInfoContainer_layout)}>
                      <div className={clsx(styles.otherInfoContainer_item)}>
                        <div className={clsx(styles.author, styles.author_layout)}>
                          {playerStore.course?.author.name}
                        </div>
                      </div>
                      {/* {
                        playerStore.profile && (
                          <div
                            className={clsx(styles.otherInfoContainer_item1)}
                            onClick={() => {
                              this.vm.onFavoriteClick(playerStore.profile?.id, this.props.emitter);
                            }}
                          >
                            <div style={{ '--src': `url(${playerStore.chapter?.isFavorite ? favIcon : unfavIcon})` }} className={clsx(styles.favIcon, styles.favIcon_layout)} />
                          </div>
                        )
                      } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(styles.playerFlex_spacer1)} />
          <div className={clsx(styles.playerFlex_item3)}>
            <div className={clsx(styles.controlContainer, styles.controlContainer_layout)}>
              <div className={clsx(styles.courseTitle, styles.smallCourseTitle)}>
                {`${playerStore.chapter?.priority ?? ''}. ${playerStore.chapter?.name ?? ''}`}
              </div>
              <div className={clsx(styles.controlContainer_item)}>
                <div className={clsx(styles.progressBarContainer, styles.progressBarContainer_layout)}>
                  <div className={clsx(styles.nowTime, styles.nowTime_layout)}>
                    {`${this.vm.calcCurrentTime}`}
                  </div>
                  <div className={clsx(styles.progressBarContainer_spacer)} />
                  <div className={clsx(styles.progressBarContainer_item)}>
                    <div className={clsx(styles.progressBar, styles.progressBar_layout)}>
                      {playerStore.duration && (
                        <InputRange
                          allowSameValues
                          minValue={0}
                          maxValue={playerStore.duration}
                          step={0.1}
                          value={playerStore.currentTime ?? 0}
                          formatLabel={() => { }}
                          onChange={this.vm.onChangeTime}
                          classNames={timeLineClassNames}
                        />
                      )}
                    </div>
                  </div>
                  <div className={clsx(styles.progressBarContainer_spacer)} />
                  <div className={clsx(styles.totalTime, styles.totalTime_layout)}>
                    {`${this.vm.calcDuration}`}
                  </div>
                </div>
              </div>
              <div className={clsx(styles.controlContainer_spacer)} />
              <div className={clsx(styles.controlContainer_item1)}>
                <div className={clsx(styles.controlBtnsContainer, styles.controlBtnsContainer_layout)}>
                  <div
                    className={clsx(styles.controlBtnsContainer_item)}
                    onClick={this.vm.onCurrentTimeBackward}
                  >
                    <div style={{ '--src': `url(${Back15})` }} className={clsx(styles.back15Btn, styles.back15Btn_layout)} />
                  </div>
                  <div className={clsx(styles.controlBtnsContainer_spacer)} />
                  <div
                    className={clsx(styles.controlBtnsContainer_item1)}
                    onClick={this.vm.playerAPIs.handlerPrevChapterAPI}
                  >
                    <div style={{ '--src': `url(${PrevBtn})` }} className={clsx(styles.prevBtn, styles.prevBtn_layout)} />
                  </div>
                  <div className={clsx(styles.controlBtnsContainer_spacer1)} />
                  <div className={clsx(styles.controlBtnsContainer_item2)}>
                    <div
                      style={{ '--src': `url(${playerStore.isPaused ? PlayIcon : StopIcon})` }}
                      className={clsx(styles.mainContorl, styles.mainContorl_layout)}
                      onClick={playerStore.isPaused ? this.vm.onPlay : this.vm.onStop}
                    />
                  </div>
                  <div className={clsx(styles.controlBtnsContainer_spacer2)} />
                  <div
                    className={clsx(styles.controlBtnsContainer_item3)}
                    onClick={this.vm.playerAPIs.handlerNextChapterAPI}
                  >
                    <div style={{ '--src': `url(${PrevBtn})` }} className={clsx(styles.nextBtn, styles.nextBtn_layout)} />
                  </div>
                  <div className={clsx(styles.controlBtnsContainer_spacer3)} />
                  <div
                    className={clsx(styles.controlBtnsContainer_item)}
                    onClick={this.vm.onCurrentTimeForward}
                  >
                    <div style={{ '--src': `url(${Fast15})` }} className={clsx(styles.add15Btn, styles.add15Btn_layout)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(styles.playerFlex_spacer2)} />
          <div className={clsx(styles.playerFlex_item4)}>
            <div
              className={clsx(styles.speedContainer, styles.speedContainer_layout)}
              onClick={playerViewModel.speedSelectorModal.toggleOpen}
            >
              <div style={{ '--src': `url(${this._renderSpeedImg()})` }} className={clsx(styles.speedImage, styles.speedImage_layout)} />
              <div className={clsx(styles.speedText, styles.speedText_layout)}>
                {i18n.t('player_playrate')}
              </div>
            </div>
            <SpeedSelector viewModel={playerViewModel.speedSelectorModal} />
          </div>
          <div className={clsx(styles.playerFlex_spacer3)} />
          <div className={clsx(styles.playerFlex_item5)}>
            <div className={styles.manuscriptContainer}>
              {this.vm.isNotVideo && (
                <div className={clsx(styles.manuscriptBtn, styles.manuscriptBtn_layout, isChapterMain && styles.activeManuscript)}>
                  <Link
                    to={this.vm.chapterManuscriptLink}
                    className={clsx(styles.containerFlex, styles.containerFlex_layout)}
                    onClick={this.vm.onManuscriptClick}
                  >
                    <div style={{ '--src': `url(${playerStore.chapter?.document ? PdfIcon : ManuscriptBtn})` }} className={clsx(styles.manuscriptIcon, styles.manuscriptIcon_layout)} />
                    <div className={clsx(styles.manuscriptText_box, styles.manuscriptText_box_layout)}>
                      <pre className={clsx(styles.manuscriptText)}>
                        {playerStore.chapter?.document ? i18n.t('player_pdf') : i18n.t('player_script')}
                      </pre>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className={clsx(styles.playerFlex_spacer4)} />
          <div className={clsx(styles.playerFlex_item6)}>
            <div className={clsx(styles.volumeContainer, styles.volumeContainer_layout)}>
              <div
                className={clsx(styles.volumeContainer_item)}
                onClick={this.vm.setMuted}
              >
                <div style={{ '--src': `url(${playerStore.isMuted ? MuteIcon : VolumeIcon})` }} className={clsx(styles.volumeIcon, styles.volumeIcon_layout)} />
              </div>
              <div className={clsx(styles.volumeContainer_spacer)} />
              <div className={clsx(styles.volumeContainer_item1)}>
                <InputRange
                  allowSameValues
                  minValue={0}
                  maxValue={1}
                  step={0.1}
                  value={playerStore.volume}
                  formatLabel={() => { }}
                  classNames={volumeLineClassNames}
                  onChange={this.vm.onChangeVolume}
                />
              </div>
            </div>
          </div>
          <div className={clsx(styles.playerFlex_spacer5)} />
          <div className={clsx(styles.smallGridContainer)}>
            <div
              className={clsx(styles.playerFlex_item7)}
              onClick={this.vm.onSetBigScreen}
            >
              <div style={{ '--src': `url(${OpenBtn})` }} className={clsx(styles.openIcon, styles.openIcon_layout, playerStore.isBigScreen && styles.rotate)} />
            </div>
            <div className={clsx(styles.playerFlex_item8)}>
              <div
                style={{ '--src': `url(${MoreBtn})` }}
                className={clsx(styles.othersIcon, styles.othersIcon_layout)}
                onClick={playerViewModel.toggleMoreContent}
              />
              {
                playerViewModel.showMoreContent
                && (
                  <>
                    <div className={styles.shadow} onClick={playerViewModel.toggleMoreContent} />
                    <div
                      className={clsx(styles.moreMenuContainer, isLandscape && styles.moreInLand)}
                    >
                      {this.vm.isNotVideo && (
                        <>
                          {
                            playerStore.chapter?.document
                              ? (
                                <Link to={this.vm.chapterManuscriptLink} onClick={this.vm.onManuscriptClick} className={clsx(styles.rowContent, isLandscape && styles.landRowContent)}>
                                  <div style={{ '--src': `url(${isChapterMain ? PdfIconActivate : PdfIcon})` }} className={clsx(styles.manuscriptIcon, styles.manuscriptIcon_layout)} />
                                  <div className={clsx(styles.manuscriptText_box, styles.manuscriptText_box_layout)}>
                                    <pre className={clsx(styles.manuscriptText, isChapterMain && styles.orangeText)}>
                                      檔案
                                    </pre>
                                  </div>
                                </Link>
                              )
                              : (
                                <Link to={this.vm.chapterManuscriptLink} onClick={this.vm.onManuscriptClick} className={clsx(styles.rowContent, isLandscape && styles.landRowContent)}>
                                  <div style={{ '--src': `url(${isChapterMain ? ManuscriptBtnActive : ManuscriptBtn})` }} className={clsx(styles.manuscriptIcon, styles.manuscriptIcon_layout)} />
                                  <div className={clsx(styles.manuscriptText_box, styles.manuscriptText_box_layout)}>
                                    <pre className={clsx(styles.manuscriptText, isChapterMain && styles.orangeText)}>
                                      {i18n.t('player_script')}
                                    </pre>
                                  </div>
                                </Link>
                              )
                          }
                        </>

                      )}
                      <div
                        className={clsx(styles.rowContent, isLandscape && styles.landRowContent)}
                        onClick={this.vm.onCurrentTimeForward}
                      >
                        <div style={{ '--src': `url(${Fast15})` }} className={clsx(styles.chapterBtn)} />
                        <div className={clsx(styles.speedText, styles.speedText_layout)}>
                          {i18n.t('player_add_15')}
                        </div>
                      </div>
                      <div
                        className={clsx(styles.rowContent, isLandscape && styles.landRowContent)}
                        onClick={playerViewModel.speedSelectorModal.toggleOpen}
                      >
                        <div style={{ '--src': `url(${this._renderSpeedImg()})` }} className={clsx(styles.speedImage, styles.speedImage_layout)} />
                        <div className={clsx(styles.speedText, styles.speedText_layout)}>
                          {i18n.t('player_playrate')}
                        </div>
                      </div>
                      <SpeedSelector
                        viewModel={playerViewModel.speedSelectorModal}
                        inMenu
                        isLand={isLandscape}
                      />
                      <div
                        className={clsx(styles.rowContent, isLandscape && styles.landRowContent)}
                        onClick={this.vm.onCurrentTimeBackward}
                      >
                        <div style={{ '--src': `url(${Back15})` }} className={clsx(styles.chapterBtn)} />
                        <div className={clsx(styles.speedText, styles.speedText_layout)}>
                          {i18n.t('player_back_15')}
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
          <div className={clsx(styles.playerFlex_spacer6)} />
        </div>
      </div>
    );
  }
}

ControlsView.propTypes = {
  showBigScreenButton: PropTypes.bool,
  showScreenfullButton: PropTypes.bool,
  outerControls: PropTypes.bool
};

ControlsView.defaultProps = {
  showBigScreenButton: true,
  showScreenfullButton: false,
  outerControls: true
};

export default withRouter(ControlsView);
