import dayjs from 'dayjs';

export default class Notification {
  static fromRes(data) {
    return new Notification(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    // dayjs 實例.
    const dayjsInstance = dayjs(data.createdAt);

    this.id = data.id;

    this.notification = {
      // 標題.
      title: data.title,

      // 時間.
      date: data.createdAt,

      dayjsInstance,
      dayjsFormat: dayjsInstance.format('MM月DD日'),

      // 內容.
      content: data.content,

      // 導航.
      navigation: {
        type: data.navigation.type,
        value: data.navigation.value
      },

      // 這個通知給誰.
      target: {
        type: data.target?.type,
        value: data.target?.value
      },

      createdAt: data.createdAt
    };

    this.readAt = data.readAt;

    this.createdAt = data.createdAt;
  }
}
