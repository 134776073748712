export const delay = (ms) => new Promise((r) => setTimeout(r, ms));

/**
 *
 * @param {string} value
 * @param {(string|RegExp)[]} patterns
 * @returns {boolean}
 */
export const some = (value, patterns = []) => {
  return patterns.some((p) => {
    const reg = Object.prototype.toString.call(value) === '[object String]' ? new RegExp(p) : p;
    return reg.test(value);
  });
};

/**
 * array 1D to 2D
 * @param {any[]} arr
 * @param {number} size group size
 * @returns
 */
export const group = (arr, size) => Array.from(
  { length: Math.ceil(arr.length / size) },
  (_, idx) => arr.slice(idx * size, (idx + 1) * size)
);


export const getShareHost = () => {
  // local
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      return `${r[0]}:3000`;
    }
  }

  // dev
  if (/^dev-/.test(window.location.host)) {
    return 'https://dev-a512b8beacd7.sandshour.com';
  }

  // pro
  return window.location.origin;
};
