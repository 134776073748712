import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import AuthService from 'src/services/authenticate';
import AuthenticateError from 'src/services/error/models/authenticate';

// email 驗證狀態.
const status = 'Verified';

class EmailAuthViewModel {
  @observable reciprocal = 0;
  @observable getProfileTimer = null;
  @observable reciprocalTimer = null;
  @observable isAwait = false;
  @observable isShowResMessage = false;
  @observable resMessage = 'Res 錯誤';

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  @computed
  get isDisabledSubmit() {
    return this.isAwait || this.reciprocal !== 0;
  }

  @computed
  get buttonContent() {
    if (this.reciprocal === 0) {
      return '再寄一次';
    }

    return `重新寄發驗證信 (${this.reciprocal})`;
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  @action
  resetReciprocal = () => {
    // 倒數時間.
    this.reciprocal = 180;
    // 倒數秒數每秒減少的排程.
    this.reciprocalTimer = setInterval(this.handlerReciprocal, 1000);
  };

  // 倒數計時的排程
  @action
  handlerReciprocal = () => {
    this.reciprocal -= 1;

    if (this.reciprocal === 0) {
      this.reciprocalTimer = clearInterval(this.reciprocalTimer);
      this.isAwait = false;
    }
  };

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  // 重複讀取 profile 的 timeout.
  @action
  handlerGetProfileAPI = async () => {
    try {
      const res = await AuthService.getProfile();

      runInAction(() => {
        if (res.status === status) {
          // 清除排程.
          this.getProfileTimer = clearInterval(this.getProfileTimer);
          // 保存 profile.
          this.props.setSaveProfile(res);
          // 前往下一步.
          this.props.handlerNextStep();
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isShowResMessage = true;
        AuthenticateError.profile(error);
      });
    }
  };

  // 寄驗證信的 api.
  @action
  handlerEmailAuthAPI = async () => {
    this.isShowResMessage = false;

    try {
      // 寄出驗證信.
      await AuthService.emailAuth({
        email: this.props.saveProfile.email
      });

      runInAction(() => {
        // 異步結束.
        this.isAwait = false;
      });
    } catch (error) {
      runInAction(() => {
        // 開啟 res 提示.
        this.isShowResMessage = true;
        AuthenticateError.emailAuth(error);
        // 異步結束.
        this.isAwait = false;
      });
    }
  };

  // 按鈕, 再寄一次驗證信.
  @action
  handlerSubmit = async () => {
    if (!this.isDisabledSubmit) {
      // call api.
      this.handlerEmailAuthAPI();
      // 倒數秒數與排程重置.
      this.resetReciprocal();
      this.isAwait = true;
    }
  };

  @action
  handlerToPrev = async () => {
    this.isAwait = true;

    try {
      // 登出.
      await AuthService.logout();

      runInAction(() => {
        // 返回登入頁.
        this.props.handlerToLogin();
        // 清除 saveProfile.
        this.props.setSaveProfile(null);
        // 刪除排程.
        this.unMount();
        this.isAwait = false;
      });
    } catch (error) {
      runInAction(() => {
        AuthenticateError.logout(error);
        this.isAwait = false;
      });
    }
  };

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  @action
  didMount = () => {
    // 讀取 profile 的排程.
    this.getProfileTimer = setInterval(this.handlerGetProfileAPI, 3000);
    // 倒數秒數與排程重置.
    this.resetReciprocal();

    // 從登入來, 缺少資料時發出 api.
    if (this.props.source === this.props.pages.login) {
      // 寄出驗證信.
      this.handlerEmailAuthAPI();
    }
  };

  @action
  unMount = () => {
    // 刪除排程.
    this.getProfileTimer = clearInterval(this.getProfileTimer);
    // 刪除排程.
    this.reciprocalTimer = clearInterval(this.reciprocalTimer);
  };

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
}

export default EmailAuthViewModel;
