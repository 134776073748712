import dayjs from 'dayjs';

export default class SubscriptionTradeInfo {
  constructor(data) {

    this.init(data);
  }

  init = (data) => {
    const { type, item, createdAt, amount } = data.trade[0];

    this.itemType = item;
    this.transactionType = type;
    this.transactionTime = dayjs(createdAt).format('YYYY-MM-DD -- HH:mm:ss');
    this.transactionPrice = Math.ceil(amount);
    this.amount = Math.ceil(amount);
  }

  static fromRes(data) {
    return new SubscriptionTradeInfo(data);
  }
}
