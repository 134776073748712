import { request, getHost } from './utils';

export const getHome = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/home`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: 10
    }
  };
  return request(options);
};

export const getCategories = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/category`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  return request(options);
};

export const getAllBookList = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/custom/group`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};
