import API from 'src/apis';
import ChapterList from 'src/models/response/ChapterList';
import ChapterDetail from 'src/models/response/ChapterDetail';
import Auth from './auth';

export default class ChapterService {
  static async getChapters(cid, anchor) {
    const res = await API.chapter.getChapters(Auth.getToken(), cid, anchor);

    return ChapterList.fromRes(res.data);
  }

  static async getAvailableChapters(cid, anchor) {
    const res = await API.chapter.getAvailableChapters(Auth.getToken(), cid, anchor);

    return ChapterList.fromRes(res.data);
  }

  static async getChapterDetail(eid) {
    const res = await API.chapter.getChapterDetail(Auth.getToken(), eid);

    return ChapterDetail.fromRes(res.data);
  }


  static async getPrevChapter(cid, eid) {
    const res = await API.chapter.getPrevChapter(Auth.getToken(), cid, eid);

    return ChapterDetail.fromRes(res.data);
  }

  static async getNextChapter(cid, eid) {
    const res = await API.chapter.getNextChapter(Auth.getToken(), cid, eid);

    return ChapterDetail.fromRes(res.data);
  }

  static async changeIsFavorite(uid, eid) {
    const res = await API.chapter.changeIsFavorite(Auth.getToken(), uid, eid);

    return res.data;
  }

  static async getChapterComments(cid, anchor) {
    const res = await API.chapter.getChapterComments(Auth.getToken(), cid, anchor);

    return res.data;
  }

  static async getSingleChapterComments(eid, anchor) {
    const res = await API.chapter.getSingleChapterComments(Auth.getToken(), eid, anchor);

    return res.data;
  }

  static async getMyComments(cid, anchor) {
    const res = await API.chapter.getMyComments(Auth.getToken(), cid, anchor);

    return res.data;
  }

  static async getMyCommentsByChapter(eid, anchor) {
    const res = await API.chapter.getMyCommentsByChapter(Auth.getToken(), eid, anchor);

    return res.data;
  }

  static async postNewComment(eid, comment) {
    const res = await API.chapter.postNewComment(Auth.getToken(), eid, comment);

    return res.data;
  }

  static async editComment(eid, tid, comment) {
    const res = await API.chapter.editComment(Auth.getToken(), eid, tid, comment);

    return res.data;
  }

  static async getChapterPermission(tid) {
    const res = await API.chapter.getChapterPermission(Auth.getToken(), tid);

    return res.data.permission;
  }
}
