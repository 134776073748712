import API from 'src/apis';
import UserData from 'src/models/response/UserData';
// 會員中心, 優惠卷.
import Coupon from 'src/models/response/Coupon';
// 會員中心, 兌換.
import Redeem from 'src/models/response/Redeem';
// 會員中心, 購買記錄.
import Purchase from 'src/models/response/Purchase';
import PurchaseListItem from 'src/models/response/PurchaseListItem';
// 會員中心, 學習時間與類型數量.
import LearnedStatus from 'src/models/response/LearnedStatus';
// 會員中心, 學習歷史紀錄.
import LearnedHistory from 'src/models/response/LearnedHistory';
// 興趣與喜好.
import Tag from 'src/models/response/Tag';
import UserTag from 'src/models/response/UserTag';
// 我的課程, 課程播放紀錄.
// import LearnedType from 'src/models/response/LearnedType';
import MyLearned from 'src/models/response/MyLearned';
// Teddy 的 data model.
import CourseCard from 'src/models/response/CourseCard';
import Chapter from 'src/models/response/Chapter';

import Auth from './auth';

export default class UserService {
  // 更新會員資料.
  static async updateProfile({ id, data }) {
    const res = await API.user.updateProfile({
      id,
      data,
      token: Auth.getToken()
    });

    return UserData.fromRes(res.data);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取優惠卷.
  static async getCoupon({ id, anchor, status }) {
    const res = await API.user.getCoupon({
      id,
      token: Auth.getToken(),
      anchor,
      status
    });

    return {
      list: res.data.list.map((item) => Coupon.fromRes(item)),
      anchor: res.data.anchor
    };
  }

  // 輸入優惠卷.
  static async postCoupon({ id, data }) {
    const res = await API.user.postCoupon({
      id,
      token: Auth.getToken(),
      data
    });
    // NOTE: use data.coupon instead of data cuz different construct.
    return Coupon.fromRes(res.data.coupon);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取兌換紀錄.
  static async getRedeem({ id, limit, anchor }) {
    const res = await API.user.getRedeem({
      id,
      token: Auth.getToken(),
      limit,
      anchor
    });

    return {
      list: res.data.list.map((item) => Redeem.fromRes(item)),
      anchor: res.data.anchor
    };
  }

  // 輸入兌換.
  static async postRedeem({ id, data }) {
    const res = await API.user.postRedeem({
      id,
      token: Auth.getToken(),
      data
    });

    return Redeem.fromRes(res.data);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取訂單列表.
  static async getPurchaseList({ id, limit, anchor }) {
    const res = await API.user.getPurchaseList({
      id,
      token: Auth.getToken(),
      limit,
      anchor
    });

    return {
      list: res.data.list.map((item) => PurchaseListItem.fromRes(item)),
      anchor: res.data.anchor
    };
  }

  // 讀取單筆訂單
  // 讀取兌換紀錄.
  static async getPurchaseItem({ id, pid }) {
    const res = await API.user.getPurchaseItem({
      id,
      pid,
      token: Auth.getToken()
    });

    return Purchase.fromRes(res.data.history);
  }

  // 讀取點數紀錄.
  static async getPointHistory(uid, anchor) {
    const res = await API.user.getPointHistory(Auth.getToken(), uid, anchor);

    return res;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 學習履歷, 時間與課程數量.
  static async getLearnedStatus({ id }) {
    const res = await API.user.getLearnedStatus({ id, token: Auth.getToken() });

    return LearnedStatus.fromRes(res.data);
  }

  // 學習履歷, 歷史紀錄.
  static async getLearnedHistory({ id, limit, anchor }) {
    const res = await API.user.getLearnedHistory({
      id,
      token: Auth.getToken(),
      limit,
      anchor
    });

    return {
      anchor: res.data.anchor,
      list: res.data.list.map((item) => LearnedHistory.fromRes(item))
    };
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取現在可以勾選的興趣列表.
  static async getTag() {
    const res = await API.user.getTag();

    return {
      list: res.data.list.map((item) => Tag.fromRes(item))
    };
  }

  // 讀取會員的興趣列表.
  static async getUserTag({ id }) {
    const res = await API.user.getUserTag({ id, token: Auth.getToken() });

    return {
      list: res.data.tags.map((item) => UserTag.fromRes(item))
    };
  }

  // 設定會員的興趣.
  static async putUserTag({ id, data }) {
    const res = await API.user.putUserTag({
      id,
      data,
      token: Auth.getToken()
    });

    return res;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 課程的播放進度, type 是課程的類型.
  static async getLearnedType({ id, type, limit, anchor }) {
    const res = await API.user.getLearnedType({
      id,
      type,
      limit,
      anchor,
      token: Auth.getToken()
    });

    return {
      anchor: res.data.anchor,
      list: res.data.list.map((item) => new MyLearned(item))
    };
  }

  // 收藏的課程.
  static async getFavoriteCourse({ id, limit, anchor }) {
    const res = await API.user.getFavoriteCourse({
      id,
      limit,
      anchor,
      token: Auth.getToken()
    });

    return {
      anchor: res.data.anchor,
      list: res.data.list.map((item) => CourseCard.fromRes(item))
    };
  }

  // 收藏的單集
  static async getFavoriteChapter({ id, limit, anchor }) {
    const res = await API.user.getFavoriteChapter({
      id,
      limit,
      anchor,
      token: Auth.getToken()
    });

    return {
      anchor: res.data.anchor,
      list: res.data.list.map((item) => Chapter.fromRes(item))
    };
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
}
