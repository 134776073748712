import { makeObservable, observable, action } from 'mobx';

export default class BookListCardViewModel {
  @observable id = '';
  @observable title = '';
  @observable subtitle = '';
  @observable tag = '';
  @observable thumbnail = '';

  constructor(data) {
    makeObservable(this);

    this.deserialize(data);
  }

  @action
  deserialize = (data) => {
    const {
      id,
      title,
      subtitle,
      tag,
      thumbnail
    } = data;

    this.id = id;
    this.title = title;
    this.subtitle = subtitle;
    this.tag = tag;
    this.thumbnail = thumbnail;
  }

}
