export default class Tag {
  static fromRes(data) {
    return new Tag(data);
  }

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.order = data.order;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
