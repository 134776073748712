import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import i18n from 'src/i18n';
import { getShareHost } from 'src/utils';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const AccountSetting = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="登入／註冊 sandshour 散時間會員帳號" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            sandshour 散時間提供四種登入／註冊方式：你可以連結 Google、Apple ID「社群帳號」進行登入，或是以訪客身分登入，也可以使用一組常用 Email 作為登入帳號。（網站與 App 均使用同一組會員帳號）
          </div>
          {/* {
            props.isMobile
              ? (
                <> */}
          {/* <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>1.</div>
            點選功能列右下方
            <span className={useStyles.bolder}>
              「我的」
            </span>
            ，點選
            <span className={useStyles.bolder}>
              「登入／註冊」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>2.</div>
            選擇註冊方式
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Email 註冊（請輸入 Email 及 自訂密碼，密碼設定最少為八碼以上英數組合），並至設定的 Email 收取帳號驗證信件完成註冊。
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Google 註冊（連結 Google 帳號）
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Apple ID 註冊（連結 Apple ID 帳號）
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用訪客身分登入使用（後續可註冊信箱，或連結 Google、Apple ID歸戶使用）
            </div>
          </div> */}
          {/* </>
              )
              : (
                <> */}
          <div className={useStyles.bolder}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            點選首頁右上方
            <span className={useStyles.bolder}>
              「登入／註冊」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            選擇註冊方式
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Email 註冊（請輸入 Email 及 自訂密碼，密碼設定最少為八碼以上英數組合，留意英文有分大小寫），並至設定的 Email 收取帳號驗證信件完成註冊
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Google 註冊（連結 Google 帳號）
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Apple ID 註冊（連結 Apple ID 帳號）
            </div>
          </div>
          <div className={useStyles.bolder}>
            ▎  App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            點擊打開 sandshour 散時間 App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            選擇註冊方式
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Email 註冊（請輸入 Email 及 自訂密碼，密碼設定最少為八碼以上英數組合，留意英文有分大小寫），並至設定的 Email 收取帳號確認信件完成認證。
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Google 註冊（連結 Google 帳號）
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用 Apple ID 註冊（連結 Apple ID 帳號）
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              使用訪客身分登入使用（後續可註冊信箱，或連結 Google、Apple ID歸戶使用）
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listNum}>⚠︎</div>
              如果你曾註冊過 sandshour 散時間會員且購買過課程，但登入後找不到先前購課內容。可能是由於目前登入的帳號非當初購買課程時使用的帳號，可以嘗試以其他帳號登入確認。
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listNum}>⚠︎</div>
              已註冊完成之帳號無法變更，亦無法轉移帳戶中的所有課程資料。
            </div>
          </div>
          {/* </>
              )
          } */}
        </div>
      </FAQCard>
      <FAQCard title="使用信箱註冊後沒有收到驗證信" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            完成註冊步驟後，系統會自動發送通知信至你的註冊信箱，部分免費信箱可能會阻擋系統寄發的信件。未收到通知信可能是因為信箱輸入錯誤，或是郵件被阻擋，被當作垃圾郵件，請依照以下步驟依序確認：
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            至信箱
            <span className={useStyles.bolder}>
              「垃圾郵件區」
            </span>
            查看是否有確認信，或在信箱搜尋「sandshour」關鍵字
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            上述步驟如未發現確認信，可重新設定信箱並重寄確認信
          </div>
          <div className={useStyles.content}>
            若上述方式無法解決您的問題，請聯絡客服信箱
            <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`} className={useStyles.aLink}>
              service@sandshour.com
            </a>
            並提供「註冊 Email」，我們會協助查詢確認狀況。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="無法透過 Google／Apple 連動帳號登入" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            如果無法順利登入，請提供以下資訊：相關畫面截圖／操作步驟／遇到的問題／使用載具、型號／會員帳號／聯絡方式，並來信聯絡客服信箱
            <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`} className={useStyles.aLink}>
              service@sandshour.com
            </a>
            ，我們會協助查詢確認狀況。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="註冊成為 sandshour 會員需要費用嗎" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            加入 sandshour 散時間會員無需另外支付費用。一般會員可瀏覽全站
            <span className={useStyles.bolder}>
              「免費試閱」
            </span>
            內容，如需升級為訂閱會員可參考：
            <a href={`${getShareHost()}/subscription`} className={useStyles.aLink}>
              方案與費用
            </a>
          </div>
          <div className={useStyles.content}>
            單購課程請參考下方
            <span className={useStyles.bolder}>
              【購買課程】→【課程方案】
            </span>
            說明。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="修改個人資料" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.bolder}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            點擊首頁右上方
            <span className={useStyles.bolder}>
              「登入／註冊」
            </span>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊右上方個人頭像，打開
            <span className={useStyles.bolder}>
              「我的帳戶」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點擊
            <span className={useStyles.bolder}>
              「基本資料」
            </span>
            即可修改個人資料
          </div>
          <br />
          <div className={useStyles.bolder}>
            ▎  App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊功能列右下方
            <span className={useStyles.bolder}>
              「我的」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            找到個人暱稱旁邊鉛筆圖示，點選進入
            <span className={useStyles.bolder}>
              「編輯檔案」
            </span>
            ，即可修改個人資料
          </div>
          <br />
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⚠︎</div>
            個人生日在初次更新儲存後就無法再次修改。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="忘記密碼" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            使用 Email 註冊，點選
            <span className={useStyles.bolder}>
              「忘記密碼」
            </span>
            ，輸入註冊 Email 會另外發送驗證信，通過驗證後即可重新設定登入密碼。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="如何刪除帳號" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            很遺憾知道你不再使用 sandshour 散時間而打算刪除帳號，若你有刪除帳號需求，可以自行於 iOS App / Android App 上操作申請刪除會員帳號，或來信聯絡客服信箱
            <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`} className={useStyles.aLink}>
              service@sandshour.com
            </a>
            ，我們後續將為你協助處理。
          </div>
          <div className={useStyles.content}>
            ※特別提醒，刪除會員帳號後，包含會員帳號內的個人資料、資料將同步刪除。且無法再以該帳號瀏覽已購買課程及查詢先前訂單紀錄等資訊，也無法再享有 sandshour 散時間提供會員服務。若有加入訂閱服務也請確認已取消目前訂閱方案。會員資料一旦刪除將無法恢復，建議刪除前可以再次確認，如需刪除會員，請依照下方步驟操作：
          </div>
          {
            !props.isMobile
            && (
              <>
                <div className={clsx(useStyles.content, useStyles.bolder)}>
                  ▎  網站
                </div>
                <div className={useStyles.content}>
                  目前刪除帳號功能僅限  iOS App / Android App。若你使用網頁版，請
                  <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`} className={useStyles.aLink}>
                    聯絡我們
                  </a>
                  申請刪除帳號。我們會在7天內透過來信回覆處理結果。
                </div>
                <div className={clsx(useStyles.content, useStyles.bolder)}>
                  ▎  App
                </div>
              </>
            )
          }
          <div className={useStyles.content}>
            <p>
              ⑴ 登入會員帳號
            </p>
            <p>
              ⑵ 點擊下方功能列
              <span className={clsx(useStyles.bolder)}>
                「我的」
              </span>

            </p>
            <p>
              ⑶ 點擊個人名稱旁
              <span className={clsx(useStyles.bolder)}>
                「鉛筆圖示」
              </span>
              ，進入
              <span className={clsx(useStyles.bolder)}>
                「編輯檔案」
              </span>
            </p>
            <p>
              ⑷
              <span className={clsx(useStyles.bolder)}>
                「編輯檔案」
              </span>
              下拉至頁面底部，點選
              <span className={clsx(useStyles.bolder)}>
                「刪除帳號申請」
              </span>
            </p>
          </div>
        </div>
      </FAQCard>
    </>
  );

};

export default AccountSetting;
