export default class Rating {
  constructor(data) {
    const {
      id,
      title,
      score,
      comment,
      createdAt
    } = data.rating;

    this.id = id;
    this.title = title;
    this.score = score;
    this.comment = comment;
    this.createdAt = createdAt;
  }

  static fromRes(data) {
    return new Rating(data);
  }
}
