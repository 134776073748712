import { LIST_LIMIT_PER_CALL } from 'src/constants';
import { request, getHost } from './utils';

export const getCoursesByType = (token, type, category, order, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/course`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      type,
      category,
      order,
      anchor,
      limit: LIST_LIMIT_PER_CALL.courseList
    }
  };
  return request(options);
};

export const getCourseDetail = (token, cid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/course/${cid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getCourseRating = (token, cid, anchor, order) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/course/${cid}/rating?`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: LIST_LIMIT_PER_CALL.commentAndRating,
      anchor,
      order
    }
  };
  return request(options);
};

export const getMyRating = (token, cid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/course/${cid}/rating/me`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const postRating = (token, cid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/course/${cid}/rating`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      title: data.title,
      score: data.score,
      comment: data.comment
    }
  };
  return request(options);
};

export const putRating = (token, cid, rid, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/course/${cid}/rating/${rid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      title: data.title,
      score: data.score,
      comment: data.comment
    }
  };
  return request(options);
};

export const changeIsFavorite = (token, uid, cid) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/user/${uid}/favorite/course/${cid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const addToAlreadyLearned = (token, uid, cid) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/${uid}/learned/course/${cid}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};
