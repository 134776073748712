import { LIST_LIMIT_PER_CALL } from 'src/constants';
import { getHost, request } from './utils';

export const getChapters = (token, cid, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v2/course/${cid}/chapter`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: LIST_LIMIT_PER_CALL.chapterList,
      anchor
    }
  };
  return request(options);
};

export const getAvailableChapters = (token, cid, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v2/course/${cid}/chapter`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: LIST_LIMIT_PER_CALL.chapterList,
      available: true,
      anchor
    }
  };
  return request(options);
};

export const getChapterDetail = (token, eid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/chapter/${eid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const changeIsFavorite = (token, uid, eid) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/user/${uid}/favorite/chapter/${eid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getChapterComments = (token, cid, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/course/${cid}/chapter/comment`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: LIST_LIMIT_PER_CALL.commentAndRating,
      anchor
    }
  };
  return request(options);
};

export const getSingleChapterComments = (token, eid, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/chapter/${eid}/comment`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: LIST_LIMIT_PER_CALL.commentAndRating,
      anchor
    }
  };
  return request(options);
};

export const getMyComments = (token, cid, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/course/${cid}/chapter/comment`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: LIST_LIMIT_PER_CALL.commentAndRating,
      anchor,
      me: 1
    }
  };
  return request(options);
};

export const getMyCommentsByChapter = (token, eid, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/chapter/${eid}/comment`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    params: {
      limit: LIST_LIMIT_PER_CALL.commentAndRating,
      anchor,
      me: 1
    }
  };
  return request(options);
};

export const postNewComment = (token, eid, comment) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/chapter/${eid}/comment`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      comment
    }
  };
  return request(options);
};

export const editComment = (token, eid, tid, comment) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/chapter/${eid}/comment/${tid}`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },
    data: {
      comment
    }
  };
  return request(options);
};

export const getPrevChapter = (token, cid, eid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v2/course/${cid}/chapter/${eid}/prev`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getNextChapter = (token, cid, eid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v2/course/${cid}/chapter/${eid}/next`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};

export const getChapterPermission = (token, tid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/chapter/${tid}/permission`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };
  return request(options);
};
