import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import BubbleArrow from 'src/assets/header/bubbleArrow.png';
import SearchIcon from 'src/assets/header/searchIcon.png';

import withRouter from 'src/components/withRouter';
import i18n from 'src/i18n';
import KeywordViewModel from './viewModel';

import styles from './style.module.scss';

@observer
class KeywordView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new KeywordViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate() {
    // this.vm.didUpdate(this.props);
  }

  render() {
    return (
      <>
        <div
          className={clsx(styles.contentFlexBox_item1)}
        >
          <div
            style={{ '--src': `url(${SearchIcon})` }}
            className={clsx(styles.searchTiny, styles.searchTiny_layout)}
            onClick={this.vm.toggleInputContent}
          />
          {
            this.vm.isShowHotKeyword && (
              <div className={clsx(styles.popularBubbleContainer, styles.bubbleFullScreen)}>
                <div className={clsx(styles.popularBubbleContainer_item)}>
                  <img src={BubbleArrow} alt="arrow-position-scrubber" className={clsx(styles.arrow, styles.arrow_layout)} />
                </div>
                <div className={clsx(styles.popularBubbleContainer_item1)}>
                  <div className={clsx(styles.contentContainer, styles.contentContainer_layout)}>
                    <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
                      <div className={clsx(styles.contentFlex_item)}>
                        <div className={clsx(styles.searchBarContainer, styles.searchBarContainer_layout)}>
                          <div className={clsx(styles.searchBarContainer_item)}>
                            <div className={clsx(styles.searchInputContainer, styles.searchInputContainer_layout)}>
                              <div className={clsx(styles.inouFlex, styles.inouFlex_layout)}>
                                <div className={clsx(styles.inouFlex_item)}>
                                  <div style={{ '--src': `url(${SearchIcon})` }} className={clsx(styles.searchIcon, styles.searchIcon_layout)} />
                                </div>
                                <div className={clsx(styles.inouFlex_spacer)} />
                                <input
                                  type="text"
                                  className={clsx(styles.searchInput, styles.searchInput_layout)}
                                  value={this.vm.keyword}
                                  onChange={this.vm.setKeyword}
                                  onKeyDown={this.vm.onKeydown}
                                  onFocus={this.vm.onFocus}
                                  onBlur={this.vm.onBlur}
                                  ref={this.vm.inputRef}
                                  placeholder={i18n.t('home_search')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={clsx(styles.searchBarContainer_item1)}>
                            <div
                              className={clsx(styles.cancelBtn, styles.cancelBtn_layout)}
                              onClick={this.vm.toggleInputContent}
                            >
                              <h4 className={clsx(styles.cancelText, styles.cancelText_layout)}>
                                {i18n.t('btn_cancel')}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={clsx(styles.contentFlex_spacer)} />
                      <div className={clsx(styles.contentFlex_item1)}>
                        <div className={clsx(styles.popularContainer, styles.popularContainer_layout)}>
                          <h3 className={clsx(styles.popularTitle, styles.popularTitle_layout)}>
                            {i18n.t('search_hot')}
                          </h3>
                          <div className={clsx(styles.popularContainer_spacer)} />

                          <div className={clsx(styles.listContainer, styles.listContainer_layout)}>
                            {this.vm.hotKeywords.map((item) => {
                              return (
                                <div
                                  key={item.id}
                                  className={clsx(styles.listContainer_item)}
                                  onClick={(event) => {
                                    this.vm.onClickHotKeyword(event, item.name);
                                  }}
                                >
                                  <div className={clsx(styles.listItem, styles.listItem_layout)}>
                                    <h3 className={clsx(styles.itemText, styles.itemText_layout)}>
                                      {item.name}
                                    </h3>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className={clsx(styles.contentFlex_spacer1)} />
                    </div>
                  </div>
                </div>
              </div>
            )
          }

        </div>
        <div className={clsx(styles.contentFlexBox_spacer1)} />
        <div className={clsx(styles.contentFlexBox_item2)}>
          <div
            className={clsx(
              styles.searchContainer,
              styles.searchContainer_layout
            )}
          >
            <div className={clsx(styles.innerContent, styles.innerContent_layout)}>
              <div className={clsx(styles.innerContent_item)}>
                <div
                  style={{ '--src': `url(${SearchIcon})` }}
                  className={clsx(styles.searchIcon, styles.searchIcon_layout)}
                />
              </div>
              <div className={clsx(styles.innerContent_spacer)} />
              <div className={clsx(styles.innerContent_item1)}>
                <div
                  className={styles.keyword}
                  tabIndex="-1"
                  onFocus={this.vm.onFocus}
                  onBlur={this.vm.onBlur}
                  ref={this.vm.inputContainerRef}
                >
                  <input
                    type="text"
                    className={styles.input}
                    value={this.vm.keyword}
                    onChange={this.vm.setKeyword}
                    onKeyDown={this.vm.onKeydown}
                    onFocus={this.vm.onFocus}
                    onBlur={this.vm.onBlur}
                    ref={this.vm.inputRef}
                    placeholder={i18n.t('home_search')}
                  />
                  <div className={clsx(styles.popularBubbleContainer, styles.d_none, this.vm.isShowHotKeyword && styles.d_block)}>
                    <div className={clsx(styles.popularBubbleContainer_item)}>
                      <img src={BubbleArrow} alt="arrow-position-scrubber" className={clsx(styles.arrow, styles.arrow_layout)} />
                    </div>
                    <div className={clsx(styles.popularBubbleContainer_item1)}>
                      <div className={clsx(styles.contentContainer, styles.contentContainer_layout)}>
                        <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
                          <div className={clsx(styles.contentFlex_item1)}>
                            <div className={clsx(styles.popularContainer, styles.popularContainer_layout)}>
                              <h3 className={clsx(styles.popularTitle, styles.popularTitle_layout)}>
                                {i18n.t('search_hot')}
                              </h3>
                              <div className={clsx(styles.popularContainer_spacer)} />

                              <div className={clsx(styles.listContainer, styles.listContainer_layout)}>
                                {this.vm.hotKeywords.map((item) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className={clsx(styles.listContainer_item)}
                                      onClick={(event) => {
                                        this.vm.onClickHotKeyword(event, item.name);
                                      }}
                                    >
                                      <div className={clsx(styles.listItem, styles.listItem_layout)}>
                                        <h3 className={clsx(styles.itemText, styles.itemText_layout)}>
                                          {item.name}
                                        </h3>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className={clsx(styles.contentFlex_spacer1)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(KeywordView);
