import dayjs from 'dayjs';

export default class CourseRating {
  constructor(data) {
    const {
      anchor,
      list
    } = data;

    this.anchor = anchor;
    this.list = list;
  }

  static fromRes(data) {
    return new CourseRating(data);
  }
}
