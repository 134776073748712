import { makeObservable, observable, action, computed } from 'mobx';
import i18n from 'src/i18n';

import ExposeCardViewModel from 'src/components/CourseCards/ExposeCard/viewModel';


export default class RankingSectionViewModel {
  @observable videoList;
  @observable audioList;
  @observable audioBookList;
  @observable typeBtns = [
    {
      id: 'video',
      name: i18n.t('home_ranking_tab_video')
    },
    {
      id: 'audio',
      name: i18n.t('home_ranking_tab_audio')
    },
    {
      id: 'audiobook',
      name: i18n.t('home_ranking_tab_audiobook')
    }
  ];
  @observable nowType = 'video';

  @observable swiper;

  constructor() {
    makeObservable(this);
  }

  @action
  updateData = (data) => {
    this.videoList = data.videos.map((el) => new ExposeCardViewModel(el));
    this.audioList = data.audios.map((el) => new ExposeCardViewModel(el));
    this.audioBookList = data.audiobooks.map((el) => new ExposeCardViewModel(el));
  }

  @action
  onBtnClick = (type) => {
    this.nowType = type;
    this.swiper.slideTo(0);
  }

  @action
  onSwiper = (swiper) => {
    this.swiper = swiper;
  }

  @computed
  get nowList() {
    switch (this.nowType) {
      case 'video':
        return this.videoList;
      case 'audio':
        return this.audioList;
      case 'audiobook':
        return this.audioBookList;
      default:
        return [];
    }
  }
}
