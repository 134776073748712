import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const OrderRelated = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="訂單查詢" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            可供查詢之訂單為單購課程和點數儲值紀錄。
          </div>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            <div className={useStyles.content}>
              登入會員帳號
            </div>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            <div className={useStyles.content}>
              點擊右上方個人頭像，打開
              <span className={useStyles.bolder}>
                「我的帳戶」
              </span>
            </div>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            <div className={useStyles.content}>
              點擊
              <span className={useStyles.bolder}>
                「訂單」
              </span>
              ，可查看已購買課程內容和 App 點數儲值紀錄
            </div>
          </div>
          <br />
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            <div className={useStyles.content}>
              登入會員帳號
            </div>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            <div className={useStyles.content}>
              點擊下方功能列
              <span className={useStyles.bolder}>
                「我的」
              </span>
            </div>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            <div className={useStyles.content}>
              點擊
              <span className={useStyles.bolder}>
                「訂單」
              </span>
              ，可查看已購買課程內容和 App 點數儲值紀錄
            </div>
          </div>
        </div>
      </FAQCard>
      <FAQCard title="發票開立" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            為響應政府「環保愛地球、節能減碳」政策， sandshour 散時間全面開立電子發票，恕不提供郵寄電子發票證明聯紙本。於網站購買商品後將由鯨耀科技系統發送「電子發票開立通知信」至您註冊帳號的電子信箱，且同步將發票號碼上傳至政府電子發票整合服務平台。
          </div>
          <div className={useStyles.content}>
            如未收到「電子發票開立通知信」，請來信至客服信箱
            <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`} className={useStyles.aLink}>
              service@sandshour.com
            </a>
            ，我們將盡快提供您協助。
          </div>
          <div className={useStyles.content}>
            ⚠︎ 在 App 以點數購買課程，發票已在「儲值點數」時開出，扣抵課程時將不會再次開立發票。
          </div>
          <div className={useStyles.content}>
            發票類型：捐贈發票、個人發票、統編發票
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            <span className={useStyles.bolder}>
              捐贈發票
            </span>
            <div className={useStyles.content}>
              點選下拉式選單，選擇捐贈單位，或自行輸入單位愛心碼，開立發票時就會直接進行發票捐贈。
            </div>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            <span className={useStyles.bolder}>
              個人發票
            </span>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              會員載具：發票儲存在 sandshour 散時間會員，僅寄發「電子發票開立通知」，如需要購課收據證明，請自行至「電子發票開立通知」中的「電子發票明細連結」，進入頁面列印。若發票為中獎發票，請參考下方
              <span className={useStyles.bolder}>
                【發票中獎】
              </span>
              。
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              手機條碼：格式為 8 碼英數字符號（第一個字元/ + 7 個英數字符號）。
            </div>
            <div className={useStyles.listItem}>
              <div className={useStyles.listDot} />
              自然人憑證條碼：格式為 16 碼英數字符號（前兩碼大寫字母 + 14 個數字符號）。
            </div>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            <span className={useStyles.bolder}>
              統編發票
            </span>
            <div className={useStyles.content}>
              如需開立統一編號，請於購課結帳頁面中，於發票類型欄位選擇
              <span className={useStyles.bolder}>
                「統編發票」
              </span>
              ，並填寫公司統一編號，系統後續將開立公司戶電子發票，發送至註冊帳號的電子信箱，可自行下載列印。 sandshour 散時間將不再另外寄送紙本統編發票。
            </div>
          </div>
          <br />
          <div className={useStyles.content}>
            ⚠︎ 電子發票一經開立後，統編無法再做更動，請務必確認資訊正確。
          </div>
          <div className={useStyles.content}>
            鯨躍平台開立完發票後，30分鐘-1小時左右會上傳至財政部平台，2小時-1天內會排程發送通知信，實際寄送時間依當日所有營業人寄送量而定。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="發票中獎" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            發票開立系統會於每單月 29 日自動為發票對獎，如果你儲存在 sandshour 散時間的電子發票幸運中獎，後續會寄出
            <span className={useStyles.bolder}>
              「中獎通知」
            </span>
            電子信件至你的
            <span className={useStyles.bolder}>
              「註冊信箱」
            </span>
            ，收到通知後，可前往全家便利商店  FamiPort 機台列印中獎發票。
          </div>
          <div className={useStyles.content}>
            ⚠︎ FamiPort 機台列印發票操作方式及常見問題請參考：
            <a href="https://reurl.cc/g2Q3rV" className={useStyles.aLink}>
              FamiPort列印中獎電子發票
            </a>
          </div>
        </div>
      </FAQCard>
    </>
  );

};

export default OrderRelated;
