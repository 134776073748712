import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import isMobile from 'ismobilejs';
import screenfull from 'screenfull';

import fullScreenIcon from 'src/assets/player/fullScreen.png';
import styles from './style.module.scss';
import ControlsView from '../Controls';
import ScreenControlsViewModel from './viewModel';
import playerViewModel from '../../viewModel';
import playerStore from '../../store';

@observer
class ScreenControlsView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ScreenControlsViewModel();
  }

  render() {
    return (
      <div
        className={clsx(styles.screenControls)}
        onMouseMove={this.vm.onMouseMove}
        onClick={this.vm.onScreenfull}
      >
        {
          (!isMobile(window.navigator).apple.device && screenfull.isEnabled) && (
            <div
              className={clsx(styles.curtain, playerStore.isScreenfull && styles.show)}
              onClick={this.vm.onClick}
            />
          )
        }

        <div
          className={clsx([
            styles.controlsContainer,
            this.vm.isShow && styles.hover,
            !playerStore.isScreenfull && styles.hide
          ])}
        >

          {
            (!isMobile(window.navigator).apple.device && screenfull.isEnabled) && (
              <ControlsView
                showBigScreenButton={false}
                showScreenfullButton
                emitter={this.props.emitter}
                outerControls={false}
              />
            )
          }
        </div>

        <div className={styles.screenfullButtonContainer}>
          {(!playerStore.isScreenfull && this.vm.showFullScreen) && (
            <div style={{ '--src': `url(${fullScreenIcon})` }} className={clsx(styles.fullscreenIcon, styles.fullscreenIcon_layout)} />
          )}
        </div>
      </div>
    );
  }
}

export default ScreenControlsView;
