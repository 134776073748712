import dayjs from 'dayjs';

export default class Coupon {
  static fromRes(data) {
    return new Coupon(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    const targets = data.coupon.targets.map((target) => {
      return {
        id: target.id
      };
    });
    // 優惠卷起始時間.
    const startAt = data.coupon.startAt;
    // 優惠卷結束時間.
    const endAt = data.coupon.endAt;
    // dayjs instance.
    const startAtFromDayjsInstance = dayjs(startAt);
    const endAtFromDayjsInstance = dayjs(endAt);
    const endAtText = endAtFromDayjsInstance.format('到YYYY年MM月DD有效');
    const validTime = `${startAtFromDayjsInstance.format(
      'YYYY/MM/DD'
    )}~${endAtFromDayjsInstance.format('YYYY/MM/DD')}`;

    this.id = data.coupon.id;
    this.name = data.coupon.name;
    // 描述.
    this.description = data.coupon.description;
    // 折扣.
    this.discount = Number(data.coupon.discount);
    // 可使用.
    this.isAvailable = data.coupon.isAvailable;
    this.targets = targets;
    // 使用時間.
    this.usedAt = data.usedAt;
    // 優惠卷起始時間.
    this.startAt = startAt;
    // 優惠卷結束時間.
    this.endAt = endAt;
    // 使用 dayjs 轉時間.
    this.endAtText = endAtText;
    this.startAtFromDayjsInstance = startAtFromDayjsInstance;
    this.endAtFromDayjsInstance = endAtFromDayjsInstance;
    this.validTime = validTime;
    this.type = data.coupon.type;
  }
}
