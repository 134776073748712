import { makeObservable, action, observable, computed } from 'mobx';

import RecommendCardViewModel from 'src/components/ChapterCards/RecommendCard/viewModel';

export default class RecommendSectionViewModel {
  @observable cardList;

  constructor() {
    makeObservable(this);
  }

  @action
  updateData = (data) => {
    this.cardList = data.map((card) => new RecommendCardViewModel(card));
  }
}
