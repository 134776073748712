import dayjs from 'dayjs';

export default class PurchaseListItem {
  static fromRes(data) {
    return new PurchaseListItem(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    const dayjsInstance = dayjs(data.createdAt);

    this.id = data.id;

    this.course = {
      id: data.item.id,
      type: data.item.type,
      name: data.item.name,
      thumbnail: data.item.thumbnails?.web.url,
      originPrice: data.item.price
    };

    this.couponPrice = data.coupon?.discount ?? null;

    this.price = data.price;
    this.transactionId = data.transactionId;

    this.date = data.createdAt;
    this.dayjsInstance = dayjsInstance;
    this.dateFormat = dayjsInstance.format('YYYY/MM/DD HH:mm');
  }
}
