import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import i18n from 'src/i18n';

import { SOCIAL_LINK } from 'src/constants';

import facebook from 'src/assets/footer/facebook.png';
import instagram from 'src/assets/footer/insta.svg';
import youtube from 'src/assets/footer/youtube.svg';

import appStore from 'src/assets/footer/appStore.png';
import googlePlay from 'src/assets/footer/googlePlay.png';

import deco from 'src/assets/footer/deco.png';
import footerLogo from 'src/assets/footer/footerLogo.png';

import styles from './style.module.scss';


class FooterView extends React.Component {
  render() {
    return (
      <section className={`footer ${clsx(styles.footerContainer, styles.footerContainer_layout)}`}>
        <div className={clsx(styles.contentContainer, styles.contentContainer_layout)}>
          <div className={clsx(styles.contentFlexBox, styles.contentFlexBox_layout)}>
            <div className={clsx(styles.mainContent, styles.mainContent_layout)}>
              <div className={clsx(styles.mainFlexBox, styles.mainFlexBox_layout)}>
                <div className={clsx(styles.mainFlexBox_item)}>
                  <div className={clsx(styles.aboutUs, styles.aboutUs_layout)}>
                    <h5 className={clsx(styles.aboutUsTitle, styles.aboutUsTitle_layout)}>
                      {i18n.t('footer_about_sandshour')}
                    </h5>
                    <Link
                      to="/about-us"
                    >
                      <h5 className={clsx(styles.footerContent, styles.footerContent_layoutFirst)}>
                        {i18n.t('footer_about_us')}
                      </h5>
                    </Link>

                    <a href={SOCIAL_LINK.sandsbook} target="_blank" rel="noreferrer" className={clsx(styles.row2, styles.row2_layout)}>
                      <h5 className={clsx(styles.footerContent, styles.footerContent_layout)}>
                        {i18n.t('footer_sandsbook')}
                      </h5>
                    </a>

                    <div className={clsx(styles.aboutUs_spacer)} />
                  </div>
                </div>
                <div className={clsx(styles.mainFlexBox_spacer)} />
                <div className={clsx(styles.mainFlexBox_item1)}>
                  <div className={clsx(styles.helpCenter, styles.helpCenter_layout)}>
                    <h5 className={clsx(styles.helpCenterTitle, styles.helpCenterTitle_layout)}>
                      {i18n.t('footer_help')}
                    </h5>
                    <Link
                      to="/faq"
                    >
                      <h5 className={clsx(styles.footerContent, styles.footerContent_layoutFirst)}>
                        {i18n.t('footer_faq')}
                      </h5>
                    </Link>
                    <Link
                      to="/term-of-use"
                    >
                      <h5 className={clsx(styles.footerContent, styles.footerContent_layout)}>
                        {i18n.t('footer_term_of_use')}
                      </h5>
                    </Link>
                    <Link
                      to="/private-policy"
                    >
                      <h5 className={clsx(styles.footerContent, styles.footerContent_layout)}>
                        {i18n.t('footer_private_policy')}
                      </h5>
                    </Link>
                    <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`}>
                      <h5 className={clsx(styles.footerContent, styles.footerContent_layout)}>
                        {i18n.t('footer_contact_us')}
                      </h5>
                    </a>
                  </div>
                </div>
                <div className={clsx(styles.mainFlexBox_spacer1)} />
                <div className={clsx(styles.mainFlexBox_item2)}>
                  <div className={clsx(styles.socialMedia, styles.socialMedia_layout)}>
                    <h5 className={clsx(styles.socialMediaTitle, styles.socialMediaTitle_layout)}>
                      {i18n.t('footer_social')}
                    </h5>

                    <div className={clsx(styles.flex131, styles.flex131_layout)}>
                      <a href={SOCIAL_LINK.facebook} target="_blank" rel="noreferrer">
                        <div className={clsx(styles.fb, styles.fb_layout)}>
                          <div style={{ '--src': `url(${facebook})` }} className={clsx(styles.image7, styles.image7_layout)} />
                          <h5 className={clsx(styles.highlights1_box, styles.highlights1_box_layout)}>
                            <pre className={clsx(styles.highlights1)}>{i18n.t('footer_facebook')}</pre>
                          </h5>
                        </div>
                      </a>

                      <a href={SOCIAL_LINK.instagram} target="_blank" rel="noreferrer">
                        <div className={clsx(styles.ig, styles.ig_layout)}>
                          <div style={{ '--src': `url(${instagram})` }} className={clsx(styles.icon2, styles.icon2_layout)} />
                          <h5 className={clsx(styles.highlights11, styles.highlights11_layout)}>
                            {i18n.t('footer_instagram')}
                          </h5>
                        </div>
                      </a>

                      <a href={SOCIAL_LINK.youtube} target="_blank" rel="noreferrer">
                        <div className={clsx(styles.yt, styles.yt_layout)}>
                          <div style={{ '--src': `url(${youtube})` }} className={clsx(styles.image5, styles.image5_layout)} />
                          <h5 className={clsx(styles.highlights12, styles.highlights12_layout)}>
                            {i18n.t('footer_youtube')}
                          </h5>
                        </div>
                      </a>

                      <div className={clsx(styles.flex131_spacer)} />
                    </div>
                  </div>
                </div>
                <div className={clsx(styles.mainFlexBox_spacer2)} />
                <div className={clsx(styles.mainFlexBox_item3)}>
                  <div className={clsx(styles.downLoad, styles.downLoad_layout)}>
                    <div className={clsx(styles.downLoad_item)}>
                      <h5 className={clsx(styles.downLoadTitle, styles.downLoadTitle_layout)}>
                        {i18n.t('footer_download')}
                      </h5>
                    </div>
                    <div className={clsx(styles.downLoad_spacer)} />
                    <div className={clsx(styles.downLoad_item)}>
                      <div className={clsx(styles.btnContainer, styles.btnContainer_layout)}>
                        <a href={SOCIAL_LINK.appstore} target="_blank" rel="noreferrer">
                          <div style={{ '--src': `url(${appStore})` }} className={clsx(styles.appStore, styles.appStore_layout)} />
                        </a>
                        <a href={SOCIAL_LINK.googleplay} target="_blank" rel="noreferrer">
                          <div style={{ '--src': `url(${googlePlay})` }} className={clsx(styles.googlePlay, styles.googlePlay_layout)} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ '--src': `url(${deco})` }} className={clsx(styles.image9, styles.image9_layout)} />
            </div>

            <div className={clsx(styles.line, styles.line_layout)} />
            <div className={clsx(styles.contentFlexBox_spacer)} />

            <div className={clsx(styles.rightSection, styles.rightSection_layout)}>
              <div className={clsx(styles.rightSection_item)}>
                <div style={{ '--src': `url(${footerLogo})` }} className={clsx(styles.footerIcon, styles.footerIcon_layout)} />
              </div>
              <div className={clsx(styles.rightSection_spacer)} />
              <div className={clsx(styles.rightSection_item1)}>
                <div className={clsx(styles.rightContent, styles.rightContent_layout)}>
                  {i18n.t('footer_content_right')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FooterView;
