import {
  observable,
  action,
  computed,
  makeObservable,
  runInAction
} from 'mobx';
// 表單驗證.
import validator from 'validator';
import AuthService from 'src/services/authenticate';
import AuthenticateError from 'src/services/error/models/authenticate';

class ForgotPasswordViewModel {
  @observable email = '';
  @observable isAwait = false;
  @observable isShowResMessage = false;
  @observable resMessage = 'Res 錯誤';

  @observable onCheck = {
    email: false
  }

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  // -------------------------------------------------------------------
  // -------------------------------------------------------------------
  // -------------------------------------------------------------------

  // email 驗證.
  @computed
  get validEmail() {
    return validator.isEmail(this.email);
  }

  @computed
  get isDisabledSubmit() {
    return this.isAwait || !this.validEmail;
  }

  // -------------------------------------------------------------------
  // -------------------------------------------------------------------
  // -------------------------------------------------------------------

  @action
  setEmail = (event) => {
    this.email = event.target.value.trim();
    this.isShowResMessage = false;
  };


  // -------------------------------------------------------------------
  // -------------------------------------------------------------------
  // -------------------------------------------------------------------

  @action
  onEmailChange = (func, e) => {
    this.email = e.target.value;
    func(e);
  }

  @action
  setOnCheck = () => {
    this.onCheck.email = true;
  }

  // 寄驗證碼按鈕.
  @action
  handlerSubmit = async (event, tickFunc) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isDisabledSubmit) {
      this.isAwait = true;

      try {
        await AuthService.forgotPassword({ email: this.email });

        runInAction(() => {
          tickFunc();
          // 將 email 更新至 parent.
          this.props.setEmail(this.email);
          // 前往重置密碼.
          this.props.handlerToResetPassword();
          // 異步等待結束.
          this.isAwait = false;
        });
      } catch (error) {
        runInAction(() => {
          // 顯示 res 錯誤.
          AuthenticateError.forgotPassword(error);

          // 異步等待結束.
          this.isAwait = false;
        });
      }
    }
  };

  @action
  backToResetPage = () => {
    this.props.handlerToResetPassword();
  }

  // -------------------------------------------------------------------
  // -------------------------------------------------------------------
  // -------------------------------------------------------------------

  @action
  unMount = () => {
    this.reciprocalTimer = clearInterval(this.reciprocalTimer);
  };
}

export default ForgotPasswordViewModel;
