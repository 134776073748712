import API from 'src/apis';
import Home from 'src/models/response/Home';
import Auth from './auth';

export default class HomeService {
  static async getHome() {
    const [res, bookList] = await Promise.all([
      await API.home.getHome(Auth.getToken()),
      await API.home.getAllBookList(Auth.getToken())
    ]);

    return Home.fromRes(res.data, bookList.data.result);
  }

  static async getCategories() {
    const res = await API.home.getCategories();

    return res.data;
  }
}
