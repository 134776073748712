import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const CouponGifts = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="優惠券／禮物碼使用辦法" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>1.</div>
            sandshour 散時間不定期有行銷活動配合可使用優惠券於活動期間折抵課程費用，或是以禮物碼兌換課程和訂閱會員體驗。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>2.</div>
            尚未兌換為優惠券前，折扣代碼使用是以代碼序號為依據，不限使用會員身分，故請妥善保管折扣代碼。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>3.</div>
            優惠券／禮物碼使用方式等同折抵現金但不可兌換現金。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>4.</div>
            優惠券／禮物碼皆有使用效期，逾期則視為棄權，不得申請補發。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>5.</div>
            優惠券／禮物碼以每堂課程或是訂閱體驗為單位，請依活動說明選擇優惠券／禮物碼進行折抵或兌換。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>6.</div>
            每筆訂單限使用一張優惠券，優惠券一經結帳使用後即失效。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>7.</div>
            購買課程時一次只能用一張優惠券，如果折價金額超出方案金額，多餘的不予退還，優惠券折抵部分不再另開立發票，開立發票金額，以該訂單「消費時實際支付的金額」來計算。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>8.</div>
            訂閱型優惠券限於網站平台使用。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="優惠券使用流程" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊右上方個人頭像，打開
            <span className={useStyles.bolder}>
              「我的帳戶」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點選
            <span className={useStyles.bolder}>
              「優惠券」
            </span>
            （可查看目前可使用、已使用和已失效之優惠券）
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑷</div>
            輸入優惠代碼，即可領取專屬優惠券
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑸</div>
            在課程結帳頁面點選
            <span className={useStyles.bolder}>
              「選擇優惠券」
            </span>
            ，可選擇已領取且適用本課程之優惠券，或是直接將優惠券代碼輸入進行折抵
          </div>

          <br />
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊下方功能列
            <span className={useStyles.bolder}>
              「我的」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點選
            <span className={useStyles.bolder}>
              「優惠券」
            </span>
            （可查看目前可使用、已使用和已失效之優惠券）
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑷</div>
            輸入優惠代碼，即可領取專屬優惠券
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑸</div>
            在課程結帳頁面點選
            <span className={useStyles.bolder}>
              「選擇優惠券」
            </span>
            ，可選擇已領取且適用本課程之優惠券，或是直接將優惠券代碼輸入進行折抵
          </div>
        </div>
      </FAQCard>
      <FAQCard title="禮物碼使用流程" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            收到禮物碼，需自行至個人帳戶兌換課程或是訂閱會員體驗。
          </div>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊右上方個人頭像，打開
            <span className={useStyles.bolder}>
              「我的帳戶」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點選
            <span className={useStyles.bolder}>
              「禮物」
            </span>
            ，輸入禮物碼，即會自動兌換課程或是訂閱會員體驗
          </div>

          <br />
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊下方功能列
            <span className={useStyles.bolder}>
              「我的」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點選
            <span className={useStyles.bolder}>
              「禮物」
            </span>
            ，輸入禮物碼，即會自動兌換課程或是訂閱會員體驗
          </div>
        </div>
      </FAQCard>
    </>
  );

};

export default CouponGifts;
