import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'react-resize-detector';
import i18n from 'src/i18n';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const ContactUs = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  const { width, height, ref } = useResizeDetector();
  return (
    <div className={useStyles.contentContainer} ref={ref}>
      <div className={clsx(useStyles.content, props.isLanding && useStyles.contactContent)}>
        若在常見問題中無法找尋到可幫助您的問答，請來信至客服信箱：
        <div>
          <a href={`mailto:service@sandshour.com?subject=${i18n.t('profile_contact_subject')}`} className={useStyles.aLink}>
            service@sandshour.com
          </a>
        </div>
        主要回覆時間為週一至週五10:00-18:00（國定假日除外），我們將盡快提供您協助。
      </div>
    </div>
  );

};

export default ContactUs;
