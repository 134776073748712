import React, { useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import ArrowDown from 'src/assets/landing/arrowDown.svg';

import styles from './styles.module.scss';
import withFontStyles from './withFontStyles.module.scss';

const FAQCard = (props) => {
  const [isOpen, toggleOpen] = useState(false);
  const useStyles = props.isLanding ? withFontStyles : styles;

  return (
    <>
      <div
        className={clsx(useStyles.flex27, useStyles.flex27_layout, isOpen && useStyles.flex27Open)}
        onClick={() => toggleOpen(!isOpen)}
      >
        <div className={clsx(useStyles.flex28, useStyles.flex28_layout)}>
          <div className={clsx(useStyles.flex28_item)}>
            <div className={clsx(useStyles.box27, useStyles.box27_layout)} />
          </div>
          <div className={clsx(useStyles.flex28_spacer)} />
          <div className={clsx(useStyles.flex28_item1)}>
            <h2 className={clsx(useStyles.medium_title3, useStyles.medium_title3_layout)}>
              {props.title}
            </h2>
          </div>
          <div className={clsx(useStyles.flex28_spacer1)} />
          <div className={clsx(useStyles.flex28_item2)}>
            <div className={clsx(useStyles.icon6, isOpen && useStyles.rotate)} style={{ '--src': `url(${ArrowDown})` }} />
          </div>
        </div>
      </div>
      <AnimateHeight
        duration={500}
        height={isOpen ? 'auto' : 0}
      >
        <div className={clsx(useStyles.cover_block5, useStyles.cover_block5_layout, isOpen && useStyles.openContainer)}>
          {props.children}
        </div>
      </AnimateHeight>

    </>
  );
};

FAQCard.propsTypes = {
  children: PropTypes.element,
  title: PropTypes.string
};

FAQCard.defaultProps = {
  children: null,
  title: ''
};


export default observer(FAQCard);
