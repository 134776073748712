import dayjs from 'dayjs';

export default class LearnedHistory {
  static fromRes(data) {
    return new LearnedHistory(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    this.id = data.id;

    this.course = {
      id: data.course.id,
      name: data.course.name,
      type: data.course.type
    };

    this.chapter = {
      id: data.chapter.id,
      name: data.chapter.name
    };

    this.date = data.createdAt;
    this.dateFormat = dayjs(data.createdAt, 'second').format('YYYY/MM/DD');
    this.timeFormat = dayjs(data.createdAt, 'second').format('HH:mm');
  }
}
