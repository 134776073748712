import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const CourseLearning = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="開始學習課程" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            sandshour 散時間線上影音內容在網站和 App 都可同步學習，無限次數、除另有標示觀看期限之課程，均沒有觀看期限。
          </div>

          {/* NOTE: web */}
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            登入會員帳號後，點擊首頁右上角
            <span className={useStyles.bolder}>
              「我的課程」
            </span>
            ，進入
            <span className={useStyles.bolder}>
              「我的學習」
            </span>
            頁面，裡面包含已購買與已點擊閱聽的課程。若有已開始學習的課程，會於首頁出現
            <span className={useStyles.bolder}>
              「最近在學」
            </span>
            的課程列表。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            選擇欲學習的課程，進入課程頁面後點擊右側
            <span className={useStyles.bolder}>
              「開始學習」
            </span>
            ，或下方選單切換至
            <span className={useStyles.bolder}>
              「章節」
            </span>
            ，選擇想上課的章節即可開始學習。
          </div>
          {/* NOTE: app */}
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            登入會員帳號後，點擊下方功能列
            <span className={useStyles.bolder}>
              「學習」
            </span>
            ，裡面包含已購買與已點擊閱聽的課程。若有已開始學習的課程，會於 App 首頁出現
            <span className={useStyles.bolder}>
              「最近在學」
            </span>
            課程列表。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            選擇欲學習的課程，進入課程頁面後點選下方
            <span className={useStyles.bolder}>
              「開始學習」
            </span>
            ，系統會自動播放最近一次學習的章節，或你也可以將切換至
            <span className={useStyles.bolder}>
              「章節」
            </span>
            頁籤，點選想要自行學習的章節內容。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="收藏課程、單集" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            點選課程頁面或單集章節的
            <span className={useStyles.bolder}>
              「愛心圖示」
            </span>
            後，即可收藏，再次點選
            <span className={useStyles.bolder}>
              「愛心圖示」
            </span>
            即可取消收藏。
          </div>
          <div className={clsx(useStyles.bolder, useStyles.content)}>
            「我的收藏」位置
          </div>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊右上方
            <span className={useStyles.bolder}>
              「我的課程」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點擊切換至
            <span className={useStyles.bolder}>
              「我的收藏」
            </span>
            ，即可看到已收藏的課程和單集內容
          </div>

          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊下方功能列
            <span className={useStyles.bolder}>
              「收藏」
            </span>
            ，即可看到已收藏的課程和單集內容
          </div>
        </div>
      </FAQCard>
      <FAQCard title="課程評分及評論" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            登入會員帳號後，進入欲評分及評論課程頁面，於
            <span className={useStyles.bolder}>
              「簡介」
            </span>
            頁籤下方點選
            <span className={useStyles.bolder}>
              「點一下來評分」
            </span>
            的星星位置即可為課程留下您的評分及評論。
          </div>
          <div className={useStyles.content}>
            已購買過課程，或為訂閱會員可針對課程撰寫學習評價，並可隨時重新編輯。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="單集留言" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            登入會員帳號後，進入欲留言課程頁面，於
            <span className={useStyles.bolder}>
              「留言」
            </span>
            頁籤下方點選
            <span className={useStyles.bolder}>
              「請輸入留言」
            </span>
            ，選擇對應留言章節後，即可新增您的留言。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="文稿字級調整" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            部分課程內容提供文稿閱讀，可在文稿功能列中點選
            <span className={useStyles.bolder}>
              「雙T字」
            </span>
            圖示，依閱讀習慣調整適宜的字級大小，調整後系統會自動套用到其他文稿頁。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="播放速度" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            請點選播放器內
            <span className={useStyles.bolder}>
              「播放速度」
            </span>
            按鈕，即可調整喜好速度，選擇後系統會自動套用在所有影像、音頻內容。
          </div>
          <div className={useStyles.content}>
            ⚠︎ 網頁版、App 會分別記錄播放速度設定，不會同步更改。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="PDF瀏覽與課程下載" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  PDF 瀏覽
          </div>
          <div className={useStyles.content}>
            sandshour 散時間平台支援 PDF 閱讀模式，可開啟課程章節內PDF檔案，供線上學習檢視。 課程內提供PDF檔案僅網頁版支援下載，供離線時持續學習，App端則僅可線上檢視。
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⚠︎</div>
            課程內提供之PDF檔案，仍屬授課內容，受版權保護，請勿公開展示、轉傳或做其他商業用途。
          </div>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  課程下載
          </div>
          <div className={useStyles.content}>
            購買課程後，若於 App 內瀏覽，另提供下載功能，可供離線學習已下載之課程。
          </div>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            下載方式
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            點擊進入想要下載的課程
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            切換到章節頁籤
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點選
            <span className={useStyles.bolder}>
              「下載」
            </span>
            功能，可全選所有課程章節，或選擇想要下載的個別章節內容，再按一次
            <span className={useStyles.bolder}>
              「下載」
            </span>
            ，即會開始執行章節下載
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑷</div>
            於離線狀態時，App 將引導至
            <span className={useStyles.bolder}>
              「下載頁面」
            </span>
            ，即可選取已下載之章節課程繼續學習
          </div>
        </div>
      </FAQCard>
      <FAQCard title="適用裝置" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  網站（適用電腦、平板電腦裝置之瀏覽器）
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            執行 58 或以上版本的 Google Chrome
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            執行 16 或以上版本的 Microsoft Edge
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            執行 14.1 或以上版本的 Safari
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            執行 54 或以上版本的 Firefox
          </div>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  App（適用手機裝置）
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            執行 iOS11 或以上版本的 iPhone 手機
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            執行 Android 5.0 或以上版本的 Android 手機
          </div>
        </div>
      </FAQCard>
    </>
  );

};

export default CourseLearning;
