import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import commaIcon from 'src/assets/homepage/commaIcon.png';
import defaultAvatar from 'src/assets/header/defaultAvatar.png';

import styles from './styles.module.scss';

@observer
class CelebrityCard extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      // <div className={styles.cardContainer}>
      //   <div className={styles.upperSec}>
      //     <div className={styles.leftImg} />
      //     <div className={styles.content}>
      //       {viewModel.content}
      //     </div>
      //   </div>
      //   <div className={styles.downerSec}>
      //     <img className={styles.img} src={viewModel.celebrityImg} alt={viewModel.celebrityName} />
      //     <div className={styles.infoContainer}>
      //       <div className={styles.name}>{viewModel.celebrityName}</div>
      //       <div className={styles.description}>{viewModel.celebrityDescription}</div>
      //     </div>
      //   </div>
      // </div>
      <div className={`comment-n ${clsx(styles.cardContainer, styles.cardContainer_layout)}`}>
        <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
          <div className={clsx(styles.contentFlex_item)}>
            <div className={clsx(styles.commaIcon, styles.commaIcon_layout)}>
              <img src={commaIcon} alt="“" className={clsx(styles.hero_title, styles.hero_title_layout)} />
            </div>
          </div>
          <div className={clsx(styles.contentFlex_spacer)} />
          <div className={clsx(styles.contentFlex_item)}>
            <h5 className={clsx(styles.content, styles.content_layout)}>
              {viewModel.content}
            </h5>
          </div>
          <div className={clsx(styles.contentFlex_spacer1)} />
          <div className={clsx(styles.contentFlex_item)}>
            <div className={clsx(styles.celebrityContainer, styles.celebrityContainer_layout)}>
              <div className={clsx(styles.celebrityContainer_spacer)} />
              <div className={clsx(styles.celebrityContainer_item)}>
                <div className={clsx(styles.infoContainer, styles.infoContainer_layout)}>
                  <h5 className={clsx(styles.celebrityName, styles.celebrityName_layout)}>
                    {viewModel.celebrityName}
                  </h5>
                  <h5 className={clsx(styles.celebrityDescription, styles.celebrityDescription_layout)}>
                    {viewModel.celebrityDescription}
                  </h5>
                </div>
              </div>
              <div className={clsx(styles.celebrityContainer_spacer1)} />
              <div className={clsx(styles.celebrityContainer_item1)}>
                <div style={{ '--src': `url(${viewModel.celebrityImg ?? defaultAvatar})` }} className={clsx(styles.avatar, styles.avatar_layout)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CelebrityCard.propTypes = {
  viewModel: PropTypes.object
};

CelebrityCard.defaultProps = {
  viewModel: {}
};

export default CelebrityCard;
