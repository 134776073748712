import { action, makeObservable, observable, runInAction } from 'mobx';

import AuthService from 'src/services/authenticate';

class ConfigStore {
  @observable isYearlyOn = false;

  constructor() {
    makeObservable(this);

    this.checkYearSubscription();
  }

  @action checkYearSubscription = async () => {
    try {
      const { feature } = await AuthService.checkYearSubscription();

      runInAction(() => {
        this.isYearlyOn = feature;
        // this.isYearlyOn = true;
      });

    } catch (error) {
      // > 無視
    }
  }
}

const configStore = new ConfigStore();

export default configStore;
