import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';
import CommonError from './common';

export default class ChapterError {
  static getChapters(error) {
    switch (error.response?.status) {
      case 400:
        // ErrorToast(i18n.t('api_course_chapter_list_400'));
        break;
      case 403:
        ErrorToast(i18n.t('api_course_chapter_list_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_course_chapter_list_404'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getAvailableChapters(error) {
    switch (error.response?.status) {
      case 400:
        // ErrorToast(i18n.t('api_course_chapter_list_400'));
        break;
      case 403:
        ErrorToast(i18n.t('api_course_chapter_list_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_course_chapter_list_404'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getChapterDetail(error) {
    switch (error.response?.status) {
      case 403:
        ErrorToast(i18n.t('api_chapter_detail_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_chapter_detail_404'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getPrevChapter(error) {
    switch (error.response?.status) {
      case 403:
        ErrorToast(i18n.t('api_chapter_prev_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_chapter_prev_404'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getNextChapter(error) {
    switch (error.response?.status) {
      case 403:
        ErrorToast(i18n.t('api_chapter_next_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_chapter_next_404'));
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static changeIsFavorite(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_chapter_favorite_4xx'));
        break;
    }
  }

  static getChapterComments(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_course_comment_4xx'));
        break;
    }
  }

  static getSingleChapterComments(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_course_comment_4xx'));
        break;
    }
  }

  static getMyComments(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        ErrorToast(i18n.t('api_course_my_comment_4xx'));
        break;
    }
  }

  static getMyCommentsByChapter(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        break;
      default:
        ErrorToast(i18n.t('api_course_my_comment_4xx'));
        break;
    }
  }

  static postNewComment(error) {
    switch (error.response?.status) {
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static editComment(error) {
    switch (error.response?.status) {
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getChapterPermission(error) {
    switch (error.response?.status) {
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }
}
