import React from 'react';
import { observer } from 'mobx-react';
import ScrollPlayerViewModel from './viewModel';

const scrollPlayer = (Component) => {
  @observer
  class ScrollPlayerHOC extends React.Component {
    static displayName = 'ScrollPlayerHOC';

    constructor(props) {
      super(props);
      this.vm = new ScrollPlayerViewModel();
    }

    componentDidMount() {
      this.vm.didMount();
    }

    componentWillUnmount() {
      this.vm.unMount();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return ScrollPlayerHOC;
};

export default scrollPlayer;
