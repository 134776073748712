import Chapter from './Chapter';

export default class ChapterList {
  constructor(data) {
    const {
      list,
      course,
      anchor
    } = data;

    const {
      id,
      count,
      duration,
      saleType,
      isOwned,
      lastIs
    } = course;

    this.list = list.map((el) => new Chapter(el));
    this.courseInfo = {
      id,
      duration: Math.ceil(duration),
      saleType,
      isOwned,
      lastIs,
      count
    };

    this.anchor = anchor;
  }

  static fromRes(data) {
    return new ChapterList(data);
  }
}
