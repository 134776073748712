import { request, getHost } from './utils';

export const checkDonateCode = (lovecode) => {
  const options = {
    method: 'get',
    url: 'https://api.cetustek.com.tw/LoveCodeBar.php',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    params: {
      lovecode,
      rentid: 53118823,
      authkey: 'Cetus9Phone1API7'
    }
  };
  return request(options);
};
