import { makeObservable, observable, action, computed } from 'mobx';

import CelebirtyOne from 'src/assets/homepage/celebirties/celebirty_01.jpg';
import CelebirtyTwo from 'src/assets/homepage/celebirties/celebirty_02.jpg';
import CelebirtyThree from 'src/assets/homepage/celebirties/celebirty_03.jpg';
import CelebirtyFour from 'src/assets/homepage/celebirties/celebirty_04.jpg';
import CelebirtyFive from 'src/assets/homepage/celebirties/celebirty_05.jpg';
import CelebirtySix from 'src/assets/homepage/celebirties/celebirty_06.jpg';
import CelebirtySeven from 'src/assets/homepage/celebirties/celebirty_07.jpg';
import CelebirtyEight from 'src/assets/homepage/celebirties/celebirty_08.jpg';
import CelebirtyNine from 'src/assets/homepage/celebirties/celebirty_09.jpg';

const imgArr = [CelebirtyOne, CelebirtyTwo, CelebirtyThree, CelebirtyFour, CelebirtyFive, CelebirtySix, CelebirtySeven, CelebirtyEight, CelebirtyNine];

export default class CelebrityCardViewModel {
  @observable id;
  @observable celebrityName;
  @observable celebrityDescription;
  @observable celebrityImg;

  @observable content;

  constructor(data) {
    makeObservable(this);

    this.deserialize(data);
  }

  @action
  deserialize = (data) => {
    this.id = data.id;
    this.celebrityName = data.name;
    this.celebrityDescription = data.description;
    this.celebrityImg = imgArr[data.id - 1];
    this.content = data.content;
  }
}
