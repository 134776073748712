import API from 'src/apis';
import Course from 'src/models/response/Course';
import CourseDetail from 'src/models/response/CourseDetail';
import CourseRating from 'src/models/response/CourseRating';
import CourseDetailForPurchase from 'src/models/response/CourseDetailForPurchase';
import Rating from 'src/models/response/Rating';
import Auth from './auth';

export default class CourseService {
  static async getCoursesByType(type, category, order, anchor) {
    const res = await API.course.getCoursesByType(Auth.getToken(), type, category, order, anchor);

    return Course.fromRes(res.data);
  }

  static async getCourseDetail(cid) {
    const res = await API.course.getCourseDetail(Auth.getToken(), cid);

    return CourseDetail.fromRes(res.data.course);
  }

  static async getCourseDetailForPurchase(cid) {
    const res = await API.course.getCourseDetail(Auth.getToken(), cid);

    return CourseDetailForPurchase.fromRes(res.data.course);
  }

  static async getCourseRating(cid, anchor, order) {
    const res = await API.course.getCourseRating(Auth.getToken(), cid, anchor, order);

    return CourseRating.fromRes(res.data);
  }

  static async getMyRating(cid) {
    const res = await API.course.getMyRating(Auth.getToken(), cid);

    return res;
  }

  static async postRating(cid, data) {
    const res = await API.course.postRating(Auth.getToken(), cid, data);

    return Rating.fromRes(res.data);
  }

  static async putRating(cid, rid, data) {
    const res = await API.course.putRating(Auth.getToken(), cid, rid, data);

    return res;
  }

  static async changeIsFavorite(uid, cid) {
    const res = await API.course.changeIsFavorite(Auth.getToken(), uid, cid);

    return res;
  }

  static async addToAlreadyLearned(uid, cid) {
    const res = await API.course.addToAlreadyLearned(Auth.getToken(), uid, cid);

    return res;
  }

  static async getCoursesByBookListId() {
    const res = await API.course.getCoursesByType(Auth.getToken(), 'Video', null, 'Date', null);

    return Course.fromRes(res.data);
  }
}
