
import validator from 'validator';

export default class SelfValidator {
  static email(value) {
    return validator.isEmail(value);
  }

  static password(value) {
    // 是否有包含英文.
    const isAlphaRE = new RegExp(/[a-zA-Z]+/gi);
    // 是否有包含數字.
    const isNumberRE = new RegExp(/[0-9]+/g);
    // 包含英文.
    const flag1 = !!value.match(isAlphaRE);
    // 包含數字.
    const flag2 = !!value.match(isNumberRE);
    // 是不是只有英文或數字.
    const flag3 = validator.isAlphanumeric(value);
    // 八位數.
    const flag4 = validator.isLength(value, { min: 8 });

    return flag1 && flag2 && flag3 && flag4;
  }
}
