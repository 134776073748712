import API from 'src/apis';
import PlayerCourse from 'src/models/response/PlayerCourse';
import PlayerChapter from 'src/models/response/PlayerChapter';
import PlayerUrl from 'src/models/response/PlayerUrl';
import Auth from './auth';

export default class PlayerService {
  // 取得單集詳情.
  static async getChapter({ id }) {
    const res = await API.player.getChapter({ id, token: Auth.getToken() });

    return {
      course: PlayerCourse.fromRes(res.data.course),
      chapter: PlayerChapter.fromRes(res.data.chapter)
    };
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取上一集.
  static async getChapterPrev({ courseId, chapterId }) {
    const res = await API.player.getChapter({
      courseId,
      chapterId,
      token: Auth.getToken()
    });

    return {
      course: PlayerCourse.fromRes(res.data.course),
      chapter: PlayerChapter.fromRes(res.data.chapter)
    };
  }

  // 讀取下一集.
  static async getChapterNext({ courseId, chapterId }) {
    const res = await API.player.getChapter({
      courseId,
      chapterId,
      token: Auth.getToken()
    });

    return {
      course: PlayerCourse.fromRes(res.data.course),
      chapter: PlayerChapter.fromRes(res.data.chapter)
    };
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 取得課程可播放清單.
  static async getChapterList({ id, limit, include, anchor, available }) {
    const res = await API.player.getChapterList({
      id,
      limit,
      include,
      anchor,
      available,
      token: Auth.getToken()
    });

    return {
      course: PlayerCourse.fromRes(res.data.course),
      list: res.data.list.map((item) => PlayerChapter.fromRes(item)),
      anchor: res.data.anchor
    };
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 取得單集 url.
  static async getPlayerUrl({ id }) {
    const res = await API.player.getPlayerUrl({ id, token: Auth.getToken() });

    return PlayerUrl.fromRes(res.data);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 最後學習的單集與秒數.
  static async postLearnedLast({ id, chapterId, lastAt }) {
    const res = await API.player.postLearnedLast({
      id,
      chapterId,
      lastAt,
      token: Auth.getToken()
    });

    return res;
  }

  // 已經看完的單集.
  static async postLearnedHistory({ id, chapterId }) {
    const res = await API.player.postLearnedHistory({
      id,
      chapterId,
      token: Auth.getToken()
    });

    return res;
  }

  // 加入到已學習的課程.
  static async postLearnedCourse({ id, courseId }) {
    const res = await API.player.postLearnedCourse({
      id,
      courseId,
      token: Auth.getToken()
    });

    return res;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
}
