import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import AuthService from 'src/services/authenticate';
import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';
import AlertViewModel from '../../../Alert/viewModel';

class SignInViewModel extends AlertViewModel {

  @observable page = 'selectSignup';
  @observable source = 'selectSignup';
  @observable email = 'ian.chuang@miraclemobile.com.tw';
  @observable resetEmail = ''
  // 保留給註冊使用.
  @observable saveProfile = null;
  // 頁面名稱.
  pages = {
    // 選擇登入
    selectLogin: 'selectLogin',
    // 選擇註冊
    selectSignup: 'selectSignup',
    // 登入.
    login: 'login',
    // 忘記密碼, 輸入 email.
    forgotPassword: 'forgotPassword',
    // 忘記密碼, 輸入兩次密碼.
    resetPassword: 'resetPassword',
    // 註冊.
    signup: 'signup',
    // 註冊驗證 email.
    emailAuth: 'emailAuth',
    // 設置名稱.
    inputName: 'inputName',
    // 設置興趣.
    inputInterests: 'inputInterests'
  };

  @observable reciprocal = 0;
  @observable reciprocalTimer = null;

  constructor() {
    super();
    makeObservable(this);
  }

  // ----------------------------------------------------------
  // ----------------------------------------------------------
  // ----------------------------------------------------------

  @action
  handleOpen = () => {
    this.handlerOpenAlert();
    this.toggleOverflow();

  }

  @action
  handleClose = () => {
    this.handlerCloseAlert();
    this.toggleOverflow();
  }

  toggleOverflow() {
    const doc = document.documentElement;
    const overflow = this.isOpenAlert ? 'hidden' : 'auto';

    doc.style.overflow = overflow;
  }

  // 跳轉頁面.
  @action
  setPage = (value) => {
    this.page = value;
  };

  // 前往 email 驗證頁的來源.
  @action
  setSource = (value) => {
    this.source = value;
  };

  // 保存共用的 email.
  @action
  setEmail = (value) => {
    this.email = value.trim();
  };

  @action
  onEmailChange = (e) => {
    this.resetEmail = e.target.value;
  }

  // 保存共用的 profile.
  @action
  setSaveProfile = (value) => {
    this.saveProfile = value;
  };

  // 倒數重置.
  @action
  resetReciprocal = () => {
    this.reciprocal = 300;
    this.reciprocalTimer = setInterval(this.handlerReciprocal, 1000);
  };


  // 倒數排程.
  @action
  handlerReciprocal = () => {
    this.reciprocal -= 1;

    if (this.reciprocal === 0) {
      this.reciprocalTimer = clearInterval(this.reciprocalTimer);
      this.reciprocalTimer = null;
    }
  };


  // ----------------------------------------------------------
  // ----------------------------------------------------------
  // ----------------------------------------------------------

  handleSelectChange = () => {
    if (this.page === 'selectLogin') {
      this.handlerToSignupSelect();
      return;
    }
    this.handlerToLoginSelect();
  }

  // 前往登入方式選擇頁面
  handlerToLoginSelect = () => {
    this.setPage(this.pages.selectLogin);
  }

  // 前往註冊選擇頁面
  handlerToSignupSelect = () => {
    this.setPage(this.pages.selectSignup);
  }

  // 前往登入.
  handlerToLogin = () => {
    this.setPage(this.pages.login);
  };

  // 前往忘記密碼.
  handlerToForgotPassword = () => {
    this.setPage(this.pages.forgotPassword);
  };

  // 前往重設密碼.
  handlerToResetPassword = () => {
    this.setPage(this.pages.resetPassword);
  };

  // 前往註冊
  handlerToSignup = () => {
    this.setPage(this.pages.signup);
  };

  // 前往驗證 email.
  handlerToEmailAuth = () => {
    this.setPage(this.pages.emailAuth);
  };

  // 前往設置名稱.
  handlerToInputName = () => {
    this.setPage(this.pages.inputName);
  };

  // 前往設置興趣.
  handlerToInputInterests = () => {
    this.setPage(this.pages.inputInterests);
  };

  handleBack = () => {
    switch (this.page) {
      case 'login':
        this.handlerToLoginSelect();
        break;
      case 'signup':
        this.handlerToSignupSelect();
        break;
      case 'forgotPassword':
        this.handlerToLogin();
        break;
      case 'resetPassword':
        this.handlerToForgotPassword();
        break;
      case 'emailAuth':
        this.handlerToSignup();
        break;
      case 'inputName':
        this.handlerToLogin();
        break;
      case 'inputInterests':
        this.handlerToInputName();
        break;
      default:
        this.handlerCloseAlert();
    }
  }

  // 完成登入.
  handlerCompleteLogin = () => {
    // 關閉 alert.
    super.handlerCloseAlert();

    runInAction(() => {
      // 更新 context profile.
      // this.context.actions.updateProfile(this.saveProfile);
      // 跳轉回登入頁.
      // this.page = this.pages.login;
      // 刪除 save profile.
      // this.saveProfile = null;
      // 重新整理.
      window.location.reload();
    });
  };

  // 基本上每個頁面的下一步, 都是這個函式, 需要判斷用戶資料再決定前進路線.
  handlerNextStep = () => {
    // 尚未驗證 email.
    if (this.saveProfile.status === 'Pending') {
      this.handlerToEmailAuth();
    } else if (
      // 尚未設置暱稱.
      this.saveProfile.nickname === null
    ) {
      this.handlerToInputName();
    } else if (
      // 尚未勾選興趣.
      this.saveProfile.hasNewspaper === null
    ) {
      this.handlerToInputInterests();
    } else {
      // 登入.
      this.handlerCompleteLogin();
    }
  };

  // ----------------------------------------------------------
  // ----------------------------------------------------------
  // ----------------------------------------------------------

  didMount = ({ context }) => {
    this.context = context;
  };

  // ----------------------------------------------------------
  // ----------------------------------------------------------
  // ----------------------------------------------------------

  // alert 打叉關閉.
  handlerCloseAlert = async () => {
    if (this.saveProfile) {
      try {
        await AuthService.logout();

        runInAction(() => {
          // 關閉 alert.
          super.handlerCloseAlert();
          // 重新整理.
          window.location.reload();
        });
      } catch (error) {
        runInAction(() => {
          ErrorToast(`${i18n.t('api_common_general')}: ${error.response?.status}`);
        });
      }
    } else {
      // 關閉 alert.
      super.handlerCloseAlert();
    }

    runInAction(() => {
      // 回到登入頁.
      this.page = this.pages.selectSignup;
    });
  };

  // alert 背景關閉.
  handlerBackgroundCloseAlert = async () => {
    if (this.saveProfile) {
      try {
        await AuthService.logout();

        runInAction(() => {
          // 關閉 alert.
          super.handlerCloseAlert();
          // 重新整理.
          window.location.reload();
        });
      } catch (error) {
        runInAction(() => {
          ErrorToast(`${i18n.t('api_common_general')}: ${error.response?.status}`);
        });
      }
    } else {
      // 關閉 alert.
      super.handlerCloseAlert();
    }

    runInAction(() => {
      // 回到登入頁.

      // Test: to selectLogin
      this.page = this.pages.selectSignup;
    });
  };
}

const signInViewModel = new SignInViewModel();

export default signInViewModel;
