import dayjs from 'dayjs';

export default class Purchase {
  static fromRes(data) {
    return new Purchase(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    const dayjsInstance = dayjs(data.date);

    this.id = data.transactionId;

    this.course = {
      id: data.item.id,
      type: data.item.type,
      name: data.item.name
    };

    if (data.couponId) {
      this.coupon = {
        id: data.couponId.id,
        discount: data.couponId.discount
      };
    } else {
      this.coupon = null;
    }

    this.pid = data.price;

    this.date = data.date;
    this.dayjsInstance = dayjsInstance;
    this.dateFormat = dayjsInstance.format('YYYY/MM/DD HH:mm');
  }
}
