import {
  observable,
  action,
  computed,
  makeObservable,
  runInAction
} from 'mobx';
import AuthService from 'src/services/authenticate';
import AuthenticateError from 'src/services/error/models/authenticate';
import SelfValidator from 'src/components/Header/component/SignIn/selfValidator';

class ResetPasswordViewModel {
  @observable code = '';
  @observable password = '';
  @observable passwordAgain = '';
  @observable isAwait = false;
  @observable isShowResMessage = false;
  @observable resMessage = '';

  @observable onCheck = {
    code: false,
    password: false,
    passwordCheck: false
  };

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  // code 驗證.
  @computed
  get validCode() {
    return !!this.code;
  }

  // password 驗證.
  @computed
  get validPassword() {
    return SelfValidator.password(this.password);
  }

  // password again 驗證.
  @computed
  get validPasswordAgain() {
    return SelfValidator.password(this.passwordAgain);
  }

  @computed
  get isDisabledSubmit() {
    const flag = this.password === this.passwordAgain;

    return (
      this.isAwait || !this.validCode
      || !this.validPassword
      || !this.validPasswordAgain
      || !flag
    );
  }

  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------

  @computed
  get isShowCodeMessage() {
    // 有輸入 code, 驗証沒通過.
    return !this.code && !this.validCode;
  }

  @computed
  get isShowPasswordMessage() {
    if (!this.validCode) {
      return false;
    }

    if (this.password && !this.validPassword) {
      return true;
    }

    if (this.passwordAgain && !this.validPasswordAgain) {
      return true;
    }

    return false;
  }

  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------

  @action
  setCode = (event) => {
    this.code = event.target.value.trim();
    this.isShowResMessage = false;
  };

  @action
  setPassword = (event) => {
    this.password = event.target.value.trim();
    this.isShowResMessage = false;
  };

  @action
  setPasswordAgain = (event) => {
    this.passwordAgain = event.target.value.trim();
    this.isShowResMessage = false;
  };

  @action
  setOnCheck = (type) => {
    switch (type) {
      case 'code':
        this.onCheck.code = true;
        break;
      case 'password':
        this.onCheck.password = true;
        break;
      case 'passwordCheck':
        this.onCheck.passwordCheck = true;
        break;
      default:
        break;
    }
  }

  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------

  @action
  handlerSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isDisabledSubmit) {
      this.isAwait = true;

      try {
        // 更新密碼.
        await AuthService.resetPassword({
          email: this.props.email,
          password: this.password,
          code: this.code
        });

        // 使用 email 與新的 password 登入.
        const loginRes = await AuthService.login({
          email: this.props.email,
          password: this.password
        });

        runInAction(() => {
          // 設定來源.
          this.props.setSource(this.props.pages.login);
          // 保存 profile.
          this.props.setSaveProfile(loginRes);
          // 下一步.
          this.props.handlerNextStep();
          // 異步結束.
          this.isAwait = false;
        });
      } catch (error) {
        runInAction(() => {
          // 顯示 res 錯誤的 message;
          this.isShowResMessage = true;
          AuthenticateError.resetPassword(error);
          // 異步結束.
          this.isAwait = false;
        });
      }
    }
  };
}

export default ResetPasswordViewModel;
