import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import i18n from 'src/i18n';

import withProfile from 'src/components/withProfile';

import VideoIcon from 'src/assets/courseCard/videoIcon.svg';
import AudiobookIcon from 'src/assets/courseCard/audiobookIcon.svg';
import AudioIcon from 'src/assets/courseCard/audioIcon.svg';

import viewIcon from 'src/assets/courseCard/viewIcon.svg';

import favIcon from 'src/assets/courseCard/favIcon.png';
import unfavIcon from 'src/assets/courseCard/unfavIcon.svg';

import rankTag from 'src/assets/courseCard/rankTag.png';
import disk from 'src/assets/courseCard/disk.png';

// import styles from './styles.module.css';
import styles from './styles.module.scss';

@observer
class ExposeCard extends React.Component {
  constructor(props) {
    super(props);
  }

  _renderTypeIcon = (type) => {
    switch (type) {
      case 'Audio':
        return AudioIcon;
      case 'Video':
        return VideoIcon;
      case 'Audiobook':
        return AudiobookIcon;
      default:
        return null;
    }
  }

  render() {
    const { viewModel, profile, syncFavorite, section, page, breadcrumb } = this.props;
    return (
      <div
        className={`courseCard ${clsx(
          styles.courseCard,
          styles.courseCard_layout
        )} block`}
      >
        <Link
          to={`/course/${viewModel.id}/info${breadcrumb
            ? `?module=${breadcrumb[0].id}&list=${breadcrumb[1].id}`
            : ''}`}
          state={{ breadcrumb }}
          className={styles.cardLink}
        >
          <div className={clsx(styles.courseCardAudiobook_item)}>
            <div className={clsx(styles.upperContainer, styles.upperContainer_layout)}>
              <div className={clsx(styles.imageContainer, styles.imageContainer_layout)}>
                <div
                  style={{ '--src': `url(${viewModel.isFavorite ? favIcon : unfavIcon})` }}
                  onClick={(e) => viewModel.changeIsFavorite(e, profile?.id, syncFavorite, section)}
                  className={`${clsx(styles.typeIcon, styles.typeIcon_layout)} like`}
                />
                {
                  viewModel.type === 'Video' && (
                    <div className={clsx(styles.videoThumbnailContainer, styles.videoThumbnailContainer_layout)}>
                      <img
                        src={viewModel.thumbnail}
                        alt={viewModel.title}
                        className={`${clsx(
                          styles.videoThumbnail,
                          styles.videoThumbnail_layout
                        )} hover-zoom`}
                      />
                    </div>
                  )
                }

                {
                  viewModel.type === 'Audiobook' && (
                    <div className={clsx(styles.audiobookThumbnailContainer, styles.audiobookThumbnailContainer_layout)}>
                      <img
                        src={viewModel.thumbnail}
                        alt={viewModel.title}
                        className={`${clsx(
                          styles.audiobookThumbnail,
                          styles.audiobookThumbnail_layout
                        )} hover-zoom`}
                      />
                    </div>
                  )
                }

                {
                  viewModel.type === 'Audio' && (
                    <>
                      <div
                        style={{ '--src': `url(${disk})` }}
                        className={`${clsx(styles.image, styles.image_layout)} hover-zoom`}
                      />

                      <div className={clsx(styles.audioThumbnailContainer, styles.audioThumbnailContainer_layout)}>
                        <img
                          src={viewModel.thumbnail}
                          alt={viewModel.title}
                          className={`${clsx(styles.audioThumbnail, styles.audioThumbnail_layout)} hover-zoom`}
                        />
                      </div>
                    </>
                  )
                }


                <div className={styles.tagsContainer}>
                  {
                    page === 'home'
                      ? (
                        <>
                          {/* p2 */}
                          {viewModel.isLatest && (
                            <div className={clsx(styles.block9, styles.block9_layout)}>
                              <div className={clsx(styles.text_body, styles.text_body_layout)}>{i18n.t('tag_new')}</div>
                            </div>
                          )}
                          {/* p3 */}
                          {(viewModel.isPurchaseOnly && !(viewModel.isDiscount && viewModel.isLatest)) && (
                            <div className={clsx(styles.block9, styles.block9_layout)}>
                              <div className={clsx(styles.text_body, styles.text_body_layout)}>{i18n.t('tag_purchased_only')}</div>
                            </div>
                          )}
                          {/* p1 */}
                          {viewModel.isDiscount && (
                            <div className={clsx(styles.discountCard)}>
                              <div className={clsx(styles.discountText)}>{i18n.t('tag_is_discount')}</div>
                            </div>
                          )}
                        </>
                      )
                      : (
                        <>
                          {
                            section === 'learned'
                              ? (
                                <>
                                  {(!viewModel.isRating && (
                                    viewModel.isOwned || (profile.group === 'Subscriber' && !viewModel.isPurchaseOnly)
                                  ))
                                    && (
                                      <div className={clsx(styles.block10, styles.block10_layout)}>
                                        <div className={clsx(styles.text_body, styles.text_body_layout)}>{i18n.t('tag_not_yet_rate')}</div>
                                      </div>
                                    )}
                                </>
                              )
                              : (
                                <>
                                  {!(viewModel.isOwned || (profile.group === 'Subscriber' && !viewModel.isPurchaseOnly))
                                    && (
                                      <div className={clsx(styles.block9, styles.block9_layout, styles.notYetPurchaseContainer)}>
                                        <div className={clsx(styles.text_body, styles.text_body_layout, styles.notYetPurchase)}>{i18n.t('tag_not_yet_purchase')}</div>
                                      </div>
                                    )}
                                  {/* p2 */}
                                  {viewModel.isLatest && (
                                    <div className={clsx(styles.block9, styles.block9_layout)}>
                                      <div className={clsx(styles.text_body, styles.text_body_layout)}>{i18n.t('tag_new')}</div>
                                    </div>
                                  )}
                                  {/* p3 */}
                                  {(viewModel.isPurchaseOnly && !(viewModel.isDiscount && viewModel.isLatest)) && (
                                    <div className={clsx(styles.block9, styles.block9_layout)}>
                                      <div className={clsx(styles.text_body, styles.text_body_layout)}>{i18n.t('tag_purchased_only')}</div>
                                    </div>
                                  )}
                                  {/* p1 */}
                                  {viewModel.isDiscount && (
                                    <div className={clsx(styles.discountCard)}>
                                      <div className={clsx(styles.discountText)}>{i18n.t('tag_is_discount')}</div>
                                    </div>
                                  )}
                                </>
                              )
                          }
                        </>
                      )
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={clsx(styles.bottomContainer, styles.bottomContainer_layout)}>
            <div className={clsx(styles.titleOuter, styles.titleOuter_layout)}>
              <div className={clsx(styles.titleContainer, styles.titleContainer_layout)}>
                <div
                  style={{ '--src': `url(${this._renderTypeIcon(viewModel.type)})` }}
                  className={clsx(styles.image1, styles.image1_layout)}
                />
                <h3 className={clsx(styles.title_box, styles.title_box_layout)}>
                  <pre className={clsx(styles.title)}>
                    {viewModel.title}
                  </pre>
                </h3>
              </div>
            </div>

            <div className={clsx(styles.otherInfo, styles.otherInfo_layout)}>
              <h5 className={clsx(styles.authorName, styles.authorName_layout)}>
                {viewModel.lector}
              </h5>
              {
                section === 'learned'
                  ? (
                    <div className={clsx(styles.otherInfo_item)}>
                      <div
                        className={clsx(
                          styles.popularityContainer,
                          styles.popularityContainer_layout
                        )}
                      >
                        <h5 className={clsx(styles.popularity, styles.popularity_layout)}>
                          {viewModel.learnedProgressText}
                        </h5>
                      </div>
                    </div>
                  )
                  : (
                    <div className={clsx(styles.otherInfo_item)}>
                      <div
                        className={clsx(
                          styles.popularityContainer,
                          styles.popularityContainer_layout
                        )}
                      >
                        <div className={clsx(styles.popularityContainer_item)}>
                          <div
                            style={{ '--src': `url(${viewIcon})` }}
                            className={clsx(styles.viewIcon, styles.viewIcon_layout)}
                          />
                        </div>
                        <div className={clsx(styles.popularityContainer_spacer)} />
                        <h5 className={clsx(styles.popularity, styles.popularity_layout)}>
                          {viewModel.popularity}
                        </h5>
                      </div>
                    </div>
                  )
              }

            </div>
          </div>
        </Link>
      </div>
    );
  }
}

ExposeCard.propTypes = {
  viewModel: PropTypes.object,
  profile: PropTypes.object,
  syncFavorite: PropTypes.func,
  section: PropTypes.string,
  page: PropTypes.string,
  breadcrumb: PropTypes.array
};

ExposeCard.defaultProps = {
  viewModel: {},
  profile: {},
  syncFavorite: () => { },
  section: '',
  page: 'home',
  breadcrumb: null
};

export default withProfile(ExposeCard, false);
