import React from 'react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import signInViewModel from 'src/components/Header/component/SignIn/viewModel';
import CourseService from 'src/services/course';
import CourseError from 'src/services/error/models/course';
import { SuccessToast, ErrorToast } from 'src/libs/toast';
import i18n from 'src/i18n';

export default class LearningCardViewModel {
  @observable id;
  @observable thumbnail;
  @observable title;
  @observable lector;
  @observable type;
  @observable ranking;
  @observable isRating;
  @observable learningProgress;
  @observable isFavorite;
  @observable isOwned;
  @observable isPurchaseOnly;

  @observable isProcessing = false;

  constructor(data) {
    makeObservable(this);

    this.deserialize(data);
  }

  @action
  deserialize = (data) => {
    this.id = data.course.id;

    this.thumbnail = data.course.thumbnail;

    this.title = data.course.name;

    this.lector = data.course.authorName;

    this.type = data.course.type;

    this.isRating = data.course.isRating;

    this.isFavorite = data.course.isFavorite;

    this.isOwned = data.course.isOwned;

    this.isPurchaseOnly = data.course.saleType === 'Single';

    // 學習百分比.
    this.learningProgress = Math.ceil((data.learned / data.total) * 100);

    // 沒有這個資料.
    // this.ranking = ranking;
  };

  @action
  changeIsFavorite = async (e, uid, syncFavorite, section) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (!uid) {
        SuccessToast(i18n.t('common_login_unlock'));
        setTimeout(() => {
          signInViewModel.handleOpen();
        }, 500);
        return;
      }
      this.isProcessing = true;
      const res = await CourseService.changeIsFavorite(uid, this.id);
      runInAction(() => {
        this.isFavorite = res.data.isFavorite;
        if (this.isFavorite) {
          SuccessToast(i18n.t('course_favorite_add'));
        } else {
          ErrorToast(i18n.t('course_favorite_remove'));
        }
        syncFavorite(this.id, res.data.isFavorite, this.type, section);
        this.isProcessing = true;
      });
    } catch (error) {
      CourseError.changeIsFavorite(error);
      this.isProcessing = true;
    }
    // APIS
    // call outer loop function;
  };
}
