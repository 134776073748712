import dayjs from 'dayjs';

export default class Redeem {
  static fromRes(data) {
    return new Redeem(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    if (data.result) {
      // check for data detail.
      const { item } = data.result[0];
      const { type, value } = item;
      const { id } = value[0];

      this.type = type;
      this.id = id;

    } else {
      const { id, group, code, usedAt } = data;
      const { item, title } = group;
      const { type, value } = item;
      this.id = id;
      this.title = title;
      this.type = type;
      this.code = code;
      this.usedAt = usedAt;

      if (type === 'Course') {
        const targetCourse = value[0];
        this.courseId = targetCourse.id;
        this.courseTitle = targetCourse.name;
        this.courseThumbnail = targetCourse.thumbnails.web.url;
        this.courseType = targetCourse.type;
      }

      if (type === 'Subscription') {
        this.value = value;
      }

      // 輸入兌換.
      this.usedAtFormat = '尚未使用';

      // 兌換的歷史紀錄.
      if (data.usedAt) {
        const usedAtFromDayjsInstance = dayjs(data.usedAt);
        const usedAtFormat = usedAtFromDayjsInstance.format(
          'YYYY/MM/DD HH:mm:ss'
        );

        this.usedAtFromDayjsInstance = usedAtFromDayjsInstance;
        // 覆蓋輸入兌換.
        this.usedAtFormat = usedAtFormat;
      }
    }
  }
}
