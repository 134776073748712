import { makeObservable, observable, action, computed } from 'mobx';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class LoadingService {
  @observable loadingQueue = [];

  @computed get isLoading() {
    return this.loadingQueue.length > 0;
  }

  constructor() {
    makeObservable(this);
  }

  @action onLoading = (name = 'default') => {
    this.loadingQueue.push(name);
    if (this.loadingQueue.length === 1) {
      // > 第一個人阻擋
      disableBodyScroll(document);
    }
  }

  @action onLoadingSuccess = (name = 'default') => {
    this.loadingQueue = this.loadingQueue.filter((item) => item !== name);
    if (this.loadingQueue.length === 0) {
      // > 最後一個人開啟
      enableBodyScroll(document);
    }
  }

}

const loading = new LoadingService();

export default loading;
