import React from 'react';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import is from 'is_js';
import NotFound from 'src/assets/404.png';

import { color, fontSize } from './Customize/constants';

export default class QuillViewModel {
  @observable value = { ops: [] };
  @observable quill;
  quillRef = React.createRef();
  @observable modules;
  @observable formats;

  constructor() {
    makeObservable(this);

    this.initQuill();
  }

  @action
  didMount = () => {
    this.quill = this.quillRef.current.getEditor();
  }

  @action
  setQuillContent = async (content, ratio) => {
    try {
      if (this.quill) {
        if (!is.object(content)) {
          const translateManuscript = (() => {
            if (!content) return { ops: [] };
            const parseData = JSON.parse(content);
            const replacedArr = parseData.ops.map((op) => ({ ...op, insert: is.string(op.insert) ? decodeURIComponent(op.insert) : op.insert }));
            // return { ops: [...replacedArr, { insert: { carousel: { images: [NotFound, NotFound, NotFound] } } }] };
            return { ops: replacedArr };
          })();
          await this.quill.setContents(translateManuscript);
          runInAction(() => {
            this.rerenderIframe();
            this.checkContent(ratio);
          });
          return;
        }
        await this.quill.setContents(content);
      }
      runInAction(() => {
        this.rerenderIframe();
        this.checkContent(ratio);
      });
    } catch (error) {
      console.log(error);
      this.quill.setContents({ ops: [] });
    }
  }

  @action
  initQuill = () => {
    this.modules = {
      // NOTE: for edit.
      // blotFormatter: {
      // },
      // VideoResize: {
      //   modules: ['Resize', 'DisplaySize', 'Toolbar'],
      //   tagName: 'iframe' // iframe | video
      // },
      // NOTE:
      toolbar: [
        [{ size: [...fontSize] }],
        [{ color: [...color] }, { background: [...color] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ]
    };

    this.formats = [
      'header',
      'size', 'code-block', 'script',
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
      'list', 'bullet', 'indent',
      'link', 'image', 'video',
      // 'link', 'image', 'video', 'carousel',
      'height', 'width', 'style'
    ];
  }

  @action
  setValue = () => {
    this.value = this.quill.getContents();
    // console.log('this.value', this.value);
  }

  @action
  saveContent = () => {
    this.setQuillContent(this.value);
    const saveArr = this.value.ops.map((op) => {
      const copy = { ...op };
      if (!!copy.insert.video || !!copy.insert.image) {
        if (copy.attributes?.height) {
          copy.attributes.style += `aspect-ratio: ${copy.attributes.width / copy.attributes.height};`;
          delete copy.attributes.height;
        }
      } else {
        const encodeURI = encodeURIComponent(copy.insert);
        copy.insert = encodeURI;
        if (!copy.attributes) {
          copy.attributes = {
            size: '13px'
          };
        } else if (copy.attributes && !copy.attributes.size) {
          copy.attributes = {
            ...copy.attributes,
            size: '13px'
          };
        }
      }
      return copy;
    });
    this.value = { ops: saveArr };
    // const legalArr = this.value.ops.map((op) => ({ ...op, insert: op.insert.replace?.(/\n/g, '%escape%') ?? op.insert }));
  }

  @action
  rerenderIframe = () => {
    const browser = (() => {
      const userAgent = window.navigator.userAgent;
      if (userAgent.match(/chrome|chromium|crios/i)) {
        return 'chrome';
      }
      if (userAgent.match(/firefox|fxios/i)) {
        return 'firefox';
      }
      if (userAgent.match(/safari/i)) {
        return 'safari';
      }
      if (userAgent.match(/opr\//i)) {
        return 'opera';
      }
      if (userAgent.match(/edg/i)) {
        return 'edge';
      }
      return null;
    })();

    if (browser !== 'safari') {
      return;
    }

    const iframeList = this.quill.root.querySelectorAll('iframe');
    iframeList.forEach((el) => {
      const src = el.src;
      const blockContainer = document.createElement('p');
      const iframe = document.createElement('iframe');
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allowfullscreen', true);
      iframe.setAttribute('src', src);
      iframe.classList.add('video');
      blockContainer.classList.add('content');
      blockContainer.appendChild(iframe);
      el.replaceWith(blockContainer);
    });
  }
  checkContent = (ratio = 1) => {
    const editors = document.querySelectorAll('.ql-editor');
    const editorsClassList = [];
    editors.forEach((el) => {
      editorsClassList.push(el.classList);
    });
    const index = editorsClassList.findIndex((el) => el.length === 1);
    const editor = editors[index];
    const child = editor?.childNodes;

    if (!child || child.length === 0) {
      return;
    }

    const size = ratio * 16;

    child.forEach((el) => {
      el.setAttribute('style', `font-size: ${size}px`);
    });
  }
}


