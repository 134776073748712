import React from 'react';
import { action, runInAction, makeObservable, observable, computed } from 'mobx';
import screenfull from 'screenfull';
import isMobile from 'ismobilejs';
import { SuccessToast } from 'src/libs/toast';
import i18n from 'src/i18n';
import dayjs from 'dayjs';
import ClipboardJS from 'clipboard';

import { getHost } from 'src/apis/utils';
import signInViewModel from '../Header/component/SignIn/viewModel';
import playerStore from './store';
import PlayerAPIs from './apis';
import SpeedSelectorViewModel from './components/Controls/components/SpeedSelector/viewModel';

// 自訂事件, nodejs events.
const events = require('events');

class PlayerViewModel {
  @observable base = 'width';
  @observable keys = '';
  @observable speedSelectorModal = new SpeedSelectorViewModel();
  @observable showMoreContent = false;

  @observable isShareSelectOpen = false;
  shareChapterRef = React.createRef();
  shareNowRef = React.createRef();
  clipBoard = null;

  @computed get shareUrl() {
    return `${getHost()}/course/${playerStore.course?.id}/chapter/${playerStore.chapter?.id}?type=app`;
  }

  @computed get shareCurrentUrl() {
    return `${getHost()}/course/${playerStore.course?.id}/chapter/${playerStore.chapter?.id}?startAt=${Math.round(playerStore.currentTime)}`;
  }

  constructor() {
    // 自訂事件.
    this.emitter = new events.EventEmitter();
    // apis.
    this.playerAPIs = new PlayerAPIs({ emitter: this.emitter });
    // refs.
    this.mediaContainerRef = React.createRef();
    this.mediaRef = React.createRef();

    makeObservable(this);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // scroll 顯示播放器.
  @action
  show = () => {
    if (playerStore.isPlayerOpen && !playerStore.isPlayerShow) {
      playerStore.isPlayerShow = true;
    }
  };

  // scroll 隱藏播放器.
  @action
  hide = () => {
    if (
      playerStore.isPlayerOpen
      && playerStore.isPlayerShow
      && !playerStore.isBigScreen
    ) {
      playerStore.isPlayerShow = false;
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 紀錄全螢幕的狀態.
  @action
  onScreenfullChange = () => {
    playerStore.isScreenfull = screenfull.isFullscreen;
  };

  // Waypoint enter event.
  onEnterWaypoint = async () => {
    await this.playerAPIs.handlerNextListAPI({});
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  onResizeObservable = () => {
    // FIXME: specific.
    if (!playerStore.isBigScreen) {
      return;
    }

    const bigScreenContainer = document.querySelector('.bigScreen');

    const clientWidth = bigScreenContainer.clientWidth * (2 / 3) - 50;
    const clientHeight = bigScreenContainer.clientHeight - 120;

    // > set base for web
    if ((clientWidth / clientHeight) > (16 / 9)) {
      this.base = 'height';
    } else {
      this.base = 'width';
    }

    if (!isMobile(window.navigator).phone) {
      return;
    }

    if (window.innerWidth > window.innerHeight) {
      if (playerStore.screenOrientationIsLand) {
        return;
      }
      if (screenfull.isEnabled) {
        if (!playerStore.isScreenfull) {
          screenfull.request(playerStore.mediaContainerRef.current);
        }
      } else {
        const player = playerStore.mediaRef.current.getInternalPlayer();
        player.webkitEnterFullScreen();
      }
      playerStore.screenOrientationIsLand = true;
    } else {
      playerStore.screenOrientationIsLand = false;
    }
  }

  @action
  onRateChange = (rate) => {
    console.log('onRateChange');
    playerStore.speed = rate;
  }

  @action
  onPause = () => {
    console.log('on paused');
    playerStore.isPaused = true;
  }

  @action

  onPlay = () => {
    console.log('on play');
    playerStore.needStop = false;
    playerStore.isPaused = false;

  }

  // 影片可以播放時更新 state 資料, 影片 dom 的事件.
  @action
  onCanplay = async () => {

    console.log('on ready');
    // 第一次自動播放.
    if (playerStore.isFirstPlay) {
      // 不再自動播放.
      playerStore.isFirstPlay = false;
      // 紀錄影片當前秒數.

      playerStore.isPaused = false;

      const urlSeekTime = (playerStore.seekTo && playerStore.duration > playerStore.seekTo) ? playerStore.seekTo : 0;
      playerStore.mediaRef.current.seekTo(playerStore.seekTo ? urlSeekTime : playerStore.currentTime, 'seconds');
      playerStore.seekTo = null;

      // 開啟排程.
      this.playerAPIs.setIntervals();
      // call api.
      await this.playerAPIs.handlerLearnedLastAPI();
      await this.playerAPIs.handlerLearnedHistoryAPI();
      await this.playerAPIs.handlerLearnedCourseAPI();
    }
  };

  @action
  onLoadedMetadata = () => {
    console.log('onLoadedMetadata');
    if (!screenfull.isEnabled) {
      const player = playerStore.mediaRef.current.getInternalPlayer();
      if (!playerStore.isRegisteredFullScreen) {
        console.log('is registered');
        // > https://bugs.webkit.org/show_bug.cgi?id=174626 use webkitpresentationmodechanged
        player.addEventListener('webkitpresentationmodechanged', this.stopPrevent);
        playerStore.isRegisteredFullScreen = true;
      }
    }
    if (isMobile(window.navigator).apple.phone) {
      setTimeout(() => {
        runInAction(() => {
          playerStore.isPaused = playerStore.needStop;
        });
      }, 1000);
    }
  }

  @action
  onLoadedData = () => {
    console.log('onLoadedData');
    // > for iPad use!
    if (isMobile(window.navigator).apple.tablet) {
      playerStore.isPaused = false;
    }
  }

  // > discuss: is paused!!!

  @action
  stopPrevent = () => {
    const player = playerStore.mediaRef.current.getInternalPlayer();
    const isPaused = player.paused;

    console.log('on action');

    playerStore.iosFullscreen = !playerStore.iosFullscreen;

    if (isPaused !== playerStore.isPaused) {
      setTimeout(() => {
        // > for same video leave full screen!
        playerStore.isPaused = false;
        player.play();
        // > currently will not play from full screen video to audio, sync playing status.
        if (player.paused) {
          playerStore.isPaused = true;
        }
      }, 1000);
    }
  }


  @action
  onSeek = () => {
    console.log('on seek');
    playerStore.isPaused = true;
    playerStore.isPaused = false;
  }

  // 影片播放結束.
  @action
  onEnded = async () => {
    console.log('on ended');
    // 刪除排程.
    this.playerAPIs.clearIntervals();
    // call api.
    await this.playerAPIs.handlerLearnedLastAPI();
    await this.playerAPIs.handlerLearnedHistoryAPI();

    // 播放下一集.
    await this.playerAPIs.handlerNextChapterAPI({ showMessage: true });
  };

  // 影片播放中的每毫秒事件, 影片播放中的重複執行事件.
  // 快轉或倒退會觸發.
  // 影片準備完成會觸發.
  @action
  onProgress = (data) => {
    console.log('on progress');

    const { playedSeconds, played } = data;
    // 紀錄當前播放的時間.
    if (playerStore.isFirstPlay) {
      return;
    }
    playerStore.currentTime = playedSeconds;
    if (played > 0.8) {
      // FIXME: add params for better performance.
      this.playerAPIs.handlerLearnedHistoryAPI();
    }

    if (playerStore.needStop) {
      this.onPause();
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 綁定監視全螢幕的事件.
  didMount = () => {
    window.addEventListener('resize', this.onResizeObservable);
    if (screenfull.isEnabled) {
      screenfull.on('change', this.onScreenfullChange);
    }
  }

  // 刪除監視全螢幕的事件.
  unMount = () => {
    window.removeEventListener('resize', this.onResizeObservable);
    if (screenfull.isEnabled) {
      screenfull.off('change', this.onScreenfullChange);
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 外部修改收藏時, 同步 player 的收藏.
  setFavorite = ({ id, isFavorite }) => {
    if (playerStore.isPlayerOpen && playerStore.chapter.id === id) {
      playerStore.chapter = { ...playerStore.chapter, isFavorite };
    }

    this.emitter.emit('listFavorite', { id, isFavorite });
  };

  // 綁定事件.
  // type: favorite, player 收藏切換時觸發.
  addListener = (type, func) => {
    this.emitter.addListener(type, func);
  };

  // 刪除事件.
  removeListener = (type, func) => {
    this.emitter.removeListener(type, func);
  };

  // 從頁面播放影片.
  @action
  handlerSetChapterId = async ({ chapterId, isPaused = false, seekTo }) => {
    // 點擊同一個 chapter.
    if (playerStore.chapter?.id === chapterId) {
      // player scroll 顯示.
      playerStore.isPlayerShow = true;
      // player 重新播放.
      playerStore.currentTime = 0;
      playerStore.mediaRef.current.seekTo(0, 'seconds');


      // 當前暫停中.
      if (playerStore.isPaused) {
        playerStore.isPaused = false;
        // 影片播放.
        // 開啟排程.
        this.playerAPIs.setIntervals();
      }
      setTimeout(() => {
        playerStore.isBigScreen = true;

        this.toggleOverflow();
      }, 1);
    } else {
      // 清空列表.
      playerStore.resetStates();

      playerStore.needStop = isPaused;
      playerStore.seekTo = seekTo;
      // 影片資料.
      await this.playerAPIs.handlerGetChapterAPI(chapterId);

      // 課程播放列表.
      await this.playerAPIs.handlerGetChapterListAPI({
        include: chapterId
      });
      // 下一頁 list.
      await this.playerAPIs.handlerNextListAPI({});

      // 影片 url.
      await this.playerAPIs.handlerGetPlayerUrlAPI(chapterId);
      // 目前看來, 可以打開播放器, 就表示該影片是可以播放的.
      runInAction(() => {
        // 打開播放器.
        playerStore.isPlayerOpen = true;
        // player scroll 顯示.
        playerStore.isPlayerShow = true;
        // 允許 Waypoint 開始下載.
        playerStore.isReady = true;

        // > 2023 / 09 分享按鈕在大播放器內，因此一律改開大播放器
        setTimeout(() => {
          playerStore.isBigScreen = true;

          this.toggleOverflow();
        }, 1);
      });
    }
  };

  onCourseTitleClick = () => {
    if (playerStore.isBigScreen) {
      playerStore.isBigScreen = false;
    }

    this.toggleOverflow();
  }

  toggleOverflow() {
    const doc = document.documentElement;
    const overflow = playerStore.isBigScreen ? 'hidden' : 'auto';

    doc.style.overflow = overflow;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  toggleMoreContent = () => {
    this.showMoreContent = !this.showMoreContent;
  }

  @action
  onFavoriteClick = async () => {
    if (!playerStore.profile?.id) {
      SuccessToast(i18n.t('common_login_unlock'));
      if (!playerStore.isPaused) {
        // 紀錄暫停.
        playerStore.isPaused = true;
        // 紀錄影片當前秒數.
        playerStore.currentTime = playerStore.mediaRef.current.getCurrentTime();

        // 清除排程.
        this.playerAPIs.clearIntervals();
        // call api.
        await this.playerAPIs.handlerLearnedLastAPI();
        await this.playerAPIs.handlerLearnedHistoryAPI();
      }
      setTimeout(() => {
        signInViewModel.handleOpen();
      }, 500);
      return;
    }

    await this.playerAPIs.handlerFavoriteAPI({
      id: playerStore.profile?.id
    });

    this.emitter.emit('listFavorite', { id: playerStore.chapter.id, isFavorite: playerStore.chapter.isFavorite });
  }

  @action
  onShareSelectToggle = () => {
    this.isShareSelectOpen = !this.isShareSelectOpen;
    if (!this.clipBoard && this.isShareSelectOpen) {
      this.generateClipBoard();
    }
  }

  @action generateClipBoard = () => {
    if (!this.clipBoard) {
      console.log('in');
      const chapterClipboard = new ClipboardJS(this.shareChapterRef.current);
      const nowClipboard = new ClipboardJS(this.shareNowRef.current);
      chapterClipboard.on('success', this.onShareSuccess);
      chapterClipboard.on('error', this.onShareFail);
      nowClipboard.on('success', this.onShareSuccess);
      nowClipboard.on('error', this.onShareFail);
      this.clipBoard = chapterClipboard;
    }
    console.log('out');
  }

  @action onShareSuccess = () => {
    SuccessToast('複製成功！');
    setTimeout(() => {
      this.onShareSelectClose();
    }, 1);
    console.log('isShareSelectOpen', this.isShareSelectOpen);
  }

  onShareFail = () => {
    SuccessToast('複製失敗！');
  }

  @action
  onShareSelectClose = () => {
    this.isShareSelectOpen = false;
  }
}

const playerViewModel = new PlayerViewModel();

export default playerViewModel;
