import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import styles from './style.module.scss';

@observer
class AlertView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return ReactDOM.createPortal(
      <div
        className={clsx(
          styles.alert,
          this.vm.isOpenAlert && styles.active,
          this.props.className
        )}
      >
        <div
          className={styles.background}
          style={{ backgroundColor: this.props.closeBackgroundColor }}
          onClick={this.vm.handlerBackgroundCloseAlert}
        />

        <div
          className={clsx(styles.contentContainer, this.props.contentContainerStyles)}
          style={{ backgroundColor: this.props.contentBackgroundColor }}
        >
          {this.props.showClose && (
            <button
              type="button"
              className={styles.close}
              onClick={this.vm.handlerCloseAlert}
            >
              X
            </button>
          )}

          {this.props.children}

          {this.props.showButtons && (
            <div className={styles.buttons}>
              <button
                type="button"
                className="cancel"
                onClick={this.vm.handlerCancelCloseAlert}
              >
                {this.props.cancelText}
              </button>

              <button
                type="button"
                className="confirm"
                onClick={this.vm.handlerConfirmCloseAlert}
              >
                {this.props.confirmText}
              </button>
            </div>
          )}
        </div>
      </div>,
      document.querySelector('#root')
    );
  }
}

AlertView.propTypes = {
  showClose: PropTypes.bool,
  // 顯示取消確認按鈕.
  showButtons: PropTypes.bool,
  // 取消按鈕文字.
  cancelText: PropTypes.string,
  // 確認按鈕文字.
  confirmText: PropTypes.string,
  // 背景顏色.
  closeBackgroundColor: PropTypes.string,
  // 內容容器顏色
  contentBackgroundColor: PropTypes.string,
  // alert style class.
  className: PropTypes.string,

  contentContainerStyles: PropTypes.string
};

AlertView.defaultProps = {
  // 顯示 close 按鈕.
  showClose: true,
  // 顯示取消確認按鈕.
  showButtons: true,
  // 取消按鈕文字.
  cancelText: '取消',
  // 確認按鈕文字.
  confirmText: '確認',
  // 背景顏色.
  closeBackgroundColor: 'rgba(0, 0, 0, 0.25)',
  // 內容容器顏色
  contentBackgroundColor: '#ffffff',
  // alert style class.
  className: '',

  contentContainerStyles: ''
};

export default AlertView;
