export default class CourseDetail {
  constructor(data) {
    this.init(data);
  }

  init = (data) => {
    const {
      id,
      type,
      name,
      subtitle,
      introduction,
      notice,
      saleType,
      duration,
      thumbnails,
      category,
      tags,
      author,
      narrator,
      vendor,
      popularity,
      price,
      discount,
      isFavorite,
      isOwned,
      rating
    } = data;


    this.mainCardInfo = {
      id,
      type,
      name,
      subtitle,
      thumbnail: thumbnails.web.url,
      category,
      tags,
      saleType,
      duration: Math.ceil(duration),
      popularity,
      price,
      discount,
      isFavorite,
      isOwned,
      notice,
      rating: rating.score
    };


    // const translateManuscript = (() => {
    //   if (!introduction) return { ops: [] };

    //   const parseData = JSON.parse(introduction);
    //   const replacedArr = parseData.ops.map((op) => ({ ...op, insert: op.insert.replace?.(/%escape%/g, '\n') ?? op.insert }));
    //   return { ops: replacedArr };
    // })();


    this.infoSecInfo = {
      id,
      introduction,
      author,
      narrator,
      vendor,
      notice,
      // NOTE: for modal authen check.
      saleType,
      isOwned,
      rating
    };

    this.chapterSecInfo = {
      id
    };

    this.commentSecInfo = {
      id,
      saleType,
      isOwned
    };
  }

  static fromRes(data) {
    return new CourseDetail(data);
  }
}
