import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import AlertView from 'src/components/Alert';

import playerStore from 'src/components/Player/store';

import styles from './styles.module.scss';

@observer
class SpeedSelector extends React.Component {
  render() {
    const { viewModel, inMenu, isLand } = this.props;
    return (
      <>
        {
          viewModel.isSpeedSelectorOpen
          && (
            <>
              <div className={styles.shadow} onClick={viewModel.toggleOpen} />
              <div className={clsx(styles.containerStyles, inMenu && styles.inMenu, isLand && styles.isLand)}>
                {
                  viewModel.speedList.map((el) => {
                    return (
                      <div
                        className={clsx(styles.tag, playerStore.speed === el && styles.active, isLand && styles.isLand)}
                        onClick={() => viewModel.onSpeedSet(el)}
                      >
                        {`x${el}`}
                      </div>
                    );
                  })
                }
              </div>
            </>
          )
        }
      </>
    );
  }
}

SpeedSelector.propTypes = {
  viewModel: PropTypes.object,
  inMenu: PropTypes.bool
};

SpeedSelector.defaultProps = {
  viewModel: {},
  inMenu: false
};

export default SpeedSelector;
