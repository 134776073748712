import Manuscript from './Manuscript';

export default class ChapterDetail {
  constructor(data) {
    const {
      id,
      type,
      name,
      summary,
      manuscript,
      document,
      duration,
      isTrial,
      priority
    } = data.chapter;

    const {
      id: courseId,
      name: courseName,
      type: courseType,
      category,
      thumbnails,
      saleType,
      author,
      isOwned,
      totalChapters
    } = data.course;

    this.courseInfo = {
      id: courseId,
      name: courseName,
      thumbnail: thumbnails.web.url,
      authorName: author.name,
      courseType,
      category,
      saleType,
      isOwned,
      totalChapters
    };

    this.chapterInfo = {
      id,
      type,
      name,
      summary,
      manuscript,
      duration,
      isTrial,
      priority,
      document
    };
  }

  static fromRes(data) {
    return new ChapterDetail(data);
  }
}
