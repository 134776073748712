const adjustManifest = () => {
  const manifestContent = {
    short_name: 'sandshour 翻轉零散時間的價值｜影音內容訂閱平台',
    name: 'sandshour 翻轉零散時間的價值｜影音內容訂閱平台',
    icons: [
      {
        src: 'favicon.ico',
        sizes: '64x64 32x32 24x24 16x16',
        type: 'image/x-icon'
      },
      {
        src: 'christLogo.png',
        type: 'image/png',
        sizes: '192x192'
      },
      {
        src: 'christLogo.png',
        type: 'image/png',
        sizes: '512x512'
      }
    ],
    start_url: window.location.pathname,
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff'
  };

  const stringManifest = JSON.stringify(manifestContent);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);

  document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
};

export default adjustManifest;
