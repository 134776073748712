import {
  makeObservable,
  observable,
  action
} from 'mobx';

import inAppRedirectModalViewModel from 'src/components/InAppRedirectModal/viewModel';

class PagesRootViewModel {
  // @observable isAskOpen = false;

  // constructor() {
  //   makeObservable(this);

  //   this.checkIsInAppBrowser();
  // }

  // @action checkIsInAppBrowser = () => {
  //   const userAgent = navigator.userAgent;
  //   const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari\/)', 'Android.*(wv|\.0\.0\.0)'];
  //   const regex = new RegExp(`(${rules.join('|')})`, 'ig');

  //   if (userAgent.match(regex)) {
  //     inAppRedirectModalViewModel.handlerOpenAlert();
  //   }
  // }
}

export default PagesRootViewModel;
