import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import ToastIcon from 'src/assets/toastIcon.svg';

// const dismissPrevToast = () => {
//   return new Promise((res, rej) => {
//     toast.dismiss();
//     res();
//   });
// };

const appWidth = document.querySelector('#root').offsetWidth;

export const SuccessToast = async (title) => {

  toast.success(title, {
    position: appWidth >= 576 ? 'top-center' : 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    icon: () => <img src={ToastIcon} alt="Arrow" />
  });
};

export const ErrorToast = async (title) => {

  toast.error(title, {
    position: appWidth >= 576 ? 'top-center' : 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    icon: () => <img src={ToastIcon} alt="Arrow" />
  });
};

export const InfoToast = async (title) => {

  toast.info(title, {
    position: appWidth >= 576 ? 'top-center' : 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    icon: () => <img src={ToastIcon} alt="Arrow" />
  });
};

export const WarningToast = async (title) => {

  toast.warn(title, {
    position: appWidth >= 576 ? 'top-center' : 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    icon: () => <img src={ToastIcon} alt="Arrow" />
  });
};
