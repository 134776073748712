import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

class MenuViewModel {
  @observable isOpen = false;

  @observable observer = null;

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    this.observeNav();
  }

  unMount = () => {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  @action
  observeNav = () => {
    const target = document.querySelector('.header-fixed');
    const callback = (mutationsList) => {
      for (let i = 0; i < mutationsList.length; i += 1) {
        if (mutationsList[i].type === 'attributes') {
          const classList = mutationsList[i].target.classList;
          if (classList.contains('hide-header') && this.isOpen) {
            this.handlerCloseMenu();
          }
        }
      }
    };
    const config = {
      attributes: true, // 監聽屬性變動
      attributeFilter: ['class'] // 只監聽class屬性的變動
    };
    const observer = new MutationObserver(callback);
    observer.observe(target, config);
    this.observer = observer;
  }

  @action
  handlerOpenMenu = () => {
    if (this.isOpen) {
      return;
    }
    this.isOpen = true;
    const root = document.querySelector('#root').clientWidth;
    if (root < 576) {
      this.toggleOverflow();

      const app = document.querySelector('.App');
      if (app) {
        app.style.position = 'fixed';
        app.style.overflow = 'hidden';
      }
    }
  };

  @action
  handlerCloseMenu = () => {
    this.isOpen = false;
    const root = document.querySelector('#root').clientWidth;
    if (root < 576) {
      this.toggleOverflow();

      const app = document.querySelector('.App');
      if (app) {
        app.style.position = 'relative';
        app.style.overflow = 'auto';
      }
    }
  };

  toggleOverflow() {
    const doc = document.documentElement;
    const overflow = this.isOpen ? 'hidden' : 'auto';

    doc.style.overflow = overflow;
  }
}

export default MenuViewModel;
