import { makeObservable, action, observable, computed } from 'mobx';

import LearningCardViewModel from 'src/components/CourseCards/LearningCard/viewModel';

export default class LearningSectionViewModel {
  @observable cardList;
  @observable swiper;

  constructor() {
    makeObservable(this);
  }

  @action
  updateData = (data) => {
    this.deserialize(data);
  }

  @action
  deserialize = (data) => {
    this.getCards(data);
  }

  @action
  getCards = (data) => {
    this.cardList = data.map((card) => new LearningCardViewModel(card));
  }

  @action
  onSwiper = (swiper) => {
    this.swiper = swiper;
  }
}
