import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

class ButtonView extends React.Component {
  render() {
    const { handlerClick, disabled, isShowResMessage, resMessage, text }
      = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.item}>
          <div
            className={clsx([styles.button, disabled && styles.disabled])}
            onClick={handlerClick}
          >
            {text}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.resMessage}>
            {isShowResMessage && resMessage}
          </div>
        </div>
      </div>
    );
  }
}

ButtonView.propTypes = {
  handlerClick: PropTypes.func,
  disabled: PropTypes.bool,
  isShowResMessage: PropTypes.bool,
  resMessage: PropTypes.string,
  text: PropTypes.string
};

ButtonView.defaultProps = {
  disabled: false,
  isShowResMessage: false,
  resMessage: ''
};

export default ButtonView;
