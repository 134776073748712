import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import i18n from 'src/i18n';

import CelebrityBg from 'src/assets/homepage/celebrityBg.jpg';

import CelebrityCard from './CelebrityCard';
import CelebritySectionViewModel from './viewModel';

import './customizeSwiper.css';


import styles from './styles.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

@observer
class CelebritySection extends React.Component {
  constructor(props) {
    super(props);

    this.viewModel = new CelebritySectionViewModel();
  }

  render() {
    return (
      <section
        style={{ '--src': `url(${CelebrityBg})` }}
        className={clsx('celebritySec', styles.celebritySection, styles.celebritySection_layout)}
      >
        <div className={clsx(styles.contentContainer, styles.contentContainer_layout)}>
          <div className={clsx(styles.innerFlexBox, styles.innerFlexBox_layout)}>
            <div className={clsx(styles.innerFlexBox_item)}>
              <h1 className={clsx(styles.sectionTitle, styles.sectionTitle_layout)}>
                {i18n.t('home_recommend_title')}
              </h1>
            </div>
            <div className={clsx(styles.innerFlexBox_spacer)} />
            <div className={clsx(styles.innerFlexBox_item)}>
              <div className={clsx(styles.flex93, styles.flex93_layout)}>
                <div className={clsx(styles.flex94, styles.flex94_layout)}>
                  <Swiper
                    style={{ minWidth: 0, width: '100%', height: '100%', paddingBottom: 50 }}
                    direction="horizontal"
                    onSwiper={this.viewModel.onSwiper}
                    loop
                    modules={[Pagination, Autoplay]}
                    pagination={{
                      type: 'bullets',
                      clickable: true,
                      bulletClass: 'bullet',
                      bulletActiveClass: 'active-bullet'
                    }}
                    autoplay={{
                      disableOnInteraction: false
                    }}
                    breakpoints={{
                      0: { slidesPerView: 1, spaceBetween: 20 },
                      384: { slidesPerView: 1, spaceBetween: 20 },
                      480: { slidesPerView: 1, spaceBetween: 20 },
                      576: { slidesPerView: 2, spaceBetween: 20 },
                      768: { slidesPerView: 2, spaceBetween: 20 },
                      992: { slidesPerView: 3, spaceBetween: 20, centeredSlides: false },
                      1200: { slidesPerView: 3, spaceBetween: 20, centeredSlides: true },
                      1400: { slidesPerView: 3, spaceBetween: 20, centeredSlides: true },
                      1920: { slidesPerView: 3, spaceBetween: 20, centeredSlides: true }

                    }}
                  >
                    {
                      this.viewModel.celebrityList.map((el) => {
                        return (
                          <SwiperSlide key={el.id}>
                            <CelebrityCard viewModel={el} />
                          </SwiperSlide>
                        );
                      })
                    }
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CelebritySection;
