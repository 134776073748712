import API from 'src/apis';
import Keyword from 'src/models/response/keyword';

export default class KeywordService {
  static async getKeyword() {
    const res = await API.keyword.getKeyword();

    return {
      list: res.data.list.map((item) => Keyword.fromRes(item))
    };
  }
}
