import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const MomoPurchase = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="如何在 momo 平台購買 sandshour 散時間線上課程？" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            前往 momo 品牌專區，瀏覽你感興趣的課程：
            <a
              href="https://www.momoshop.com.tw/category/DgrpCategory.jsp?d_code=4003100073&p_orderType=6&showType=chessboardType"
              target="_blank"
              rel="noreferrer"
              className={useStyles.aLink}
            >
              前往 sandshour 散時間品牌館
            </a>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            選取課程加入購物車，前往結帳付款
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            購買後，兌換序號將統一由 momo 購物網於確認付款成功後 15 分鐘內，透過電子郵件或簡訊發送至會員帳號（您也可以至會員中心查看訂單狀態或補發序號）
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑷</div>
            收取課程兌換序號後，自行至sandshour散時間網站或 App 內進行兌換
          </div>
        </div>
      </FAQCard>
      <FAQCard title="momo 購買序號兌換流程" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  網站
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            收到序號後，請先註冊／登入會員
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊右上方個人頭像，打開
            <span className={useStyles.bolder}>
              「我的帳戶」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點選
            <span className={useStyles.bolder}>
              「禮物」
            </span>
            ，輸入課程兌換序號，即可完成兌換
          </div>
          <br />
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  App
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑴</div>
            登入會員帳號
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑵</div>
            點擊下方功能列
            <span className={useStyles.bolder}>
              「我的」
            </span>
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listNum}>⑶</div>
            點選
            <span className={useStyles.bolder}>
              「禮物」
            </span>
            ，輸入課程兌換序號，即可完成兌換
          </div>
        </div>
      </FAQCard>
      <FAQCard title="momo 購買後可以申請退費嗎？" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            序號開通後無法取消或退貨，如有商品疑慮或退費需求，請聯繫 momo 客服處理，後續將由客服協助確認是否符合課程退費資格。
          </div>
          <div className={useStyles.content}>
            ⚠︎ 點擊前往
            <a
              href="https://www.momoshop.com.tw/mypage/MemberCenter.jsp?cid=memb&oid=mcenter&mdiv=1099800000-bt_0_150_01-bt_0_150_01_e13&ctype=B"
              target="_blank"
              rel="noreferrer"
              className={useStyles.aLink}
            >
              momo 會員中心
            </a>
          </div>
          <div className={useStyles.content}>
            momo 消費客戶服務專線：
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            市話直撥4128-200
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            行動電話請撥02-4128-200
          </div>
        </div>
      </FAQCard>
    </>
  );

};

export default MomoPurchase;
