import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import clsx from 'clsx';
import readIcon from 'src/assets/header/readIcon.svg';
import i18n from 'src/i18n';

import styles from './style.module.scss';



// 通知列表.
function NotificationsView(props) {
  const listRef = useRef(null);
  const { handlerAllReadAPI } = props;

  return (
    <>
      <div
        onClick={handlerAllReadAPI}
        className={styles.readAllBtn}
      >
        <img src={readIcon} alt="readIcon" />
        <div>{i18n.t('message_readAll')}</div>
      </div>
      <div className={styles.list} ref={listRef}>
        {props.list.map((item) => {
          const isRead = !!item.readAt;

          return (
            <div
              className={clsx([styles.item, isRead && styles.read, isRead && styles.readBg])}
              key={Math.random()}
              onClick={() => {
                props.onClickItem(item.id);
              }}
            >
              <div className={styles.content}>
                <div className={styles.title}>{item.notification.title}</div>
                <div className={styles.text}>{item.notification.content}</div>
              </div>

              <div className={styles.time}>{item.notification.dayjsFormat}</div>
            </div>
          );
        })}

        {props.isReady && (
          <Waypoint
            scrollableAncestor={listRef.current}
            topOffset={0}
            onEnter={props.onEnterWaypoint}
          />
        )}
      </div>
    </>
  );
}

NotificationsView.propTypes = {
  // 列表.
  list: PropTypes.array.isRequired,
  // item click function.
  onClickItem: PropTypes.func.isRequired,
  // Waypoint enter function
  onEnterWaypoint: PropTypes.func.isRequired,
  // 開始使用 Waypoint.
  isReady: PropTypes.bool.isRequired
};

export default NotificationsView;
