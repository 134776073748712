import React from 'react';

import { action, makeObservable, observable } from 'mobx';

class LayoutViewModel {
  @observable path = '';
  @observable isAlertOpen = true;
  headerRef = React.createRef();

  @observable headerHeight = 0;
  @observable footerHeight = 400;

  @observable isShowFooter = false;

  constructor() {
    makeObservable(this);
  }

  @action
  didMount = () => {
    window.scrollTo(0, 0);
    this.toggleFooter();
  };

  @action
  closeAlert = () => {
    this.isAlertOpen = false;
  }

  @action
  updateHeight = (height) => {
    this.headerHeight = height;
  }

  @action
  updateFooter = (height) => {
    this.footerHeight = height;
  }

  @action
  hideFooter = () => {
    this.isShowFooter = false;
  }

  @action
  showFooter = () => {
    this.isShowFooter = true;
  }

  @action
  toggleFooter = () => {
    this.hideFooter();

    setTimeout(() => {
      this.showFooter();
    }, 1000);
  }
}

export default LayoutViewModel;
