import { makeObservable, observable, action } from 'mobx';

export default class RecommendCardViewModel {
  @observable id;
  @observable courseId;
  @observable type;
  @observable thumbnail;
  @observable title;
  @observable lectorName;
  @observable lectorAvatar;
  @observable isNew;
  @observable popularity;
  @observable courseType;
  @observable isPurchaseOnly;

  constructor(data) {
    makeObservable(this);

    this.deserialize(data);
  }

  @action
  deserialize = (data) => {
    const {
      id,
      name,
      type,
      thumbnail,
      courseId,
      courseName,
      authorName,
      authorAvatar,
      isNew,
      popularity,
      courseType,
      isPurchaseOnly,
      isTrial
    } = data;

    this.id = id;
    this.courseId = courseId;
    this.courseName = courseName;
    this.type = type;
    this.thumbnail = thumbnail;
    this.name = name;
    this.authorName = authorName;
    this.authorAvatar = authorAvatar;
    this.isNew = isNew;
    this.popularity = popularity;
    this.courseType = courseType;
    this.isPurchaseOnly = isPurchaseOnly;
    this.isTrial = isTrial;
  }
}
