import { makeObservable, observable, action, computed } from 'mobx';
import i18n from 'src/i18n';
import { v4 as uuidv4 } from 'uuid';
import data from './data.json';

import CelebrityCardViewModel from './CelebrityCard/viewModel';

export default class CelebritySectionViewModel {
  @observable celebrityList = [];
  @observable swiper;
  constructor() {
    makeObservable(this);

    this.init();
  }

  @action
  init = () => {
    this.celebrityList = data.map((el) => new CelebrityCardViewModel(el));
  }

  @action
  onSwiper = (swiper) => {
    this.swiper = swiper;
  }
}
