/* eslint-disable no-case-declarations */
// NOTE: need to confirm.
import { makeObservable, observable, action, runInAction, computed } from 'mobx';

import HomeService from 'src/services/home';
import diff from 'deep-diff';

import { ErrorToast } from 'src/libs/toast';

import { COURSE_CATEGORY } from 'src/constants';
import BannerSectionViewModel from './components/BannerSection/viewModel';
import LearningSectionViewModel from './components/LearningSection/viewModel';
import ExposeSectionViewModel from './components/ExposeSection/viewModel';
import RankingSectionViewModel from './components/RankingSection/viewModel';
import RecommendSectionViewModel from './components/RecommendSection/viewModel';
import ModuleSectionViewModel from './components/ModuleSection/viewModel';

export default class HomePageViewModel {
  @observable bannerSectionVm = new BannerSectionViewModel();
  @observable learningSectionVm = new LearningSectionViewModel();
  @observable videoExposeVm = new ExposeSectionViewModel(COURSE_CATEGORY.video);
  @observable audioExposeVm = new ExposeSectionViewModel(COURSE_CATEGORY.audio);
  @observable audioBookExposeVm = new ExposeSectionViewModel(COURSE_CATEGORY.audiobook);
  @observable rankingVm = new RankingSectionViewModel();
  @observable recommendVm = new RecommendSectionViewModel();
  @observable modules = [];

  constructor() {
    makeObservable(this);
  }

  @action
  getHomeData = async () => {
    try {
      const res = await HomeService.getHome();
      runInAction(() => {
        const { banner, learned, videos, audios, audiobooks, rank, recommends, modules } = res;

        this.bannerSectionVm.updateData(banner);
        this.learningSectionVm.updateData(learned);
        this.videoExposeVm.updateData(videos);
        this.audioExposeVm.updateData(audios);
        this.audioBookExposeVm.updateData(audiobooks);
        this.rankingVm.updateData(rank);
        this.recommendVm.updateData(recommends);
        this.modules = modules.map((el) => new ModuleSectionViewModel(el));
      });
    } catch (error) {
      // ErrorToast('發生異常，請稍候再試');
    }

  }
  @action
  didMount = async () => {
    await this.getHomeData();
  }

  @action
  didUpdate = async (prevProfile, profile) => {
    try {
      if (diff(prevProfile, profile)) {
        await this.getHomeData();
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action
  syncFavorite = (targetId, isFavorite, type, now) => {
    switch (now) {
      case 'Learned':
        this.syncRankFavorite(targetId, isFavorite, type);
        this.syncExposeFavorite(targetId, isFavorite, type);
        break;
      case 'Exposed':
        this.syncRankFavorite(targetId, isFavorite, type);
        this.syncLearnedFavorite(targetId, isFavorite);
        break;
      case 'Ranking':
        this.syncExposeFavorite(targetId, isFavorite, type);
        this.syncLearnedFavorite(targetId, isFavorite);
        break;
      default:
        break;
    }
  }

  @action
  syncExposeFavorite = (targetId, isFavorite, type) => {
    switch (type) {
      case 'Video':
        const targetVideo = this.videoExposeVm.cardList.find((course) => course.id === targetId);
        if (targetVideo) {
          targetVideo.isFavorite = isFavorite;
        }
        break;
      case 'Audio':
        const targetAudio = this.audioExposeVm.cardList.find((course) => course.id === targetId);
        if (targetAudio) {
          targetAudio.isFavorite = isFavorite;
        }
        break;
      case 'Audiobook':
        const targetAudiobook = this.audioBookExposeVm.cardList.find((course) => course.id === targetId);
        if (targetAudiobook) {
          targetAudiobook.isFavorite = isFavorite;
        }
        break;
      default:
        break;
    }
  }

  @computed
  get browser() {
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/chrome|chromium|crios/i)) {
      return 'chrome';
    }
    if (userAgent.match(/firefox|fxios/i)) {
      return 'firefox';
    }
    if (userAgent.match(/safari/i)) {
      return 'safari';
    }
    if (userAgent.match(/opr\//i)) {
      return 'opera';
    }
    if (userAgent.match(/edg/i)) {
      return 'edge';
    }
    return null;
  }

  @action
  syncRankFavorite = (targetId, isFavorite, type) => {
    switch (type) {
      case 'Video':
        const targetVideo = this.rankingVm.videoList.find((course) => course.id === targetId);
        if (targetVideo) {
          targetVideo.isFavorite = isFavorite;
        }
        break;
      case 'Audio':
        const targetAudio = this.rankingVm.audioList.find((course) => course.id === targetId);
        if (targetAudio) {
          targetAudio.isFavorite = isFavorite;
        }
        break;
      case 'Audiobook':
        const targetAudiobook = this.rankingVm.audioBookList.find((course) => course.id === targetId);
        if (targetAudiobook) {
          targetAudiobook.isFavorite = isFavorite;
        }
        break;
      default:
        break;
    }
  }

  @action
  syncLearnedFavorite = (targetId, isFavorite) => {
    const target = this.learningSectionVm.cardList.find((course) => course.id === targetId);
    if (target) {
      target.isFavorite = isFavorite;
    }
  }
}
