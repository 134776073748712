export default class CourseDetailForPurchase {
  constructor(data) {
    const {
      id,
      name,
      type,
      thumbnails,
      price,
      discount,
      saleType,
      isOwned
    } = data;

    this.id = id;
    this.name = name;
    this.type = type;
    this.thumbnail = thumbnails.web.url;
    this.price = price;
    this.discountPrice = discount ? price - discount.price : null;
    this.discountStartAt = discount ? discount.startAt : '';
    this.discountEndAt = discount ? discount.endAt : '';
    this.saleType = saleType;
    this.isOwned = isOwned;
  }

  static fromRes(data) {
    return new CourseDetailForPurchase(data);
  }
}
