import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';
import CommonError from './common';

export default class KeywordError {
  static getKeyword(error) {
    switch (error.response?.status) {
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }
}
