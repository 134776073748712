import React from 'react';
import { createSearchParams } from 'react-router-dom';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { diff } from 'deep-diff';
import KeywordService from 'src/services/keyword';
import KeywordError from 'src/services/error/models/keyword';

export default class KeywordViewModel {
  @observable keyword = '';
  @observable hotKeywords = [];
  @observable isShowHotKeyword = false;
  inputRef = React.createRef();
  inputContainerRef = React.createRef();

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  constructor(props) {
    this.router = props.router;
    makeObservable(this);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  didMount = async () => {
    const keyword = this.router.searchParams[0].get('keyword');

    if (keyword) {
      this.keyword = keyword;
    }

    await this.handlerKeyWordsAPI();
  };

  // @action
  // didUpdate = async (props) => {
  //   const keyword = props.router.searchParams[0].get('keyword');

  //   if (!this.router) {
  //     this.router = props.router;
  //   }

  //   console.log('keywordkeywordkeyword', keyword);
  // };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  setKeyword = (event) => {
    this.keyword = event.target.value;
  };

  @action
  setHotKeywordShow = () => {
    if (this.hotKeywords.length) {
      this.isShowHotKeyword = true;
    }
  };

  @action
  setHotKeywordHide = () => {
    this.isShowHotKeyword = false;
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 跳轉至搜尋結果頁.
  toSearchPage = () => {
    this.router.navigate({
      pathname: '/search',
      search: `?${createSearchParams({ keyword: this.keyword })}`
    });
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // input 獲取焦點時.
  @action
  onFocus = () => {
    // 顯示熱門關鍵字.
    this.setHotKeywordShow();
  };

  // input 失去焦點時.
  @action
  onBlur = () => {
    this.setHotKeywordHide();
  };

  @action
  toggleInputContent = () => {
    this.isShowHotKeyword = !this.isShowHotKeyword;
  }

  // 按下鍵盤.
  @action
  onKeydown = (event) => {
    // 按下 enter.
    if (event.code === 'Enter' || event.keyword === 13) {
      // FIXME: find better func
      if (event.nativeEvent.isComposing) {
        return;
      }
      // FIXME: end.
      this.toSearchPage();

      event.target.blur();
    }
  };

  // 熱門關鍵字點擊.
  @action
  onClickHotKeyword = (event, keyword) => {
    // 紀錄搜尋關鍵字.
    this.keyword = keyword;
    // 跳轉到搜尋頁面.
    this.toSearchPage();
    // 關閉熱門搜尋.
    this.setHotKeywordHide();
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  handlerKeyWordsAPI = async () => {
    try {
      const res = await KeywordService.getKeyword();

      runInAction(() => {
        this.hotKeywords = res.list;
      });
    } catch (error) {
      KeywordError.getKeyword(error);
    }
  };
}
