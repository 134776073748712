import { request, getHost } from './utils';

// 取得單集詳情.
export const getChapter = ({ id, token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/chapter/${id}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

// 讀取上一集.
export const getChapterPrev = ({ courseId, chapterId, token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/course/${courseId}/chapter/${chapterId}/prev`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// 讀取下一集.
export const getChapterNext = ({ courseId, chapterId, token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/course/${courseId}/chapter/${chapterId}/next`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

// 課程中可播放的列表.
export const getChapterList = ({
  id,
  limit,
  include,
  anchor,
  available,
  token
}) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/course/${id}/chapter`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    params: {
      limit,
      include,
      anchor,
      available
    }
  };

  return request(options);
};

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

// 讀取影片 url.
export const getPlayerUrl = ({ id, token }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/chapter/${id}/url`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

// 最後學習的單集與秒數.
export const postLearnedLast = ({ id, chapterId, lastAt, token }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/${id}/learned/last`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    data: {
      chapterId,
      lastAt
    }
  };

  return request(options);
};

// 已經看完的單集.
export const postLearnedHistory = ({ id, chapterId, token }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/${id}/learned/history`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    },

    data: {
      chapterId
    }
  };

  return request(options);
};

// 加入到已學習的課程.
export const postLearnedCourse = ({ id, courseId, token }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/${id}/learned/course/${courseId}`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
