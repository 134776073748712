import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import is from 'is_js';

import unfavIcon from 'src/assets/chapterCard/unfavIcon.svg';
import favIcon from 'src/assets/chapterCard/favIcon.png';

import PlayingIcon from 'src/assets/player/playingIcon.svg';
import PlayIcon from 'src/assets/player/playOrange.svg';
import divider from 'src/assets/player/divider.svg';
import viewedIcon from 'src/assets/player/viewedIcon.svg';
import manuscriptIcon from 'src/assets/player/manuscriptIconList.svg';
import pdfIcon from 'src/assets/chapterCard/pdfIcon.svg';

import playerStore from '../../store';
import styles from './style.module.scss';
import ChapterViewModel from './viewModel';

@observer
class ChapterView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ChapterViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentWillUnmount() {
    this.vm.willUnmount();
  }

  render() {
    // NOTE: workaround cuz can not control chapter view model on other component.
    const { lastAt } = this.props;
    // NOTE:
    const { id, name, priority, calcDuration, popularity } = this.vm;
    return (
      <div
        className={`playerlist ${clsx(
          styles.chapterContainer, styles.chapterContainer_layout,
          is.number(lastAt) && styles.learnedBg,
          playerStore.chapter.id === id && styles.playingBg
        )}`}
        onClick={this.vm.onSetChapterId}
      >
        <div className={styles.flexOne}>
          <div className={clsx(styles.chapterContainer_item)}>
            <div
              className={clsx(styles.chapterStatusIcon, styles.chapterStatusIcon_layout)}
            >
              {
                playerStore.chapter.id === id
                  ? (<div style={{ '--src': `url(${PlayingIcon})` }} className={clsx(styles.playing, styles.playing_layout)} />)
                  : (<div style={{ '--src': `url(${PlayIcon})` }} className={clsx(styles.available, styles.available_layout)} />)
              }
            </div>
          </div>
          <div className={clsx(styles.chapterContainer_spacer)} />
          <div className={clsx(styles.chapterContainer_item1)}>
            <div className={clsx(styles.chapterInfo, styles.chapterInfo_layout)}>
              <div className={clsx(styles.chapterInfo_item)}>
                <h5 className={clsx(styles.chapterTitle_box, styles.chapterTitle_box_layout)}>
                  <div
                    className={clsx(styles.chapterTitle, !is.number(lastAt) && styles.bolder)}
                  >
                    {`${priority}. ${name}`}
                  </div>
                </h5>
              </div>
              <div className={clsx(styles.chapterInfo_spacer)} />
              <div className={clsx(styles.chapterInfo_item)}>
                <div className={clsx(styles.infoContainer, styles.infoContainer_layout)}>
                  <div className={clsx(styles.infoContainer_item)}>
                    <div className={clsx(styles.timeInfo, styles.timeInfo_layout)}>
                      {calcDuration}
                    </div>
                  </div>
                  <div className={clsx(styles.infoContainer_spacer)} />
                  <div className={clsx(styles.infoContainer_item)}>
                    <div style={{ '--src': `url(${divider})` }} className={clsx(styles.block3, styles.block3_layout)} />
                  </div>
                  <div className={clsx(styles.infoContainer_spacer1)} />
                  <div className={clsx(styles.infoContainer_item)}>
                    <div className={clsx(styles.viewedContainer, styles.viewedContainer_layout)}>
                      <div className={clsx(styles.viewedContainer_item)}>
                        <div style={{ '--src': `url(${viewedIcon})` }} className={clsx(styles.viewedIcon, styles.viewedIcon_layout)} />
                      </div>
                      <div className={clsx(styles.viewedContainer_spacer)} />
                      <div className={clsx(styles.viewedText, styles.viewedText_layout)}>
                        {popularity}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.spacer} />
        {this.vm.isNotVideo && (
          <Link
            to={this.vm.chapterManuscriptLink}
            className={clsx(styles.chapterContainer_item)}
            onClick={this.vm.onManuscriptClick}
          >
            <div style={{ '--src': `url(${this.vm.document ? pdfIcon : manuscriptIcon})` }} className={clsx(styles.manuscriptIcon, styles.manuscriptIcon_layout)} />
          </Link>
        )}
        <div
          className={clsx(styles.favContainer)}
          onClick={(e) => {
            this.vm.onFavClick(e);
          }}
        >
          <div style={{ '--src': `url(${this.vm.isFavorite ? favIcon : unfavIcon})` }} className={clsx(styles.favIconRWD, styles.favIconRWD_layout)} />
        </div>

      </div>
    );
  }
}

ChapterView.propTypes = {
  lastAt: PropTypes.number
};

ChapterView.defaultProps = {
  lastAt: undefined
};

export default ChapterView;
