import React from 'react';
import {
  makeObservable,
  observable,
  action,
  runInAction
} from 'mobx';
import { createSearchParams } from 'react-router-dom';
import i18n from 'src/i18n';
// import { ErrorToast } from 'src/libs/toast';
import MenuViewModel from 'src/components/Header/component/Menu/viewModule';
import AlertViewModel from 'src/components/Alert/viewModel';
import MessageService from 'src/services/message';
import MessageError from 'src/services/error/models/message';
// 編輯器 view model.
import QuillViewModel from 'src/components/Quill/viewModel';



class MessagesViewModel extends AlertViewModel {
  // notification 通知.
  // announcement 公告.
  @observable type = 'announcement';
  // list 長度.
  @observable limit = 10;

  // 通知.
  @observable noAnchor = undefined;
  @observable totalUnreadCount = 0;
  @observable notifications = [];
  // 通知列表讀取中.
  @observable isAwaitNotifications = false;
  // 通知列表準備好.
  @observable isReadyNotifications = false;

  // 公告.
  @observable anAnchor = undefined;
  @observable announcements = [];
  // 公告讀取中.
  @observable isAwaitAnnouncements = false;
  // 公告列表準備好.
  @observable isReadyAnnouncements = false;
  // 編輯器 view model.
  @observable quillViewModel = new QuillViewModel();

  // 已讀全部使用.
  @observable isAwaitReadAll = false;

  // component vm.
  menuVM = new MenuViewModel();
  listRef = React.createRef();

  @observable profile = null;
  @observable router = null;

  @observable openAnnounceTitle = '';

  // > 24/05/02
  @observable observer = null;

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  didMount = async () => {
    // 公告.
    await this.handlerAnnouncementsListAPI();

    runInAction(() => {
      this.isReadyAnnouncements = true;
    });
    this.observeNav();
  };

  unMount = () => {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  @action
  observeNav = () => {
    const target = document.querySelector('.header-fixed');
    const callback = (mutationsList, observer) => {
      for (let i = 0; i < mutationsList.length; i += 1) {
        if (mutationsList[i].type === 'attributes') {
          const classList = mutationsList[i].target.classList;
          if (classList.contains('hide-header') && this.menuVM.isOpen) {
            this.menuVM.handlerCloseMenu();
          }
        }
      }
    };
    const config = {
      attributes: true, // 監聽屬性變動
      attributeFilter: ['class'] // 只監聽class屬性的變動
    };
    const observer = new MutationObserver(callback);
    observer.observe(target, config);
    this.observer = observer;
  }

  @action
  didUpdate = async (props) => {
    if (!this.router) {
      this.router = props.router;
    }

    // 第一次請求.
    if (props.profile && !this.profile) {
      this.profile = props.profile;

      // 讀取通知.
      await this.handlerNotificationsListAPI();

      runInAction(() => {
        this.isReadyNotifications = true;
      });
    }
  };


  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // > new set default open for notification
  @action onMessageIconClick = (profile) => {
    if (!profile) {
      this.setTypeAnnouncement();
    } else {
      this.setTypeNotification();
    }
    this.menuVM.handlerOpenMenu();
  }

  // 切換到通知.
  @action
  setTypeNotification = () => {
    this.type = 'notification';
  };

  // 切換到公告.
  @action
  setTypeAnnouncement = () => {
    this.type = 'announcement';
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 判斷通知跳轉網址.
  getAnnouncementLink = ({ type, value }) => {
    if (type === 'Home') {
      return '/';
    }

    if (type === 'VideosList') {
      return {
        pathname: '/list/video',
        search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
      };
    }

    if (type === 'AudiosList') {
      return {
        pathname: '/list/audio',
        search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
      };
    }

    if (type === 'AudiobooksList') {
      return {
        pathname: '/list/audiobook',
        search: `?${createSearchParams({ filter: 'all', sort: 'Date' })}`
      };
    }

    if (type === 'Course') {
      return `/course/${value}/info`;
    }

    if (type === 'Me') {
      return '/profile/information';
    }

    if (type === 'Subscription') {
      return '/subscription';
    }

    if (type === 'Link') {
      return value;
    }

    return '/';
  };

  // 前往通知網址.
  goToLink = (target) => {
    const navigation = target.notification.navigation;
    const link = this.getAnnouncementLink(navigation);

    // 關閉 menu.
    this.menuVM.handlerCloseMenu();

    if (navigation.type === 'Link') {
      window.open(link);
    } else if (navigation.type === 'Home') {
      // 跳轉至 home.
      this.router.navigate(link);
      // 重新整理.
      window.location.reload();
    } else {
      this.router.navigate(link);
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 通知全部已讀.
  @action
  onClickAllRead = () => {
    if (!this.isAwaitReadAll) {
      this.isAwaitReadAll = true;
    }
  };

  // 通知點擊.
  onClickNotification = async (id) => {
    await this.handlerReadAPI(id);
  }

  // 點擊公告.
  @action
  onClickAnnouncement = async (id) => {
    await this.handlerAnnouncementDataAPI(id);
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取通知列表.
  @action
  handlerNotificationsListAPI = async () => {
    if (this.noAnchor !== null && !this.isAwaitNotifications) {
      this.isAwaitNotifications = true;

      try {
        const res = await MessageService.getNotificationsList({
          id: this.profile.id,
          limit: this.limit,
          anchor: this.noAnchor
        });

        runInAction(() => {
          // 紀錄 anchor.
          this.noAnchor = res.anchor;
          // 通知未讀數量.
          if (res.totalUnreadCount !== null) {
            this.totalUnreadCount = res.totalUnreadCount;
          }
          // 通知 list.
          this.notifications = [...this.notifications, ...res.list];
          // 異步結束.
          this.isAwaitNotifications = false;
        });
      } catch (error) {
        runInAction(() => {
          MessageError.getNotificationsList(error);
          this.isAwaitNotifications = false;
        });
      }
    } else {
      // ErrorToast('沒有通知了!');
    }
  };

  // 通知已讀.
  handlerReadAPI = async (id) => {
    const target = this.notifications.filter((item) => item.id === id)[0];

    // 已經讀取過.
    if (target.readAt) {
      // 跳轉頁面.
      this.goToLink(target);
    } else {
      try {
        const res = await MessageService.putNotification({
          id: this.profile.id,
          nid: id
        });

        runInAction(() => {
          // 更新通知列表.
          this.notifications = this.notifications.map((item) => {
            return item.id === id ? { ...item, readAt: true } : item;
          });

          // 跳轉頁面.
          this.goToLink(target);

          // 更新總數.
          this.totalUnreadCount = res.totalUnreadCount;
        });
      } catch (error) {
        runInAction(() => {
          MessageError.putNotification(error);
        });
      }
    }
  };

  // 所有通知已讀.
  @action
  handlerAllReadAPI = async () => {
    if (!this.isAwaitReadAll) {
      this.isAwaitReadAll = true;

      try {
        const res = await MessageService.putNotificationAllRead({
          id: this.profile.id
        });

        runInAction(() => {
          this.notifications = this.notifications.map((item) => {
            return { ...item, readAt: true };
          });

          this.totalUnreadCount = 0;

          this.isAwaitReadAll = false;
        });
      } catch (error) {
        runInAction(() => {
          MessageError.putNotificationAllRead(error);
          this.isAwaitReadAll = false;
        });
      }
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取公告列表.
  @action
  handlerAnnouncementsListAPI = async () => {
    // 還有清單可以讀取.
    if (this.anAnchor !== null && !this.isAwaitAnnouncements) {
      this.isAwaitAnnouncements = true;

      try {
        const res = await MessageService.getAnnouncementsList({
          limit: this.limit,
          anchor: this.anAnchor
        });

        runInAction(() => {
          this.anAnchor = res.anchor;
          this.announcements = [...this.announcements, ...res.list];

          // 異步結束.
          this.isAwaitAnnouncements = false;
        });
      } catch (error) {
        runInAction(() => {
          MessageError.getAnnouncementsList(error);
          this.isAwaitAnnouncements = false;
        });
      }
    } else {
      // ErrorToast('沒有公告了!');
    }
  };

  // 讀取公告詳情
  handlerAnnouncementDataAPI = async (id) => {
    try {
      const { content, title } = await MessageService.getAnnouncement({
        id
      });

      runInAction(() => {
        // 更新標題
        this.openAnnounceTitle = title;
        // 更新編輯器的內容.
        this.quillViewModel.setQuillContent(content);
        // 打開 alert.
        this.handlerOpenAlert();
      });
    } catch (error) {
      MessageError.getAnnouncement(error);
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
}

export default MessagesViewModel;
