import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';
import CommonError from './common';

export default class CourseError {
  static getCoursesByType(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_course_list_4xx'));
        break;
    }
  }

  static getCourseDetail(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 403:
        ErrorToast(i18n.t('api_course_detail_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_course_detail_404'));
        break;
      default:
        ErrorToast(i18n.t('api_course_detail_4xx'));
        break;
    }
  }

  static getCourseDetailForPurchase(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        ErrorToast(i18n.t('api_course_detail_404'));
        break;
      default:
        ErrorToast(i18n.t('api_course_detail_4xx'));
        break;
    }
  }

  static getCourseRating(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_course_rating_4xx'));
        break;
    }
  }

  static getMyRating(error) {
    switch (error.response?.status) {
      case 401:
        break;
      // > prevent error.
      case 404:
        break;
      default:
        ErrorToast(i18n.t('api_course_my_rating_4xx'));
        break;
    }
  }

  static postRating(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_course_rating_add_4xx'));
        break;
    }
  }

  static putRating(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_course_rating_edit_4xx'));
        break;
    }
  }

  static changeIsFavorite(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        ErrorToast(i18n.t('api_course_favorite_404'));
        break;
      default:
        ErrorToast(i18n.t('api_course_favorite_4xx'));
        break;
    }
  }

  static addToAlreadyLearned(error) {
    switch (error.response?.status) {
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }
}
