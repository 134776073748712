import React from 'react';
import clsx from 'clsx';

import { Link } from 'react-router-dom';
import i18n from 'src/i18n';

import styles from './styles.module.scss';

class SubscriptionSection extends React.Component {
  render() {
    return (
      <Link to="/subscription" className={`banner ${clsx(styles.subscriptionSection, styles.subscriptionSection_layout)}`}>
        <div className={clsx(styles.joinBtn, styles.joinBtn_layout)}>
          <h2 className={clsx(styles.medium_title, styles.medium_title_layout)}>
            {i18n.t('entrance_subscription_cta_free7')}
          </h2>
        </div>
      </Link>
    );
  }
}

export default SubscriptionSection;
