import { action, observable, computed, makeObservable } from 'mobx';
import i18n from 'src/i18n';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import signInViewModel from 'src/components/Header/component/SignIn/viewModel';
import { SuccessToast } from 'src/libs/toast';
import screenfull from 'screenfull';
import playerStore from '../../store';
import PlayerAPIs from '../../apis';
import playerViewModel from '../../viewModel';

dayjs.extend(duration);

class ControlsViewModel {
  @observable isMoreBtnOpen = false;

  constructor(props) {
    this.playerAPIs = new PlayerAPIs({ emitter: props.emitter });
    this.emitter = props.emitter;
    makeObservable(this);
  }

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 當前秒數.
  @computed
  get calcCurrentTime() {
    return dayjs
      .duration(playerStore.calcCurrentTime, 'seconds')
      .format('mm:ss');
  }

  // 影片長度.
  @computed
  get calcDuration() {
    const value = playerStore.chapter?.ceilDuration || 0;
    return dayjs.duration(value, 'seconds').format('mm:ss');
  }

  // 當前單集是 video.
  @computed
  get isNotVideo() {
    return playerStore.chapter?.type !== 'Video';
  }

  // 文稿頁 link.
  @computed
  get chapterManuscriptLink() {
    return `/course/${playerStore.course?.id}/chapter/${playerStore.chapter?.id}`;
  }

  @computed
  get courseInfoLink() {
    return `/course/${playerStore.course?.id}/info}`;
  }

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  @action
  didMount = (isOuter) => {
    if (isOuter) {
      window.addEventListener('keydown', this.keyboardEventListener);
      this.isListenerOn = true;
    }
  }

  @action
  willUnmount = () => {
    window.removeEventListener('keydown', this.keyboardEventListener);
  }

  // 關閉播放器.
  @action
  onPlayerClose = () => {
    if (playerStore.isPlayerOpen) {
      // 關閉全螢幕.
      if (playerStore.isScreenfull) {
        playerStore.isScreenfull = false;
        screenfull.exit(playerStore.mediaContainerRef.current);
      }

      if (playerStore.isRegisteredFullScreen) {
        const player = playerStore.mediaRef.current.getInternalPlayer();
        player.removeEventListener('webkitpresentationmodechanged', playerViewModel.stopPrevent);
        playerStore.isRegisteredFullScreen = false;
      }

      playerStore.isPlayerOpen = false;
      playerStore.isInit = false;
      this.playerAPIs.handlerLearnedLastAPI();

      this.emitter.emit('closePlayer');

      setTimeout(() => {
        // store 狀態重置.
        playerStore.resetStates();
        this.toggleOverflow();
        // 清除排程.
        this.playerAPIs.clearIntervals();
      }, 300);
    }
  };

  // 展開播放器大螢幕.
  @action
  onSetBigScreen = () => {
    if (playerStore.isScreenfull) {
      this.onSetScreenfull();
      this.toggleOverflow();
      return;
    }
    playerStore.isBigScreen = !playerStore.isBigScreen;
    this.toggleOverflow();
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 全螢幕切換.
  @action
  onSetScreenfull = () => {
    if (screenfull.isEnabled && playerStore.isBigScreen) {
      // 紀錄全螢幕狀態.
      playerStore.isScreenfull = !playerStore.isScreenfull;

      // 切換全螢幕.
      if (playerStore.isScreenfull) {
        // 打開全螢幕.
        screenfull.request(playerStore.mediaContainerRef.current);
      } else {
        // 關閉全螢幕.
        screenfull.exit(playerStore.mediaContainerRef.current);
      }
    }
  };

  // NOTE: on manuscript btn click for < 768.
  onManuscriptClick = () => {
    if (playerStore.isBigScreen) {
      playerStore.isBigScreen = false;
    }

    this.toggleOverflow();
  }

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 快轉 15 秒.
  @action
  onCurrentTimeForward = async () => {
    const currentTime = playerStore.currentTime + playerStore.jump > playerStore.duration ? playerStore.duration : playerStore.currentTime + playerStore.jump;
    playerStore.currentTime = currentTime;
    playerStore.mediaRef.current.seekTo(currentTime, 'seconds');

    await this.playerAPIs.handlerLearnedLastAPI();
  };

  // 後退 15 秒.
  @action
  onCurrentTimeBackward = async () => {
    const currentTime = playerStore.currentTime - playerStore.jump > 0 ? playerStore.currentTime - playerStore.jump : 0;
    playerStore.currentTime = currentTime;
    playerStore.mediaRef.current.seekTo(currentTime, 'seconds');

    await this.playerAPIs.handlerLearnedLastAPI();
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------


  // 設置播放速度, 播放速度的選單事件.
  @action
  setSpeed = (event) => {
    playerStore.speed = Number(event.target.value);
  };

  // 設置靜音.
  @action
  setMuted = () => {
    playerStore.isMuted = !playerStore.isMuted;

    if (playerStore.isMuted) {
      playerStore.volume = 0;
    } else {
      playerStore.volume = 0.5;
    }
  };

  // 設置音量, 音量的拖放事件.
  @action
  onChangeVolume = (value) => {
    playerStore.volume = value;

    // 簡單版本.
    if (value === 0) {
      playerStore.isMuted = true;
    } else {
      playerStore.isMuted = false;
    }
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 影片播放, 影片播放按鈕的事件.
  @action
  onPlay = async () => {
    // 當前是否暫停.
    playerStore.isPaused = false;
    // 紀錄影片當前秒數.

    // 開啟排程.
    this.playerAPIs.setIntervals();
    // call api.
    await this.playerAPIs.handlerLearnedLastAPI();
    await this.playerAPIs.handlerLearnedHistoryAPI();
  };

  // 影片暫停, 影片暫停按鈕的事件.
  @action
  onStop = async () => {
    // 紀錄暫停.
    playerStore.isPaused = true;
    // 紀錄影片當前秒數.
    playerStore.currentTime = playerStore.mediaRef.current.getCurrentTime();

    // 清除排程.
    this.playerAPIs.clearIntervals();
    // call api.
    await this.playerAPIs.handlerLearnedLastAPI();
    await this.playerAPIs.handlerLearnedHistoryAPI();
  };

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  // 配合 react-input-range 使用, 拖放時.
  @action
  onChangeTime = (value) => {
    playerStore.currentTime = value;
    playerStore.mediaRef.current.seekTo(value, 'seconds');
  };

  @action
  onChangeStart = () => {
    playerStore.isPaused = true;
  }

  @action
  onChangeComplete = () => {
    playerStore.isPaused = false;
  }

  toggleOverflow() {
    const doc = document.documentElement;
    const overflow = playerStore.isBigScreen ? 'hidden' : 'auto';


    doc.style.overflow = overflow;
  }

  keyboardEventListener = (event) => {
    if (event.code === 'Space') {
      if (!playerStore.isBigScreen) {
        return;
      }

      if (playerStore.isPaused) {
        this.onPlay();
        return;
      }
      this.onStop();
    }

    if (event.code === 'Escape') {
      if (!playerStore.isBigScreen) {
        this.onPlayerClose();
        return;
      }
      this.onSetBigScreen();
    }
  }

  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  @action
  onFavoriteClick = async (id, emitter) => {
    if (!id) {
      SuccessToast(i18n.t('common_login_unlock'));
      if (!playerStore.isPaused) {
        this.onStop();
      }
      setTimeout(() => {
        signInViewModel.handleOpen();
      }, 500);
      return;
    }

    await this.playerAPIs.handlerFavoriteAPI({
      id
    });

    emitter.emit('listFavorite', { id: playerStore.chapter.id, isFavorite: playerStore.chapter.isFavorite });
  }
}

export default ControlsViewModel;
