import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import i18n from 'src/i18n';

import AlertView from '../Alert';
import logoutAlertViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class LogoutAlert extends React.Component {
  render() {
    return (
      <AlertView
        vm={logoutAlertViewModel}
        className={styles.zIndex}
        showClose={false}
        showButtons={false}
        contentContainerStyles={styles.container}
      >
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>
            {i18n.t('modal_error')}
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.contentText}>
            {logoutAlertViewModel.isForbidden ? i18n.t('api_common_403') : i18n.t('api_common_401_web')}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <div
            className={styles.button}
            onClick={logoutAlertViewModel.onLinkClick}
          >
            我知道了
          </div>
        </div>
      </AlertView>
    );
  }
}

LogoutAlert.propTypes = {
};

LogoutAlert.defaultProps = {
};


export default LogoutAlert;
