import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import dayjs from 'dayjs';
import i18n from 'src/i18n';

import signInViewModel from 'src/components/Header/component/SignIn/viewModel';
import CourseService from 'src/services/course';
import CourseError from 'src/services/error/models/course';

import { SuccessToast, ErrorToast } from 'src/libs/toast';

export default class ExposeCardViewModel {
  @observable id;
  @observable thumbnail;
  @observable title;
  @observable lector;
  @observable type;
  @observable category;
  @observable lastUpdatedAt;
  @observable popularity;
  @observable top;
  @observable isFavorite;
  @observable isPurchaseOnly;
  @observable isRating;
  @observable isOwned;
  @observable total = 0;
  @observable learned = 0;

  @observable isProcessing = false;

  // > 24/05/02 new
  @observable isDiscount = false

  constructor(data) {
    makeObservable(this);

    this.deserialize(data);
  }

  @action
  deserialize = (data) => {
    const {
      id,
      thumbnail,
      name,
      authorName,
      type,
      category,
      lastUpdatedAt,
      popularity,
      top,
      isFavorite,
      saleType,
      // NOTE: for card in learned.
      isOwned,
      isRating,
      total,
      learned,
      isNew,
      isDiscount
      // end
    } = data;
    this.id = id;
    this.thumbnail = thumbnail;
    this.title = name;
    this.lector = authorName;
    this.type = type;
    this.category = category;
    this.lastUpdatedAt = lastUpdatedAt;
    this.popularity = popularity;
    this.top = top;
    this.isFavorite = isFavorite;
    this.isPurchaseOnly = saleType === 'Single';
    this.isNew = isNew;

    this.isOwned = isOwned;
    this.isRating = isRating;
    this.learned = learned;
    this.total = total;
    this.isDiscount = isDiscount;
  }

  @action
  changeIsFavorite = async (e, uid, syncFavorite, section) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (!uid) {
        SuccessToast(i18n.t('common_login_unlock'));
        setTimeout(() => {
          signInViewModel.handleOpen();
        }, 500);
        return;
      }
      this.isProcessing = true;
      const res = await CourseService.changeIsFavorite(uid, this.id);
      runInAction(() => {
        this.isFavorite = res.data.isFavorite;
        if (this.isFavorite) {
          SuccessToast(i18n.t('course_favorite_add'));
        } else {
          ErrorToast(i18n.t('course_favorite_remove'));
        }
        syncFavorite(this.id, res.data.isFavorite, this.type, section);
        this.isProcessing = false;
      });
    } catch (error) {
      CourseError.changeIsFavorite(error);
      this.isProcessing = false;
    }
  }

  @computed
  get isLatest() {
    const today = dayjs();
    const lastUpdateTime = dayjs(this.lastUpdatedAt);
    return today.diff(lastUpdateTime, 'day') <= 14;
  }

  @computed
  get learnedProgressText() {
    if (this.learned === 0) {
      return i18n.t('tag_not_yet_learn');
    }

    if (this.learned === this.total) {
      return i18n.t('tag_finished');
    }

    return `${i18n.t('tag_learned')} ${Math.ceil((this.learned / this.total) * 100)}%`;
  }
}
