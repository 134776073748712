export default class MyLearned {
  constructor(data) {
    const {
      total,
      learned,
      course
    } = data;

    const {
      id: courseId,
      type,
      isFavorite,
      // NOTE: confirm.
      isRating,
      isOwned,
      name,
      saleType,
      author,
      thumbnails
    } = course;

    this.id = courseId;

    this.total = total;
    this.learned = learned;
    this.isRating = isRating;
    this.isOwned = isOwned;

    this.name = name;
    this.type = type;
    this.thumbnail = thumbnails.web.url;

    this.authorName = author.name;
    this.isFavorite = isFavorite;
    this.saleType = saleType;
  }

  static fromRes(data) {
    return new MyLearned(data);
  }
}
