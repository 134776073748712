import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { createSearchParams, Link } from 'react-router-dom';
import i18n from 'src/i18n';

import ExposeCard from 'src/components/CourseCards/ExposeCard';
import { COURSE_CATEGORY_TW } from 'src/constants';

import VideoIcon from 'src/assets/homepage/sectionIconVideo.svg';
import AudioIcon from 'src/assets/homepage/sectionIconAudio.svg';
import AudiobookIcon from 'src/assets/homepage/sectionIconAudiobook.svg';

import ArrowRight from 'src/assets/homepage/arrowRight.svg';

import CarouselLeft from 'src/assets/homepage/carouselLeft.svg';
import CarouselRight from 'src/assets/homepage/carouselRight.svg';

import exposeSectionStyles from './styles.module.scss';
import 'swiper/css';
import './customizeCarousel.css';


@observer
class ExposeSection extends React.Component {
  constructor(props) {
    super(props);
  }

  _renderIcon = (type) => {
    switch (type) {
      case 'video':
        return VideoIcon;
      case 'audio':
        return AudioIcon;
      case 'audiobook':
        return AudiobookIcon;
      default:
        return null;
    }
  }

  render() {
    const { viewModel, syncFavorite, browser } = this.props;
    return (
      <section
        className={clsx(
          'expose',
          `${viewModel.type}Expose`,
          exposeSectionStyles.exposeSection,
          exposeSectionStyles.exposeSection_layout,
          viewModel.type === 'video' && exposeSectionStyles.videoBg
        )}
      >
        <div
          className={clsx(
            exposeSectionStyles.exposeContent,
            exposeSectionStyles.exposeContent_layout
          )}
        >
          <div
            className={clsx(
              exposeSectionStyles.exposeFlexBox,
              exposeSectionStyles.exposeFlexBox_layout
            )}
          >
            <div
              className={clsx(
                exposeSectionStyles.exposeTitle,
                exposeSectionStyles.exposeTitle_layout
              )}
            >
              <div className={clsx(exposeSectionStyles.exposeTitle_item)}>
                <div
                  className={clsx(
                    exposeSectionStyles.titleFlex,
                    exposeSectionStyles.titleFlex_layout
                  )}
                >
                  <div className={clsx(exposeSectionStyles.titleFlex_item)}>
                    <div
                      style={{ '--src': `url(${this._renderIcon(viewModel.type)})` }}
                      className={clsx(
                        exposeSectionStyles.typeIcon,
                        exposeSectionStyles.typeIcon_layout
                      )}
                    />
                  </div>
                  <div className={clsx(exposeSectionStyles.titleFlex_spacer)} />
                  <div className={clsx(exposeSectionStyles.titleFlex_item1)}>
                    <h1
                      className={clsx(
                        exposeSectionStyles.typeTitle,
                        exposeSectionStyles.typeTitle_layout
                      )}
                    >
                      {COURSE_CATEGORY_TW[viewModel.type]}
                    </h1>
                  </div>
                </div>
              </div>
              <div className={clsx(exposeSectionStyles.exposeTitle_spacer)} />
              <div className={clsx(exposeSectionStyles.exposeTitle_item1)}>
                <Link
                  to={{
                    pathname: `/list/${viewModel.type}`,
                    search: `?${createSearchParams({ filter: viewModel.selectedTab, sort: 'Date' })}`
                  }}
                  className={clsx(
                    exposeSectionStyles.moreBtn,
                    exposeSectionStyles.moreBtn_layout
                  )}
                >
                  <h5
                    className={clsx(
                      exposeSectionStyles.moreText,
                      exposeSectionStyles.moreText_layout
                    )}
                  >
                    {i18n.t('btn_see_more')}
                  </h5>
                  <div className={clsx(exposeSectionStyles.moreBtn_spacer)} />
                  <div className={clsx(exposeSectionStyles.moreBtn_item)}>
                    <div
                      style={{
                        '--src': `url(${ArrowRight})`
                      }}
                      className={clsx(
                        exposeSectionStyles.arrowIcon,
                        exposeSectionStyles.arrowIcon_layout
                      )}
                    />
                  </div>
                </Link>
              </div>
            </div>

            <div className={clsx(exposeSectionStyles.exposeFlexBox_item)}>
              <div
                className={clsx(
                  exposeSectionStyles.carouselContainer,
                  exposeSectionStyles.carouselContainer_layout
                )}
              >
                <div
                  className={clsx(
                    exposeSectionStyles.carouselWrapper,
                    exposeSectionStyles.carouselWrapper_layout
                  )}
                >
                  <div
                    className={clsx(
                      exposeSectionStyles.carousel,
                      exposeSectionStyles.carousel_layout
                    )}
                  >
                    <Swiper
                      style={{ minWidth: 0, width: '100%' }}
                      direction="horizontal"
                      onSwiper={viewModel.onSwiper}
                      slidesPerView={4}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: `.slidePre${viewModel.type}`,
                        nextEl: `.slideNext${viewModel.type}`
                      }}
                      cssMode={browser !== 'safari'}
                      breakpoints={{
                        0: { slidesPerView: 1 },
                        384: { slidesPerView: 1 },
                        480: { slidesPerView: 1 },
                        576: { slidesPerView: 2 },
                        768: { slidesPerView: 3 },
                        992: { slidesPerView: 4 },
                        1200: { slidesPerView: 4 },
                        1400: { slidesPerView: 4 },
                        1920: { slidesPerView: 4 }
                      }}
                    >
                      {
                        viewModel.filteredCardList?.map((card) => {
                          return (
                            <SwiperSlide
                              key={card.id}
                            >
                              <ExposeCard
                                viewModel={card}
                                syncFavorite={syncFavorite}
                                section="Exposed"
                              />
                            </SwiperSlide>
                          );
                        })
                      }
                    </Swiper>
                  </div>
                </div>
                <div
                  className={clsx(`slidePre${viewModel.type}`, exposeSectionStyles.leftArrow)}
                  style={{ '--src': `url(${CarouselLeft})` }}
                />

                <div
                  className={clsx(`slideNext${viewModel.type}`, exposeSectionStyles.rightArrow)}
                  style={{ '--src': `url(${CarouselRight})` }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ExposeSection.propTypes = {
  viewModel: PropTypes.object,
  syncFavorite: PropTypes.func,
  browser: PropTypes.string
};

ExposeSection.defaultProps = {
  viewModel: {},
  syncFavorite: () => { },
  browser: ''
};

export default ExposeSection;
