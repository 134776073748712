import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import TrophyIcon from 'src/assets/homepage/trophyIcon.svg';
import DummyBookList from 'src/assets/dummyBookList.png';

import LearnedType from './LearnedType';

export default class Home {
  constructor(data, bookList) {
    this.init(data, bookList);
  }

  init = (data, bookList) => {
    const {
      popup,
      banners,
      category,
      learned,
      videos,
      audios,
      audiobooks,
      rank,
      recommends
    } = data;
    this.popup = popup ? this.deserializePopup(popup) : null;
    this.banner = this.deserializeBanner(banners);
    this.category = category;
    this.learned = this.deserializeLearned(learned);
    this.videos = this.deserializeCourse(videos, category);
    this.audios = this.deserializeCourse(audios, category);
    this.audiobooks = this.deserializeCourse(audiobooks, category);
    this.rank = this.deserializeRank(rank);
    this.recommends = this.deserializeRecommend(recommends);
    this.modules = this.deserializeModule(bookList);
  };

  // 彈屏.
  deserializePopup = (data) => {
    return {
      createdAt: data.createdAt,

      id: data.id,

      isActive: data.id,

      navigation: {
        type: data.navigation.type,

        value: data.navigation.value
      },

      thumbnails: {
        app: {
          url: data.thumbnails?.app.url,
          width: data.thumbnails?.app.width,
          height: data.thumbnails?.app.url
        },

        web: {
          url: data.thumbnails?.web.url,
          width: data.thumbnails?.web.width,
          height: data.thumbnails?.web.url
        }
      },

      updatedAt: data.updatedAt
    };
  };

  deserializeBanner = (data) => {
    const res = data?.map((el) => {
      const { id, title, thumbnails, navigation } = el;

      return {
        id,
        title,
        thumbnail: thumbnails.web.url,
        navigationType: navigation.type,
        navigationValue: navigation.value,
        navigationTarget: navigation.target
      };
    });
    return res;
  };

  deserializeLearned = (data) => {
    return data?.map((el) => LearnedType.fromRes(el)) ?? [];
  };

  deserializeCourse = (data, category) => {
    const res = data?.map((el) => {
      const {
        id,
        type,
        name,
        thumbnails,
        author,
        isFavorite,
        releaseAt,
        lastUpdatedAt,
        top,
        popularity,
        saleType,
        discount
      } = el.course;

      const { startAt, endAt } = discount ?? {};

      return {
        id,
        type,
        name,
        thumbnail: thumbnails.web.url,
        authorName: author.name,
        isFavorite,
        lastUpdatedAt: Math.max(releaseAt, lastUpdatedAt),
        top,
        popularity,
        saleType,
        isDiscount: startAt ? dayjs().valueOf() > startAt && dayjs().valueOf() < endAt : false
      };
    });
    return { list: res, tabs: category };
  };

  deserializeRank = (data) => {
    const { videos, audios, audiobooks } = data;
    const rankVideos = videos?.map((el) => {
      const {
        id,
        type,
        name,
        thumbnails,
        author,
        isFavorite,
        releaseAt,
        top,
        popularity,
        saleType
      } = el;

      return {
        id,
        type,
        name,
        thumbnail: thumbnails.web.url,
        authorName: author.name,
        isFavorite,
        releaseAt,
        top,
        popularity,
        saleType
      };
    });

    const rankAudios = audios?.map((el) => {
      const {
        id,
        type,
        name,
        thumbnails,
        author,
        isFavorite,
        releaseAt,
        top,
        popularity,
        saleType
      } = el;

      return {
        id,
        type,
        name,
        thumbnail: thumbnails.web.url,
        authorName: author.name,
        isFavorite,
        releaseAt,
        top,
        popularity,
        saleType
      };
    });

    const rankAudiobooks = audiobooks?.map((el) => {
      const {
        id,
        type,
        name,
        thumbnails,
        author,
        isFavorite,
        releaseAt,
        top,
        popularity,
        saleType
      } = el;

      return {
        id,
        type,
        name,
        thumbnail: thumbnails.web.url,
        authorName: author.name,
        isFavorite,
        releaseAt,
        top,
        popularity,
        saleType
      };
    });

    return {
      videos: rankVideos,
      audios: rankAudios,
      audiobooks: rankAudiobooks
    };
  };

  deserializeRecommend = (data) => {
    const res = data?.map((el) => {
      const { id, cid, name, course, popularity, type, createdAt } = el;

      const isNew = (() => {
        const today = dayjs().startOf('day');
        const createdDate = dayjs(createdAt);

        return today.diff(createdDate, 'day') <= 14;
      })();

      const isPurchaseOnly = (() => {
        return course.saleType === 'Single';
      })();

      return {
        id,
        name,
        courseId: cid,
        // FIXME: author data
        authorName: course?.author.name,
        authorAvatar: course?.author.avatar,
        popularity,
        type,
        // FIXME: course type
        isNew,
        courseType: course?.type,
        isPurchaseOnly
      };
    });
    return res;
  };

  deserializeModule = (modules) => {
    return modules.filter((el) => el.customList.length >= 1).filter((el) => el.customList.some((item) => item.courses.length >= 1)).map((el) => ({
      id: el.id,
      title: el.title,
      icon: el.icon.image.url,
      list: el.customList.filter((list) => list.courses.length >= 1).map((list) => ({
        id: list.id,
        title: list.name,
        tag: list.tag,
        subtitle: list.subTitle,
        thumbnail: list.thumbnails.web.url
      }))
    }));
  }

  static fromRes(data, bookList) {
    return new Home(data, bookList);
  }
}
