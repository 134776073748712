export default class PlayerCourse {
  static fromRes(data) {
    return new PlayerCourse(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    // 課程 id.
    this.id = data.id;
    // 課程類型.
    this.type = data.type;
    // 課程名稱.
    this.name = data.name;
    // 課程縮圖.
    this.thumbnails = {
      app: {
        url: data.thumbnails.app.url,
        width: data.thumbnails.app.width,
        height: data.thumbnails.app.height
      },

      web: {
        url: data.thumbnails.web.url,
        width: data.thumbnails.web.width,
        height: data.thumbnails.web.height
      }
    };

    // 小標題?
    this.subtitle = data.subtitle;

    // 等待補充
    this.summary = data.summary;
    // 等待補充
    this.introduction = data.introduction;
    // 等待補充
    this.isAvailable = data.isAvailable;

    // 課程作者.
    this.author = {
      id: data.author?.id,
      name: data.author?.name,
      avatar: data.author?.avatar,
      description: data.author?.description
    };

    // 等待補充.
    this.narrator = {
      id: data.narrator?.id,
      name: data.narrator?.name,
      avatar: data.narrator?.avatar,
      description: data.narrator?.description
    };

    // 等待補充.
    this.vendor = {
      id: data.vendor?.id,
      name: data.vendor?.name,
      avatar: data.vendor?.avatar,
      description: data.vendor?.description
    };

    // 等待補充.
    this.category = {
      id: data.category.id,
      name: data.category.name,
      order: data.category.order
    };

    // 標籤.
    this.tags = data.tags.map((item) => {
      return {
        id: item.id,
        name: item.name
      };
    });

    // 等待補充.
    this.notice = {
      id: data.notice.id,
      title: data.notice.title,
      description: data.notice.description
    };

    // 是否是訂閱的類型.
    this.saleType = data.saleType;

    // 等待補充.
    this.price = data.price;

    // 等待補充.
    this.discount = {
      price: data.discount?.price,
      startAt: data.discount?.startAt,
      endAt: data.discount?.endAt
    };

    // 等待補充.
    this.profitRatio = data.profitRatio;
    // 等待補充.
    this.profitRemark = data.profitRemark;

    // 課程集數?
    this.limit = data.limit;

    // 等待補充.
    this.releaseAt = data.releaseAt;
    // 等待補充.
    this.expiredAt = data.expiredAt;
    // 等待補充.
    this.top = data.top;
    // 等待補充.
    this.rank = data.rank;
    // 等待補充.
    this.popularity = data.popularity;
    // 等待補充.
    this.duration = data.duration;

    // 是否收藏.
    this.isFavorite = data.isFavorite;

    // 是否購買.
    this.isOwned = data.isOwned;
  }
}
