import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import i18n from 'src/i18n';

import CloseBtn from 'src/assets/account/closeBtn.svg';
import SuccessIcon from 'src/assets/account/successIcon.svg';
import BackIcon from 'src/assets/account/back.svg';

import EmailAuthViewModel from './viewModel';
import styles from './style.module.scss';


@observer
class EmailAuthView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new EmailAuthViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentWillUnmount() {
    this.vm.unMount();
  }

  render() {
    const { saveProfile, handleBack, handlerCloseAlert } = this.props;
    const { email } = saveProfile;
    return (
      <div className={`learnhistory ${clsx(styles.emailVerifyPage, styles.emailVerifyPage_layout)}`}>
        <div className={clsx(styles.contentFlex, styles.contentFlex_layout1)}>
          <div className={styles.flexBox}>
            <div
              style={{ '--src': `url(${BackIcon})` }}
              className={clsx(styles.closeBtn, styles.closeBtn_layout)}
              onClick={handleBack}
            />
            <div
              style={{ '--src': `url(${CloseBtn})` }}
              className={clsx(styles.closeBtn, styles.closeBtn_layout)}
              onClick={handlerCloseAlert}
            />
          </div>

          <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
            <div style={{ '--src': `url(${SuccessIcon})` }} className={clsx(styles.successIcon, styles.successIcon_layout)} />
            <div className={clsx(styles.contentFlex_spacer)} />
            <h2 className={clsx(styles.verifyTitle, styles.verifyTitle_layout)}>
              {i18n.t('signup_verified_note')}
            </h2>
            <div className={clsx(styles.contentFlex_spacer1)} />
            <div className={clsx(styles.contentFlex_item2)}>
              <h5 className={clsx(styles.verifyDescription, styles.verifyDescription_layout)}>
                {i18n.t('signup_verified_content_head')}
              </h5>
              <h5 className={clsx(styles.verifyDescription, styles.verifyDescription_layout)}>
                {email}
              </h5>
              <h5 className={clsx(styles.verifyDescription, styles.verifyDescription_layout)}>
                {i18n.t('signup_verified_content_end')}
              </h5>
            </div>
            <div className={clsx(styles.contentFlex_spacer2)} />
            {
              (this.vm.reciprocal > 0)
                ? (
                  <div className={clsx(styles.contentFlex_item)}>
                    <div className={clsx(styles.resendBtn, styles.resendBtn_layout)}>
                      <h5 className={clsx(styles.resendText, styles.resendText_layout)}>
                        {`${i18n.t('signup_verified_btn_resend_count')}(${this.vm.reciprocal})`}
                      </h5>
                    </div>
                  </div>
                )
                : (
                  <div
                    className={clsx(styles.contentFlex_item)}
                    onClick={this.vm.handlerSubmit}
                  >
                    <div className={clsx(styles.resendBtn, styles.resendBtn_layout, styles.resendBtnActive)}>
                      <h5 className={clsx(styles.resendText, styles.resendText_layout)}>
                        {i18n.t('signup_verified_btn_resend_count')}
                      </h5>
                    </div>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    );
  }
}

EmailAuthView.propTypes = {
  saveProfile: PropTypes.oneOfType([PropTypes.object, PropTypes.any])
};

EmailAuthView.defaultProps = {
  saveProfile: {}
};

export default EmailAuthView;
