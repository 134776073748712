import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';
import CommonError from './common';

export default class UserError {
  static updateProfile(error) {
    switch (error.response?.status) {
      case 400:
        ErrorToast(i18n.t('api_user_edit_profile_400'));
        break;
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        ErrorToast(i18n.t('api_user_edit_profile_404'));
        break;
      default:
        ErrorToast(i18n.t('api_user_edit_profile_4xx'));
        break;
    }
  }

  static getCoupon(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_user_get_coupon_list_4xx'));
        break;
    }
  }

  static postCoupon(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 403:
        ErrorToast(i18n.t('api_add_coupon_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_add_coupon_404'));
        break;
      case 409:
        ErrorToast(i18n.t('api_add_coupon_409'));
        break;
      case 412:
        ErrorToast(i18n.t('api_add_coupon_412'));
        break;
      default:
        ErrorToast(i18n.t('api_add_coupon_4xx'));
        break;
    }
  }

  static getRedeem(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_redeem_4xx'));
        break;
    }
  }

  static postRedeem(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 403:
        ErrorToast(i18n.t('api_use_redeem_403'));
        break;
      case 404:
        ErrorToast(i18n.t('api_use_redeem_404'));
        break;
      case 409:
        ErrorToast(i18n.t('api_use_redeem_409'));
        break;
      case 410:
        ErrorToast(i18n.t('api_use_redeem_410'));
        break;
      case 412:
        ErrorToast(i18n.t('api_use_redeem_412'));
        break;
      default:
        ErrorToast(i18n.t('api_use_redeem_4xx'));
        break;
    }
  }

  static getPurchaseList(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_course_purchase_history_4xx'));
        break;
    }
  }

  static getPurchaseItem(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        ErrorToast(i18n.t('api_course_purchase_detail_404'));
        break;
      default:
        ErrorToast(i18n.t('api_course_purchase_detail_4xx'));
        break;
    }
  }

  static getPointHistory(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_point_purchase_history_4xx'));
        break;
    }
  }

  static getLearnedStatus(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_learned_status_4xx'));
        break;
    }
  }

  static getLearnedHistory(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_learned_history_4xx'));
        break;
    }
  }

  static getTag(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        CommonError.defaultError(error.response?.status);
        break;
    }
  }

  static getUserTag(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        ErrorToast(i18n.t('api_user_tags_404'));
        break;
      default:
        ErrorToast(i18n.t('api_user_tags_4xx'));
        break;
    }
  }

  static putUserTag(error) {
    switch (error.response?.status) {
      case 400:
        ErrorToast(i18n.t('api_user_edit_tags_400'));
        break;
      case 401:
        CommonError.default401Error();
        break;
      case 404:
        ErrorToast(i18n.t('api_user_edit_tags_404'));
        break;
      default:
        ErrorToast(i18n.t('api_user_edit_tags_4xx'));
        break;
    }
  }

  static getLearnedType(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_learned_course_4xx'));
        break;
    }
  }

  static getFavoriteCourse(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_favorite_course_list_4xx'));
        break;
    }
  }

  static getFavoriteChapter(error) {
    switch (error.response?.status) {
      case 401:
        CommonError.default401Error();
        break;
      default:
        ErrorToast(i18n.t('api_favorite_chapter_list_4xx'));
        break;
    }
  }
}
