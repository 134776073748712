import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import FAQCard from '../../FAQCard';

import styles from '../../styles.module.scss';
import withFontStyles from '../../withFontStyles.module.scss';

const Subscriptions = (props) => {
  const useStyles = props.isLanding ? withFontStyles : styles;
  return (
    <>
      <FAQCard title="什麼是訂閱會員" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            加入訂閱會員後，將立即開通全站內容服務，可跨裝置在 sandshour 散時間網站和 App 隨點隨看。不限時間、次數學習全站影音課程、音頻課程及有聲書。唯特別標示「限單購」課程則需單獨購買。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="訂閱規則" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            加入任一方案即可成為訂閱會員，會員訂閱可隨時加入，隨時停用。本服務採自動續訂制，後續將會按您設定的付款方案週期進行續訂，直到主動取消訂閱為止。如遇信用卡扣款失敗，無法順利完成扣款時，訂閱方案將會自動取消。方案到期後如無續訂，將無法繼續觀看、下載或收聽內容。
          </div>
        </div>
      </FAQCard>
      <FAQCard title="訂閱會員免費體驗" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            <span className={useStyles.bolder}>
              每個帳號可享 1 次 7 天免費體驗。
            </span>
            申請試用後，可享與有訂閱會員相同的會員權益，於試用期間內，無限次數暢享學習全站非「限單購」之內容。試用期結束時，系統會按月繳方案自動續訂。如果未在試用期間內取消訂閱服務，系統每月會按照當前方案價格收費。
          </div>
          <div className={useStyles.content}>
            <span className={useStyles.bolder}>
              ⚠︎ 免費試用期間無法變更續訂年訂閱方案。你可以在試用期結束轉為月訂閱後，使用升級年訂閱服務，或於試用期結束前取消訂閱再直接加入年訂閱方案。
            </span>
          </div>
        </div>
      </FAQCard>
      <FAQCard title="訂閱管理" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          {
            props.isMobile
              ? (
                <div className={clsx(useStyles.content)}>
                  登入會員帳號後，點擊下方
                  <span className={useStyles.bolder}>
                    「訂閱」
                  </span>
                  圖示，進入訂閱管理頁面，以檢視目前訂閱方案及付款明細紀錄，並可以隨時取消訂閱或更換方案。
                </div>
              )
              : (
                <div className={clsx(useStyles.content)}>
                  登入會員帳號後，至
                  <span className={clsx(useStyles.bolder, useStyles.underline)}>
                    「我的帳戶」→「訂閱管理」
                  </span>
                  ，以檢視目前訂閱方案及付款明細紀錄、綁定扣款之信用卡，並可以隨時取消訂閱或更換方案。
                </div>
              )
          }
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  取消訂閱
          </div>
          <div className={useStyles.content}>
            取消訂閱後，下一個付款週期系統就不會再向您收費。（您仍然可以在剩餘的訂閱期間內享受會員服務）
          </div>
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            ▎  更換訂閱方案
          </div>
          <div className={useStyles.content}>
            可隨時變更訂閱新的方案（請留意原方案到期日）
          </div>
        </div>
      </FAQCard>
      <FAQCard title="查詢訂閱到期日" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          {
            !props.isMobile
              ? (
                <>
                  <div className={useStyles.content}>
                    使用電腦版／手機版網頁瀏覽，請先登入會員帳號，至右上方
                    <span className={clsx(useStyles.bolder, useStyles.underline)}>
                      「我的帳戶」→「訂閱管理」
                    </span>
                    ，查看目前訂閱狀態與到期資訊。
                  </div>
                  <div className={useStyles.content}>
                    若以 App 平台訂閱，請點擊下方
                    <span className={useStyles.bolder}>
                      「訂閱」
                    </span>
                    圖示，進入訂閱管理頁面，以檢視當前方案到期日，與變更方案生效日期。
                  </div>
                </>
              )
              : (
                <div className={useStyles.content}>
                  在 App 平台上訂閱，可以點擊下方
                  <span className={useStyles.bolder}>
                    「訂閱」
                  </span>
                  圖示，進入訂閱管理頁面，以檢視當前方案到期日，與變更方案生效日期。
                </div>
              )
          }
        </div>
      </FAQCard>
      <FAQCard title="更換方案" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          {
            props.isMobile
              ? (
                <div className={useStyles.content}>
                  訂閱會員會在當期方案到期後隔日自動扣款，為你續訂下一期服務，在當期方案到期日之前，可隨時至
                  <span className={clsx(useStyles.bolder, useStyles.underline)}>
                    「訂閱方案頁」
                  </span>
                  變更續訂方案，待原方案的會員到期後才會啟用新方案並收取新方案費用。
                </div>
              )
              : (
                <div className={useStyles.content}>
                  訂閱會員會在當期方案到期後隔日自動扣款，為您續訂下一期服務，在當期方案到期日之前，可至
                  <span className={clsx(useStyles.bolder, useStyles.underline)}>
                    「訂閱方案頁」
                  </span>
                  隨時更換您想要使用的方案，待原方案的會員到期後才會啟用新方案並收取新方案費用。
                </div>
              )
          }
        </div>

      </FAQCard>
      <FAQCard title="加入訂閱會員有哪些付款方式" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          {
            !props.isMobile
            && (
              <>
                <div className={clsx(useStyles.content)}>
                  目前提供
                  <span className={clsx(useStyles.bolder)}>
                    「網站訂閱」
                  </span>
                  與
                  <span className={clsx(useStyles.bolder)}>
                    「App 訂閱」
                  </span>
                  兩種方式購買訂閱會員。其中 Google Play 和 App store 可以綁定信用卡或者電信帳單進行付費，您可以根據自身需求進行選擇。
                </div>
                <div className={clsx(useStyles.content, useStyles.bolder)}>
                  ▎  網站訂閱
                </div>
                <div className={useStyles.content}>
                  支援線上刷卡（接受 Visa／Master／JCB／AE 等發卡組織），同時接受海外信用卡。
                </div>
                <div className={clsx(useStyles.content, useStyles.bolder)}>
                  ▎  App 訂閱
                </div>
              </>
            )
          }
          <div className={useStyles.content}>
            依照不同裝置系統，可分別透過 Google Play / App store 訂閱（IAP），並直接透過平台系統綁定的付款方式扣款。
          </div>
        </div>
      </FAQCard>

      <FAQCard title="付款週期" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          {
            !props.isMobile
              ? (
                <>
                  <div className={clsx(useStyles.content)}>
                    <span className={useStyles.bolder}>
                      分為「月訂閱」和「年訂閱」兩種會員方案。訂閱服務會在每個計費週期開始前自動續約
                    </span>
                    ，並依據你所選定的付款方式，就該計費週期收取費用。（如果選擇月訂閱制，將每月續約扣款，如果是年訂閱制，將每年計費）
                  </div>
                  <div className={clsx(useStyles.content, useStyles.bolder)}>
                    收費週期說明如下：
                  </div>
                  <div className={clsx(useStyles.content, useStyles.bolder)}>
                    ▎  月訂閱
                  </div>
                  <div className={useStyles.content}>
                    首次加入訂閱會員需綁定扣款信用卡，並以 30 天為週期，第 31 天將自動進行續訂扣款，請款失敗後每 6 小時後會再次請款，請滿三次後就不再請款，並於第 31 天23:59後訂閱失效。
                  </div>
                  <div className={useStyles.content}>
                    例如：12/1 首次扣款訂閱，12/31 將扣除第二次款項並自動續訂（若請款未成功會員資格則於 1/1 00:00 失效），以此類推。
                  </div>
                  <div className={clsx(useStyles.content, useStyles.bolder)}>
                    ▎  年訂閱
                  </div>
                  <div className={useStyles.content}>
                    首次加入訂閱會員需綁定扣款信用卡，並以 365 天為週期，第 366 天將自動進行續訂扣款，請款失敗後每 6 小時後會再次請款，請滿三次後就不再請款，並於第 366 天 23:59 後失效。
                  </div>
                  <div className={useStyles.content}>
                    • 當期會員週期結束前可任意變更訂閱方案。提前變更方案，將於當前方案到期後，自新方案生效首日，進行首次扣款。
                  </div>
                </>
              )
              : (
                <div className={clsx(useStyles.content)}>
                  Google play / App store 系統以30天為週期，在當期訂閱到期日24小時前將會自動扣款，若請款失敗會於6小時後再請款，未成功滿三次後，訂閱會於第31天23:59後失效。
                </div>
              )
          }
        </div>
      </FAQCard>
      <FAQCard title="更新付款資訊" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          {
            !props.isMobile
            && (
              <>
                <div className={clsx(useStyles.content, useStyles.bolder)}>
                  ▎  網站
                </div>
                <div className={clsx(useStyles.content)}>
                  登入會員帳號後，至
                  <span className={useStyles.bolder}>
                    「我的帳戶」→「訂閱管理」
                  </span>
                  滑動至「付款明細」，點選
                  <span className={useStyles.bolder}>
                    「更換綁定信用卡」
                  </span>
                  ，即可更新信用卡資訊。
                </div>
                <div className={useStyles.content}>
                  •  請留意信用卡效期，若到期換卡則需要重新綁定。
                </div>
                <div className={clsx(useStyles.content, useStyles.bolder)}>
                  ▎  App
                </div>
              </>
            )
          }
          <div className={useStyles.content}>
            若透過 App 訂閱（Google Play / App store），請按下列個別裝置系統操作。
          </div>
          <div className={useStyles.content}>
            <p>
              •  Google Play 支援：
              <a href="https://support.google.com/googleplay/answer/4646404?co=GENIE.Platform%3DAndroid&hl=zh-Hant" target="_blank" rel="noreferrer" className={useStyles.aLink}>
                如何新增、移除或編輯 Google Play 付款方式
              </a>
            </p>
            <p>
              •  App store 支援：
              <a href="https://support.apple.com/zh-tw/HT213276" target="_blank" rel="noreferrer" className={useStyles.aLink}>
                如果你需要更改或更新 Apple ID 付款方式
              </a>
            </p>
          </div>
        </div>
      </FAQCard>

      <FAQCard title="取消訂閱" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          {
            !props.isMobile
            && (
              <>
                <div className={useStyles.content}>
                  如需取消後續訂閱服務，
                  <span className={clsx(useStyles.bolder)}>
                    可以在本期訂閱到期日 / 續訂扣款日至少 24 小時前至原購買平台（網站／App）取消訂閱。
                  </span>
                  取消訂閱後，仍可享有會員權益至該期效期結束。
                </div>
                <div className={useStyles.content}>
                  （如果您選擇的是月訂閱制，即需要於每月訂閱到期日前24小時取消。如果您選擇的是年訂閱制，即年度訂閱到期日前24小時取消）
                </div>
                <br />
                <div className={useStyles.content}>
                  你可以依據以下方式進行取消訂閱：
                </div>
                <div className={clsx(useStyles.content, useStyles.bolder)}>
                  ▎  網站
                </div>
                <div className={useStyles.content}>
                  <p>
                    ⑴ 登入您購買訂閱服務的會員帳號
                  </p>
                  <p>
                    ⑵ 點擊右上角頭像至
                    <span className={clsx(useStyles.bolder)}>
                      「我的帳戶」
                    </span>
                    →點擊進入到
                    <span className={clsx(useStyles.bolder)}>
                      「訂閱管理」
                    </span>
                    ，即可管理你的續費狀態
                  </p>
                  <p>
                    ⑶ 確認並點選
                    <span className={clsx(useStyles.bolder)}>
                      「取消訂閱」
                    </span>
                    ，即可解除訂閱會員資格，取消方案後，當期訂閱方案將於到期日後中止，並停止自動扣款。
                  </p>
                </div>
                <div className={useStyles.content}>
                  •  若需重啟訂閱會員資格，回到「訂閱管理」點選「重新訂閱」，即會恢復訂閱會員資格，並重新開始到期自動續訂。
                </div>
                <br />
                <div className={clsx(useStyles.content, useStyles.bolder)}>
                  ▎  App
                </div>
              </>
            )
          }
          <div className={useStyles.content}>
            在當期訂閱到期日24小時前，手動至 Google Play / App store 設置管理中關閉自動續訂功能，操作位置如下：
          </div>
          <br />
          {/* IOS */}
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            •  取消／管理 Apple 訂閱項目：
          </div>
          <div className={useStyles.content}>
            <p>
              ⑴ 請在iOS的裝置中，打開
              <span className={clsx(useStyles.bolder)}>
                「設定」
              </span>
            </p>
            <p>
              ⑵ 點一下你的姓名
              <span className={clsx(useStyles.bolder)}>
                「頭像」
              </span>
            </p>
            <p>
              ⑶ 點擊
              <span className={clsx(useStyles.bolder)}>
                「訂閱項目」
              </span>
            </p>
            <p>
              ⑷ 點擊要取消的訂閱項目（sandshour 訂閱方案）
            </p>
            <p>
              ⑸ 確認並點選
              <span className={clsx(useStyles.bolder)}>
                「取消訂閱」
              </span>
              ，即可解除訂閱會員資格，取消方案後，當期訂閱方案將於到期日後中止，並停止自動扣款。
            </p>
          </div>
          <br />
          <div className={clsx(useStyles.content)}>
            ⚠︎ App store 支援：
            <a href="https://support.apple.com/zh-tw/HT202039" target="_blank" rel="noreferrer" className={useStyles.aLink}>
              如果想要取消 Apple 提供的訂閱項目
            </a>
          </div>
          <br />
          <div className={clsx(useStyles.content)}>
            <span className={useStyles.bolder}>
              •  若你在 iOS 的「訂閱項目」中查詢不到相關訂閱資料，
            </span>
            請先確認：
          </div>
          <div className={clsx(useStyles.content)}>
            <p>
              ⑴ 當初訂閱購買平台是否為 App store
            </p>
            <p>
              ⑵ 是否擁有多個 Apple ID 帳號，若有，請於設定中切換其他 Apple ID 帳號，並再於「訂閱項目」中嘗試確認
            </p>
          </div>

          {/* android */}
          <br />
          <div className={clsx(useStyles.content, useStyles.bolder)}>
            •  取消／管理 Google Play 訂閱項目：
          </div>
          <div className={useStyles.content}>
            <p>
              ⑴ 打開手機上的
              <span className={clsx(useStyles.bolder)}>
                「Play 商店」
              </span>
              應用程式
            </p>
            <p>
              ⑵ 點擊右上方的
              <span className={clsx(useStyles.bolder)}>
                「個人圖示」
              </span>
            </p>
            <p>
              ⑶ 點擊
              <span className={clsx(useStyles.bolder)}>
                「付款與訂閱」- 「訂閱」
              </span>
            </p>
            <p>
              ⑷ 找到要取消的訂閱項目（sandshour 訂閱方案）

            </p>
            <p>
              ⑸ 點擊
              <span className={clsx(useStyles.bolder)}>
                「管理」
              </span>
              （可查看目前訂閱方案內容）
            </p>
            <p>
              ⑹ 確認並點選
              <span className={clsx(useStyles.bolder)}>
                「取消」
              </span>
              ，即可解除訂閱會員資格，取消方案後，當期訂閱方案將於到期日後中止，並停止自動扣款
            </p>
          </div>
          <br />
          <div className={clsx(useStyles.content)}>
            ⚠︎ 前往 Google Play 取消訂閱：
            <a href="https://support.google.com/googleplay/workflow/9827184?sjid=9170809052724216159-AP&visit_id=638320936896066833-4144026303&p=cancelsubsawf&rd=1" target="_blank" rel="noreferrer" className={useStyles.aLink}>
              取消 Google Play 訂閱項目
            </a>
          </div>
          <br />
          <div className={clsx(useStyles.content)}>
            <span className={useStyles.bolder}>
              •  若你在 Play 商店的「訂閱」中查詢不到相關訂閱資料，
            </span>
            請先確認：
          </div>
          <div className={clsx(useStyles.content)}>
            <p>
              ⑴ 當初訂閱購買平台是否為 Google Play
            </p>
            <p>
              ⑵ 是否擁有多個 Google（Gmail） 帳號，可先切換其他 Google 帳號，並再次於「訂閱」中確認）
            </p>
          </div>
        </div>
      </FAQCard>
      <FAQCard title="付款失敗" isLanding={props.isLanding}>
        <div className={useStyles.contentContainer}>
          <div className={useStyles.content}>
            如果您的週期性訂閱遭遇付款失敗時，代表目前的付款方式可能出現了某些問題：
          </div>
          <div className={useStyles.content}>
            您可以先確認付款方式是否仍然有效，請進行以下步驟：
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            檢查信用卡的有效日期，確認您的信用卡資訊符合現況，並重新驗證信用卡資訊，然後再試一次
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            確保帳戶額度足以支付扣款費用
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            與您的銀行或發卡銀行聯絡
          </div>
          <div className={useStyles.listItem}>
            <div className={useStyles.listDot} />
            改用其他付款方式付費
          </div>
          <div className={useStyles.content}>
            如果您的付款方式已無法繼續使用，可以隨時登入網站更新付款資訊
          </div>
        </div>
      </FAQCard>
    </>
  );

};

export default Subscriptions;
