import dayjs from 'dayjs';

export default class PlayerChapter {
  static fromRes(data) {
    return new PlayerChapter(data);
  }

  constructor(data) {
    this.init(data);
  }

  init(data) {
    // 單集 id.
    this.id = data.id;

    // 課程 id.
    this.cid = data.cid;

    // 單集類型．
    this.type = data.type;

    // 單集名稱.
    this.name = data.name;

    // 單集影片長度.
    this.duration = data.duration;
    // 秒刪除小數, 無條件進位.
    this.ceilDuration = Math.ceil(data.duration);

    // 是否免費.
    this.isTrial = data.isTrial;

    // 等待補充.
    this.priority = data.priority;

    // 等待補充.
    this.summary = data.summary;

    // 是否有文稿.
    this.manuscript = data.manuscript;

    // 等待補充.
    this.popularity = data.popularity;

    // 等待補充.
    this.createdAt = data.createdAt;

    // 等待補充.
    this.updatedAt = data.updatedAt;

    // 是否收藏.
    this.isFavorite = data.isFavorite;

    this.lastAt = data.lastAt;

    this.document = data.document;
  }
}
