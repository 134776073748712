import { getHost, request } from './utils';

// 登入
export const login = ({ email, password }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/login`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email,
      password,
      type: 'ClientLogin'
    }
  };
  return request(options);
};

// 登出.
export const logout = (token) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/logout`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// 註冊.
export const signup = ({ email, password }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/signup`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email,
      password,
      type: 'ClientLogin'
    }
  };

  return request(options);
};

// 自動登入.
export const profile = (token) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/me/profile`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

// 忘記密碼.
export const forgotPassword = ({ email }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/forgot-password`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email
    }
  };

  return request(options);
};

// 重置密碼.
export const resetPassword = ({ email, password, code }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/reset-password`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email,
      password,
      code
    }
  };

  return request(options);
};

// 註冊時驗證 email.
export const emailAuth = ({ email }) => {
  const options = {
    method: 'post',

    url: `${getHost()}/api/v1/user/email-auth`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },

    data: {
      email
    }
  };

  return request(options);
};

export const checkAvailable = (token) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/user/me/check`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      auth_token: token
    }
  };

  return request(options);
};

export const isCouponOn = () => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/config`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  return request(options);
};

export const checkYearSubscription = () => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/feature`,

    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  return request(options);
};
