import i18n from './i18n';

export const AUTH_TOKEN_KEY = 'webtoken';

export const TAPPAY_CONFIG_PRO = {
  appID: 124345,
  appKey: 'app_OQbA5bgrBBloL4OqgOau87urNfdfncW054qEf4Fku13NAX9GnsUVHSulb8VS',
  serverType: 'production'
};

export const TAPPAY_CONFIG_DEV = {
  appID: 124345,
  appKey: 'app_OQbA5bgrBBloL4OqgOau87urNfdfncW054qEf4Fku13NAX9GnsUVHSulb8VS',
  serverType: 'sandbox'
};

export const COURSE_CATEGORY = {
  video: 'video',
  audio: 'audio',
  audiobook: 'audiobook'
};

export const COURSE_CATEGORY_TW = {
  video: i18n.t('btn_video_entrance'),
  audio: i18n.t('btn_audio_entrance'),
  audiobook: i18n.t('btn_audiobook_entrance'),
  chapter: i18n.t('search_episode_title')
};

export const LIST_LIMIT_PER_CALL = {
  searchPage: 4,
  courseList: 16,
  chapterList: 20,
  commentAndRating: 20
};

export const PRIVATE_POLICY_CONTENT_TYPE = {
  default: 'default',
  list: 'list',
  orderList: 'orderList',
  toggle: 'toggle',
  mixin: 'mixin'
};

export const COUPON_TICKET_TYPE = {
  PointDiscount: 'PointDiscount',
  SubscriptionDiscount: 'SubscriptionDiscount',
  YearSubDiscount: 'YearSubDiscount',
  MonthSubDiscount: 'MonthSubDiscount'
};

export const SOCIAL_LINK = {
  sandsbook: 'https://www.sandsbook.com/',
  facebook: 'https://sandshour.pse.is/66jead',
  instagram: 'https://www.instagram.com/sandshour.sandsbook/',
  youtube: 'https://www.youtube.com/channel/UCha6cGwTseoxNKAcFiOB8gQ',
  appstore: 'https://apps.apple.com/tw/app/sandshour-%E5%BD%B1%E9%9F%B3%E5%85%A7%E5%AE%B9%E8%A8%82%E9%96%B1%E5%B9%B3%E5%8F%B0/id1611532905',
  googleplay: 'https://play.google.com/store/apps/details?id=com.sandshour'
};

export const SUBSCRIPTION_PRICE = {
  MonthlyPerMonth: 300,
  MonthlyPerMonthNew: 400,
  YearlyOrigin: 4800,
  Yearly: 3600,
  FirstYearly: 3200,
  UpgradeYearly: 2800
};

export const SUBSCRIPTION_TYPE_DESC = {
  Monthly: i18n.t('payment_vip_monthly'),
  FirstYearly: i18n.t('payment_vip_yearly'),
  Yearly: i18n.t('payment_vip_yearly'),
  Free7: i18n.t('profile_subscription_title_free7')
};

export const SUBSCRIPTION_TYPE = {
  FreeTrial: 'FreeTrial',
  MonthlyRegular: 'MonthlyRegular',
  YearlyRegular: 'YearlyRegular',
  YearlyDiscount: 'YearlyDiscount',
  YearlyUpgrade: 'YearlyUpgrade'
};

export const SUBSCRIPTION_SOURCE_TYPE = {
  Redeem: 'Redeem',
  Internal: 'Internal',
  IOS: 'IOS',
  Android: 'Android',
  Web: 'Web'
};

export const SUBSCRIPTION_ITEM = {
  Monthly: 'Monthly',
  Free7: 'Free7',
  Yearly: 'Yearly',
  FirstYearly: 'FirstYearly',
  UpgradeYearly: 'UpgradeYearly',
  DowngradeMonthly: 'DowngradeMonthly'
};

export const SUBSCRIPTION_ITEM_PRICE = {
  Monthly: 300,
  Free7: 0,
  Yearly: 3600,
  FirstYearly: 3200,
  UpgradeYearly: 2800,
  DowngradeMonthly: 300
};

export const SUBSCRIPTION_ITEM_PRICE_NEW = {
  Monthly: 400,
  Free7: 0,
  Yearly: 3600,
  FirstYearly: 3200,
  UpgradeYearly: 2800,
  DowngradeMonthly: 300
};

// > NOTE: 除了第一層以外的 都要有: id type title content
export const PRIVATE_POLICY = [
  {
    id: 1,
    title: '隱私權政策',
    titleSize: 30,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '歡迎使用由內容變現股份公司 Content monetization Co.,Ltd.（以下稱「本公司」，或簡稱「我們」）負責營運之 sandshour 散時間網站（http://www.sandshour.com） 與 sandshour App（以下合稱「sandshour 平台」)。我們相當重視您的隱私權保護，將依個人資料保護法及隱私權政策蒐集、處理及利用您的資料，並提供您對個人資料權利之行使與保護。當您進入 sandshour 平台使用本服務時，即表示您已閱讀、瞭解並同意接受此隱私權政策中描述的行為。\n\n為了讓您能夠安心的使用sandshour 平台的各項服務與資訊，特此向您說明我們的隱私權保護政策，以保障您的權益，請您詳閱下列內容：'
      }
    ]
  },
  {
    id: 2,
    title: '隱私權保護政策的適用範圍',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'list',
        title: '',
        listContent: [
          {
            id: 1,
            order: 1,
            type: 'default',
            title: '',
            content: '請您於使用 sandshour 平台服務（以下稱「本服務」）前，確認您已審閱並同意本隱私權政策所列全部條款，若您不同意全部或部份者，則請勿使用本服務。'
          },
          {
            id: 2,
            order: 2,
            type: 'default',
            title: '',
            content: '本隱私權政策僅適用於本服務對您個人資料所為蒐集、處理與利用，不及於其他非本公司所有或控制之其他公司或個人。'
          },
          {
            id: 3,
            order: 3,
            type: 'default',
            title: '',
            content: '隱私權保護政策不適用於本服務以外的相關連結網站 ，也不適用於非本服務所委託或參與管理的人員。若經由本服務連結至第三人所經營之網站，各該網站所為之個人資料蒐集係依其網站之隱私權政策規定處理，與本公司無涉。'
          }
        ]
      }
    ]
  },
  {
    id: 3,
    title: '個人資料的蒐集、處理及利用方式',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: 'sandshour 平台所蒐集之個人資料將用於提供服務，維持網站、App營運，及提供技術支援和資安保護。'
      },
      {
        id: 2,
        type: 'toggle',
        title: '我們如何收集資訊',
        content: [
          {
            id: 1,
            type: 'list',
            title: '',
            listContent: [
              {
                id: 1,
                order: 1,
                title: '',
                type: 'mixin',
                content: [
                  {
                    id: 1,
                    type: 'default',
                    title: '',
                    content: 'sandshour 平台所蒐集之個人資料將用於提供服務，維持網站、App營運，及提供技術支援和資安保護。'
                  },
                  {
                    id: 2,
                    type: 'orderList',
                    title: '',
                    listContent: [
                      {
                        id: 1,
                        order: 1,
                        type: 'default',
                        title: '',
                        content: '個人辨識：如姓名、地址、電話、電子郵件等資訊。'
                      },
                      {
                        id: 2,
                        order: 2,
                        type: 'default',
                        title: '',
                        content: '個人描述：如性別、出生年月日等資訊。'
                      },
                      {
                        id: 3,
                        order: 3,
                        type: 'default',
                        title: '',
                        content: '財務辨識：如信用卡或金融機構帳戶等資訊。'
                      }
                    ]
                  },
                  {
                    id: 3,
                    type: 'default',
                    title: '',
                    content: '及其他得以直接或間接識別使用者身分之個人資料。並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。'
                  }
                ]
              },
              {
                id: 2,
                order: 2,
                type: 'default',
                title: '',
                content: '於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的 IP 位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站與相關服務的參考依據，此記錄為內部應用，絕不對外公佈。'
              },
              {
                id: 3,
                order: 3,
                type: 'default',
                title: '',
                content: '為遵守第三方應用程式的隱私權政策及廣告宣傳規範，經您授權所蒐集的個人資料，將僅用於協助 sandshour 提供服務給您。sandshour 在您使用第三方登錄時，僅蒐集並使用您的 E-mail 帳號與姓名資訊，以提供客制化的內容及服務。'
              },
              {
                id: 4,
                order: 4,
                type: 'default',
                title: '',
                content: '您可以隨時向我們提出請求，以更正或刪除本服務所蒐集您錯誤或不完整的個人資料。'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 4,
    title: '資料之保護',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'list',
        title: '',
        listContent: [
          {
            id: 1,
            order: 1,
            type: 'default',
            title: '',
            content: '本服務主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料。並採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。'
          },
          {
            id: 2,
            order: 2,
            type: 'default',
            title: '',
            content: '如因業務需要有必要委託其他單位提供服務時，本服務亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。'
          }
        ]
      }
    ]
  },
  {
    id: 5,
    title: 'sandshour 平台對外的相關連結',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '本服務提供之其他網站的網路連結，您也可經由本服務所提供的連結，點選進入其他網站。但該連結網站不適用 sandshour 平台的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。'
      }
    ]
  },
  {
    id: 6,
    title: '與第三人共用個人資料之政策',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '我們對您的資訊承擔保密義務，本服務絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。\n\n前項但書之情形包括不限於：'
      },
      {
        id: 2,
        type: 'list',
        title: '',
        listContent: [
          {
            id: 1,
            order: 1,
            type: 'default',
            title: '',
            content: '經由您書面同意。'
          },
          {
            id: 2,
            order: 2,
            type: 'default',
            title: '',
            content: '法律明文規定。'
          },
          {
            id: 3,
            order: 3,
            type: 'default',
            title: '',
            content: '為免除您生命、身體、自由或財產上之危險。'
          },
          {
            id: 4,
            order: 4,
            type: 'default',
            title: '',
            content: '與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。'
          },
          {
            id: 5,
            order: 5,
            type: 'default',
            title: '',
            content: '當您在平台的行為，違反我們的服務條款或可能損害或妨礙 sandshour 平台權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。'
          },
          {
            id: 6,
            order: 6,
            type: 'default',
            title: '',
            content: '有利於您的權益。'
          },
          {
            id: 7,
            order: 7,
            type: 'default',
            title: '',
            content: '本服務委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。'
          }
        ]
      }
    ]
  },
  {
    id: 7,
    title: ' Cookie 技術',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '為便於日後辨識，提供您更輕鬆的使用體驗，本服務可能會在您的電腦上設定與存取 Cookie。您可以透過設定您的個人電腦或上網設備，決定是否允許 Cookie 技術的使用。若您關閉 Cookie時，可能會造成您使用本服務時之不便利或部分功能限制。'
      }
    ]
  },
  {
    id: 8,
    title: ' 分享之資訊',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '本服務可讓您公開分享 sandshour 平台部分內容資訊，當您使用這些功能時，您同意且明白您所張貼的內容將會被公眾所閱覽。若您不同意該等訊息之發布，請您勿使用相關功能，或於事後透過各該社群服務之會員機制，移除該等資訊或拒絕該網站繼續發布相關訊息。若有任何問題，仍可與我們聯絡，我們將協助您確認、處理相關問題。\n\n除依法應提供予司法、檢調機關、相關主管機關，或與本公司協力廠商為執行相關活動必要範圍之利用外，本公司將不會任意將您的個人資料提供予第三人。'
      }
    ]
  },
  {
    id: 9,
    title: '保密與安全性',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '為保障您的資訊安全，我們努力採取各種合理的安全措施來保護您的資訊，以防資訊的洩漏、毀損或者丟失。請您不要任意將個人帳號、密碼提供予第三人或允許第三人以您的個人資料申請帳號、密碼，否則，相關責任由您自行負擔。若您的個人資訊有外洩之虞，請您立即更改密碼，或通知我們暫停該帳號（我們可能會要求核對您的個人資料）。'
      }
    ]
  },
  {
    id: 10,
    title: '未成年人保護',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '本服務並非特別為未成年人/兒童設計，未成年人使用本網站時，若同意本網站蒐集、利用其個人資訊時，應在法定代理人或監護人之同意下為之。法定代理人或監護人得隨時請求本公司停止特定帳號及其相關之個人資料之蒐集、處理及利用行為。'
      }
    ]
  },
  {
    id: 11,
    title: '隱私權保護政策之修正',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '本服務隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。'
      }
    ]
  }
];


export const TERM_OF_USE = [
  {
    id: 1,
    title: 'sandshour 使用者服務條款',
    titleSize: 30,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '歡迎您使用由內容變現股份有限公司 Content monetization Co.,Ltd.（以下稱本公司，或「我們」）經營及建置之 sandshour 網站：（http://www.sandshour.com） 與 sandshour App（以下合稱「sandshour 平台」），我們重視每一個會員所享有的服務，特此說明本約定平台使用政策，以保障您的權益，請您詳細參閱本使用條款內容：'
      }
    ]
  },
  {
    id: 2,
    title: '認知與同意條款',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '本條款適用於所有連結至 sandshour 平台服務（以下稱「本服務」） 之使用者與訪客，使用我們提供之服務，即表示您已知悉且同意本條款，若您不同意本服務或部分條款的內容，您應立即停止使用本服務。\n\n如您尚未年滿二十歲，在您加入會員前，請將本服務條款交由您的法定代理人（如父母、輔助人或監護人）閱讀，並得到其同意，才可註冊及使用本服務。當您開始使用或繼續使用本服務時，則表示您的法定代理人（如父母、輔助人或監護人）已經閱讀、了解並同意本服務條款及其後修改變更。\n\n以下所稱之「您」或「會員」，均係指註冊及使用本服務之使用者 ：'
      }
    ]
  },
  {
    id: 3,
    title: '條款更新',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '本公司有權於法律准許的範圍內，於任何時間修改或變更本使用條款之內容，您可以定期查看以瞭解您當前的權利及義務。若您於 sandshour 平台為任何修改或變更本使用條款後仍繼續使用本服務，視為您已同意接受本使用條款之修改及變更。'
      }
    ]
  },
  {
    id: 4,
    title: '名詞定義與服務說明',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'toggle',
        title: '名詞定義',
        content: [
          {
            id: 1,
            type: 'orderList',
            title: '',
            listContent: [
              {
                id: 1,
                order: 1,
                type: 'default',
                title: '',
                content: '一般訪客：是指尚未註冊成為 sandshour 平台會員，僅有基本瀏覽功能之身分者。'
              },
              {
                id: 2,
                order: 2,
                type: 'default',
                title: '',
                content: 'sandshour 平台會員：完成 sandshour 平台註冊流程並通過認證，具有會員身分者。'
              },
              {
                id: 3,
                order: 3,
                type: 'default',
                title: '',
                content: '單次購買者：是指以單次付費購買之方式，於 sandshour 平台消費任一產品之會員。'
              },
              {
                id: 4,
                order: 4,
                type: 'default',
                title: '',
                content: '訂閱者：是指以每個月固定連續型扣款之方式，使用 sandshour 平台訂閱方案之會員。'
              }
            ]
          }
        ]
      },
      {
        id: 2,
        type: 'toggle',
        title: '服務概要',
        content: [
          {
            id: 1,
            type: 'default',
            title: '',
            content: '本平台提供錄製影音及有聲教學創作內容（包括但不限於文字、照片、圖像、音訊、影片、評論或其任何素材等），為跨地域及時間之學習平台，平台上刊登之相關內容，可供平台用戶瀏覽、訂閱或購買，並為平台用戶提供回饋交流之功能或服務。'
          }
        ]
      }
    ]
  },
  {
    id: 5,
    title: '會員帳號、密碼及安全',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '一般訪客可以任意瀏覽 sandshour 平台上的內容，但只有完成註冊流程，通過驗證之平台會員．於登入會員帳號後，始得購買平台上刊登之內容及享受本平台提供的各項服務。一般訪客可透過本平台所定之方式（包括但不限於信箱登入、Google登入或Apple ID登入等）輕鬆註冊建立會員帳號。\n\n一般訪客需使用本平台設定方式進行註冊作業，並完成驗證程序。您必須擔保在註冊及使用本服務過程中所提供予本公司的資料、以及所留存的資料，均為完整、正確。如無法真實提供所需之會員資料者，即視為違反本服務條款，本公司有權隨時拒絕對您提供本服務之一部或全部，且本公司不因此而承擔任何責任。\n\n對於會員取得使用的帳號和密碼，必須自行負妥善保管和保密的義務，不得以有償或無償之方式透露或提供予第三人登錄或使用，任何經由該組帳號及密碼所為之全部行為，應由註冊該帳號之會員負其責任。\n\n會員可隨時於 sandshour 平台上更新其個人資料，亦有權利通知本公司要求註銷其會員帳號。'
      }
    ]
  },
  {
    id: 6,
    title: '使用規定與條約',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '會員在 sandshour 平台以訂閱或零售方式購買之付費內容，僅在約定期限內供單一購買會員使用觀看。禁止重製、公開傳輸、轉售、公開播放、分享。如因此造成本公司或第三人之權益受到任何損失或損害，應由該平台用戶自行負擔所有因此侵權行為而生之法律責任。\n\n會員在使用 sandshour 平台服務時，應遵守中華民國相關法律法規的規定，且不會利用本服務進行任何非法之行為或從事不正當的活動，包括但不限於：'
      },
      {
        id: 2,
        type: 'list',
        title: '',
        listContent: [
          {
            id: 1,
            order: 1,
            type: 'default',
            title: '',
            content: '上載、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上。'
          },
          {
            id: 2,
            order: 2,
            type: 'default',
            title: '',
            content: '侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。'
          },
          {
            id: 3,
            order: 3,
            type: 'default',
            title: '',
            content: '違反依法律或契約所應負之保密義務。'
          },
          {
            id: 4,
            order: 4,
            type: 'default',
            title: '',
            content: '冒用他人名義使用本服務。'
          },
          {
            id: 5,
            order: 5,
            type: 'default',
            title: '',
            content: '上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料。'
          },
          {
            id: 6,
            order: 6,
            type: 'default',
            title: '',
            content: '從事不法交易行為或張貼虛假不實、引人犯罪之訊息。'
          },
          {
            id: 7,
            order: 7,
            type: 'default',
            title: '',
            content: '販賣槍枝、毒品、禁藥、盜版軟體或其他違禁物。'
          },
          {
            id: 8,
            order: 8,
            type: 'default',
            title: '',
            content: '提供賭博資訊或以任何方式引誘他人參與賭博。'
          },
          {
            id: 9,
            order: 9,
            type: 'default',
            title: '',
            content: '濫發廣告訊息、垃圾訊息、連鎖信、違法之多層次傳銷訊息等。'
          },
          {
            id: 10,
            order: 10,
            type: 'default',
            title: '',
            content: '以任何方法傷害未成年人。'
          },
          {
            id: 11,
            order: 11,
            type: 'default',
            title: '',
            content: '偽造訊息來源或以任何方式干擾傳輸來源之認定。'
          },
          {
            id: 12,
            order: 12,
            type: 'default',
            title: '',
            content: '干擾或中斷本服務或伺服器或連結本服務之網路，或不遵守連結至本服務之相關需求、程序、政策或規則等，包括但不限於：使用任何設備、軟體或刻意規避 sandshour 之排除自動搜尋之標頭 ( robot exclusion headers )。'
          },
          {
            id: 13,
            order: 13,
            type: 'default',
            title: '',
            content: '對於恐怖行動提供任何實質支持或資源。'
          },
          {
            id: 14,
            order: 14,
            type: 'default',
            title: '',
            content: '追蹤他人或其他干擾他人或為前述目前蒐集或儲存他人之個人資訊。'
          },
          {
            id: 15,
            order: 15,
            type: 'default',
            title: '',
            content: '其他 sandshour 有正當理由認為不適當之行為。'
          }
        ]
      }
    ]
  },
  {
    id: 7,
    title: '內容訂閱提供與使用',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '本公司使用的金流服務屬第三方服務，如 sandshour 平台會員使用該金流服務進行線上內容訂閱，即視為已認知並同意內容訂閱條款內容。\n\n訂閱者可於 sandshour 平台選購訂閱方案，並依據其訂閱方式，於使用期間內享有線上觀看所有訂閱服務之影音內容。訂閱者所選購之方案，其僅為本公司授權訂閱者之訂閱服務使用權，亦即訂閱者僅得透過 sandshour 平台進行收聽、觀看該付費訂閱方案，除該付費訂閱方案另有約定外，訂閱者不得因付費訂閱而取得線上內容所有權，一旦取消訂閱即無法再閱讀、收看其訂閱服務內之方案內容。\n\n在法律允許範圍內及除內容訂閱條款另有約定者外，因內容供應商、硬體商、第三方服務商及其他使用者的故意或過失所造成內容訂閱用戶的任何瑕疵與損害，本公司不負擔保與賠償之責。\n\n訂閱者可利用下列方式訂閱本服務（依配合服務所實際提出訂閱方式之時程而定）。'
      },
      {
        id: 2,
        type: 'list',
        title: '',
        listContent: [
          {
            id: 1,
            order: 1,
            type: 'default',
            title: 'IAP 訂閱（In-App Purchase）：',
            content: '使用 sandshour App 的用戶可以透過綁定於行動裝置系統平台所認定有效之付款方式訂閱本服務。IAP 訂閱為「自動續訂制」，亦即，用戶付款訂閱本服務成為內容訂閱用戶後，除非在次扣款日前 24 小時前 （實際依行動裝置系統平台公司之規定決定）於行動裝置系統之訂閱項目中按鍵取消訂閱，否則將視為同意繼續訂閱本服務。\n\n取消訂閱時，可使用本服務之期間與費用將會計算至當次計費週期屆滿為止。使用 IAP 訂閱之內容訂閱用戶，因行動裝置系統平台公司規範，須自行依其行動裝置系統平台公司規範處理取消訂閱或退款事宜，如因此有生任何爭議，本公司並不負責。\n\n使用 sandshour 網站的用戶，將於每月定期自訂閱者所設定之信用卡進行扣款，除訂閱者自行取消訂閱外，訂閱者得於每月信用卡成功扣款後，於 sandshour 平台閱讀、收看所有線上訂閱影音內容。'
          },
          {
            id: 2,
            order: 2,
            type: 'default',
            title: '序號代碼兌換訂閱',
            content: '序號代碼為  sandshour 平台發行用於兌換訂閱服務方案，序號代碼為一連串英數組合，各個序號代碼均不相同且每帳號僅可兌換一次，用戶於取得序號後，即可至 sandshour 平台兌換專頁中輸入該序號代碼後完成兌換訂閱服務，且可於支援序號使用期間，享訂閱會員之訂閱服務使用權。\n\n序號代碼可能設有完成兌換之期限或單一用戶帳號兌換數量之限制，其以取得該序號代碼時所附說明為準。'
          }
        ]
      }
    ]
  },
  {
    id: 8,
    title: '智慧財產權聲明',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: 'sandshour 擁有相關平台服務刊載內容之所有權利，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網頁設計，均由本平台或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。\n\n非經 sandshour 或其他權利人書面同意，不得擅自使用、修改、複製、傳播、變更、散佈、發行或發表相關內容。如因違反有關法律、法規或本使用條款項下的任何條款而致使 sandshour 平台或任何其他第三方造成損失，您同意承擔所造成損害之賠償責任。'
      }
    ]
  },
  {
    id: 9,
    title: '廣告聲明',
    titleSize: 24,
    content: [
      {
        id: 1,
        type: 'default',
        title: '',
        content: '在本平台中瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，均由各該廣告商、產品與服務的供應商所設計與提出。您對於廣告之正確性與可信度應自行斟酌與判斷。本服務僅接受委託予以刊登，不對前述廣告負擔保責任。'
      }
    ]
  }
];
