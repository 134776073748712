import React from 'react';

import styles from './style.module.scss';

class LogoView extends React.Component {
  render() {
    return (
      <div className={styles.logoContainer}>
        <div className={styles.container}>
          <div className={styles.logo}>Logo</div>
        </div>

        <div className={styles.container}>
          <div className={styles.text}>
            讓散時間有意義，為自己積累改變的準備
          </div>
        </div>
      </div>
    );
  }
}

export default LogoView;
