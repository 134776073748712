import CourseCard from './CourseCard';

export default class Course {
  constructor(params) {
    const {
      anchor,
      list
    } = params;

    this.anchor = anchor;
    this.list = list.map((el) => new CourseCard(el));
  }

  static fromRes(data) {
    return new Course(data);
  }
}
