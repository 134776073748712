import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { Link } from 'react-router-dom';

import Logo from 'src/assets/account/mainLogo.png';
import AccountBg from 'src/assets/account/bg.png';
import CloseBtn from 'src/assets/account/closeBtn.svg';
import BackIcon from 'src/assets/account/back.svg';

import InputNameViewModel from './viewModel';
import LogoView from '../Logo';
import InputView from '../input';
import ButtonView from '../Button';
import styles from './style.module.scss';


@observer
class InputNameView extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InputNameViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    const { handleBack, handlerCloseAlert } = this.props;
    return (
      <div style={{ '--src': `url(${AccountBg})` }} className={`login2password ${clsx(styles.forgetPassword, styles.forgetPassword_layout)}`}>
        <div className={clsx(styles.contentContainer, styles.contentContainer_layout)}>
          <div className={styles.flexBox}>
            <div
              style={{ '--src': `url(${BackIcon})` }}
              className={clsx(styles.closeBtn, styles.closeBtn_layout)}
              onClick={this.vm.handlerPrev}
            />
            <div
              style={{ '--src': `url(${CloseBtn})` }}
              className={clsx(styles.closeBtn, styles.closeBtn_layout)}
              onClick={handlerCloseAlert}
            />
          </div>


          <div className={clsx(styles.contentFlex, styles.contentFlex_layout)}>
            <div style={{ '--src': `url(${Logo})` }} className={clsx(styles.mainLogo, styles.mainLogo_layout)} />
            <div className={clsx(styles.contentFlex_spacer)} />
            <div className={clsx(styles.inputsContainer, styles.inputsContainer_layout)}>
              <div className={`loginpassword ${clsx(styles.newPasswordComponent, styles.newPasswordComponent_layout)}`}>
                <div className={clsx(styles.componentContainer, styles.componentContainer_layout)}>
                  <h2 className={clsx(styles.componentTitle, styles.componentTitle_layout)}>
                    {i18n.t('signup_nickname_title')}
                  </h2>
                  <div className={clsx(styles.codeInputContainer, styles.codeInputContainer_layout)}>
                    <div className={clsx(styles.codeFlex, styles.codeFlex_layout)}>
                      <div className={clsx(styles.passwordFlex_item1)}>
                        <InputView
                          value={this.vm.name}
                          handlerChange={this.vm.setName}
                          onSubmit={this.vm.handlerNext}
                          valid={this.vm.validName}
                          placeholder={i18n.t('signup_nickname_title')}
                          inputStyle={clsx(styles.placeholder, styles.placeholder_layout)}
                          inputContainerStyles={styles.noBorder}
                          onBlur={this.vm.setOnCheck}
                        />
                      </div>
                    </div>

                    <hr className={clsx(styles.divider, styles.divider_layout)} />
                    {
                      (!this.vm.validEmail && this.vm.onCheck.email) && (
                        <div className={clsx(styles.errorAlert, styles.errorAlert_layout)}>
                          {i18n.t('signup_nickname_error')}
                        </div>
                      )
                    }
                  </div>

                  <div className={clsx(styles.componentContainer_item1)}>
                    <div
                      className={clsx(styles.submitBtn, styles.submitBtn_layout)}
                      onClick={this.vm.handlerNext}
                    >
                      <h5 className={clsx(styles.submitText, styles.submitText_layout)}>
                        {i18n.t('signup_next')}
                      </h5>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className={clsx(styles.contentFlex_spacer1)} />
            <Link to="/term-of-use" target="_blank">
              <div className={clsx(styles.policyBtns, styles.policyBtns_layout)}>
                {i18n.t('login_forget_password_protocol')}
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default InputNameView;
